import styled from 'styled-components/macro';

export const WrapDisclaimer = styled.div`
  margin-top: 20px;
`;

export const Text = styled.div`
  max-width: 220px;
  margin: 0 auto;
  text-align: center;
`;
export const SubTitle = styled.div`
  font-size: 14px;
  @media screen and (max-height: 625px) {
    font-size: 12px;
  }
`;
export const Title = styled.div`
  font-size: 18px;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  @media screen and (max-height: 625px) {
    font-size: 15px;
  }
`;
