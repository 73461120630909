import React from 'react';
import { ImagenLeft, ImagenRight } from './styles';
import SocialNetworks from '../SocialNetworks';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    footerData: state.footerState.footerData,
  };
};

const BannersSide = ({ footerData, className }) => {
  return (
    <>
      <ImagenLeft className={`ImagenLeft ${className || ''}`}>
        <figure>
          <img src='/assets/images/banner-left.svg' alt='' width={479} height={840} />
          <SocialNetworks data={footerData} className={`inBanners ${className || ''}`} />
        </figure>
      </ImagenLeft>
      <ImagenRight className='ImagenRight'>
        <img src='/assets/images/banner-right.svg' alt='' width={523} height={705} />
      </ImagenRight>
    </>
  );
};

// export default connect(mapStateToProps, mapDispatchToProps)(BannersSide);
export default connect(mapStateToProps)(BannersSide);
