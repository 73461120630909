import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';

export const Container = styled.div`
  max-width: 521px;
  width: 93%;
  margin: auto;
  padding-bottom: 50px;
  padding-top: 30px;
  position: relative;
  z-index: 10;
`;

export const Title = styled.div`
  max-width: 231px;
  width: 100%;
  margin: auto;
  h2 {
    margin: 0;
    font-family: ${fonts.lato800};
    font-size: 25px;
    line-height: 1;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    strong {
      font-weight: normal;
      color: #3aa229;
    }
  }
  span {
    font-family: ${fonts.lato800};
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fecf30;
    padding-top: 10px;
  }
`;
export const Text1 = styled.div`
  font-family: ${fonts.lato400};
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #717171;
  opacity: 0.9;
  padding-top: 63px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #3aa229;
    font-weight: bold;
  }
  strong {
    color: #3aa229;
  }
  @media screen and (max-width: 480px) {
    padding-top: 15px;
    max-width: 260px;
    margin: 0 auto;
  }
`;
