import styled from 'styled-components/macro';

export const WrapLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-secondary);
    z-index: 2;
    opacity: 0.1;
  }
`;
export const Loading = styled.div`
  width: 200px;
  position: relative;
  z-index: 3;
`;
