import React, { useCallback, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CntButton,
  Button,
  IconButton,
  IconButtonContainer,
  NoData,
} from '../theme/global';
import { Title } from '../components/Text';
import AccountListItem from '../components/AccountListItem';
import PlusIcon from '../components/PlusIcon';
import {
  getAccountsUser,
  getDeleteAccountBank,
  saveInfoCurrentAccountBank,
} from '../store/users';
import PopUp from '../components/Popup';
import Alert from '../components/Alert';
import { WrapPage } from '../theme/shared';
const BankAccounts = ({
  desktop,
  getAccountsUser,
  getDeleteAccountBank,
  saveInfoCurrentAccountBank,
  deleteAccountBank,
  accountsUser,
  history,
  user,
}) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const popupRef = useRef(null);
  const popupConfirmRef = useRef(null);

  const onAddCallback = useCallback(() => {
    history.push('/accounts/add-bank-account');
  }, [history]);
  const deleteAccount = (id) => {
    const dataAccount = {
      token: user.info.token,
      id_post: id,
    };
    getDeleteAccountBank(dataAccount);
    popupRef.current.close();
  };
  useEffect(() => {
    if (deleteAccountBank && deleteAccountBank.status === 200) {
      popupConfirmRef.current.open();
    }
  }, [deleteAccountBank]);
  return (
    <WrapPage className={`${desktop || ''} bank-accounts`}>
      <div>
        <Title>Cuentas Bancarias</Title>

        {accountsUser && accountsUser.list_account.length >= 1 ? (
          <>
            {accountsUser.list_account.map((v, key) => (
              <AccountListItem
                key={key}
                {...v}
                onDelete={() => {
                  setCurrentAccount(v);
                  popupRef.current.open();
                }}
                onEdit={() => {
                  saveInfoCurrentAccountBank(v);
                  history.push('/accounts/edit-bank-account');
                }}
              />
            ))}
          </>
        ) : (
          <>
            <NoData>No tiene cuentas, debe agregar una en soles y otra en dólares.</NoData>
          </>
        )}
      </div>
      <div>
        <CntButton center>
          <IconButtonContainer margin='0 0 20px'>
            <IconButton round onClick={onAddCallback}>
              <PlusIcon />
            </IconButton>
          </IconButtonContainer>
        </CntButton>
        <CntButton center>
          <Button
            onClick={() => {
              history.push('/start-transfer');
            }}
          >
            Nueva operación
          </Button>
        </CntButton>
      </div>

      <PopUp ref={popupRef} onClose={() => { }}>
        {currentAccount && (
          <Alert
            icon='error'
            title='¿Esta seguro de eliminar esta cuenta?'
            subtitle={`${currentAccount.currency} | ${currentAccount.name_bank} ${currentAccount.number_account}`}
            description={currentAccount.type_account}
          />
        )}
        <CntButton center>
          <Button
            onClick={() => {
              deleteAccount(currentAccount.id_cuenta);
            }}
            className='redirect'
          >
            Continuar
          </Button>
        </CntButton>
        <br />
      </PopUp>
      <PopUp
        ref={popupConfirmRef}
        onClose={() => {
          const dataCurrentUser = {
            id: user.info.user_id,
            token: user.info.token,
          };
          getAccountsUser(dataCurrentUser);
        }}
      >
        {currentAccount && (
          <>
            <figure className='ico-success'>
              <img src='/assets/images/img-message.png' alt='' />
            </figure>
            <Alert title='Cuenta eliminada con exito' />
          </>
        )}
      </PopUp>
    </WrapPage>
  );
};

const mapStateToProps = (state) => {
  return {
    accountsUser: state.userState.accountsUser,
    deleteAccountBank: state.userState.deleteAccountBank,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getDeleteAccountBank: (id) => dispatch(getDeleteAccountBank(id)),
  saveInfoCurrentAccountBank: (data) => dispatch(saveInfoCurrentAccountBank(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
