import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CntButton, Button } from '../theme/global';
import { TabsContainer, TabItem } from '../components/NavTabs';
import { Teeny, Typography, Strong, Title } from '../components/Text';
import BoxShadow from '../components/BoxShadow';
import Box from '@material-ui/core/Box';
import GreenBell from '../components/GreenBell';
import { cleanOperations, getOperationPending } from '../store/operations';
import Markdown from 'react-markdown/with-html';
import { WrapPage } from '../theme/shared';
import NoData from '../components/NoData';
const mapStateToProps = (state) => {
  return {
    resultOperation: state.operationState.resultOperation,
  };
};
const mapDispatchToProps = (dispatch) => ({
  cleanOperations: () => dispatch(cleanOperations()),
  getOperationPending: (id) => dispatch(getOperationPending(id)),
});
const TransferDone = ({
  user,
  getOperationPending,
  history,
  desktop,
  resultOperation,
  cleanOperations,
  generalInfo,
}) => {
  const [infoOperation, setInfoOperation] = useState(null);

  const goOperations = () => {
    //window.location.reload();
    window.location.replace('/start-transfer');
    //history.push('/start-transfer');
  };
  useEffect(() => {
    if (resultOperation && resultOperation.status === 200) {
      setInfoOperation(resultOperation);
      cleanOperations();
      const dataCurrentUser = {
        id: user.info.user_id,
        token: user.info.token,
      };
      getOperationPending(dataCurrentUser);

      return;
    }
  }, [cleanOperations, resultOperation, getOperationPending, user]);

  return (
    <>
      {infoOperation ? (
        <WrapPage className={`${desktop || ''} transfer-done`}>
          <TabsContainer className='tabs-container-up'>
            <TabItem className='strong'>
              Paso <Teeny color='primary'>01</Teeny>
            </TabItem>
            <TabItem className='strong'>
              Paso <Teeny color='primary'>02</Teeny>
            </TabItem>
            <TabItem className='active'>
              Paso <Teeny color='primary'>03</Teeny>
            </TabItem>
          </TabsContainer>
          <Title className={`${desktop || ''} title-transfer-done`}>
            Transferencia Realizada
          </Title>
          {infoOperation && (
            <>
              <BoxShadow className='wrap-title-code'>
                <div className='title-code'>
                  <p>Código de Operación:</p>
                  <h3>{infoOperation.code_operation}</h3>
                </div>
              </BoxShadow>

              <TabsContainer className='tabs-container-resume' autoCount as='div'>
                <TabItem padding='16px 10px'>
                  <Typography size='9px' font='quaternary'>
                    ENVIAS {infoOperation.sent_currency_text}
                    <br />
                    <Strong secondary size='16px'>
                      {infoOperation.sent_amount}
                    </Strong>
                  </Typography>
                </TabItem>
                <TabItem padding='16px 10px'>
                  <Typography size='9px' font='quaternary'>
                    RECIBES {infoOperation.receive_currency_text}
                    <br />
                    <Strong secondary size='16px'>
                      {infoOperation.receive_amount}
                    </Strong>
                  </Typography>
                </TabItem>
              </TabsContainer>
            </>
          )}

          {generalInfo && (
            <>
              <BoxShadow margin className='box-shadow-transferdone'>
                <Box display='flex' alignItems='center'>
                  <Box clone width='19px' mr='10px'>
                    <GreenBell color='secondary' shrink={0} />
                  </Box>
                  <Typography className='info-general' size='13px'>
                    <Markdown escapeHtml={false}>
                      {generalInfo.info_operation.notification_text}
                    </Markdown>
                  </Typography>
                </Box>
              </BoxShadow>
              <BoxShadow margin className='box-shadow-transferdone'>
                <Box display='flex'>
                  <Box clone width='19px' mr='10px'>
                    <img
                      src='/assets/images/icon-clock.svg'
                      alt=''
                      style={{ flexShrink: 0 }}
                    />
                  </Box>
                  <Typography className='info-general' size='13px'>
                    <Markdown escapeHtml={false}>
                      {generalInfo.info_operation.transfer_time_destiny}
                    </Markdown>
                  </Typography>
                </Box>
              </BoxShadow>

              <Typography className='info-general large-text' center size='13px'>
                <Markdown escapeHtml={false}>
                  {generalInfo.info_operation.normative_text}
                </Markdown>
              </Typography>
            </>
          )}

          <CntButton center>
            <Button
              onClick={() => {
                goOperations();
              }}
            >
              {'Realizar una nueva Operación'.toUpperCase()}
            </Button>
          </CntButton>
        </WrapPage>
      ) : (
        <WrapPage className={`${desktop || ''} no-data`}>
          <NoData />
        </WrapPage>
      )}
    </>
  );
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TransferDone));
