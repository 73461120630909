import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTermsConditions } from '../../../store/web/termsConditions';
import Markdown from 'react-markdown/with-html';
import { WrapPage } from '../../../theme/shared';
import { Container, Title, Text1 } from './styles';
const PoliciesPrivacity = ({ desktop, termsConditions, fetchTermsConditions }) => {
  useEffect(() => {
    fetchTermsConditions();
  }, [fetchTermsConditions]);

  return (
    <>
      {termsConditions && (
        <WrapPage className={`${desktop || ''} web-wrap`}>
          <Container className='nosotros_container'>
            <Title className='nosotros_title'>
              <h2>
                Políticas<strong> de Privacidad </strong>
              </h2>
            </Title>
            <Text1 className='nosotros_text'>
              <Markdown escapeHtml={false}>
                {termsConditions.acf.privacy_policies}
              </Markdown>
            </Text1>
          </Container>
        </WrapPage>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    termsConditions: state.termsConditionsState.termsConditions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTermsConditions: () => dispatch(fetchTermsConditions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesPrivacity);
