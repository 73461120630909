import styled from "styled-components/macro";

const Hr = styled.div`
  width: 100%;
  height: 0;
  opacity: 0.15;
  border-top: 0.25px solid #000000;
  max-width: 300px;
  margin: 0 auto;
`;

export default Hr
