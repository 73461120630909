import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { devToolsEnhancer } from 'redux-devtools-extension';
import { userReducer } from './users/reducers';
import { sliderIntroReducer } from './intro/reducers';
import { infoReducer } from './info/reducers';
import { operationsReducer } from './operations/reducers';
import { footerReducer } from './footer/reducers';
import { uiReducer } from './ui/reducers';
// import logger from 'redux-logger';
// web
import { frequentQuestionsReducer } from './web/frequentsQuestions/reducers';
import { comoFuncionaReducer } from './web/home/reducers';
import { nosotrosReducer } from './web/nosotros/reducers';
import { novedadesReducer } from './web/novedades/reducers';
import { contactReducer } from './web/contact/reducers';
import { dashboardReducer } from './web/dashboard/reducers';
import { userDashboardReducer } from './web/users/reducers';
import { termsConditionsReducer } from './web/termsConditions/reducers';
// web

const rootReducer = combineReducers({
  userState: userReducer,
  introState: sliderIntroReducer,
  infoState: infoReducer,
  operationState: operationsReducer,
  footerState: footerReducer,
  uiState: uiReducer,
  frequentsQuestionsState: frequentQuestionsReducer,
  homeState: comoFuncionaReducer,
  nosotrosState: nosotrosReducer,
  novedadesState: novedadesReducer,
  contactState: contactReducer,
  dashboardState: dashboardReducer,
  userDashboardState: userDashboardReducer,
  termsConditionsState: termsConditionsReducer,
});

// const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, logger));
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
