import React from 'react';
import styled from 'styled-components/macro';
import Strong from '../Strong';

export const BuyAndSalePriceContainer = styled.section`
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-style: normal;
  font-family: var(--ion-font-family);
  max-width: 300px;
  margin: 0 auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  margin-bottom: 25px;

  div.vr {
    opacity: 0.15;
    border-left: 0.25px solid #000000;
    width: 0;
    height: 45px;
  }
  h2 {
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: var(--ion-color-primary);
    margin: 0;
  }
  ${Strong} {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.03em;
    margin: 0;
    display: block;
  }
`;

const BuyAndSalePrice = ({ buyPrice, sellPrice, className }) => (
  <BuyAndSalePriceContainer className={className || ''}>
    <div>
      <h2>compra</h2>
      <Strong secondary>{buyPrice}</Strong>
    </div>
    <div className='vr' />
    <div>
      <h2>venta</h2>
      <Strong secondary>{sellPrice}</Strong>
    </div>
  </BuyAndSalePriceContainer>
);

export default BuyAndSalePrice;
