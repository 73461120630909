import React, { useEffect } from 'react';
import Item from '../../../components/web/Items';
import CategoriesList from '../../../components/web/CategoriesList';
import { Wancho, WrappItem } from '../../../components/web/Items/styles';
import TitlePages from '../../../components/web/TitlePages/Titulo';
import SocialNetworks from '../../../components/web/SocialNetworks';

import { WrapNews } from '../../../theme/global';
const Novedades = ({ novedades, fetchNovedades, location, footerData }) => {
  useEffect(() => {
    fetchNovedades();
  }, [fetchNovedades]);

  return (
    <WrapNews>
      <TitlePages
        className='title_pregunta_frecuente'
        title='Nuestro Blog'
        subtitle='Últimas Novedades'
      />
      <CategoriesList location={location} />
      <Wancho>
        {novedades && novedades.length > 0 && (
          <WrappItem>
            {novedades && (
              <>
                {novedades.map((item, key) => (
                  <Item data={item} url='' idCategory='' key={key} />
                ))}
              </>
            )}
          </WrappItem>
        )}
        <SocialNetworks data={footerData} className='inNews' />
      </Wancho>
    </WrapNews>
  );
};

export default Novedades;
