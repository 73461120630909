import {
  REQUEST_USER_DASHBOARD,
  RECEIVE_USER_DASHBOARD,
  INFO_CURRENT_USER_DASHBOARD,
  CLEAN_USER_ROLE_DASHBOARD,
  CHECK_EMAIL_USER_DASHBOARD,
  RECEIVE_CODE_VERIFICATION_DASHBOARD,
  VALIDATE_CODE_VERIFICATION_DASHBOARD,
  SET_NEW_PASSWORD_DASHBOARD,
  CLEAN_VALIDATE_CODE_DASHBOARD,
  CLEAN_CHECK_EMAIL_DASHBOARD,
  USER_OFF_LINE_DASHBOARD,
} from './types';

export const requestUserDash = () => ({
  type: REQUEST_USER_DASHBOARD,
});

export const receiveUserDash = (user) => ({
  type: RECEIVE_USER_DASHBOARD,
  userDashboard: user,
});

export const receiveInfoCurrentUserDash = (data) => ({
  type: INFO_CURRENT_USER_DASHBOARD,
  infoCurrentUserDashboard: data,
});

export const cleanUserRoleDash = () => ({
  type: CLEAN_USER_ROLE_DASHBOARD,
});
export const checkEmailUserDash = (email) => ({
  type: CHECK_EMAIL_USER_DASHBOARD,
  checkEmailDashboard: email,
});

export const receiveCodeSecurityDash = (code) => ({
  type: RECEIVE_CODE_VERIFICATION_DASHBOARD,
  codeSecurityDashboard: code,
});

export const receiveValidateCodeDash = (code) => ({
  type: VALIDATE_CODE_VERIFICATION_DASHBOARD,
  validateCodeDashboard: code,
});

export const setNewPasswordDash = (data) => ({
  type: SET_NEW_PASSWORD_DASHBOARD,
  newpasswordDashboard: data,
});
export const cleanValidateCodeDash = () => ({
  type: CLEAN_VALIDATE_CODE_DASHBOARD,
});
export const cleanCheckEmailDash = () => ({
  type: CLEAN_CHECK_EMAIL_DASHBOARD,
});

export const receiveUserOffLineDashboard = (data) => ({
  type: USER_OFF_LINE_DASHBOARD,
  userOffLine: data,
});
