import React, { useEffect, useState, useMemo } from 'react';
import { IonSlides, IonSlide, IonAlert } from '@ionic/react';
import { isPlatform } from '@ionic/react';

// import { , IonButton, IonContent } from '@ionic/react';
import {
	Item,
	Isotipo,
	Figure,
	Title,
	Description,
	Section,
	OmitirLink,
	Text,
	ItemInner,
	Button,
} from './styles';
import Markdown from 'react-markdown/with-html';

const SliderIntro = ({ banners, fetchBanners, onInit }) => {
	const [showAlert, setShowAlert] = useState(false);
	const [dataButton, setDataButton] = useState([])
	const currentCodeVersion = "1.0.8";

	const detectMobilIos = ['ios'].some(isPlatform);
	const detectMobilAndroid = ['android'].some(isPlatform);

	console.log('detectMobil', detectMobilIos )
	console.log('detectMobil2', detectMobilAndroid )

	
	useEffect(() => {
		if(!banners){
			fetchBanners()
				.then(() => {
					// console.log('fetch finished');
				})
				.catch((e) => {
					// console.error('fetch with error', e);
				})
				.finally(() => {
					onInit();
				});
		}
		if(banners){

			const objectBtnCritical = [
				{
					text: 'Actualizar',
					role: 'Actualizar',
					handler: () => {
						if(detectMobilIos){
							window.location.href = banners.url_ios;
						}else if(detectMobilAndroid){
							window.location.href =banners.url_android;
						}
					},
				}
			]

			const objectBtnSimple = [
				{
				text: 'Actualizar',
				role: 'Actualizar',
					handler: () => {
						if(detectMobilIos){
							window.location.href =banners.url_ios;
						}else if(detectMobilAndroid){
							window.location.href =banners.url_android;
						}
					}
				},
				{
					text: 'Continuar',
					role: 'Continuar',
					handler: () => {setShowAlert(false)},
				}
			]

			if(banners.version_code_app !== currentCodeVersion) {setShowAlert(true)}
			
			if(banners.type_update_app === 'critica-update'){
				setDataButton(objectBtnCritical)

				
			}
			else{
				setDataButton(objectBtnSimple)
			}
		}
	}, [fetchBanners, onInit, banners, detectMobilIos, detectMobilAndroid]);

	const slideOpts = useMemo(
		() => ({
			initialSlide: 0,
			speed: 400,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			on: {
				beforeInit() {
					const wrapIntro = document.querySelector('.introWrap');
					wrapIntro.classList.add('animation');
				},
			},
		}),
		[]
	);

	return (
		<>
			
			{(showAlert && banners) && (
				<div className="popup_alert">
					<div className="popup_alert_content">
						<IonAlert
							isOpen={showAlert}
							// onDidDismiss={(e) => showBtnUdpate ? window.location.replace(banners.url_ios) : null }
							header={banners.alert_title}
							buttons={dataButton}
							backdropDismiss= {false}
							subHeader=""
							message={banners.type_update_app === 'critica-update' ? banners.description_update_critical : banners.description_update_simple}
							// buttons={['Actualizar APP', 'ok']}
						/>
					</div>
				</div>
			)}
			<Section className='introWrap'>
				{(banners && banners.slides_list) && (
					<IonSlides pager={true} options={slideOpts}>
						{banners.slides_list.map((item, key) => (
							<IonSlide key={key}>
								<Item>
									<ItemInner>
										<Isotipo>
											<img
												className='isotipo-white'
												src='/assets/images/isotipo.svg'
												width={78}
												alt=''
											/>
											<img
												className='isotipo-color'
												src='/assets/images/isotipo-color.svg'
												width={78}
												alt=''
											/>
										</Isotipo>
										<Figure>
											<img src={item.imagen_intro.url} alt='Sr.Cambio' />
										</Figure>
										<Text>
											<Title>{item.titulo_intro_slide}</Title>
											<Description>
												<Markdown escapeHtml={false}>{item.descripcion_slide}</Markdown>
											</Description>
											<Button to='/simulador'>Inicia tu Operación</Button>
										</Text>
									</ItemInner>
								</Item>
							</IonSlide>
						))}
					</IonSlides>
				)}
				<OmitirLink to='/simulador'>Omitir</OmitirLink>

				{/* //POPUP */}

			</Section>
		</>

	);
};

export default SliderIntro;
