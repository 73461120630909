import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown/with-html';
import { NewDate, NewTitle, NewBody, NewText } from './styles';

const News = ({ dataNews, fetchNews }) => {
  const slugNews = useParams('slug');

  useEffect(() => {
    if (slugNews) {
      fetchNews(slugNews);
    }
  }, [slugNews, fetchNews]);

  return (
    <>
      {dataNews && (
        <>
          <NewDate>
            <li> {dataNews.category_name} </li>
            <li> {dataNews.last_date_post} </li>
          </NewDate>
          <NewTitle> {dataNews.title} </NewTitle>
          <NewBody>
            {dataNews.image && <img src={dataNews.image} alt='' />}
            <NewText>
              <Markdown escapeHtml={false}>{dataNews.description}</Markdown>
            </NewText>
          </NewBody>
        </>
      )}
    </>
  );
};

export default News;
