import { requestBanners, receiveBanners, requestBannersFinished } from './actions';
import { showApiError } from '../ui';
import { routesApi } from '../../routesApi';

export const fetchBanners = () => {
  return (dispatch) => {
    dispatch(requestBanners);
    return fetch(`${routesApi.pages}99`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveBanners(data.acf));
        
      })
      .catch((e) => {
        dispatch(showApiError());
        dispatch(requestBannersFinished());
        throw e;
      });
  };
};
