import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
`;
export const Text = styled.div`
  max-width: 220px;
  margin: 0 auto;
  text-align: center;
`;
export const SubTitle = styled.div`
  font-size: 14px;
`;
export const Title = styled.div`
  font-size: 18px;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
`;
export const WrapButtonChat = styled.div`
  width: 60px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
export const ButtonChat = styled.button``;
export const NavFooter = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
`;
export const IconNav = styled.div`
  position: relative;
  z-index: 2;
`;
export const ItemNav = styled(Link)`
  position: relative;
  width: 25%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  &:before {
    content: '';
    width: 1px;
    height: 35px;
    position: absolute;
    top: 13px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: all 0.3s;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    background-color: #fff;
    left: 0;
    z-index: 1;
    width: 102%;
    height: 80px;
    box-shadow: 0px 3px 25px rgba(58, 162, 41, 0.1);
    border-radius: 0px;
    opacity: 0;
    transition: all 0.3s;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
  &.active {
    &:after {
      height: 90px;
      border-radius: 15px 15px 0 0;
      opacity: 1;
    }
  }
  &:hover {
    color: var(--ion-color-secondary);
    &:before {
      opacity: 0;
    }
    &:after {
      border-radius: 15px 15px 0 0;
      height: 90px;
      opacity: 1;
    }
  }
`;
export const NamePage = styled.div`
  font-size: 12px;
  position: relative;
  z-index: 2;
`;

export const WrapMenuFoot = styled.div`
  width: 80px;
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 100;
  @media screen and (max-width: 480px) {
    right: 5px;
  }
`;
export const MenuFoot = styled.button`
  cursor: pointer;
`;

export const Tooltip = styled.figure`
  width: 172px;
  position: absolute;
  right: calc(100% - 25px);
  bottom: calc(100% - 25px);
  margin: 0;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const CloseTooltip = styled.button`
  display: inline-block;
  background: none;
  color: var(--ion-color-secondary);
  font-size: 8px;
  border: none;
  position: absolute;
  top: 17px;
  left: 17px;
  z-index: 2;
  padding: 0;
  cursor: pointer;
`;

export const TextTooltip = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  line-height: 1;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding-top: 25px;
  padding-left: 27px;
`;
