import { connect } from 'react-redux';
import {
  fetchUser,
  getInforCurrentUser,
  getCleanUserInfo,
} from '../../store/users/operations';

import LoginForm from './LoginForm';

const defaultValues = {
  username: '',
  password: '',
};
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    infoCurrentUser: state.userState.infoCurrentUser,
    defaultValues: defaultValues,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchUser: (data) => dispatch(fetchUser(data)),
  getInforCurrentUser: (data) => dispatch(getInforCurrentUser(data)),
  getCleanUserInfo: () => dispatch(getCleanUserInfo()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
