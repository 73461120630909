import React from 'react';
import { Wrap, Isotipo, TextBig, TextSmall } from './styles';
import { Strong } from '../Text';

function TextMotto({ logo, className }) {
  return (
    <Wrap className={className ? className : ''}>
      {logo && (
        <Isotipo>
          <img
            className='isotipo-color'
            src='/assets/images/isotipo-color.svg'
            width={81}
            alt=''
          />
        </Isotipo>
      )}
      <TextBig>
        Tu cambio{' '}
        <Strong secondary font='inherit'>
          online <br />
          de confianza
        </Strong>
      </TextBig>
      <TextSmall>más de 15 años</TextSmall>
    </Wrap>
  );
}

export default TextMotto;
