import React from 'react';
import { useHistory } from 'react-router-dom';
import { Title } from '../../components/Text';
import { CntButton, Button } from '../../theme/global';
const NoData = () => {
  const history = useHistory();
  return (
    <div>
      <Title className='title-make-transfer'>Esta página ha expirado.</Title>
      <CntButton center>
        <Button
          onClick={() => {
            history.push('/start-transfer');
          }}
          round
          type='button'
        >
          Iniciar nueva Operación
        </Button>
      </CntButton>
    </div>
  );
};

export default NoData;
