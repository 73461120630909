import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';

export const WrapDashboard = styled.div`
  max-width: 1200px;
  width: 94%;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 10px;
  overflow: auto;
`;
export const TitleDashboard = styled.div`
  font-size: 20px;
  text-align: center;
  margin: 0;
  letter-spacing: -0.03em;
  color: #717171;
  font-family: ${fonts.lato400};
  opacity: 0.7;
`;

export const ContainerDashboard = styled.div`
  padding-bottom: 0px;
  padding-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  & > * {
    width: calc(100% / 3 - 1.5%) !important;
  }
`;
export const HeaderParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 15px;
`;
export const HeaderTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 260px;
  margin-right: 2%;
  &:last-child {
    margin-right: 0;
  }
  .header_name {
    margin: 0;
    margin-right: 2%;
    width: 100px;
  }
`;
export const HeaderWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
`;
export const HeaderChild = styled.div`
  padding: 14px 0px;
  position: relative;
  width: calc(100% / 2);
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    margin: auto;
    right: 0;
    bottom: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    height: 30px;
  }
  &:last-child {
    &:before {
      display: none;
    }
  }
  span {
    color: #3aa229;
    font-size: 10px;
    font-family: ${fonts.lato400};
    display: block;
    padding: 0px 5px;
  }
  strong {
    font-weight: normal;
    color: #717171;
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-family: ${fonts.lato800};
  }
`;
