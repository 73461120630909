import React from 'react';
import styled, { css } from 'styled-components';

export const textMixin = css`
  ${({ capitalice }) => (capitalice ? 'text-transform: capitalize;' : '')}
  ${({ color = 'primary' }) =>
    color ? (color === 'inherit' ? 'inherit;' : `color: var(--ion-color-${color});`) : ''}
  ${({ center }) => (center ? 'text-align: center;' : '')}
  font-size: ${({ size = '1em' }) => size};

  font-family: var(
    ${({ font }) => {
      switch (font) {
        case 'inherit':
          return 'inherit';
        case 'secondary':
          return '--ion-font-family-secondary';
        case 'tertiary':
          return '--ion-font-family-tertiary';
        case 'quaternary':
          return '--ion-font-family-quaternary';
        case 'quinary':
          return '--ion-font-family-quinary';
        case '600':
          return '--ion-font-family-600';
        case 'primary':
        default:
          return '--ion-font-family';
      }
    }}
);
`;

export const Span = styled.span`
  ${({ capitalice }) => (capitalice ? 'text-transform: capitalize;' : '')}
  ${({ color = 'primary' }) =>
    color ? (color === 'inherit' ? 'inherit;' : `color: var(--ion-color-${color});`) : ''}
  ${({ center }) => (center ? 'text-align: center;' : '')}
  font-size: ${({ size = '1em' }) => size};

  font-family: var(
    ${({ font }) => {
      switch (font) {
        case 'inherit':
          return 'inherit';
        case 'secondary':
          return '--ion-font-family-secondary';
        case 'tertiary':
          return '--ion-font-family-tertiary';
        case 'quaternary':
          return '--ion-font-family-quaternary';
        case 'quinary':
          return '--ion-font-family-quinary';
        case '600':
          return '--ion-font-family-600';
        case 'primary':
        default:
          return '--ion-font-family';
      }
    }}
  );
`;

export const Teeny = (p) => <Span {...p} font='tertiary' />;

export const Typography = styled(Span).attrs({ as: 'div' })`
  margin: 0;
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : '')}
`;

export const CreateAccount = styled(Typography).attrs({
  size: 17,
  center: true,
  font: 'quinary',
})`
  margin-top: 25px;
  a {
    color: var(--ion-color-secondary);
  }
  &:after,
  &:before {
    content: '';
    width: 12px;
    height: 15px;
    background-color: var(--ion-color-primary-contrast);
    display: inline-block;
    background-size: cover;
    background-image: url('/assets/images/icon-arrow-round.png');
  }
  &:after {
    margin-left: 10px;
    transform: rotate(180deg);
  }
  &:before {
    margin-right: 10px;
  }
`;
