import { connect } from 'react-redux';
import { saveProcessRegister } from '../../store/users';
import { getUbigeoProvincias } from '../../store/info';

import FormProfileData from './FormProfileData';

const mapStateToProps = (state) => {
  return {
    processRegister: state.userState.processRegister,
    accountsUser: state.userState.accountsUser,
    ubigeoDepartamentos: state.infoState.ubigeoDepartamentos,
    ubigeoProvincias: state.infoState.ubigeoProvincias,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveProcessRegister: (data) => dispatch(saveProcessRegister(data)),
  getUbigeoProvincias: (data) => dispatch(getUbigeoProvincias(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormProfileData);
