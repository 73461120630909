import {
  REQUEST_NOVEDADES,
  RECEIVE_NOVEDADES,
  RECEIVE_CATEGORIES,
  RECEIVE_NEWS,
  RECEIVE_NEWS2,
  RECEIVE_NEWS_CATEGORY,
  RECEIVE_NEWS_FEATURED,
} from "./types";

export const requestNovedades = () => ({
  type: REQUEST_NOVEDADES,
});

export const receiveNovedades = (json) => ({
  type: RECEIVE_NOVEDADES,
  novedades: json,
});
export const receiveFeatured = (json) => ({
  type: RECEIVE_NEWS_FEATURED,
  featured: json,
});
export const receiveCategories = (json) => ({
  type: RECEIVE_CATEGORIES,
  categories: json,
});

export const receiveNews = (json) => ({
  type: RECEIVE_NEWS,
  getNews: json,
});
export const receiveNews2 = (json) => ({
  type: RECEIVE_NEWS2,
  getNews: json,
});
export const receiveNewsCategory = (json) => ({
  type: RECEIVE_NEWS_CATEGORY,
  getNewsCategory: json,
});
