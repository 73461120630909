import { requestContact, receiveContact } from './actions';
import { routesApi } from '../../../routesApi';

export const fetchContact = (datos) => {
  return (dispatch) => {
    dispatch(requestContact());
    return fetch(routesApi.contactForm, {
      method: 'POST',
      body: datos,
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveContact(json));
      });
  };
};
