import React from 'react';
import {Wrapp, EnvolverImg, InfoMessage} from './style';



const MessageTemplate = ({title, description}) => {
	return (
		<Wrapp>
			<EnvolverImg>
				<figure>
					<img src="static/image/img-message.png" alt=""/>
				</figure>
			</EnvolverImg>
			<InfoMessage>
				<h2>{title}</h2>
				<p>{description}</p>
			</InfoMessage>
		</Wrapp>
	);
}
 
export default MessageTemplate;