import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { WrapLoading, Loading } from '../../../theme/global';
import {
  Wrap,
  Container,
  LogoForm,
  TitleForm,
  FormInner,
  InputBox,
  ShowPassword,
  WrapLink,
  LinkRecover,
  WrapButton,
} from './styles';
const ChangePassword = ({
  history,
  codeSecurity,
  newPassword,
  getNewPassword,
  loading,
}) => {
  const MySwal = withReactContent(Swal);
  const defaultValues = {
    password: '',
    passConfirm: '',
  };
  const [showPasswod, setShowPassword] = useState(true);
  useEffect(() => {
    const successChange = () => {
      MySwal.fire({
        icon: 'success',
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar',
        focusConfirm: false,
        title: 'Contraseña cambiada con exito',
        text: 'Ahora puede iniciar sesión con su nueva contraseña',
      }).then((result) => {
        if (result && result.isConfirmed) {
          sessionStorage.clear();
          window.location.replace('/login');
        }
      });
    };
    if (!codeSecurity) {
      // history.push('/login');
    }
    if (newPassword && newPassword.status === 200) {
      successChange();
    }
  }, [MySwal, codeSecurity, newPassword, history]);
  return (
    <>
      {loading && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      <Wrap>
        <Container>
          <LogoForm>
            <img
              src='/static/img/login-form-logo.png'
              width='76'
              height='56'
              alt='logo'
            />
          </LogoForm>
          <TitleForm>Cambiar Contraseña</TitleForm>
          <Formik
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values) => {
              const errors = {};

              if (!values.password.trim()) {
                errors.password = 'La contraseña no debe estar vacía';
              } else if (values.password.length < 8) {
                errors.password = 'La contraseña debe tener minimo 8 caracteres';
              }
              if (values.password !== values.passConfirm)
                errors.passConfirm = 'Las contraseñas no coinciden';

              if (Object.keys(errors).length > 0) return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                const data = {
                  id: codeSecurity.id,
                  password: values.password,
                };
                getNewPassword(data);
                setSubmitting(false);
              }, 500);
            }}
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form className='formularyLogin'>
                <FormInner>
                  <InputBox>
                    <Field
                      fullWidth
                      name='password'
                      type={showPasswod ? 'password' : 'text'}
                      placeholder='Contraseña Nueva'
                      component={TextField}
                    />
                    <ShowPassword onClick={() => setShowPassword(!showPasswod)}>
                      <img
                        src={`/assets/images/icon-eyes-${showPasswod ? 'on' : 'off'}.svg`}
                        width={25}
                        alt=''
                      />
                    </ShowPassword>
                  </InputBox>
                  <InputBox>
                    <Field
                      fullWidth
                      name='passConfirm'
                      type={showPasswod ? 'password' : 'text'}
                      placeholder='Repetir Contraseña'
                      component={TextField}
                    />
                    <ShowPassword onClick={() => setShowPassword(!showPasswod)}>
                      <img
                        src={`/assets/images/icon-eyes-${showPasswod ? 'on' : 'off'}.svg`}
                        width={25}
                        alt=''
                      />
                    </ShowPassword>
                  </InputBox>
                </FormInner>
                <WrapLink>
                  <LinkRecover>
                    Te enviaremos una verificación a tu{' '}
                    <strong>Correo Electrónico</strong>
                  </LinkRecover>
                </WrapLink>

                <WrapButton>
                  <button
                    className='ctaButton medium'
                    type='submit'
                    disabled={isSubmitting}
                    onClick={() => submitForm}
                  >
                    Iniciar Sesión
                    {isSubmitting && <Loading />}
                  </button>
                </WrapButton>
              </Form>
            )}
          </Formik>
        </Container>
      </Wrap>
    </>
  );
};

export default ChangePassword;
