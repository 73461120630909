import styled from 'styled-components/macro';
const Card = styled.div`
  margin: 10px auto;
  padding: 29px 40px;
  max-width: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  font-size: 14px;
  svg path {
    fill: var(--ion-color-secondary);
  }
`;
export default Card;
