import { requestFrequentsQuestions, receiveFrequentsQuestions } from './actions';
import { routesApi } from '../../../routesApi';
export const fetchFrecuentQuestion = () => {
  return (dispatch) => {
    dispatch(requestFrequentsQuestions());
    return fetch(`${routesApi.urlApiAcf}pages/122`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveFrequentsQuestions(json.acf.faqs_list));
      });
  };
};
