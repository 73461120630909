import React, { useRef, useEffect, useState } from 'react';
import {
  CntButton,
  Button,
  WrapError,
  TextError,
  WrapSelectItem,
  InputWrap,
  SelectItem,
} from '../../theme/global';
import { Typography, Strong, Title } from '../Text';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Input, { Container } from '../InputRounded';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import moment from 'moment';
import PopUp from '../Popup';
import { useFormikContext } from 'formik';
import TabProfile from '../TabProfile';
import { ListItem, GroupRadio, TitleRadio, SubTitle, ItemRadio } from './styles';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';
const FormEditProfileData = ({
  desktop,
  history,
  editInfoPersonal,
  setFieldValue,
  infoCurrentUser,
  getAccountsUser,
  getInforCurrentUser,
  ubigeoDepartamentos,
  getUbigeoProvincias,
  accountsUser,
  ubigeoProvincias,
  getCleanEditInfoUser,
  business = false,
  generalInfo,
  values,
  user,
  getCleanUbigeoProvincias
}) => {
  const popupRef = useRef(null);
  const popupSavedRef = useRef(null);
  const { submitForm } = useFormikContext();

  const date_setup = moment().subtract(18, 'years');
  const [selectedDate, handleDateChange] = useState(date_setup);


  const openOptionRadio = () => {
    popupRef.current.open();
  };
  const onSubmitForm = () => {
    submitForm();
  };
  function validateNumber(event) {
    var key = event.which;
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }
  useEffect(() => {
    getCleanUbigeoProvincias();
    if (editInfoPersonal && editInfoPersonal.status === 200) {
      const dataCurrentUser = {
        id: user.info.user_id,
        token: user.info.token,
      };
      getInforCurrentUser(dataCurrentUser);
      popupSavedRef.current.open();
      getAccountsUser(dataCurrentUser);
      getCleanEditInfoUser();



    }
    if (
      accountsUser &&
      accountsUser.status === 200 && accountsUser.single_info.departamento_personal &&
      ubigeoDepartamentos
    ) {
      let ubigeoData = accountsUser.single_info.departamento_personal;
      let ubigeo = ubigeoData.split('-');
      getUbigeoProvincias(ubigeoDepartamentos.provincias[ubigeo[0]]);
      handleDateChange(accountsUser.single_info.fecha_nacimiento);

    }
  }, [getCleanUbigeoProvincias, editInfoPersonal, history, getInforCurrentUser, user, accountsUser, ubigeoDepartamentos, getUbigeoProvincias, getAccountsUser, getCleanEditInfoUser]);
  return (
    <>
      <WrapPage className={`${desktop || ''} edi-profile-data`}>
        <TabProfile />
        <Form>
          <Title className='title-edit-profile'> Datos de Perfil</Title>
          <Container>
            <h3 className='label-input-field'>Nombres</h3>
            <Input className='input-text' name='names' />
            <ErrorMessage name='names'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <h3 className='label-input-field'>Apellidos</h3>
            <Input className='input-text' name='lastNames' />
            <ErrorMessage name='lastNames'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <h3 className='label-input-field'>DNI / Pasaporte</h3>
            <Input
              onKeyPress={(event) => {
                validateNumber(event);
              }}
              className='input-text'
              name='idDoc'
            />
            <ErrorMessage name='idDoc'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <h3 className='label-input-field'>Nacionalidad</h3>
            <Input
              className='input-text'
              name='nationality'
            />
            <ErrorMessage name='nationality'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>

            {infoCurrentUser && (
              <InputWrap>
                <h3 className='label-input-field'>Fecha de nacimiento</h3>
                <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                  <KeyboardDatePicker
                    format='DD/MM/YYYY'
                    name='dateBirth'
                    maxDate={date_setup}
                    value={selectedDate}
                    onChange={(e) => {
                      handleDateChange(e);
                      values.dateBirth = e;
                    }}
                    keyboardIcon={
                      <img src='/assets/images/calendar.svg' alt='calendar' />
                    }
                  />
                </MuiPickersUtilsProvider>
              </InputWrap>
            )}

            <ErrorMessage name='dateBirth'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <h3 className='label-input-field'>Estado Civil</h3>
            <Input className='input-text' name='civilStatus' />
            <ErrorMessage name='civilStatus'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <h3 className='label-input-field'>Celular</h3>
            <Input
              onKeyPress={(event) => {
                validateNumber(event);
              }}
              className='input-text'
              name='phone'
            />
            <ErrorMessage name='phone'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            {infoCurrentUser && infoCurrentUser.info.has_profile_company && (
              <>
                {generalInfo && (
                  <>
                    <div className='wrap-selects'>
                      <WrapSelectItem>
                        <SelectItem>
                          <Field component='select' name='ocupacion_user'>
                            <option value=''>Ocupación*</option>
                            {generalInfo.job_occupation.map((item, key) => (
                              <option key={key} data-id={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='ocupacion_user'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                      <WrapSelectItem>
                        <SelectItem>
                          <Field component='select' name='relacion_laboral_user'>
                            <option value=''>Relación Laboral</option>
                            {generalInfo.employment_relationship.map((item, key) => (
                              <option key={key} data-id={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='relacion_laboral_user'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                    </div>
                  </>
                )}
              </>
            )}
            {ubigeoDepartamentos && (
              <>
                <WrapSelectItem>
                  <SelectItem>
                    <Field
                      className='select-departamento'
                      component='select'
                      name='departamento_personal'
                      onChange={(e) => {
                        let current_value = e.currentTarget.value;

                        setFieldValue('departamento_personal', current_value)
                        const getProvinciaCurrent = () => {
                          let ubigeoData = current_value;
                          let ubigeo = ubigeoData.split('-');
                          getUbigeoProvincias(ubigeoDepartamentos.provincias[ubigeo[0]]);

                        };
                        getProvinciaCurrent();
                      }
                      }
                    >
                      <option value=''>Departamento</option>
                      {ubigeoDepartamentos.departamentos.map((item, key) => (
                        <option
                          key={key}
                          data-ubigeo={item.id_ubigeo}
                          value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                        >
                          {item.nombre_ubigeo}
                        </option>
                      ))}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='departamento_personal'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </WrapSelectItem>
                <WrapSelectItem>
                  <SelectItem>
                    <Field component='select' name='provincia_personal'
                      onChange={(e) => {
                        let current_value_prov = e.currentTarget.value;
                        setFieldValue('provincia_personal', current_value_prov)
                      }
                      }
                    >
                      <option value=''>Provincia*</option>
                      {ubigeoProvincias && (
                        <>
                          {ubigeoProvincias.map((item, key) => (
                            <option
                              key={key}
                              data-ubigeo={item.id_ubigeo}
                              value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                            >
                              {item.nombre_ubigeo}
                            </option>
                          ))}
                        </>
                      )}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='provincia_personal'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </WrapSelectItem>
                <Input
                  className='input-text'
                  name='distrito_personal'
                  placeholder='Distrito'
                />
                <ErrorMessage name='distrito_personal'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  className='input-text'
                  name='direccion_personal'
                  placeholder='Dirección'
                />
                <ErrorMessage name='direccion_personal'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

              </>
            )}

          </Container>
          <Typography
            className='required-fields'
            color='primary'
            center
            size='13px'
            font='quaternary'
          >
            *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
          </Typography>

          <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
            <Button
              round
              type='button'
              onClick={() => {
                openOptionRadio();
              }}
            >
              Continuar
            </Button>
          </CntButton>
          <PopUp className='profile-data' ref={popupRef} onClose={() => onSubmitForm()}>
            <GroupRadio>
              <TitleRadio>¿Es una Persona Expuesta Políticamente?</TitleRadio>
              <SubTitle>Declaración Jurada</SubTitle>
              <ListItem>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='politicallyExposed'
                    value='Si'
                    id='exposed-yes'
                  />
                  <label htmlFor='exposed-yes'>Si</label>
                </ItemRadio>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='politicallyExposed'
                    value='No'
                    id='exposed-no'
                  />
                  <label htmlFor='exposed-no'>No</label>
                </ItemRadio>
              </ListItem>
            </GroupRadio>
            <GroupRadio>
              <TitleRadio>¿Es un Sujeto Obligado a Informar a la UIF?</TitleRadio>
              <SubTitle>Declaración Jurada</SubTitle>
              <ListItem>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='informUIF'
                    value='Si'
                    id='inform-yes'
                  />
                  <label htmlFor='inform-yes'>Si</label>
                </ItemRadio>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='informUIF'
                    value='No'
                    id='inform-no'
                  />
                  <label htmlFor='inform-no'>No</label>
                </ItemRadio>
              </ListItem>
            </GroupRadio>
          </PopUp>
          <PopUp ref={popupSavedRef} onClose={() => {
          }}>
            <Alert icon='success' title='Datos actualizados con exito' />
          </PopUp>
        </Form>
      </WrapPage>
    </>
  );
};

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ infoCurrentUser, business }) {
    const values = {};
    const date_setup = moment().subtract(18, 'years');

    const info = infoCurrentUser ? infoCurrentUser.info : false;
    values.names = info ? info.first_name : '';
    values.lastNames = info ? info.last_name : '';
    values.idDoc = info ? info.doc_identidad : '';
    values.dateBirth = info ? moment(info.fecha_nacimiento) : '';
    values.civilStatus = info ? info.estado_civil : '';
    values.phone = info ? info.nro_celular : '';
    values.politicallyExposed = info ? info.persona_expuesta_politicamente : '';
    values.informUIF = info ? info.obligado_informar_uif : '';


    values.nationality = info ? info.nationality : '';
    values.departamento_personal = info ? info.departamento_personal : '';
    values.provincia_personal = info ? info.provincia_personal : '';
    values.distrito_personal = info ? info.distrito_personal : '';
    values.direccion_personal = info ? info.direccion_personal : '';


    if (infoCurrentUser && infoCurrentUser.info.has_profile_company) {

      const info = infoCurrentUser.info;
      values.ocupacion_user = info ? infoCurrentUser.info.ocupacion_user : '';
      values.relacion_laboral_user = info
        ? infoCurrentUser.info.relacion_laboral_user
        : '';
    }

    return values;
  },
  validate(values, { business, infoCurrentUser, getUbigeoProvincias, ubigeoDepartamentos }) {
    const errors = {};
    if (!values.names) errors.names = 'Campo Obligatorio';
    if (!values.lastNames) errors.lastNames = 'Campo Obligatorio';
    if (!values.idDoc) errors.idDoc = 'Campo Obligatorio';
    if (!values.dateBirth) errors.dateBirth = 'Campo Obligatorio';
    if (!values.civilStatus) errors.civilStatus = 'Campo Obligatorio';
    if (!values.phone) errors.phone = 'Campo Obligatorio';

    if (!values.nationality) errors.nationality = 'Campo Obligatorio';
    if (!values.departamento_personal) errors.departamento_personal = 'Campo Obligatorio';
    if (!values.provincia_personal) errors.provincia_personal = 'Campo Obligatorio';
    if (!values.distrito_personal) errors.distrito_personal = 'Campo Obligatorio';
    if (!values.direccion_personal) errors.direccion_personal = 'Campo Obligatorio';


    if (infoCurrentUser && infoCurrentUser.info.has_profile_company) {
      if (!values.ocupacion_user) errors.ocupacion_user = 'Campo Obligatorio';
      if (!values.relacion_laboral_user)
        errors.relacion_laboral_user = 'Campo Obligatorio';
    }
    return errors;
  },
  handleSubmit(
    values,
    { setSubmitting, props: { getEditInfoPersonal, user, infoCurrentUser } }
  ) {
    const dataUser = {
      business: infoCurrentUser.info.has_profile_company,
      user_id: user.info.user_id,
      token: user.info.token,
      first_name: values.names,
      last_name: values.lastNames,
      doc_identidad: values.idDoc,

      nationality: values.nationality,
      departamento_personal: values.departamento_personal,
      provincia_personal: values.provincia_personal,
      distrito_personal: values.distrito_personal,
      direccion_personal: values.direccion_personal,

      fecha_nacimiento: values.dateBirth,
      estado_civil: values.civilStatus,
      nro_celular: values.phone,
      persona_expuesta_politicamente: values.politicallyExposed,
      obligado_informar_uif: values.informUIF,
      ...(infoCurrentUser.info.has_profile_company
        ? {
          ocupacion_user: values.ocupacion_user,
          relacion_laboral_user: values.relacion_laboral_user,
        }
        : {}),
    };
    getEditInfoPersonal(dataUser);
    setSubmitting(false);
  },
};
export default withFormik(formConfig)(FormEditProfileData);
