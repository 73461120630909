import React, { useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { WrapLoading, Loading } from '../../../theme/global';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Wrap,
  Container,
  LogoForm,
  TitleForm,
  FormInner,
  InputBox,
  WrapLink,
  LinkRecover,
  WrapButton,
} from './styles';
const RecoverPassword = ({
  history,
  statusCheckEmail,
  getCheckEmail,
  getCodeSecurity,
  codeSecurity,
  getCleanCheckEmail,
  loading,
}) => {
  const MySwal = withReactContent(Swal);
  const defaultValues = {
    email: '',
  };
  useEffect(() => {
    const errorLogin = () => {
      MySwal.fire({
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        focusConfirm: false,
        title: 'Correo no existe, debe registrarse',
      }).then((result) => {
        if (result && result.isDismissed) {
          getCleanCheckEmail();
        }
      });
    };

    if (statusCheckEmail && statusCheckEmail.status === 303) {
      const data = {
        email: statusCheckEmail.email,
      };
      if (!codeSecurity) {
        getCodeSecurity(data).then(() => {
          history.push('/code-verification-dashboard');
        });
      }
    }
    if (statusCheckEmail && statusCheckEmail.status === 404) {
      errorLogin();
    }
  }, [
    getCleanCheckEmail,
    statusCheckEmail,
    MySwal,
    getCodeSecurity,
    codeSecurity,
    history,
  ]);
  return (
    <>
      {loading && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      <Wrap>
        <Container>
          <LogoForm>
            <img
              src='/static/img/login-form-logo.png'
              width='76'
              height='56'
              alt='logo'
            />
          </LogoForm>
          <TitleForm>Recuperar Contraseña</TitleForm>
          <Formik
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = 'Campo Obligatorio';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Email invalido';
              }

              if (Object.keys(errors).length > 0) return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                setSubmitting(false);
                getCheckEmail(values);
                // resetForm();
              }, 500);
            }}
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form className='formularyLogin'>
                <FormInner>
                  <InputBox>
                    <Field
                      fullWidth
                      name='email'
                      type='text'
                      placeholder='Correo Electrónico'
                      component={TextField}
                    />
                  </InputBox>
                </FormInner>
                <WrapLink>
                  <LinkRecover>
                    Te enviaremos una verificación a tu{' '}
                    <strong>Correo Electrónico</strong>
                  </LinkRecover>
                </WrapLink>
                <WrapButton>
                  <button
                    className='ctaButton medium'
                    type='submit'
                    disabled={isSubmitting}
                    onClick={() => submitForm}
                  >
                    Iniciar Sesión
                    {isSubmitting && <Loading />}
                  </button>
                </WrapButton>
              </Form>
            )}
          </Formik>
        </Container>
      </Wrap>
    </>
  );
};

RecoverPassword.propTypes = {
  className: PropTypes.string,
  customClass: PropTypes.string,
};
export default RecoverPassword;
