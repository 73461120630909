import styled from 'styled-components/macro';

export const TitleGreen = styled.h1`
  font-family: var(--ion-font-family-quaternary);
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 90%;
  letter-spacing: -0.03em;
  text-align: center;
  color: var(--ion-color-secondary);
`;

const Title = styled.h1`
  font-family: var(
    ${({ font }) => {
      switch (font) {
        case 'inherit':
          return 'inherit';
        case 'secondary':
          return '--ion-font-family-secondary';
        case 'tertiary':
          return '--ion-font-family-tertiary';
        case 'quaternary':
          return '--ion-font-family-quaternary';
        case 'quinary':
          return '--ion-font-family-quinary';
        case '600':
          return '--ion-font-family-600';
        case 'primary':
        default:
          return '--ion-font-family-tertiary';
      }
    }}
  );
  text-align: center;
  margin: 0;
  ${({ marginTop = 0 }) => `margin-top: ${marginTop};`}
  ${({ marginBottom = '36px' }) => `margin-bottom: ${marginBottom};`}
    ${({ color = 'primary' }) =>
    color ? (color === 'inherit' ? 'inherit;' : `color: var(--ion-color-${color});`) : ''}
  font-weight: 300;
  font-size: 25px;
  line-height: 28px;
  letter-spacing: -0.02em;
  &.desktop {
    font-family: var(--ion-font-family-quinary);
    color: var(--ion-color-secondary);
  }
  @media screen and (max-height: 635px) {
    font-size: 20px;
    margin-bottom: 5px;
  }
`;
export default Title;
