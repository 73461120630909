import { connect } from 'react-redux';
import {
  getCheckEmailDash,
  getCodeSecurityDash,
  getCleanCheckEmailDash,
} from '../../../store/web/users/';

import RecoverPassword from './RecoverPassword';

const mapStateToProps = (state) => {
  return {
    statusCheckEmail: state.userDashboardState.statusCheckEmailDashboard,
    codeSecurity: state.userDashboardState.codeSecurityDashboard,
    loading: state.userDashboardState.loadingDashboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCheckEmailDash: (data) => dispatch(getCheckEmailDash(data)),
  getCodeSecurityDash: (data) => dispatch(getCodeSecurityDash(data)),
  getCleanCheckEmailDash: () => dispatch(getCleanCheckEmailDash()),
});
export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
