import {
  cleanUserRoleDash,
  requestUserDash,
  receiveUserDash,
  receiveInfoCurrentUserDash,
  checkEmailUserDash,
  receiveCodeSecurityDash,
  receiveValidateCodeDash,
  setNewPasswordDash,
  cleanValidateCodeDash,
  cleanCheckEmailDash,
  receiveUserOffLineDashboard,
} from './actions';
import { showAlertError } from '../../users/actions';
import { routesApi } from '../../../routesApi';
const responseError = {
  status: 500,
  message: 'Error de servidor o conexión',
};
export const fetchUserDash = (data) => {
  return (dispatch) => {
    dispatch(requestUserDash());
    return fetch(routesApi.login, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.json().then((userInfo) => {
        const data = {
          status: response.status,
          message: response.statusText,
          info: userInfo ? userInfo : null,
        };
        dispatch(receiveUserDash(data));
      });
    });
  };
};

export const getInforCurrentUserDash = (data) => {
  return (dispatch) => {
    dispatch(requestUserDash());
    return fetch(`${routesApi.infoCurrentUser}${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveInfoCurrentUserDash(data));
      })
      .catch((error) => {
        dispatch(receiveInfoCurrentUserDash(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
export const getCleanUserRoleDash = () => {
  return (dispatch) => {
    return dispatch(cleanUserRoleDash());
  };
};

export const getCodeSecurityDash = (data) => {
  return (dispatch) => {
    dispatch(requestUserDash());
    return fetch(routesApi.requestCode, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveCodeSecurityDash(data));
        });
      })
      .catch((error) => {
        dispatch(receiveCodeSecurityDash(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getCheckEmailDash = (data) => {
  return (dispatch) => {
    dispatch(requestUserDash());
    return fetch(routesApi.checkEmail, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(checkEmailUserDash(data));
        });
      })
      .catch((error) => {
        dispatch(checkEmailUserDash(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getValidateCodeDash = (data) => {
  return (dispatch) => {
    dispatch(requestUserDash());
    return fetch(routesApi.validateCode, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveValidateCodeDash(data));
        });
      })
      .catch((error) => {
        dispatch(receiveValidateCodeDash(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getNewPasswordDash = (data) => {
  return (dispatch) => {
    dispatch(requestUserDash());
    return fetch(routesApi.newPassword, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(setNewPasswordDash(data));
        });
      })
      .catch((error) => {
        dispatch(setNewPasswordDash(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
export const getCleanValidateCodeDash = () => {
  return (dispatch) => {
    return dispatch(cleanValidateCodeDash());
  };
};
export const getCleanCheckEmailDash = () => {
  return (dispatch) => {
    return dispatch(cleanCheckEmailDash());
  };
};

export const getUserOffLineDashboard = (data) => {
  return (dispatch) => {
    return dispatch(receiveUserOffLineDashboard(data));
  };
};
