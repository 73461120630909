import React from 'react';
import RegisterCliente from '../components/RegisterClient';
import TextMotto from '../components/TextMotto';
import { WrapPage } from '../theme/shared';
const CreateAccount = ({ desktop, history }) => {
  return (
    <WrapPage className={`${desktop || ''} create-account`}>
      <TextMotto logo={desktop ? false : true} />
      <RegisterCliente history={history} />
    </WrapPage>
  );
};

export default CreateAccount;
