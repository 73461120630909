import React, { useEffect } from 'react';
import { CntButton, Button } from '../theme/global';
import Tabs from '../components/OperationHistoryTabsButtons';
import { Title } from '../components/Text';
import ItemOperationPending from '../components/ItemOperationPending';
import { connect } from 'react-redux';
import { getOperationPending, getcleanOpPending } from '../store/operations';
import { useIonViewDidLeave } from '@ionic/react';
import { WrapPage } from '../theme/shared';
const OngoingOperations = ({
  history,
  operationsPending,
  getOperationPending,
  desktop,
  getcleanOpPending,
  user,
}) => {
  useEffect(() => {
    if (user && !operationsPending) {
      const dataCurrentUser = {
        id: user.info.user_id,
        token: user.info.token,
      };
      getOperationPending(dataCurrentUser);
      return;
    }
  }, [getOperationPending, user, operationsPending]);
  const goSimulator = () => {
    history.push('/start-transfer');
  };
  useIonViewDidLeave(() => {
    getcleanOpPending();
  });

  return (
    <>
      <WrapPage className={`${desktop || ''} pending-operations`}>
        <div>
          <Title marginBottom='0'>Historial de Operaciones</Title>
          <Tabs />
          {operationsPending && operationsPending.status === 200 ? (
            <>
              <ItemOperationPending data={operationsPending.result} />
              <br />
            </>
          ) : (
            <h3 className='text-no-operation'>No tienes operaciones pendientes</h3>
          )}
        </div>
        <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
          <Button
            type='button'
            onClick={() => {
              goSimulator();
            }}
          >
            NUEVA OPERACIÓN
          </Button>
        </CntButton>
      </WrapPage>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    operationsPending: state.operationState.operationsPending,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOperationPending: (id) => dispatch(getOperationPending(id)),
  getcleanOpPending: () => dispatch(getcleanOpPending()),
});
export default connect(mapStateToProps, mapDispatchToProps)(OngoingOperations);
