import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { WrapLoading, Loading } from '../../../theme/global';
import { Link } from 'react-router-dom';
import {
  Wrap,
  Container,
  LogoForm,
  TitleForm,
  FormInner,
  InputBox,
  ShowPassword,
  WrapLink,
  LinkRecover,
  WrapButton,
} from './styles';
const LoginForm = ({
  className,
  userDashboard,
  fetchUserDash,
  history,
  infoCurrentUserDashboard,
  getCleanUserRoleDash,
  getInforCurrentUserDash,
  loadingDashboard,
}) => {
  const MySwal = withReactContent(Swal);

  const [showPasswod, setShowPassword] = useState(true);
  const defaultValues = {
    username: '',
    password: '',
  };
  useEffect(() => {
    const errorLogin = (message, role) => {
      MySwal.fire({
        icon: 'warning',
        showCloseButton: true,
        confirmButtonText: 'continuar',
        allowOutsideClick: false,
        focusConfirm: false,
        title: message.title,
        text: message.text,
      }).then((result) => {
        console.log('result',result)
        console.log('role',role)
        if (result) {
          console.log('xxx1')
          
          getCleanUserRoleDash();
        }
      });
    };
    if (userDashboard && userDashboard.status === 200 && !infoCurrentUserDashboard) {
      const dataCurrentUser = {
        id: userDashboard.info.user_id,
        token: userDashboard.info.token,
      };
      getInforCurrentUserDash(dataCurrentUser);
    }
    if (
      infoCurrentUserDashboard &&
      infoCurrentUserDashboard.info.role[0] === 'dashboard'
    ) {
      const dataCurrent = JSON.stringify(userDashboard);
      sessionStorage.loginDashboard = Base64.encode(dataCurrent);
      history.push('/dashboard');
    }
    if (
      infoCurrentUserDashboard &&
      infoCurrentUserDashboard.info.role[0] !== 'dashboard'
    ) {
      const message = {
        title: 'Error al iniciar sesión',
        text: 'No tiene permiso para ingresar a esta pagina',
      };
      errorLogin(message, true);
    }
    if (userDashboard && userDashboard.status === 403) {
      const message = {
        title: 'Error al iniciar sesión ',
        text: 'Revise su correo o contraseña',
        role: false,
      };
      errorLogin(message);
    }
  }, [
    getInforCurrentUserDash,
    userDashboard,
    MySwal,
    infoCurrentUserDashboard,
    getCleanUserRoleDash,
    history,
  ]);
  return (
    <>
      {loadingDashboard && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      <Wrap className={className ? className : ''}>
        <Container>
          <LogoForm>
            <img
              src='/static/img/login-form-logo.png'
              width='76'
              height='56'
              alt='logo'
            />
          </LogoForm>
          <TitleForm>Ingresa a tu Cuenta</TitleForm>
          <Formik
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values) => {
              const errors = {};

              if (!values.username) {
                errors.username = 'Campo Obligatorio';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
              ) {
                errors.username = 'Email invalido';
              }
              if (values.password.trim() === '') {
                errors.password = 'Campo Obligatorio';
              }

              if (Object.keys(errors).length > 0) return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {

              setTimeout(() => {
                setSubmitting(false);
                fetchUserDash(values);
                // resetForm();
              }, 500);
            }}
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form className='formularyLogin'>
                <FormInner>
                  <InputBox>
                    <Field
                      fullWidth
                      name='username'
                      type='text'
                      placeholder='Correo'
                      component={TextField}
                    />
                  </InputBox>
                  <InputBox>
                    <Field
                      fullWidth
                      name='password'
                      type={showPasswod ? 'password' : 'text'}
                      placeholder='Contraseña'
                      component={TextField}
                    />
                    <ShowPassword onClick={() => setShowPassword(!showPasswod)}>
                      <img
                        src={`/assets/images/icon-eyes-${showPasswod ? 'on' : 'off'}.svg`}
                        width={25}
                        alt=''
                      />
                    </ShowPassword>
                  </InputBox>
                </FormInner>
                <WrapLink>
                  <LinkRecover>
                    <Link to='/recover-password'>¿Olvidaste tu Contraseña?</Link>
                  </LinkRecover>
                </WrapLink>

                <WrapButton>
                  <button
                    className='ctaButton medium'
                    type='submit'
                    disabled={isSubmitting}
                    onClick={() => submitForm}
                  >
                    Iniciar Sesión
                    {isSubmitting && <Loading />}
                  </button>
                </WrapButton>
              </Form>
            )}
          </Formik>
        </Container>
      </Wrap>
    </>
  );
};

export default LoginForm;
