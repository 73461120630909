import { Span } from '../Text';
import styled from 'styled-components/macro';

export default styled(Span).attrs(({ font, secondary }) => {
  const parsedProps = { font: font || 'quaternary' };
  if (secondary) parsedProps.color = 'secondary';
  return parsedProps;
})`
  ${({ block }) => (block ? 'display: block;' : '')}
  font-weight: 800;
`;
