import { connect } from 'react-redux';
import { fetchNovedades } from '../../../store/web/novedades';

import Novedades from './Novedades';

const mapStateToProps = (state) => {
  return {
    novedades: state.novedadesState.novedades,
    footerData: state.footerState.footerData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNovedades: (data) => dispatch(fetchNovedades(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Novedades);
