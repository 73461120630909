import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import Fonts from './fonts';
import { fonts, colors } from './web/theme';
export const theme = {
  shadows: {
    soft: '0px 0px 10px rgba(0, 0, 0, 0.07)',
    middle: '0px 3px 15px rgba(0, 0, 0, 0.07)',
    alto: '0px 0px 10px rgba(58, 162, 41, 0.2)',
    iconButton: '0px 3px 15px rgba(58, 162, 41, 0.15)',
  },
};

export const commonStyleMixim = css`
  box-shadow: ${({ theme, shadow }) => theme.shadows[shadow] ?? 'none'};
  margin: ${({ margin }) => margin ?? 'initial'};
  border-radius: ${({ round, radius = false }) =>
    radius ? (round ? '30px' : '7px') : 0};
`;

export const GlobalStyle = createGlobalStyle`
  ion-backdrop {
    opacity: 0.3;
    &[data-type="custom-pop-up"] {
      z-index: 1000;
    }
    --ion-backdrop-color: var(--ion-color-secondary);
    position: fixed;
  }
  ${Fonts}
  .title-code{
    text-align: center;
    h3, p{
      font-weight: normal;
      margin: 0;
    }
    h3{
      letter-spacing: -0.03em;
      font-size: 25px;
      color: var(--ion-color-secondary);
      font-family: var(--ion-font-family-quinary);
    }
    p{
      letter-spacing: -0.02em;
      font-size: 13px;
    }
  }
  body{
    color: var(--ion-color-primary);
    margin:0px;
    font-weight: 400;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    background-color: #FDFDFD;
  }
  .popup-suscription{
    .swal2-title{
      font-size: 16px;
      color: var(--ion-color-secondary);
      font-family: var(--ion-font-family-quinary);
    }
  }  
  body.swal2-height-auto{
    height: 100% !important;
  }
  .swal2-popup{
    .swal2-icon.swal2-warning{
      margin: 0;
      margin-bottom: 10px;
      border-color:${colors.secondary};
      color:${colors.secondary};
    }
    .swal2-styled.swal2-confirm{
      background-color: ${colors.secondary};
    }
    .swal2-close:hover{
      color:${colors.secondary};
    }
  }
  .swal2-container.swal2-backdrop-show{
    background:rgba(58,162,41,0.3) !important
  }
  .custom-alert{
    font-size: 14px;
    p{
      margin: 0;
    }
    strong{
      font-size: 16px;
      color:${colors.secondary};
      font-family: ${fonts.lato800};
    }
  }  
  a {
    text-decoration:none;
  }
  button:focus, select:focus, input:focus, textarea:focus{
    outline:none
  }

  button {
    border: none;
    background: none;
    padding: 0;
  }

  figure {
    margin: 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  ul {
    margin: 0px;
    padding: 0px;
  }
  li{
      list-style:none
  }
  .info-general{
    strong{
      color: var(--ion-color-secondary)
    }
  }
  .box-acount-company{
    > div{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .MuiButton-textPrimary{
    color:var(--ion-color-secondary) !important;
  }
  .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
    background-color: var(--ion-color-secondary) !important;
  }
  .content{
      max-width: 1045px;
      width: 94%;
      margin: 0 auto;
  }
  .flex{
    display: flex;
    flex-flow:row wrap;
  }
  .SentAmount .inputItem ::-webkit-input-placeholder {
    color: var(--ion-color-primary) !important;
  }
  .SentAmount .inputItem :-moz-placeholder {
    color: var(--ion-color-primary) !important;
  }
  .SentAmount .inputItem ::-moz-placeholder {
    color: var(--ion-color-primary) !important;
  }
  .SentAmount .inputItem :-ms-input-placeholder {
    color: var(--ion-color-primary) !important;
  }

  .ReceivedAmount .inputItem ::-webkit-input-placeholder {
    color: var(--ion-color-secondary) !important;
  }
  .ReceivedAmount .inputItem :-moz-placeholder {
    color: var(--ion-color-secondary) !important;
  }
  .ReceivedAmount .inputItem ::-moz-placeholder {
    color: var(--ion-color-secondary) !important;
  }
  .ReceivedAmount .inputItem :-ms-input-placeholder {
    color: var(--ion-color-secondary) !important;
  }
  .popup-voucher{
    .swal2-icon{
      display:none !important;
    }
  }
  .formulary-share{
      .MuiInputBase-root{
        background: var(--ion-color-primary-contrast);
        /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07); */
        box-shadow: ${({ theme }) => theme.shadows.soft ?? '0 0 10px rgba(0,0,0,0.07)'};
        border-radius: 30px;
        height: 50px;
        width: 300px;
        box-sizing: border-box;
        padding: 0 10px;
      }
      .MuiInputBase-input{
        text-align: center;
        font-size: 17px;
      }
  }
  .MuiInput-underline {
    :after,
    :before {
      display: none;
    }
  }
  /* .identity-verification-page ion-content {
    --offset-bottom: -40px !important;
  } */

  ion-tab-button {
    --color-selected: var(--ion-color-secondary);
    --color: var(--ion-color-primary);
    background-color: #fff;
    position: relative;
    ion-label {
      font-size: 11px;
    }

    &.tab-selected {
      box-shadow: 0px 3px 25px rgba(58, 162, 41, 0.1);

      margin-top: -10px;
      height: calc(100% + 10px);
      padding-top: 10px;
      z-index: 9;
      position: relative;
      border-radius: 15px 15px 0 0;
      &:first-child {
        border-top-left-radius: 0;
      }
      &:last-child {
        border-top-right-radius: 0;
      }

    }


    &::before {
      content: '';
      width: 1px;
      height: 35px;
      top: calc(40% - (35px / 2));
      background-color: #000;
      opacity: 0.08;
      display: block;
      position: absolute;
    }

    &.tab-selected + & , &.tab-selected {
      &::before {
        display: none;
      }
    }

    &.tab-selected ion-label {
      font-weight: 600;
    }
  }
  .contentDesktop{
    overflow: auto;
    position: relative;
  }
  ion-tab-bar {
    --border: none;
    --ion-tab-bar-background: transparent;
    box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.06);
    contain: none;
  }
  .alert-radio-label.sc-ion-alert-md{
    white-space:inherit;
    text-overflow:inherit;
    padding-inline-start:40px;
  }
  .text-no-operation{
    font-size: 16px;
    color:var(--ion-color-secondary);
    text-align: center;
    padding:20px 0
  }
  .ico-success{
    text-align: center;
    margin-bottom: 20px;
  }
  /* .ion-content-app{
    @media screen and (min-height: 566px) {
      --overflow: hidden;
    }    

  } */
  .ongoing-operations-page, .completed-operations-page, .canceled-operations-page {
    &.fixed {
      .tabs-historial {
        position: fixed;
        background-color: #fff;
        top: 59px;
        left: 0;
        z-index: 10;
        margin: 0 auto;
        right: 0;
      }
    }
  }  
  .password-recovery-page{
    .iso-tipo{
      opacity: 0;
    }
  }
  .no-account{
    font-size: 11px;
    text-align: center;
    margin-bottom: 5px;
    padding: 0 20px;
  }
  .label-email{
    font-size: 10px; 
    margin: 0;
  }
  .label-input-field{
    max-width:300px;
    width: 94%;
    margin: 0 auto;    
    font-size: 12px; 
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 5px;
    margin-top: 15px;    
  }
  .option-no-data{
    display: none;
  }
  .toogle-notify-tc{
    &.is_checked{
      .btn-toogle{
        background-color: var(--ion-color-secondary);
      }
     
    }
  }
  .Error404 .web-wrap{
    justify-content:center;
  }
`;

export const Section = styled.section``;
export const InputWrap = styled.div`
  max-width: 300px;
  width: 94%;
  margin: 0 auto;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiIconButton-root {
    right: 10px;
  }
  .MuiInput-root {
    box-shadow: 0px 3px 10px rgb(0 0 0 / 7%);
    border-radius: 30px;
    border: none;
  }
  .MuiInputBase-input {
    height: 50px;
    font-size: 15px;
    padding: 0 30px;
    box-sizing: border-box;
  }
`;

export const Content = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || '480px'};
  width: 94%;
  margin: 0 auto;
  padding: ${({ padding }) => padding || '0px'};
  padding-top: 20px;
  padding-bottom: 30px;
  box-sizing: border-box;
  &.desktop {
    padding: 50px;
    min-height: 670px;
    &.simulador-page {
      min-height: 100vh;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* padding: 20px 0px; */
    }
  }
`;

/**
  este componente centra los childrens con un máximo de 300px,
  y coloca lo botones (children[1]) al final de la pantalla si el contenido
  no cubre toda la pantalla. Se deben pasar exactamente 2 hijos al componente.
  El componenete ereda los mismos props del componente "Content"

  ejemplo (usage):

    <ContentCenterAndBottomButtoms {...contentProps}>
      <>
        { // contenido }
      </>
      <>
        { // bottones o lo que se quiera mantener en el bottom }
      </>
    </ContentCenterAndBottomButtoms>
*/
export const ContentCenterAndBottomButtoms = styled(({ children, ...p }) => {
  if (React.Children.count(children) !== 2) throw new RangeError('Must be 2 childrens');
  return (
    <Content {...p}>
      <div>{children[0]}</div>
      <div>{children[1]}</div>
    </Content>
  );
})`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :first-child {
    flex: 1;
    max-width: 3000;
  }
`;

export const Button = styled.button`
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-font-family-secondary);
  font-size: 19px;
  line-height: 22px;
  ${(props) => (props.round ? 'border-radius: 30px;' : 'border-radius: 7px;')}
  box-shadow: 0px 3px 35px rgba(58, 162, 41, 0.2);
  @media screen and (max-height: 730px) {
    height: 50px;
  }
  &[disabled] {
    background-color: #928e8e;
  }
`;
export const CntButton = styled.div`
  display: flex;
  ${(props) => (props.center ? 'justify-content: center;' : '')}
  align-items: center;
  @media screen and (max-height: 650px) {
    margin-top: 5px;
  }
`;
export const IconButton = styled.button`
  --size: ${({ size = '40px', padding = '20px' }) => `calc(${size} + ${padding})`};
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-secondary);
  box-shadow: ${({ theme, shadow }) => theme.shadows[shadow] ?? theme.shadows.iconButton};
  ${(props) => (props.round ? 'border-radius: 50%;' : 'border-radius: 7px;')}
  align-self: end;
`;

export const IconButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  max-width: 300px;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`;
export const WrapError = styled.div`
  max-width: 260px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  &.hidde {
    display: none;
  }
`;
export const TextError = styled.div`
  color: var(--ion-color-danger);
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
  margin: 5px 0;
  // position: absolute;
  // top: 6px;
  // right: 0;
`;
export const NoData = styled.h3`
  font-size: 13px;
  text-align: center;
`;
export const WrapSelectItem = styled.div``;
export const SelectItem = styled.div`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  position: relative;
  margin-bottom: 15px;
  &:after {
    content: '\\e90f';
    font-family: 'icomoon';
    position: absolute;
    top: 0;
    right: 13px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: var(--ion-color-secondary);
  }
  select {
    width: 100%;
    height: 48px;
    background: #fff;
    appearance: none;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 35px;
    box-sizing: border-box;
    color: #717171;

    &::-ms-expand {
      display: none;
    }
  }
  @media screen and (max-height: 800px) {
    margin-bottom: 10px;
  }
  @media screen and (max-height: 660px) {
    select {
      height: 40px;
    }
  }
`;
export const WrapLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-secondary);
    z-index: 2;
    opacity: 0.1;
  }
`;
export const Loading = styled.div`
  width: 200px;
  position: relative;
  z-index: 3;
`;
export const WrapNews = styled.div`
  padding-top: 120px;
`;
export const RegisterTitle = styled.h4`
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
`;
export const DescriptionRegister = styled.div`
  font-size: 17px;
  font-family: ${fonts.lato700};
  letter-spacing: -0.2px;
  p {
    margin: 0;
  }
  strong {
    font-family: ${fonts.lato800};
    font-weight: normal;
    color: ${colors.secondary};
  }
`;
export const WrapRegister = styled.div`
  max-width: 190px;
  margin: 0 auto;
  text-align: center;
  margin-top: 29px;
`;

export const ButtonVolver = styled.div`
  width: 90px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  justify-content: center;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 8px 7px 0;
    border-color: transparent var(--ion-color-secondary) transparent transparent;
  }
`;

export const ButtonVolverWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50%;
  width: 50vw;
  max-width: 150px;
  z-index: 2;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.static {
    position: static;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  &.register {
    position: static;
    width: 100%;
    margin: 0;
    ${ButtonVolver} {
      width: 100%;
    }
  }
`;
