import React, { useState, useCallback, useEffect } from 'react';
import Toggle from '../toggle';
import Strong from '../Strong';
import { InputBox, InputContainer } from './styles';

const Input = ({
  label,
  value,
  checked: checkedVal,
  onCheck,
  onBlur,
  onFocus,
  className,
  ...p
}) => {
  const [checked, setChecked] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false);

  const toggle = useCallback(() => {
    let newVal;
    setChecked((v) => (newVal = !v));
    if (onCheck) onCheck(newVal);
  }, [onCheck]);
  const onFocusHandler = useCallback(
    (e) => {
      setCheckDisabled(true);
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );
  const onBlurHandler = useCallback(
    (e) => {
      setCheckDisabled(false);
      if (onBlur) onBlur(e);
    },
    [onBlur]
  );

  useEffect(() => {
    setChecked(checkedVal);
  }, [checkedVal]);
  return (
    <InputContainer className={className || ''}>
      <div style={{ textAlign: 'right', paddingRight: 10 }}>
        <p>Notificarme cuando la</p>
        <Strong secondary>{label}</Strong>
      </div>
      <InputBox>
        <input
          type='number'
          value={value}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          {...p}
        />

        <Toggle
          className={
            checked ? 'toogle-notify-tc is_checked' : 'toogle-notify-tc no-checked'
          }
          checked={checked}
          onClick={toggle}
          disabled={checkDisabled}
        />
      </InputBox>
    </InputContainer>
  );
};

export default Input;
