import React from 'react';
import LoginForm from '../../components/web/LoginForm';
const Login = ({ history }) => {
  return (
    <>
      <LoginForm history={history} />
    </>
  );
};

export default Login;
