export const colors = {
  primary: '#717171',
  secondary: '#3AA229',
  tertiary: '#FECF30',
};

export const fonts = {
  lato400: 'Lato-Regular',
  lato600: 'Lato-Semibold',
  lato700: 'Lato-Bold',
  lato800: 'Lato-ExtraBold',
  lato900: 'Lato-Black',
};
