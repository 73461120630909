export const ubigeo = {
  departamentos: [
    {
      id_ubigeo: '2534',
      nombre_ubigeo: 'Amazonas',
      codigo_ubigeo: '01',
      etiqueta_ubigeo: 'Amazonas, Per\u00fa',
      buscador_ubigeo: 'amazonas per\u00fa',
      numero_hijos_ubigeo: '7',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '2625',
      nombre_ubigeo: 'Ancash',
      codigo_ubigeo: '02',
      etiqueta_ubigeo: 'Ancash, Per\u00fa',
      buscador_ubigeo: 'ancash per\u00fa',
      numero_hijos_ubigeo: '20',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '2812',
      nombre_ubigeo: 'Apurimac',
      codigo_ubigeo: '03',
      etiqueta_ubigeo: 'Apurimac, Per\u00fa',
      buscador_ubigeo: 'apurimac per\u00fa',
      numero_hijos_ubigeo: '7',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '2900',
      nombre_ubigeo: 'Arequipa',
      codigo_ubigeo: '04',
      etiqueta_ubigeo: 'Arequipa, Per\u00fa',
      buscador_ubigeo: 'arequipa per\u00fa',
      numero_hijos_ubigeo: '8',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3020',
      nombre_ubigeo: 'Ayacucho',
      codigo_ubigeo: '05',
      etiqueta_ubigeo: 'Ayacucho, Per\u00fa',
      buscador_ubigeo: 'ayacucho per\u00fa',
      numero_hijos_ubigeo: '11',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3143',
      nombre_ubigeo: 'Cajamarca',
      codigo_ubigeo: '06',
      etiqueta_ubigeo: 'Cajamarca, Per\u00fa',
      buscador_ubigeo: 'cajamarca per\u00fa',
      numero_hijos_ubigeo: '13',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3292',
      nombre_ubigeo: 'Cusco',
      codigo_ubigeo: '08',
      etiqueta_ubigeo: 'Cusco, Per\u00fa',
      buscador_ubigeo: 'cusco per\u00fa',
      numero_hijos_ubigeo: '13',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3414',
      nombre_ubigeo: 'Huancavelica',
      codigo_ubigeo: '09',
      etiqueta_ubigeo: 'Huancavelica, Per\u00fa',
      buscador_ubigeo: 'huancavelica per\u00fa',
      numero_hijos_ubigeo: '7',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3518',
      nombre_ubigeo: 'Huanuco',
      codigo_ubigeo: '10',
      etiqueta_ubigeo: 'Huanuco, Per\u00fa',
      buscador_ubigeo: 'huanuco per\u00fa',
      numero_hijos_ubigeo: '11',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3606',
      nombre_ubigeo: 'Ica',
      codigo_ubigeo: '11',
      etiqueta_ubigeo: 'Ica, Per\u00fa',
      buscador_ubigeo: 'ica per\u00fa',
      numero_hijos_ubigeo: '5',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3655',
      nombre_ubigeo: 'Junin',
      codigo_ubigeo: '12',
      etiqueta_ubigeo: 'Junin, Per\u00fa',
      buscador_ubigeo: 'junin per\u00fa',
      numero_hijos_ubigeo: '9',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3788',
      nombre_ubigeo: 'La Libertad',
      codigo_ubigeo: '13',
      etiqueta_ubigeo: 'La Libertad, Per\u00fa',
      buscador_ubigeo: 'la libertad per\u00fa',
      numero_hijos_ubigeo: '12',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3884',
      nombre_ubigeo: 'Lambayeque',
      codigo_ubigeo: '14',
      etiqueta_ubigeo: 'Lambayeque, Per\u00fa',
      buscador_ubigeo: 'lambayeque per\u00fa',
      numero_hijos_ubigeo: '3',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '3926',
      nombre_ubigeo: 'Lima',
      codigo_ubigeo: '15',
      etiqueta_ubigeo: 'Lima, Per\u00fa',
      buscador_ubigeo: 'lima per\u00fa',
      numero_hijos_ubigeo: '10',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4108',
      nombre_ubigeo: 'Loreto',
      codigo_ubigeo: '16',
      etiqueta_ubigeo: 'Loreto, Per\u00fa',
      buscador_ubigeo: 'loreto per\u00fa',
      numero_hijos_ubigeo: '6',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4165',
      nombre_ubigeo: 'Madre de Dios',
      codigo_ubigeo: '17',
      etiqueta_ubigeo: 'Madre de Dios, Per\u00fa',
      buscador_ubigeo: 'madre de dios per\u00fa',
      numero_hijos_ubigeo: '3',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4180',
      nombre_ubigeo: 'Moquegua',
      codigo_ubigeo: '18',
      etiqueta_ubigeo: 'Moquegua, Per\u00fa',
      buscador_ubigeo: 'moquegua per\u00fa',
      numero_hijos_ubigeo: '3',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4204',
      nombre_ubigeo: 'Pasco',
      codigo_ubigeo: '19',
      etiqueta_ubigeo: 'Pasco, Per\u00fa',
      buscador_ubigeo: 'pasco per\u00fa',
      numero_hijos_ubigeo: '3',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4236',
      nombre_ubigeo: 'Piura',
      codigo_ubigeo: '20',
      etiqueta_ubigeo: 'Piura, Per\u00fa',
      buscador_ubigeo: 'piura per\u00fa',
      numero_hijos_ubigeo: '8',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4309',
      nombre_ubigeo: 'Puno',
      codigo_ubigeo: '21',
      etiqueta_ubigeo: 'Puno, Per\u00fa',
      buscador_ubigeo: 'puno per\u00fa',
      numero_hijos_ubigeo: '13',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4431',
      nombre_ubigeo: 'San Martin',
      codigo_ubigeo: '22',
      etiqueta_ubigeo: 'San Martin, Per\u00fa',
      buscador_ubigeo: 'san martin per\u00fa',
      numero_hijos_ubigeo: '10',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4519',
      nombre_ubigeo: 'Tacna',
      codigo_ubigeo: '23',
      etiqueta_ubigeo: 'Tacna, Per\u00fa',
      buscador_ubigeo: 'tacna per\u00fa',
      numero_hijos_ubigeo: '4',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4551',
      nombre_ubigeo: 'Tumbes',
      codigo_ubigeo: '24',
      etiqueta_ubigeo: 'Tumbes, Per\u00fa',
      buscador_ubigeo: 'tumbes per\u00fa',
      numero_hijos_ubigeo: '3',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
    {
      id_ubigeo: '4567',
      nombre_ubigeo: 'Ucayali',
      codigo_ubigeo: '25',
      etiqueta_ubigeo: 'Ucayali, Per\u00fa',
      buscador_ubigeo: 'ucayali per\u00fa',
      numero_hijos_ubigeo: '4',
      nivel_ubigeo: '1',
      id_padre_ubigeo: '2533',
    },
  ],
  provincias: {
    '2534': [
      {
        id_ubigeo: '2557',
        nombre_ubigeo: 'Bagua',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Bagua, Amazonas',
        buscador_ubigeo: 'bagua amazonas',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
      {
        id_ubigeo: '2563',
        nombre_ubigeo: 'Bongara',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Bongara, Amazonas',
        buscador_ubigeo: 'bongara amazonas',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
      {
        id_ubigeo: '2535',
        nombre_ubigeo: 'Chachapoyas',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Chachapoyas, Amazonas',
        buscador_ubigeo: 'chachapoyas amazonas',
        numero_hijos_ubigeo: '21',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
      {
        id_ubigeo: '2576',
        nombre_ubigeo: 'Condorcanqui',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Condorcanqui, Amazonas',
        buscador_ubigeo: 'condorcanqui amazonas',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
      {
        id_ubigeo: '2580',
        nombre_ubigeo: 'Luya',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Luya, Amazonas',
        buscador_ubigeo: 'luya amazonas',
        numero_hijos_ubigeo: '23',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
      {
        id_ubigeo: '2604',
        nombre_ubigeo: 'Rodriguez de Mendoza',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Rodriguez de Mendoza, Amazonas',
        buscador_ubigeo: 'rodriguez de mendoza amazonas',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
      {
        id_ubigeo: '2617',
        nombre_ubigeo: 'Utcubamba',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Utcubamba, Amazonas',
        buscador_ubigeo: 'utcubamba amazonas',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2534',
      },
    ],
    '2625': [
      {
        id_ubigeo: '2639',
        nombre_ubigeo: 'Aija',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Aija, Ancash',
        buscador_ubigeo: 'aija ancash',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2645',
        nombre_ubigeo: 'Antonio Raymondi',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Antonio Raymondi, Ancash',
        buscador_ubigeo: 'antonio raymondi ancash',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2652',
        nombre_ubigeo: 'Asuncion',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Asuncion, Ancash',
        buscador_ubigeo: 'asuncion ancash',
        numero_hijos_ubigeo: '2',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2655',
        nombre_ubigeo: 'Bolognesi',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Bolognesi, Ancash',
        buscador_ubigeo: 'bolognesi ancash',
        numero_hijos_ubigeo: '15',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2671',
        nombre_ubigeo: 'Carhuaz',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Carhuaz, Ancash',
        buscador_ubigeo: 'carhuaz ancash',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2683',
        nombre_ubigeo: 'Carlos Fermin Fitzcarrald',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Carlos Fermin Fitzcarrald, Ancash',
        buscador_ubigeo: 'carlos fermin fitzcarrald ancash',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2687',
        nombre_ubigeo: 'Casma',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Casma, Ancash',
        buscador_ubigeo: 'casma ancash',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2692',
        nombre_ubigeo: 'Corongo',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Corongo, Ancash',
        buscador_ubigeo: 'corongo ancash',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2626',
        nombre_ubigeo: 'Huaraz',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Huaraz, Ancash',
        buscador_ubigeo: 'huaraz ancash',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2700',
        nombre_ubigeo: 'Huari',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Huari, Ancash',
        buscador_ubigeo: 'huari ancash',
        numero_hijos_ubigeo: '16',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2717',
        nombre_ubigeo: 'Huarmey',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'Huarmey, Ancash',
        buscador_ubigeo: 'huarmey ancash',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2723',
        nombre_ubigeo: 'Huaylas',
        codigo_ubigeo: '12',
        etiqueta_ubigeo: 'Huaylas, Ancash',
        buscador_ubigeo: 'huaylas ancash',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2734',
        nombre_ubigeo: 'Mariscal Luzuriaga',
        codigo_ubigeo: '13',
        etiqueta_ubigeo: 'Mariscal Luzuriaga, Ancash',
        buscador_ubigeo: 'mariscal luzuriaga ancash',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2743',
        nombre_ubigeo: 'Ocros',
        codigo_ubigeo: '14',
        etiqueta_ubigeo: 'Ocros, Ancash',
        buscador_ubigeo: 'ocros ancash',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2754',
        nombre_ubigeo: 'Pallasca',
        codigo_ubigeo: '15',
        etiqueta_ubigeo: 'Pallasca, Ancash',
        buscador_ubigeo: 'pallasca ancash',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2766',
        nombre_ubigeo: 'Pomabamba',
        codigo_ubigeo: '16',
        etiqueta_ubigeo: 'Pomabamba, Ancash',
        buscador_ubigeo: 'pomabamba ancash',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2771',
        nombre_ubigeo: 'Recuay',
        codigo_ubigeo: '17',
        etiqueta_ubigeo: 'Recuay, Ancash',
        buscador_ubigeo: 'recuay ancash',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2782',
        nombre_ubigeo: 'Santa',
        codigo_ubigeo: '18',
        etiqueta_ubigeo: 'Santa, Ancash',
        buscador_ubigeo: 'santa ancash',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2792',
        nombre_ubigeo: 'Sihuas',
        codigo_ubigeo: '19',
        etiqueta_ubigeo: 'Sihuas, Ancash',
        buscador_ubigeo: 'sihuas ancash',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
      {
        id_ubigeo: '2803',
        nombre_ubigeo: 'Yungay',
        codigo_ubigeo: '20',
        etiqueta_ubigeo: 'Yungay, Ancash',
        buscador_ubigeo: 'yungay ancash',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2625',
      },
    ],
    '2812': [
      {
        id_ubigeo: '2813',
        nombre_ubigeo: 'Abancay',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Abancay, Apurimac',
        buscador_ubigeo: 'abancay apurimac',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
      {
        id_ubigeo: '2823',
        nombre_ubigeo: 'Andahuaylas',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Andahuaylas, Apurimac',
        buscador_ubigeo: 'andahuaylas apurimac',
        numero_hijos_ubigeo: '19',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
      {
        id_ubigeo: '2843',
        nombre_ubigeo: 'Antabamba',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Antabamba, Apurimac',
        buscador_ubigeo: 'antabamba apurimac',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
      {
        id_ubigeo: '2851',
        nombre_ubigeo: 'Aymaraes',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Aymaraes, Apurimac',
        buscador_ubigeo: 'aymaraes apurimac',
        numero_hijos_ubigeo: '17',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
      {
        id_ubigeo: '2876',
        nombre_ubigeo: 'Chincheros',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Chincheros, Apurimac',
        buscador_ubigeo: 'chincheros apurimac',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
      {
        id_ubigeo: '2869',
        nombre_ubigeo: 'Cotabambas',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Cotabambas, Apurimac',
        buscador_ubigeo: 'cotabambas apurimac',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
      {
        id_ubigeo: '2885',
        nombre_ubigeo: 'Grau',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Grau, Apurimac',
        buscador_ubigeo: 'grau apurimac',
        numero_hijos_ubigeo: '14',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2812',
      },
    ],
    '2900': [
      {
        id_ubigeo: '2901',
        nombre_ubigeo: 'Arequipa',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Arequipa, Arequipa, Arequipa',
        buscador_ubigeo: 'arequipa arequipa arequipa',
        numero_hijos_ubigeo: '29',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '2931',
        nombre_ubigeo: 'Camana',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Camana, Arequipa',
        buscador_ubigeo: 'camana arequipa',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '2940',
        nombre_ubigeo: 'Caraveli',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Caraveli, Arequipa',
        buscador_ubigeo: 'caraveli arequipa',
        numero_hijos_ubigeo: '13',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '2954',
        nombre_ubigeo: 'Castilla',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Castilla, Arequipa',
        buscador_ubigeo: 'castilla arequipa',
        numero_hijos_ubigeo: '16',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '2971',
        nombre_ubigeo: 'Caylloma',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Caylloma, Arequipa',
        buscador_ubigeo: 'caylloma arequipa',
        numero_hijos_ubigeo: '20',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '2992',
        nombre_ubigeo: 'Condesuyos',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Condesuyos, Arequipa',
        buscador_ubigeo: 'condesuyos arequipa',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '3001',
        nombre_ubigeo: 'Islay',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Islay, Arequipa',
        buscador_ubigeo: 'islay arequipa',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
      {
        id_ubigeo: '3008',
        nombre_ubigeo: 'La Union',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'La Union, Arequipa',
        buscador_ubigeo: 'la union arequipa',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '2900',
      },
    ],
    '3020': [
      {
        id_ubigeo: '3037',
        nombre_ubigeo: 'Cangallo',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Cangallo, Ayacucho',
        buscador_ubigeo: 'cangallo ayacucho',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3021',
        nombre_ubigeo: 'Huamanga',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Huamanga, Ayacucho',
        buscador_ubigeo: 'huamanga ayacucho',
        numero_hijos_ubigeo: '15',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3044',
        nombre_ubigeo: 'Huanca Sancos',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Huanca Sancos, Ayacucho',
        buscador_ubigeo: 'huanca sancos ayacucho',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3049',
        nombre_ubigeo: 'Huanta',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Huanta, Ayacucho',
        buscador_ubigeo: 'huanta ayacucho',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3058',
        nombre_ubigeo: 'La Mar',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'La Mar, Ayacucho',
        buscador_ubigeo: 'la mar ayacucho',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3067',
        nombre_ubigeo: 'Lucanas',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Lucanas, Ayacucho',
        buscador_ubigeo: 'lucanas ayacucho',
        numero_hijos_ubigeo: '21',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3089',
        nombre_ubigeo: 'Parinacochas',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Parinacochas, Ayacucho',
        buscador_ubigeo: 'parinacochas ayacucho',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3098',
        nombre_ubigeo: 'Paucar del Sara Sara',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Paucar del Sara Sara, Ayacucho',
        buscador_ubigeo: 'paucar del sara sara ayacucho',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3109',
        nombre_ubigeo: 'Sucre',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Sucre, Ayacucho',
        buscador_ubigeo: 'sucre ayacucho',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3121',
        nombre_ubigeo: 'Victor Fajardo',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Victor Fajardo, Ayacucho',
        buscador_ubigeo: 'victor fajardo ayacucho',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
      {
        id_ubigeo: '3134',
        nombre_ubigeo: 'Vilcas Huaman',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'Vilcas Huaman, Ayacucho',
        buscador_ubigeo: 'vilcas huaman ayacucho',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3020',
      },
    ],
    '3143': [
      {
        id_ubigeo: '3157',
        nombre_ubigeo: 'Cajabamba',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Cajabamba, Cajamarca',
        buscador_ubigeo: 'cajabamba cajamarca',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3144',
        nombre_ubigeo: 'Cajamarca',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Cajamarca, Cajamarca, Cajamarca',
        buscador_ubigeo: 'cajamarca cajamarca cajamarca',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3162',
        nombre_ubigeo: 'Celendin',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Celendin, Cajamarca',
        buscador_ubigeo: 'celendin cajamarca',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3175',
        nombre_ubigeo: 'Chota',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Chota, Cajamarca',
        buscador_ubigeo: 'chota cajamarca',
        numero_hijos_ubigeo: '19',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3195',
        nombre_ubigeo: 'Contumaza',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Contumaza, Cajamarca',
        buscador_ubigeo: 'contumaza cajamarca',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3204',
        nombre_ubigeo: 'Cutervo',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Cutervo, Cajamarca',
        buscador_ubigeo: 'cutervo cajamarca',
        numero_hijos_ubigeo: '15',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3220',
        nombre_ubigeo: 'Hualgayoc',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Hualgayoc, Cajamarca',
        buscador_ubigeo: 'hualgayoc cajamarca',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3224',
        nombre_ubigeo: 'Jaen',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Jaen, Cajamarca',
        buscador_ubigeo: 'jaen cajamarca',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3237',
        nombre_ubigeo: 'San Ignacio',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'San Ignacio, Cajamarca',
        buscador_ubigeo: 'san ignacio cajamarca',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3245',
        nombre_ubigeo: 'San Marcos',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'San Marcos, Cajamarca',
        buscador_ubigeo: 'san marcos cajamarca',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3253',
        nombre_ubigeo: 'San Miguel',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'San Miguel, Cajamarca',
        buscador_ubigeo: 'san miguel cajamarca',
        numero_hijos_ubigeo: '13',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3267',
        nombre_ubigeo: 'San Pablo',
        codigo_ubigeo: '12',
        etiqueta_ubigeo: 'San Pablo, Cajamarca',
        buscador_ubigeo: 'san pablo cajamarca',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
      {
        id_ubigeo: '3272',
        nombre_ubigeo: 'Santa Cruz',
        codigo_ubigeo: '13',
        etiqueta_ubigeo: 'Santa Cruz, Cajamarca',
        buscador_ubigeo: 'santa cruz cajamarca',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3143',
      },
    ],
    '3292': [
      {
        id_ubigeo: '3302',
        nombre_ubigeo: 'Acomayo',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Acomayo, Cusco',
        buscador_ubigeo: 'acomayo cusco',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3310',
        nombre_ubigeo: 'Anta',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Anta, Cusco',
        buscador_ubigeo: 'anta cusco',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3320',
        nombre_ubigeo: 'Calca',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Calca, Cusco',
        buscador_ubigeo: 'calca cusco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3329',
        nombre_ubigeo: 'Canas',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Canas, Cusco',
        buscador_ubigeo: 'canas cusco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3338',
        nombre_ubigeo: 'Canchis',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Canchis, Cusco',
        buscador_ubigeo: 'canchis cusco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3347',
        nombre_ubigeo: 'Chumbivilcas',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Chumbivilcas, Cusco',
        buscador_ubigeo: 'chumbivilcas cusco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3293',
        nombre_ubigeo: 'Cusco',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Cusco, Cusco, Cusco',
        buscador_ubigeo: 'cusco cusco cusco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3356',
        nombre_ubigeo: 'Espinar',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Espinar, Cusco',
        buscador_ubigeo: 'espinar cusco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3365',
        nombre_ubigeo: 'La Convencion',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'La Convencion, Cusco',
        buscador_ubigeo: 'la convencion cusco',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3376',
        nombre_ubigeo: 'Paruro',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Paruro, Cusco',
        buscador_ubigeo: 'paruro cusco',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3386',
        nombre_ubigeo: 'Paucartambo',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'Paucartambo, Cusco',
        buscador_ubigeo: 'paucartambo cusco',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3393',
        nombre_ubigeo: 'Quispicanchi',
        codigo_ubigeo: '12',
        etiqueta_ubigeo: 'Quispicanchi, Cusco',
        buscador_ubigeo: 'quispicanchi cusco',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
      {
        id_ubigeo: '3406',
        nombre_ubigeo: 'Urubamba',
        codigo_ubigeo: '13',
        etiqueta_ubigeo: 'Urubamba, Cusco',
        buscador_ubigeo: 'urubamba cusco',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3292',
      },
    ],
    '3414': [
      {
        id_ubigeo: '3435',
        nombre_ubigeo: 'Acobamba',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Acobamba, Huancavelica',
        buscador_ubigeo: 'acobamba huancavelica',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
      {
        id_ubigeo: '3444',
        nombre_ubigeo: 'Angaraes',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Angaraes, Huancavelica',
        buscador_ubigeo: 'angaraes huancavelica',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
      {
        id_ubigeo: '3457',
        nombre_ubigeo: 'Castrovirreyna',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Castrovirreyna, Huancavelica',
        buscador_ubigeo: 'castrovirreyna huancavelica',
        numero_hijos_ubigeo: '13',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
      {
        id_ubigeo: '3471',
        nombre_ubigeo: 'Churcampa',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Churcampa, Huancavelica',
        buscador_ubigeo: 'churcampa huancavelica',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
      {
        id_ubigeo: '3415',
        nombre_ubigeo: 'Huancavelica',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Huancavelica, Huancavelica, Huancavelica',
        buscador_ubigeo: 'huancavelica huancavelica huancavelica',
        numero_hijos_ubigeo: '19',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
      {
        id_ubigeo: '3482',
        nombre_ubigeo: 'Huaytara',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Huaytara, Huancavelica',
        buscador_ubigeo: 'huaytara huancavelica',
        numero_hijos_ubigeo: '16',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
      {
        id_ubigeo: '3499',
        nombre_ubigeo: 'Tayacaja',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Tayacaja, Huancavelica',
        buscador_ubigeo: 'tayacaja huancavelica',
        numero_hijos_ubigeo: '18',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3414',
      },
    ],
    '3518': [
      {
        id_ubigeo: '3531',
        nombre_ubigeo: 'Ambo',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Ambo, Huanuco',
        buscador_ubigeo: 'ambo huanuco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3540',
        nombre_ubigeo: 'Dos de Mayo',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Dos de Mayo, Huanuco',
        buscador_ubigeo: 'dos de mayo huanuco',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3550',
        nombre_ubigeo: 'Huacaybamba',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Huacaybamba, Huanuco',
        buscador_ubigeo: 'huacaybamba huanuco',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3555',
        nombre_ubigeo: 'Huamalies',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Huamalies, Huanuco',
        buscador_ubigeo: 'huamalies huanuco',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3519',
        nombre_ubigeo: 'Huanuco',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Huanuco, Huanuco, Huanuco',
        buscador_ubigeo: 'huanuco huanuco huanuco',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3589',
        nombre_ubigeo: 'Lauricocha',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Lauricocha, Huanuco',
        buscador_ubigeo: 'lauricocha huanuco',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3567',
        nombre_ubigeo: 'Leoncio Prado',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Leoncio Prado, Huanuco',
        buscador_ubigeo: 'leoncio prado huanuco',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3574',
        nombre_ubigeo: 'Maraqon',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Maraqon, Huanuco',
        buscador_ubigeo: 'maraqon huanuco',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3578',
        nombre_ubigeo: 'Pachitea',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Pachitea, Huanuco',
        buscador_ubigeo: 'pachitea huanuco',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3583',
        nombre_ubigeo: 'Puerto Inca',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Puerto Inca, Huanuco',
        buscador_ubigeo: 'puerto inca huanuco',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
      {
        id_ubigeo: '3597',
        nombre_ubigeo: 'Yarowilca',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'Yarowilca, Huanuco',
        buscador_ubigeo: 'yarowilca huanuco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3518',
      },
    ],
    '3606': [
      {
        id_ubigeo: '3622',
        nombre_ubigeo: 'Chincha',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Chincha, Ica',
        buscador_ubigeo: 'chincha ica',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3606',
      },
      {
        id_ubigeo: '3607',
        nombre_ubigeo: 'Ica',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Ica, Ica, Ica',
        buscador_ubigeo: 'ica ica ica',
        numero_hijos_ubigeo: '14',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3606',
      },
      {
        id_ubigeo: '3634',
        nombre_ubigeo: 'Nazca',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Nazca, Ica',
        buscador_ubigeo: 'nazca ica',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3606',
      },
      {
        id_ubigeo: '3640',
        nombre_ubigeo: 'Palpa',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Palpa, Ica',
        buscador_ubigeo: 'palpa ica',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3606',
      },
      {
        id_ubigeo: '3646',
        nombre_ubigeo: 'Pisco',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Pisco, Ica',
        buscador_ubigeo: 'pisco ica',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3606',
      },
    ],
    '3655': [
      {
        id_ubigeo: '3701',
        nombre_ubigeo: 'Chanchamayo',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Chanchamayo, Junin',
        buscador_ubigeo: 'chanchamayo junin',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3778',
        nombre_ubigeo: 'Chupaca',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Chupaca, Junin',
        buscador_ubigeo: 'chupaca junin',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3685',
        nombre_ubigeo: 'Concepcion',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Concepcion, Junin',
        buscador_ubigeo: 'concepcion junin',
        numero_hijos_ubigeo: '15',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3656',
        nombre_ubigeo: 'Huancayo',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Huancayo, Junin',
        buscador_ubigeo: 'huancayo junin',
        numero_hijos_ubigeo: '28',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3708',
        nombre_ubigeo: 'Jauja',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Jauja, Junin',
        buscador_ubigeo: 'jauja junin',
        numero_hijos_ubigeo: '34',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3743',
        nombre_ubigeo: 'Junin',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Junin, Junin, Junin',
        buscador_ubigeo: 'junin junin junin',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3748',
        nombre_ubigeo: 'Satipo',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Satipo, Junin',
        buscador_ubigeo: 'satipo junin',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3757',
        nombre_ubigeo: 'Tarma',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Tarma, Junin',
        buscador_ubigeo: 'tarma junin',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
      {
        id_ubigeo: '3767',
        nombre_ubigeo: 'Yauli',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Yauli, Junin',
        buscador_ubigeo: 'yauli junin',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3655',
      },
    ],
    '3788': [
      {
        id_ubigeo: '3801',
        nombre_ubigeo: 'Ascope',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Ascope, La Libertad',
        buscador_ubigeo: 'ascope la libertad',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3810',
        nombre_ubigeo: 'Bolivar',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Bolivar, La Libertad',
        buscador_ubigeo: 'bolivar la libertad',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3817',
        nombre_ubigeo: 'Chepen',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Chepen, La Libertad',
        buscador_ubigeo: 'chepen la libertad',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3875',
        nombre_ubigeo: 'Gran Chimu',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'Gran Chimu, La Libertad',
        buscador_ubigeo: 'gran chimu la libertad',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3821',
        nombre_ubigeo: 'Julcan',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Julcan, La Libertad',
        buscador_ubigeo: 'julcan la libertad',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3826',
        nombre_ubigeo: 'Otuzco',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Otuzco, La Libertad',
        buscador_ubigeo: 'otuzco la libertad',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3837',
        nombre_ubigeo: 'Pacasmayo',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Pacasmayo, La Libertad',
        buscador_ubigeo: 'pacasmayo la libertad',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3843',
        nombre_ubigeo: 'Pataz',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Pataz, La Libertad',
        buscador_ubigeo: 'pataz la libertad',
        numero_hijos_ubigeo: '13',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3857',
        nombre_ubigeo: 'Sanchez Carrion',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Sanchez Carrion, La Libertad',
        buscador_ubigeo: 'sanchez carrion la libertad',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3866',
        nombre_ubigeo: 'Santiago de Chuco',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Santiago de Chuco, La Libertad',
        buscador_ubigeo: 'santiago de chuco la libertad',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3789',
        nombre_ubigeo: 'Trujillo',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Trujillo, La Libertad',
        buscador_ubigeo: 'trujillo la libertad',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
      {
        id_ubigeo: '3880',
        nombre_ubigeo: 'Viru',
        codigo_ubigeo: '12',
        etiqueta_ubigeo: 'Viru, La Libertad',
        buscador_ubigeo: 'viru la libertad',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3788',
      },
    ],
    '3884': [
      {
        id_ubigeo: '3885',
        nombre_ubigeo: 'Chiclayo',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Chiclayo, Lambayeque',
        buscador_ubigeo: 'chiclayo lambayeque',
        numero_hijos_ubigeo: '20',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3884',
      },
      {
        id_ubigeo: '3906',
        nombre_ubigeo: 'Ferreqafe',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Ferreqafe, Lambayeque',
        buscador_ubigeo: 'ferreqafe lambayeque',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3884',
      },
      {
        id_ubigeo: '3913',
        nombre_ubigeo: 'Lambayeque',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Lambayeque, Lambayeque, Lambayeque',
        buscador_ubigeo: 'lambayeque lambayeque lambayeque',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3884',
      },
    ],
    '3926': [
      {
        id_ubigeo: '3971',
        nombre_ubigeo: 'Barranca',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Barranca, Lima',
        buscador_ubigeo: 'barranca lima',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '3977',
        nombre_ubigeo: 'Cajatambo',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Cajatambo, Lima',
        buscador_ubigeo: 'cajatambo lima',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '3285',
        nombre_ubigeo: 'Callao',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Callao, Callao, Lima',
        buscador_ubigeo: 'callao callao lima',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '3991',
        nombre_ubigeo: 'Ca\u00f1ete',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Ca\u00f1ete, Lima',
        buscador_ubigeo: 'ca\u00f1ete lima',
        numero_hijos_ubigeo: '16',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '3983',
        nombre_ubigeo: 'Canta',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Canta, Lima',
        buscador_ubigeo: 'canta lima',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '4008',
        nombre_ubigeo: 'Huaral',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Huaral, Lima',
        buscador_ubigeo: 'huaral lima',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '4021',
        nombre_ubigeo: 'Huarochiri',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Huarochiri, Lima',
        buscador_ubigeo: 'huarochiri lima',
        numero_hijos_ubigeo: '32',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '4054',
        nombre_ubigeo: 'Huaura',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Huaura, Lima',
        buscador_ubigeo: 'huaura lima',
        numero_hijos_ubigeo: '12',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '3927',
        nombre_ubigeo: 'Lima',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Lima, Lima, Lima',
        buscador_ubigeo: 'lima lima lima',
        numero_hijos_ubigeo: '43',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '4067',
        nombre_ubigeo: 'Oyon',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Oyon, Lima',
        buscador_ubigeo: 'oyon lima',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
      {
        id_ubigeo: '4074',
        nombre_ubigeo: 'Yauyos',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Yauyos, Lima',
        buscador_ubigeo: 'yauyos lima',
        numero_hijos_ubigeo: '33',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '3926',
      },
    ],
    '4108': [
      {
        id_ubigeo: '4123',
        nombre_ubigeo: 'Alto Amazonas',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Alto Amazonas, Loreto',
        buscador_ubigeo: 'alto amazonas loreto',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4108',
      },
      {
        id_ubigeo: '4135',
        nombre_ubigeo: 'Loreto',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Loreto, Loreto',
        buscador_ubigeo: 'loreto loreto',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4108',
      },
      {
        id_ubigeo: '4141',
        nombre_ubigeo: 'Mariscal Ramon Castilla',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Mariscal Ramon Castilla, Loreto',
        buscador_ubigeo: 'mariscal ramon castilla loreto',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4108',
      },
      {
        id_ubigeo: '4109',
        nombre_ubigeo: 'Maynas',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Maynas, Loreto',
        buscador_ubigeo: 'maynas loreto',
        numero_hijos_ubigeo: '13',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4108',
      },
      {
        id_ubigeo: '4146',
        nombre_ubigeo: 'Requena',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Requena, Loreto',
        buscador_ubigeo: 'requena loreto',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4108',
      },
      {
        id_ubigeo: '4158',
        nombre_ubigeo: 'Ucayali',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Ucayali, Loreto',
        buscador_ubigeo: 'ucayali loreto',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4108',
      },
    ],
    '4165': [
      {
        id_ubigeo: '4171',
        nombre_ubigeo: 'Manu',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Manu, Madre de Dios',
        buscador_ubigeo: 'manu madre de dios',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4165',
      },
      {
        id_ubigeo: '4176',
        nombre_ubigeo: 'Tahuamanu',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Tahuamanu, Madre de Dios',
        buscador_ubigeo: 'tahuamanu madre de dios',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4165',
      },
      {
        id_ubigeo: '4166',
        nombre_ubigeo: 'Tambopata',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Tambopata, Madre de Dios',
        buscador_ubigeo: 'tambopata madre de dios',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4165',
      },
    ],
    '4180': [
      {
        id_ubigeo: '4188',
        nombre_ubigeo: 'General Sanchez Cerro',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'General Sanchez Cerro, Moquegua',
        buscador_ubigeo: 'general sanchez cerro moquegua',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4180',
      },
      {
        id_ubigeo: '4200',
        nombre_ubigeo: 'Ilo',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Ilo, Moquegua',
        buscador_ubigeo: 'ilo moquegua',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4180',
      },
      {
        id_ubigeo: '4181',
        nombre_ubigeo: 'Mariscal Nieto',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Mariscal Nieto, Moquegua',
        buscador_ubigeo: 'mariscal nieto moquegua',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4180',
      },
    ],
    '4204': [
      {
        id_ubigeo: '4219',
        nombre_ubigeo: 'Daniel Alcides Carrion',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Daniel Alcides Carrion, Pasco',
        buscador_ubigeo: 'daniel alcides carrion pasco',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4204',
      },
      {
        id_ubigeo: '4228',
        nombre_ubigeo: 'Oxapampa',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Oxapampa, Pasco',
        buscador_ubigeo: 'oxapampa pasco',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4204',
      },
      {
        id_ubigeo: '4205',
        nombre_ubigeo: 'Pasco',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Pasco, Pasco',
        buscador_ubigeo: 'pasco pasco',
        numero_hijos_ubigeo: '13',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4204',
      },
    ],
    '4236': [
      {
        id_ubigeo: '4247',
        nombre_ubigeo: 'Ayabaca',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Ayabaca, Piura',
        buscador_ubigeo: 'ayabaca piura',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4258',
        nombre_ubigeo: 'Huancabamba',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Huancabamba, Piura',
        buscador_ubigeo: 'huancabamba piura',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4267',
        nombre_ubigeo: 'Morropon',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Morropon, Piura',
        buscador_ubigeo: 'morropon piura',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4278',
        nombre_ubigeo: 'Paita',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Paita, Piura',
        buscador_ubigeo: 'paita piura',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4237',
        nombre_ubigeo: 'Piura',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Piura, Piura, Piura',
        buscador_ubigeo: 'piura piura piura',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4302',
        nombre_ubigeo: 'Sechura',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Sechura, Piura',
        buscador_ubigeo: 'sechura piura',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4286',
        nombre_ubigeo: 'Sullana',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Sullana, Piura',
        buscador_ubigeo: 'sullana piura',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
      {
        id_ubigeo: '4295',
        nombre_ubigeo: 'Talara',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Talara, Piura',
        buscador_ubigeo: 'talara piura',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4236',
      },
    ],
    '4309': [
      {
        id_ubigeo: '4326',
        nombre_ubigeo: 'Azangaro',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Azangaro, Puno',
        buscador_ubigeo: 'azangaro puno',
        numero_hijos_ubigeo: '15',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4342',
        nombre_ubigeo: 'Carabaya',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Carabaya, Puno',
        buscador_ubigeo: 'carabaya puno',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4353',
        nombre_ubigeo: 'Chucuito',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Chucuito, Puno',
        buscador_ubigeo: 'chucuito puno',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4361',
        nombre_ubigeo: 'El Collao',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'El Collao, Puno',
        buscador_ubigeo: 'el collao puno',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4367',
        nombre_ubigeo: 'Huancane',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Huancane, Puno',
        buscador_ubigeo: 'huancane puno',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4376',
        nombre_ubigeo: 'Lampa',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Lampa, Puno',
        buscador_ubigeo: 'lampa puno',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4387',
        nombre_ubigeo: 'Melgar',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Melgar, Puno',
        buscador_ubigeo: 'melgar puno',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4397',
        nombre_ubigeo: 'Moho',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'Moho, Puno',
        buscador_ubigeo: 'moho puno',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4310',
        nombre_ubigeo: 'Puno',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Puno, Puno, Puno',
        buscador_ubigeo: 'puno puno puno',
        numero_hijos_ubigeo: '15',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4402',
        nombre_ubigeo: 'San Antonio de Putina',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'San Antonio de Putina, Puno',
        buscador_ubigeo: 'san antonio de putina puno',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4408',
        nombre_ubigeo: 'San Roman',
        codigo_ubigeo: '11',
        etiqueta_ubigeo: 'San Roman, Puno',
        buscador_ubigeo: 'san roman puno',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4413',
        nombre_ubigeo: 'Sandia',
        codigo_ubigeo: '12',
        etiqueta_ubigeo: 'Sandia, Puno',
        buscador_ubigeo: 'sandia puno',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
      {
        id_ubigeo: '4423',
        nombre_ubigeo: 'Yunguyo',
        codigo_ubigeo: '13',
        etiqueta_ubigeo: 'Yunguyo, Puno',
        buscador_ubigeo: 'yunguyo puno',
        numero_hijos_ubigeo: '7',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4309',
      },
    ],
    '4431': [
      {
        id_ubigeo: '4439',
        nombre_ubigeo: 'Bellavista',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Bellavista, San Martin',
        buscador_ubigeo: 'bellavista san martin',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4446',
        nombre_ubigeo: 'El Dorado',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'El Dorado, San Martin',
        buscador_ubigeo: 'el dorado san martin',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4452',
        nombre_ubigeo: 'Huallaga',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Huallaga, San Martin',
        buscador_ubigeo: 'huallaga san martin',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4459',
        nombre_ubigeo: 'Lamas',
        codigo_ubigeo: '05',
        etiqueta_ubigeo: 'Lamas, San Martin',
        buscador_ubigeo: 'lamas san martin',
        numero_hijos_ubigeo: '11',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4471',
        nombre_ubigeo: 'Mariscal Caceres',
        codigo_ubigeo: '06',
        etiqueta_ubigeo: 'Mariscal Caceres, San Martin',
        buscador_ubigeo: 'mariscal caceres san martin',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4432',
        nombre_ubigeo: 'Moyobamba',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Moyobamba, San Martin',
        buscador_ubigeo: 'moyobamba san martin',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4477',
        nombre_ubigeo: 'Picota',
        codigo_ubigeo: '07',
        etiqueta_ubigeo: 'Picota, San Martin',
        buscador_ubigeo: 'picota san martin',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4488',
        nombre_ubigeo: 'Rioja',
        codigo_ubigeo: '08',
        etiqueta_ubigeo: 'Rioja, San Martin',
        buscador_ubigeo: 'rioja san martin',
        numero_hijos_ubigeo: '9',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4498',
        nombre_ubigeo: 'San Martin',
        codigo_ubigeo: '09',
        etiqueta_ubigeo: 'San Martin, San Martin',
        buscador_ubigeo: 'san martin san martin',
        numero_hijos_ubigeo: '14',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
      {
        id_ubigeo: '4513',
        nombre_ubigeo: 'Tocache',
        codigo_ubigeo: '10',
        etiqueta_ubigeo: 'Tocache, San Martin',
        buscador_ubigeo: 'tocache san martin',
        numero_hijos_ubigeo: '5',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4431',
      },
    ],
    '4519': [
      {
        id_ubigeo: '4531',
        nombre_ubigeo: 'Candarave',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Candarave, Tacna',
        buscador_ubigeo: 'candarave tacna',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4519',
      },
      {
        id_ubigeo: '4538',
        nombre_ubigeo: 'Jorge Basadre',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Jorge Basadre, Tacna',
        buscador_ubigeo: 'jorge basadre tacna',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4519',
      },
      {
        id_ubigeo: '4520',
        nombre_ubigeo: 'Tacna',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Tacna, Tacna, Tacna',
        buscador_ubigeo: 'tacna tacna tacna',
        numero_hijos_ubigeo: '10',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4519',
      },
      {
        id_ubigeo: '4542',
        nombre_ubigeo: 'Tarata',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Tarata, Tacna',
        buscador_ubigeo: 'tarata tacna',
        numero_hijos_ubigeo: '8',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4519',
      },
    ],
    '4551': [
      {
        id_ubigeo: '4559',
        nombre_ubigeo: 'Contralmirante Villar',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Contralmirante Villar, Tumbes',
        buscador_ubigeo: 'contralmirante villar tumbes',
        numero_hijos_ubigeo: '2',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4551',
      },
      {
        id_ubigeo: '4552',
        nombre_ubigeo: 'Tumbes',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Tumbes, Tumbes, Tumbes',
        buscador_ubigeo: 'tumbes tumbes tumbes',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4551',
      },
      {
        id_ubigeo: '4562',
        nombre_ubigeo: 'Zarumilla',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Zarumilla, Tumbes',
        buscador_ubigeo: 'zarumilla tumbes',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4551',
      },
    ],
    '4567': [
      {
        id_ubigeo: '4575',
        nombre_ubigeo: 'Atalaya',
        codigo_ubigeo: '02',
        etiqueta_ubigeo: 'Atalaya, Ucayali',
        buscador_ubigeo: 'atalaya ucayali',
        numero_hijos_ubigeo: '4',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4567',
      },
      {
        id_ubigeo: '4568',
        nombre_ubigeo: 'Coronel Portillo',
        codigo_ubigeo: '01',
        etiqueta_ubigeo: 'Coronel Portillo, Ucayali',
        buscador_ubigeo: 'coronel portillo ucayali',
        numero_hijos_ubigeo: '6',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4567',
      },
      {
        id_ubigeo: '4580',
        nombre_ubigeo: 'Padre Abad',
        codigo_ubigeo: '03',
        etiqueta_ubigeo: 'Padre Abad, Ucayali',
        buscador_ubigeo: 'padre abad ucayali',
        numero_hijos_ubigeo: '3',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4567',
      },
      {
        id_ubigeo: '4584',
        nombre_ubigeo: 'Purus',
        codigo_ubigeo: '04',
        etiqueta_ubigeo: 'Purus, Ucayali',
        buscador_ubigeo: 'purus ucayali',
        numero_hijos_ubigeo: '1',
        nivel_ubigeo: '2',
        id_padre_ubigeo: '4567',
      },
    ],
  },
};
