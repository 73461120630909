import React, { useEffect, useRef } from 'react';
import { CntButton, Button, WrapError, TextError } from '../../theme/global';

import TextMotto from '../TextMotto';
import { withFormik, Form, ErrorMessage } from 'formik';
import withProps from 'recompose/withProps';
import Input, { PasswordInput, Container } from '../InputRounded';
import { Title } from '../Text';
import compose from 'recompose/compose';
import PopUp from '../Popup';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';

const FormNewPassword = ({ onSubmit, desktop, history, newPassword, codeSecurity }) => {
  const popupRef = useRef(null);
  useEffect(() => {
    if (!codeSecurity) {
      window.location.replace('/login');
    }
    if (newPassword && newPassword.status === 200) {
      popupRef.current.open();
    }
  }, [codeSecurity, history, newPassword]);
  return (
    <WrapPage className={`${desktop || ''} password-recovery`}>
      {desktop ? <TextMotto /> : <TextMotto logo />}
      <div>
        <Title marginBottom='30px'>Cambiar Contraseña</Title>
        <Form>
          <Container>
            <Input
              name='password'
              placeholder='Contraseña Nueva'
              center
              component={PasswordInput}
              password
            />
            <ErrorMessage name='password'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <Input
              name='passConfirm'
              placeholder='Repetir Contraseña'
              center
              component={PasswordInput}
              password
            />
            <ErrorMessage name='passConfirm'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
          </Container>

          <CntButton center>
            <Button round onClick={onSubmit}>
              Iniciar Sesión
            </Button>
          </CntButton>
        </Form>
      </div>
      <PopUp ref={popupRef} onClose={() => {
        sessionStorage.clear();
        window.location.replace('/login');
      }}>
        <Alert
          icon='success'
          title='Contraseña cambiada con exito'
          subtitle='debes iniciar sesión para iniciar una operación'
        />
      </PopUp>
    </WrapPage >
  );
};

const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      password: '',
      passConfirm: '',
    }),
    handleSubmit(values, { setSubmitting, props: { getNewPassword, codeSecurity } }) {
      const data = {
        id: codeSecurity.id,
        password: values.password,
      };
      getNewPassword(data);
      setSubmitting(false);
    },
    validate(values) {
      const errors = {};
      if (!values.password.trim()) {
        errors.password = 'La contraseña no debe estar vacía';
      } else if (values.password.length < 8) {
        errors.password = 'La contraseña debe tener minimo 8 caracteres';
      }

      if (values.password !== values.passConfirm)
        errors.passConfirm = 'Las contraseñas no coinciden';

      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);

export default enhacer(FormNewPassword);
