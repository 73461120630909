import styled from 'styled-components/macro';

export const AlertCopy = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 10px;
  background-color: var(--ion-color-secondary);
  color: #fff;
  font-size: 14px;
  z-index: 100;
  text-align: center;
  left: 0;
  min-height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--ion-font-family-quaternary);
  transform: translateY(-110%);
  transition: all 0.5s;
  &.active {
    transform: translateY(0%);
  }
`;

export const WrapInputFile = styled.div`
  margin: 10px 0;
  input {
    display: none;
  }
`;

export const IconUpload = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  width: 70px;
  height: 50px;
  justify-content: center;
  transition: all 0.4s;
`;

export const LabelImage = styled.label`
  position: relative;
  height: 50px;
  width: 300px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 80px;
  box-sizing: border-box;
  padding-left: 30px;
  cursor: pointer;
  &:hover {
    ${IconUpload} {
      transform: scale(1.05);
    }
  }
  em {
    font-size: 12px;
    margin-top: 2px;
    font-style: normal;
  }
  span {
    font-size: 15px;
    color: var(--ion-color-secondary);
    font-family: var(--ion-font-family-quinary);
    letter-spacing: -0.03em;
  }
`;
export const SelectItem = styled.div`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  position: relative;
  margin-bottom: 10px;
  &:after {
    content: '\\e90f';
    font-family: 'icomoon';
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: var(--ion-color-secondary);
  }
  select {
    width: 100%;
    color: rgba(113, 113, 113, 0.8);
    height: 50px;
    background: #fff;
    appearance: none;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    padding-left: 30px;
    color:
    box-sizing: border-box;

    &::-ms-expand {
      display: none;
    }
  }
`;
