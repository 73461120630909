import React, { useEffect, useRef } from 'react';
import { CntButton, Button, TextError, WrapError } from '../../theme/global';

import TextMotto from '../TextMotto';
import { withFormik, Form, ErrorMessage } from 'formik';
import withProps from 'recompose/withProps';
import { emailRegex } from '../../utils/regexs';
import Input from '../InputRounded';
import { Typography, Strong, Title } from '../Text';
import compose from 'recompose/compose';
import PopUp from '../Popup';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';

const FormPasswordRecovery = ({
  onSubmit,
  desktop,
  history,
  codeSecurity,
  statusCheckEmail,
  getCheckEmail,
  getCodeSecurity,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    if (statusCheckEmail && statusCheckEmail.status === 303) {
      const dataStatus = {
        email: statusCheckEmail.email,
      };
      getCodeSecurity(dataStatus).then(() => {
        history.push('/code-verification');
      });
    }
    if (statusCheckEmail && statusCheckEmail.status === 404) {
      popupRef.current.open();
    }
  }, [getCodeSecurity, statusCheckEmail, history]);
  return (
    <>
      <WrapPage className={`${desktop || ''} password-recovery`}>
        {desktop ? <TextMotto /> : <TextMotto logo />}

        <div>
          <Title marginBottom='30px'>Recuperar Contraseña</Title>
          <Form>
            <Input
              name='email'
              placeholder='Correo Electrónico'
              autoComplete='off'
              center='true'
            />
            <ErrorMessage name='email'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <Typography
              color='primary'
              center
              size='15px'
              font='primary'
              style={{ margin: '25px auto 34px', maxWidth: 220 }}
            >
              Te enviaremos una notificación a tu{' '}
              <Strong secondary>Correo Electrónico</Strong>
            </Typography>
            <CntButton center>
              <Button round>Enviar Verificación</Button>
            </CntButton>
          </Form>
        </div>
      </WrapPage>
      <PopUp ref={popupRef} onClose={() => {}}>
        <Alert
          icon='error'
          title='El correo ingresado no existe'
          subtitle='verifíquelo e intente de nuevo.'
        />
      </PopUp>
    </>
  );
};
const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      email: '',
    }),
    handleSubmit(values, { setSubmitting, props: { getCheckEmail } }) {
      const data = {
        email: values.email,
      };
      getCheckEmail(data);
      setSubmitting(false);
    },
    validate(values) {
      const errors = {};
      if (!emailRegex.test(values.email)) errors.email = 'Email invalido';
      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);

export default enhacer(FormPasswordRecovery);
