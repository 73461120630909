import React from "react";
import { Wrap } from "./styles";

function Loading() {
  return (
    <Wrap>
      <img src="/static/images/loading.svg" width={40} alt="" />
    </Wrap>
  );
}

export default Loading;
