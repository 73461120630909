import styled from 'styled-components/macro';

export const WrapSmallInputs = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 15px;
`;
export const ItemInput = styled.div`
  width: 90px;
  input {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    width: 100%;
    height: 50px;
    border: none;
    letter-spacing: -0.02em;
    font-size: 15px;
    text-align: center;
  }
`;
