import { connect } from 'react-redux';
import {
  fetchNovedades,
  fetchNewsCategory,
  fetchNews2,
} from '../../../store/web/novedades';

import NewsDetail from './NewsDetail';

const mapStateToProps = (state) => {
  return {
    novedades: state.novedadesState.novedades,
    footerData: state.footerState.footerData,
    getNews2: state.novedadesState.getNews2,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNovedades: () => dispatch(fetchNovedades()),
  fetchNewsCategory: (category) => dispatch(fetchNewsCategory(category)),
  fetchNews2: (slug) => dispatch(fetchNews2(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail);
