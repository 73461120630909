import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TabsContainer, TabItem } from './styles';
export { TabItem, TabsContainer };

export const TabsContainerWithLabel = ({ label, children, autoWrap = false, ...p }) => (
  <Grid container style={{ maxWidth: 300, margin: '0 auto' }}>
    <Grid item xs={5} direction='row' container justify='center' alignItems='center'>
      {label}
    </Grid>
    <Grid item xs={7}>
      <TabsContainer {...p}>{children}</TabsContainer>
    </Grid>
  </Grid>
);
