import {
  REQUEST_COMO_FUNCIONA,
  RECEIVE_COMO_FUNCIONA,
  REQUEST_TRUST,
  RECEIVE_TRUST,
  REQUEST_OPERATION_TYPE,
  RECEIVE_OPERATION_TYPE,
  REQUEST_YOUR_ALLY,
  RECEIVE_YOUR_ALLY,
  REQUEST_CHANGE_DOLLAR,
  RECEIVE_CHANGE_DOLLAR,
} from './types';

export const requestComoFunciona = () => ({
  type: REQUEST_COMO_FUNCIONA,
});

export const receiveComoFunciona = (datos) => ({
  type: RECEIVE_COMO_FUNCIONA,
  comoFunciona: datos,
});

export const requestTrust = () => ({
  type: REQUEST_TRUST,
});

export const receiveTrust = (data) => ({
  type: RECEIVE_TRUST,
  trust: data,
});

export const requestOperationType = () => ({
  type: REQUEST_OPERATION_TYPE,
});

export const receiveOperationType = (data) => ({
  type: RECEIVE_OPERATION_TYPE,
  operationTypeData: data,
});

export const requestYourAlly = () => ({
  type: REQUEST_YOUR_ALLY,
});
export const receiveYourAlly = (data) => ({
  type: RECEIVE_YOUR_ALLY,
  yourAlly: data,
});

export const requestChangeDollar = () => ({
  type: REQUEST_CHANGE_DOLLAR,
});

export const receiveChangeDollar = (data) => ({
  type: RECEIVE_CHANGE_DOLLAR,
  changeDollar: data,
});
