import React from 'react';
import { Typography, Strong } from '../Text';
import { Wrap, Icon } from './styles';
const Alert = ({ icon, title, subtitle, description }) => {
  return (
    <Wrap>
      {icon && <Icon className={icon}></Icon>}
      <Typography size='14px' center>
        {title}
        <br />
        <Strong secondary size='inherit'>
          {subtitle}
        </Strong>
        <br />
        {description}
      </Typography>
    </Wrap>
  );
};

export default Alert;
