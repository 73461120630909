import { connect } from 'react-redux';
import { fetchContact } from '../../../store/web/contact';
import ContactTemplate from './ContactTemplate';

const mapStateToProps = (state) => {
  return {
    contact: state.contactState.contact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContact: (data) => dispatch(fetchContact(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactTemplate);
