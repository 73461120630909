import styled from 'styled-components';
import { fonts } from '../../../theme/web/theme';

export const WrapForm = styled.div`
  max-width: 420px;
  width: 93%;
  margin: 0 auto;
  padding-bottom: 20px;
  position: relative;
  z-index: 10;
  @media screen and (max-width: 480px) {
    width: 85%;
  }
  .btn_contact {
    position: relative;
    box-shadow: 0px 3px 35px rgba(58, 162, 41, 0.2);
  }
  .contactIcono {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    background: #3aa229;
    box-shadow: 0px 0px 25px rgba(58, 162, 41, 0.1);
    border-radius: 30px;
    width: 67px;
    height: 60px;
    z-index: 2;
    line-height: 60px;
    filter: drop-shadow(3px 3px 20px rgba(0, 0, 0, 0.25));
    &:before {
      content: '\\e911';
      font-family: icomoon;
      color: white;
      font-size: 20px;
    }
  }
  .contacto_text {
    font-size: 12px;
    font-family: ${fonts.lato700};
    margin-bottom: 25px;
    margin-top: 12px;
    letter-spacing: -0.2px;
    p {
      margin: 0px;
    }
  }
  .contact_from_input {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    & > * {
      width: calc(100% / 2 - 2%);
      margin-bottom: 10px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      & > * {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .MuiFormHelperText-root.Mui-error {
    margin: 0;
    padding-left: 20px;
  }
  .MuiInput-underline.Mui-error:after {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
  .MuiInputBase-formControl {
    &:before {
      display: none;
    }
    textarea {
      height: 150px;
      padding-top: 10px;
      box-sizing: border-box;
    }
  }

  .MuiInputBase-input {
    background: #ffffff;
    -webkit-appearance: none;
    -webkit-box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
    box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
    border-radius: 5px;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .form_input_contact {
    & > * {
      input,
      textarea {
        color: #717171;
        font-family: ${fonts.lato800};
      }
      input:focus,
      textarea:focus {
        color: #3aa229;
      }
    }
  }
`;
