import styled from 'styled-components';
import { fonts } from '../../../theme/web/theme';

export const Title = styled.div`
  margin-bottom: 55px;
  h2 {
    font-family: ${fonts.lato800};
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fecf30;
    strong {
      font-family: ${fonts.lato800};
      font-style: normal;
      font-size: 25px;
      display: block;
      line-height: 30px;
      letter-spacing: -0.03em;
      color: #3aa229;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 480px) {
    h2 {
      margin-top: 30px;
      line-height: 10px;
    }
  }
`;
