import React from 'react';
import FormCompanyData from '../components/FormCompanyData';
const CompanyData = ({ desktop, history }) => {
  return (
    <>
      <FormCompanyData desktop={desktop} history={history} />
    </>
  );
};

export default CompanyData;
