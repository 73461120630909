export const REQUEST_DASHBOARD = 'REQUEST_DASHBOARD';
export const RECEIVE_DASHBOARD = 'RECEIVE_DASHBOARD';

export const REQUEST_SEARCH_CLIENTS = 'REQUEST_SEARCH_CLIENTS';
export const RECEIVE_SEARCH_CLIENTS = 'RECEIVE_SEARCH_CLIENTS';

export const REQUEST_SELECT_OPERATION = 'REQUEST_SELECT_OPERATION';
export const RECEIVE_SELECT_OPERATION = 'RECEIVE_SELECT_OPERATION';

export const RECEIVE_EDIT_OPERATION = 'RECEIVE_EDIT_OPERATION';

export const SET_ACTIVE_BUTTON = 'SET_ACTIVE_BUTTON';
export const NEW_AMOUNT_RECEIVE = 'NEW_AMOUNT_RECEIVE';
export const SINGLE_DETAIL_OPERATION = 'SINGLE_DETAIL_OPERATION';
export const CLEAR_SINGLE_OPERATION = 'CLEAR_SINGLE_OPERATION';
export const CLEAN_DASHBOAD = 'CLEAN_DASHBOAD';
export const PROCESS_DASHBOARD = 'PROCESS_DASHBOARD';
export const RECEIVE_COMPLETED_OPERATION = 'RECEIVE_COMPLETED_OPERATION';
export const RECEIVE_CANCELED_OPERATION = 'RECEIVE_CANCELED_OPERATION';
export const RECEIVE_ARCHIVE_OPERATION = 'RECEIVE_ARCHIVE_OPERATION';
export const RECEIVE_INVOICE = 'RECEIVE_INVOICE';
export const CLEAN_PROCESS_DASHBOARD = 'CLEAN_PROCESS_DASHBOARD';
export const CLEAN_RECEIVE_INVOICE = 'CLEAN_RECEIVE_INVOICE';
