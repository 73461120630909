import React from 'react';
import FormNewBankAccount from '../components/FormNewBankAccount';
const NewBankAccount = ({ asStep = true, desktop, history, generalInfo, user }) => {
  return (
    <>
      <FormNewBankAccount
        asStep={asStep}
        desktop={desktop}
        history={history}
        generalInfo={generalInfo}
        user={user}
      />
    </>
  );
};

export default NewBankAccount;
