export const REQUEST_USER_DASHBOARD = 'REQUEST_USER_DASHBOARD';
export const RECEIVE_USER_DASHBOARD = 'RECEIVE_USER_DASHBOARD';
export const INFO_CURRENT_USER_DASHBOARD = 'INFO_CURRENT_USER_DASHBOARD';
export const CLEAN_USER_ROLE_DASHBOARD = 'CLEAN_USER_ROLE_DASHBOARD';
export const CHECK_EMAIL_USER_DASHBOARD = 'CHECK_EMAIL_USER_DASHBOARD';
export const RECEIVE_CODE_VERIFICATION_DASHBOARD = 'RECEIVE_CODE_VERIFICATION_DASHBOARD';
export const VALIDATE_CODE_VERIFICATION_DASHBOARD =
  'VALIDATE_CODE_VERIFICATION_DASHBOARD';
export const SET_NEW_PASSWORD_DASHBOARD = 'SET_NEW_PASSWORD_DASHBOARD';
export const CLEAN_VALIDATE_CODE_DASHBOARD = 'CLEAN_VALIDATE_CODE_DASHBOARD';
export const CLEAN_CHECK_EMAIL_DASHBOARD = 'CLEAN_CHECK_EMAIL_DASHBOARD';
export const USER_OFF_LINE_DASHBOARD = 'USER_OFF_LINE_DASHBOARD';
