import styled from 'styled-components/macro';

export const BlockForm = styled.div`
  /* color: green; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(58, 162, 41, 0.1);
  box-sizing: border-box;
  padding: 21px 23px 19px 37px;
  @media screen and (max-width: 1024px) {
    padding: 25px 20px;
  }
  @media screen and (max-width: 960px) {
    padding: 25px 10px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
    box-shadow: none;
    padding: 0;
  }
  @media screen and (max-width: 320px) {
    padding: 30px 10px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 450px;
  @media screen and (max-width: 960px) {
    width: 385px;
  }
  @media screen and (max-width: 767px) {
    display: none;
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
`;

export const Image = styled.figure`
  width: 43px;
  margin: 0 43px 0 0;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    margin-right: 15px;
  }
`;

export const Title = styled.div`
  width: 110px;
  border-right: 0.25px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding-right: 10px;
  margin-right: 30px;
  @media screen and (max-width: 960px) {
    width: 100px;
    margin-right: 20px;
  }
  @media screen and (max-width: 767px) {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }
`;

export const TitleText = styled.h5`
  line-height: 1.2;
  letter-spacing: -0.03em;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  font-size: 16px;
  margin: 0;
`;

export const Description = styled.div`
  width: 220px;
  line-height: 1.2;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 14px;
  @media screen and (max-width: 960px) {
    width: 200px;
    font-size: 13px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 0 15px;
    margin-top: 15px;
  }
`;

export const DescriptionText = styled.p`
  margin: 0;
`;

export const Formulary = styled.div`
  width: 372px;
  position: relative;
  margin: 0;
  @media screen and (max-width: 960px) {
    width: 290px;
  }
  @media screen and (max-width: 767px) {
    max-width: 372px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const Input = styled.div`
  position: relative;
  input {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
    box-sizing: border-box;
    padding: 0 90px 0 39px;
    font-size: 14px;
  }
  /* input:focus+label{
    display: none;
  } */
  label {
    display: block;
    width: 100%;
    line-height: 1;
    color: var(--ion-color-primary);
    font-family: var(--ion-font-family-quaternary);
    font-size: 14px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    box-sizing: border-box;
    padding: 0 90px 0 39px;
    margin: 0;
    strong {
      color: var(--ion-color-secondary);
      font-weight: normal;
    }
  }
  @media screen and (max-width: 960px) {
    input {
      padding-left: 15px;
      padding-right: 65px;
    }
    label {
      font-size: 13px;
      padding-left: 15px;
      padding-right: 60px;
    }
  }
  @media screen and (max-width: 767px) {
    input {
      padding-left: 25px;
    }
    label {
      font-size: 14px;
      padding-left: 25px;
    }
  }
`;

export const Submit = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 60px;
  background-color: var(--ion-color-secondary);
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  cursor: pointer;
  right: 0;
  &:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 960px) {
    width: 60px;
    font-size: 17px;
  }
  @media screen and (max-width: 320px) {
    width: 50px;
    font-size: 15px;
  }
`;
