import { connect } from 'react-redux';
import { fetchFooter, fetchSuscription, getCleanSuscription } from '../../store/footer';

import Footer from './Footer';

const mapStateToProps = (state) => {
  return {
    footerData: state.footerState.footerData,
    suscriptionData: state.footerState.suscriptionData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFooter: () => dispatch(fetchFooter()),
  getCleanSuscription: () => dispatch(getCleanSuscription()),
  fetchSuscription: (data) => dispatch(fetchSuscription(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
