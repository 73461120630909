export default class Routes {
  constructor(routes, tabs) {
    this.routes = routes;
    this.publicRoutesMap = new Map(
      routes.map(({ name, path, realPath }) => [name, realPath ?? path])
    );
    this.routesWithTabs = routes
      .filter(({ showTabs }) => showTabs)
      .map(({ path, realPath }) => realPath || path);
  }
}
