import React from 'react';
import { useHistory } from 'react-router-dom';
import { Title } from '../../components/Text';
import { CntButton, Button } from '../../theme/global';
const NoDataBankAccount = () => {
  const history = useHistory();
  return (
    <div>
      <Title className='title-make-transfer'>
        Debe tener mínimo dos cuentas, una en soles <br /> y otra en dolares para realizar
        una operación
      </Title>
      <CntButton center>
        <Button
          onClick={() => {
            history.push('/accounts/bank-accounts');
          }}
          round
          type='button'
        >
          Agregar cuentas
        </Button>
      </CntButton>
    </div>
  );
};

export default NoDataBankAccount;
