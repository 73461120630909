import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Isotipo = styled.div`
  text-align: center;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.5s;
`;

export const Button = styled(Link)`
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-font-family-secondary);
  font-size: 20px;
  border-radius: 30px;
`;
export const Item = styled.div`
  box-sizing: border-box;
  padding-top: 5%;
  width: 100%;
  height: 100vh;
  padding-bottom: 100px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
`;

export const ItemInner = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const Figure = styled.figure`
  width: 222px;
  margin: 0 auto;
  opacity: 0;
  transform: translateX(-100px);
  transition: transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.5s;
  transition-delay: 0.5s;
  img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-height: 745px) {
    width: 150px;
  }
`;
export const Title = styled.h3`
  font-size: 25px;
  margin: 0;
  font-family: var(--ion-font-family-secondary);
  margin-bottom: 15px;
`;
export const Description = styled.div`
  max-width: 265px;
  margin: 0 auto;
  font-size: 18px;
  p {
    margin: 0;
  }
`;
export const Text = styled.div`
  opacity: 0;
  transform: translateY(50px);
  transition: transform 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.5s;
  transition-delay: 1s;
  ${Button} {
    margin: 0 auto;
    margin-top: 30px;
  }
  strong {
    font-family: var(--ion-font-family-secondary);
  }
`;

export const OmitirLink = styled(Link)`
  position: absolute;
  z-index: 3;
  bottom: 30px;
  right: 30px;
  font-size: 15px;
  font-family: var(--ion-font-family-secondary);
  color: var(--ion-color-primary-contrast);
  transform: translateX(100px);
  transition: transform 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.5s;
  transition-delay: 1.5s;
  &:after {
    content: '\\e903';
    font-family: 'icomoon';
    margin-left: 10px;
    vertical-align: middle;
  }
`;

export const Section = styled.section`
  background-image: linear-gradient(to top, #247b17 0.68%, #83d500 100%);
  &.animation {
    ${Isotipo} {
      opacity: 1;
      transform: scale(1);
    }
    ${Figure} {
      opacity: 1;
      transform: translateX(0px);
    }
    ${OmitirLink} {
      opacity: 1;
      transform: translateX(0px);
    }
    ${Text} {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .swiper-slide {
    .isotipo-color {
      display: none;
    }
    ${Button} {
      display: none;
    }
    &:last-child {
      .isotipo-white {
        display: none;
      }
      .isotipo-color {
        display: block;
        margin: 0 auto;
      }
      ${Description} {
        color: var(--ion-color-primary);
        strong {
          color: var(--ion-color-secondary);
        }
      }
      ${Button} {
        display: flex;
      }
      ${Title} {
        color: var(--ion-color-secondary);
      }
      ${Item} {
        background-image: inherit;
        background-color: var(--ion-color-primary-contrast);
      }
    }
  }
  .swiper-pagination {
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: var(--ion-color-primary-contrast);
      opacity: 1;
      margin: 0;
      margin-left: 12px;
      &:first-child {
        margin-left: 0;
      }
      /* &:last-child {
        display: none;
      } */
      &.swiper-pagination-bullet-active {
        width: 14px;
        height: 14px;
      }
    }
  }
`;
