import React, { Fragment, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import AmountReceived from './AmountReceived';
import {
  WrapDetalle,
  Title,
  EnvolverItems,
  ItemDetalle,
  DetalleCuenta,
  DetalleOptions,
  DetalleLink,
  EditarInput,
  ShowVoucher,
  ShowBoleta,
} from './styles';

const DashboardDetalle = ({
  getNewAmountReceive,
  getNewEditOperation,
  singleDetailOperation,
  getClearSingleOperation,
  getCleanDashboard,
  newEditOperation,
  completedOperation,
  getCompletedOperation,
  canceledOperation,
  getCanceledOperation,
  archiveOperation,
  getArchiveOperation,
  userDashboard,
  receiveInvoice,
  getInvoice,
  getCleanReceiveInvoice,
}) => {
  const MySwal = withReactContent(Swal);
  const getIdDetalle = singleDetailOperation ? singleDetailOperation[0] : null;

  const validationStatus = getIdDetalle ? getIdDetalle.status_id : null;

  const [enableFinish, setEnableFinish] = useState(false);
  const [enableCanceled, setEnableCanceled] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const [editInput, setEditInput] = useState(true);
  const [shoButtonSave, setShowButtonSave] = useState(false);

  const [disabledBtnEditar, setDisabledBtnEditar] = useState(false);
  const [disabledBtnFinalizado, setDisabledBtnFinalizado] = useState(false);
  const [disabledBtnCancelar, setDisabledBtnCancelar] = useState(false);
  const [disabledBtnArchivar, setDisabledBtnArchivar] = useState(false);

  const exchangeRate = getIdDetalle ? getIdDetalle.exchange_rate : '--';
  const amountReceived = getIdDetalle ? getIdDetalle.amount_received : '--';
  const amountSent = getIdDetalle ? getIdDetalle.amount_sent : '--';
  const codeOperation = getIdDetalle ? getIdDetalle.code_operation : '--';
  const nameCliente = getIdDetalle ? getIdDetalle.client_info.name : '--';
  const imageVoucher = getIdDetalle ? getIdDetalle.voucher : null;

  const inputEditar = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de editar la operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        setShowButtonSave(true);
        setDisabledBtnFinalizado(true);
        setDisabledBtnCancelar(true);
        setDisabledBtnArchivar(true);
        setFocusInput(true);
        setTimeout(function () {
          setEditInput(false);
        }, 500);
      }
    });
  };

  const changeTipoCambio = (e) => {
    const val = e.currentTarget.value;
    const currency_usd = getIdDetalle.currency_sent === 'USD' ? true : false;
    const newVal = currency_usd ? amountSent * val : amountSent / val;
    const newDataReceive = {
      amount_received: newVal.toFixed(3),
      exchange_rate: val,
      operation_id: getIdDetalle.id,
      status_id: '10',
      token: userDashboard.info.token,
    };
    getNewAmountReceive(newDataReceive);
  };

  let dataNewAmount = '';
  const newSentAmound = (data) => {
    dataNewAmount = data;
  };
  const sentNewAmount = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de modificar la  operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        getNewEditOperation(dataNewAmount);
        getClearSingleOperation();
        setFocusInput(false);
        setEditInput(true);
        setShowButtonSave(false);
      } else {
        getClearSingleOperation();
        setFocusInput(false);
        setEditInput(true);
        setShowButtonSave(false);

        setDisabledBtnArchivar(false);
        setDisabledBtnCancelar(false);
        setDisabledBtnFinalizado(false);
      }
    });
  };

  const processCompletedOperation = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de Facturar la operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        setDisabledBtnCancelar(true);
        setDisabledBtnArchivar(true);
        setDisabledBtnEditar(true);

        setTimeout(function () {
          setEnableFinish(true);
        }, 500);
      }
    });
  };

  const saveCompletedOperation = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de modificar y facturar la  operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        const dataCurrent = JSON.stringify(getIdDetalle);
        sessionStorage.currentOperation = dataCurrent;

        // getCompletedOperation(dataCompleted);
        // // aqui revisar para enviar facturacion
        // getClearSingleOperation();
        // setEnableFinish(false);
        // setShowButtonSave(false);

        facturarOperation();
      } else {
        getClearSingleOperation();
        setEnableFinish(false);
        setShowButtonSave(false);

        setDisabledBtnEditar(false);
        setDisabledBtnArchivar(false);
        setDisabledBtnCancelar(false);
      }
    });
  };

  const processCanceledOperation = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de cancelar la operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        setDisabledBtnArchivar(true);
        setDisabledBtnEditar(true);
        setDisabledBtnFinalizado(true);
        setTimeout(function () {
          setEnableCanceled(true);
        }, 500);
      }
    });
  };

  const saveCanceledOperation = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de modificar la  operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        const inputOpCanceled = document.querySelector('.inputOpCanceled');
        const dataCanceled = {
          operation_id: getIdDetalle.id,
          observations_operation: inputOpCanceled.value,
          token: userDashboard.info.token,
        };
        getCanceledOperation(dataCanceled);
        getClearSingleOperation();
        setEnableCanceled(false);
        setShowButtonSave(false);
      } else {
        getClearSingleOperation();
        setEnableCanceled(false);
        setShowButtonSave(false);

        setDisabledBtnEditar(false);
        setDisabledBtnArchivar(false);
        setDisabledBtnFinalizado(false);
      }
    });
  };

  const saveArchiveOperation = () => {
    MySwal.fire({
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: `
        <div class="custom-alert">
          <p>Esta seguro(a) de archivar la  operación:</p>
          <p>Código: <strong>${codeOperation}</strong></p>
          <p>Cliente: <strong>${nameCliente}</strong></p>
        </div>
        `,
      confirmButtonText: 'continuar',
      cancelButtonText: 'cancelar',
      focusConfirm: false,
    }).then((result) => {
      if (result && result.isConfirmed) {
        const dataArchive = {
          operation_id: getIdDetalle.id,
          token: userDashboard.info.token,
        };

        getArchiveOperation(dataArchive);
        getClearSingleOperation();
        setShowButtonSave(false);
      } else {
        // getClearSingleOperation();
      }
    });
  };
  const showVoucherOperation = () => {
    MySwal.fire({
      customClass: {
        popup: 'popup-voucher',
      },
      icon: 'warning',
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      html: `
        <div class="voucher-operation">
          <img src="${imageVoucher}" /> 
        </div>
        `,
    });
  };

  const facturarOperation = () => {
    const inputOpDestination = document.querySelector('.inputOpDestination');
    const dataOperationFact_temp = JSON.parse(sessionStorage.currentOperation);
    const dataOperationFact = {
      destination_bank_operation: inputOpDestination.value,
      ...dataOperationFact_temp,
    };
    getInvoice(dataOperationFact);
  };
  useEffect(() => {
    if (validationStatus && validationStatus === 20) {
      const dataCurrentNoFact = JSON.stringify(getIdDetalle);
      sessionStorage.currentOperation = dataCurrentNoFact;
    }
    const dataReceive = {
      amount_received: amountReceived,
      exchange_rate: exchangeRate,
      operation_id: getIdDetalle ? getIdDetalle.id : null,
      status_id: '10',
    };
    getNewAmountReceive(dataReceive);

    if (newEditOperation && newEditOperation.status === 200) {
      getCleanDashboard();
      setDisabledBtnFinalizado(false);
      setDisabledBtnCancelar(false);
      setDisabledBtnArchivar(false);
    }

    if (completedOperation && completedOperation.status === 200) {
      getCleanDashboard();
      setDisabledBtnEditar(false);
      setDisabledBtnArchivar(false);
      setDisabledBtnCancelar(false);
      setDisabledBtnFinalizado(false);

      // aqui es donde se enviara la facturacion, con el status 200
      const dataCurrentOperation = JSON.parse(sessionStorage.currentOperation);

      getInvoice(dataCurrentOperation);

      return;
    }
    if (canceledOperation && canceledOperation.status === 200) {
      getCleanDashboard();
      setDisabledBtnEditar(false);
      setDisabledBtnArchivar(false);
      setDisabledBtnCancelar(false);
      setDisabledBtnFinalizado(false);
    }

    if (archiveOperation && archiveOperation.status === 200) {
      getCleanDashboard();
    }
    if (receiveInvoice && receiveInvoice.status === 200) {
      MySwal.fire({
        icon: 'success',
        showCloseButton: false,
        allowOutsideClick: false,
        html: `
        <div class="custom-alert">
          <p>Boleta Electronica Emitida con Exito:</p>
          <p>Puede revisar la boleta en el siguiente Link</p>
          <p><strong><a target="_blank" href="${receiveInvoice.message_api.enlace_del_pdf}">Ver Boleta </strong></p>
        </div>
        `,
        confirmButtonText: 'continuar',
        focusConfirm: false,
      }).then((result) => {
        if (result) {
          // getCleanReceiveInvoice();
          getCleanDashboard();
          setDisabledBtnEditar(false);
          setDisabledBtnArchivar(false);
          setDisabledBtnCancelar(false);
          setDisabledBtnFinalizado(false);

          // getClearSingleOperation();
          setEnableFinish(false);
          setShowButtonSave(false);
        }
      });
      return;
    }

    if (receiveInvoice && receiveInvoice.status === 400) {
      MySwal.fire({
        icon: 'warning',
        showCloseButton: false,
        allowOutsideClick: false,
        html: `
        <div class="custom-alert">
          <p>No se pudo emitir la Boleta Electronica.</p>
          <p>El Servicio respondió el siguiente error:</p>
          <p><strong>${receiveInvoice.message_api.errors} </strong></p>
        </div>
        `,
        confirmButtonText: 'continuar',
        focusConfirm: false,
      }).then((result) => {
        if (result) {
          getCleanDashboard();
          setDisabledBtnEditar(false);
          setDisabledBtnArchivar(false);
          setDisabledBtnCancelar(false);
          setDisabledBtnFinalizado(false);

          setEnableFinish(false);
          setShowButtonSave(false);
        }
      });
    }
  }, [
    MySwal,
    getNewAmountReceive,
    amountReceived,
    exchangeRate,
    getIdDetalle,
    getCleanDashboard,
    newEditOperation,
    completedOperation,
    canceledOperation,
    archiveOperation,
    getInvoice,
    receiveInvoice,
    validationStatus,
    getCleanReceiveInvoice,
  ]);

  return (
    <WrapDetalle>
      <Fragment>
        <div>
          <Title>
            <h2>Detalle</h2>
            <p>
              Código: <strong>{getIdDetalle ? getIdDetalle.code_operation : '--'}</strong>
            </p>
          </Title>
          <EnvolverItems>
            <ItemDetalle className='full'>
              <p>
                <span>Perfil</span>{' '}
                <strong>{getIdDetalle ? getIdDetalle.perfil_operation : '--'}</strong>
              </p>
            </ItemDetalle>
            {getIdDetalle && getIdDetalle.pdf_boleta && (
              <ShowBoleta target='_blank' href={getIdDetalle.pdf_boleta}>
                Ver Boleta Emitida
              </ShowBoleta>
            )}
            {getIdDetalle && getIdDetalle.company_info && (
              <>
                <ItemDetalle className='full'>
                  <p>
                    <span>Razón Social</span>{' '}
                    <strong>{getIdDetalle.company_info.razon_social_company}</strong>
                  </p>
                </ItemDetalle>
                <ItemDetalle className='full'>
                  <p>
                    <span>Nro de Ruc:</span>
                    <strong>{getIdDetalle.company_info.number_ruc}</strong>
                  </p>
                </ItemDetalle>
              </>
            )}

            <ItemDetalle>
              <p>
                <span>Enviado</span>{' '}
                <strong>
                  {getIdDetalle
                    ? getIdDetalle.currency_sent === 'USD'
                      ? '$'
                      : 'S/'
                    : '--'}
                  {getIdDetalle ? getIdDetalle.amount_sent : '--'}
                </strong>
              </p>
            </ItemDetalle>
            <ItemDetalle>
              <p>
                <span>Recibe</span>
                <strong>
                  {getIdDetalle
                    ? getIdDetalle.currency_received === 'USD'
                      ? '$'
                      : 'S/'
                    : '--'}
                  <AmountReceived newSentAmound={newSentAmound} />
                </strong>
              </p>
            </ItemDetalle>
            <ItemDetalle>
              <p>
                <span>Tipo de Cambio</span>
                <input
                  onChange={(e) => changeTipoCambio(e)}
                  autoFocus={focusInput}
                  autoComplete='off'
                  type='text'
                  readOnly={editInput}
                  defaultValue={exchangeRate}
                  name='tipo_cambio'
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                />
              </p>
            </ItemDetalle>
            <ItemDetalle>
              <p>
                <span>Nº Operación Banco</span>
                <strong>
                  {getIdDetalle ? getIdDetalle.origin_bank.operation_number : '--'}
                </strong>
              </p>
            </ItemDetalle>
            <ItemDetalle>
              <p>
                <span>Estado</span>
                <strong>{getIdDetalle ? getIdDetalle.status : '--'}</strong>
              </p>
            </ItemDetalle>
            <ItemDetalle>
              <p>
                <span>Banco de Origen</span>
                <strong>{getIdDetalle ? getIdDetalle.origin_bank.name : '--'}</strong>
              </p>
            </ItemDetalle>
          </EnvolverItems>
          <DetalleCuenta>
            {imageVoucher && (
              <ShowVoucher
                onClick={() => {
                  showVoucherOperation();
                }}
              >
                Ver Voucher
              </ShowVoucher>
            )}
            <h2>Cuenta Destino</h2>
            <p>
              <span>Banco:</span>{' '}
              <strong>{getIdDetalle ? getIdDetalle.destination_bank.name : '--'}</strong>
            </p>
            <p>
              <span>Número:</span>{' '}
              <strong>
                {getIdDetalle ? getIdDetalle.destination_bank.number_account : '--'}
              </strong>
            </p>
            <p>
              <span>Moneda:</span>{' '}
              <strong>
                {getIdDetalle ? getIdDetalle.destination_bank.currency : '--'}
              </strong>
            </p>
          </DetalleCuenta>
          {enableFinish && (
            <EditarInput>
              <label>Nº Operación Banco Salida</label>
              <input
                className='inputOpDestination'
                type='text'
                autoFocus={enableFinish}
              />
            </EditarInput>
          )}
          {enableCanceled && (
            <EditarInput>
              <label>Motivo Cancelacion</label>
              <input className='inputOpCanceled' type='text' autoFocus={enableCanceled} />
            </EditarInput>
          )}
        </div>
        <DetalleOptions>
          {validationStatus === 10 ||
          validationStatus === 20 ||
          validationStatus === 11 ? (
            <Fragment>
              {shoButtonSave ? (
                <DetalleLink
                  onClick={() => {
                    sentNewAmount();
                  }}
                >
                  Guardar
                </DetalleLink>
              ) : (
                <DetalleLink
                  disabled={disabledBtnEditar}
                  onClick={() => {
                    inputEditar();
                  }}
                >
                  Editar
                </DetalleLink>
              )}
              {validationStatus !== 11 && (
                <DetalleLink
                  onClick={() => {
                    saveArchiveOperation();
                  }}
                  disabled={disabledBtnArchivar}
                >
                  Archivar
                </DetalleLink>
              )}

              {enableCanceled ? (
                <DetalleLink
                  onClick={() => {
                    saveCanceledOperation();
                  }}
                >
                  Guardar
                </DetalleLink>
              ) : (
                <DetalleLink
                  disabled={disabledBtnCancelar}
                  onClick={() => {
                    processCanceledOperation();
                  }}
                >
                  Anular
                </DetalleLink>
              )}

              {/* button facturar */}

              {enableFinish ? (
                <DetalleLink
                  onClick={() => {
                    saveCompletedOperation();
                  }}
                >
                  Continuar Facturación
                </DetalleLink>
              ) : (
                <DetalleLink
                  disabled={disabledBtnFinalizado}
                  onClick={() => {
                    processCompletedOperation();
                  }}
                >
                  Facturar
                </DetalleLink>
              )}

              {/* button facturar */}
            </Fragment>
          ) : null}
        </DetalleOptions>
      </Fragment>
    </WrapDetalle>
  );
};

export default DashboardDetalle;
