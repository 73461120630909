import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Markdown from 'react-markdown/with-html';
import { WrapPage } from '../../theme/shared';
import { TextError, CntButton, Button, WrapError, IconButton } from '../../theme/global';
import NoData from '../NoData';
import { TabsContainer, TabItem, TabsContainerWithLabel } from '../../components/NavTabs';
import { Teeny, Typography, Strong, Title } from '../../components/Text';
import Input from '../InputRounded';
import withProps from 'recompose/withProps';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import BoxShadow, { BoxShadowRounded } from '../../components/BoxShadow';
import { WrapInputFile, LabelImage, IconUpload, SelectItem, AlertCopy } from './styles';
import { useIonViewWillLeave, useIonViewDidEnter } from '@ionic/react';
import { isPlatform } from '@ionic/react';

const FILES_ACEPTED = ['image/jpeg', 'image/png', 'application/pdf'];

const FormMakeTransfer = ({
  history,
  desktop,
  isSubmitting,
  setFieldValue,
  urlImage,
  resultOperation,
  getResulNewOperation,
  operationSimulation,
  accountsUser,
  perfilOperation,
  requestCleanImageUrl,
  generalInfo,
  resetForm,
  user,
}) => {
  const [nameFile, setNameFile] = useState(null);
  const [showAlertCopy, setShowAlertCopy] = useState(null);
  const [textCopy, setTextCopy] = useState('');
  const numberAccountBank = generalInfo ? generalInfo.info_operation.account_bank : '';
  const numberAccountInterBank = generalInfo
    ? generalInfo.info_operation.account_interbank
    : '';

  const onUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };




  useEffect(() => {
    
    if (resultOperation && resultOperation.status === 200 && resultOperation.time_update) {
      console.log("rpta11",resultOperation);
      resetForm();
      history.push('/transfer-done');
    }
    if (urlImage && urlImage.status === 201) {
      const dataOperation = {
        simulation: operationSimulation,
        urlImage: urlImage,
        perfilOperation: perfilOperation,
        operationId: resultOperation.id,
        token: user.info.token,
      };
      getResulNewOperation(dataOperation);
      requestCleanImageUrl();
      return;
    } else {
      setNameFile(null);
    }
    if (urlImage && urlImage.status === 400) {
      const dataOperation = {
        simulation: operationSimulation,
        urlImage: null,
        perfilOperation: perfilOperation,
        operationId: resultOperation.id,
        token: user.info.token,
      };
      getResulNewOperation(dataOperation);
      requestCleanImageUrl();
      return;
    } else {
      setNameFile(null);
    }
  }, [
    user,
    getResulNewOperation,
    operationSimulation,
    urlImage,
    perfilOperation,
    requestCleanImageUrl,
    resultOperation,
    history,
    resetForm,
  ]);
  useIonViewDidEnter(() => {
    if (operationSimulation) {
      window.addEventListener('beforeunload', onUnload);
    }
  });
  useIonViewWillLeave(() => {
    window.removeEventListener('beforeunload', onUnload);
  });
  const getCopyAccountCompany = (text) => {
    setTextCopy(text);
    setShowAlertCopy(true);
    setTimeout(() => {
      setTextCopy('');
      setShowAlertCopy(false);
    }, 2000);
  };
  return (
    <>
      {operationSimulation ? (
        <WrapPage className={`${desktop || ''} make-transfer`}>
          <>
            <AlertCopy className={showAlertCopy ? 'active' : ''}>{textCopy}</AlertCopy>
            <TabsContainer className='tab-container-maketransfer-up'>
              <TabItem
                className='strong'
                onClick={() => {
                  history.push('/start-transfer');
                }}
              >
                Paso <Teeny color='primary'>01</Teeny>
              </TabItem>
              <TabItem className='active'>
                Paso <Teeny color='primary'>02</Teeny>
              </TabItem>
              <TabItem>
                Paso <Teeny color='primary'>03</Teeny>
              </TabItem>
            </TabsContainer>
            <Title className={`${desktop || ''} title-make-transfer`}>
              Realiza la Transferencia
            </Title>

            <TabsContainerWithLabel
              my='0'
              autoCount
              label={
                <div>
                  <Strong block font='quinary' size='11px'>
                    Detalle de
                  </Strong>
                  <Strong block secondary font='quinary' size='13px'>
                    Operación
                  </Strong>
                </div>
              }
            >
              <TabItem>
                <Strong block font='quinary' size='7px' secondary>
                  AHORRO
                </Strong>
                <Strong block font='quinary' size='13px'>
                  {operationSimulation && (
                    <span>S/{operationSimulation.save.toFixed(2)}</span>
                  )}
                </Strong>
              </TabItem>

              <TabItem>
                <Strong block font='quinary' size='7px' secondary>
                  COMPRA
                </Strong>
                <Strong block font='quinary' size='13px'>
                  {operationSimulation && (
                    <span>{operationSimulation.exchange_rate}</span>
                  )}
                </Strong>
              </TabItem>
            </TabsContainerWithLabel>

            <TabsContainer className='tab-container-maketransfer' autoCount as='div'>
              <TabItem className='tab-item' padding='16px 10px'>
                <Typography size='9px' font='quaternary'>
                  ENVIAS{' '}
                  {operationSimulation.sent.currency === 'USD' ? 'DÓLARES' : 'SOLES'}
                  <br />
                  <Strong secondary size='16px'>
                    {operationSimulation.sent.currency === 'USD' ? '$ ' : 'S/ '}
                    {operationSimulation.sent.amount}
                  </Strong>
                </Typography>
              </TabItem>
              <TabItem className='tab-item' padding='16px 10px'>
                <Typography size='9px' font='quaternary'>
                  RECIBES{' '}
                  {operationSimulation.received.currency === 'USD' ? 'DÓLARES' : 'SOLES'}
                  <br />
                  <Strong secondary size='16px'>
                    {operationSimulation.received.currency === 'USD' ? '$ ' : 'S/ '}
                    {operationSimulation.received.amount}
                  </Strong>
                </Typography>
              </TabItem>
            </TabsContainer>

            <BoxShadowRounded className='box-shadow-maketransfer'>
              <Typography size='13px'>
                <Strong>Cuenta</Strong> <Strong secondary>Destino: </Strong>
                <Strong secondary>{operationSimulation.destination_bank_name} </Strong>
                {operationSimulation.destination_bank_account}
              </Typography>
            </BoxShadowRounded>

            {generalInfo && (
              <BoxShadow className='box-acount-company'>
                <Grid container direction='row' justify='space-between'>
                  <Typography color='secondary' font='quinary' size='18px'>
                    {generalInfo.info_operation.business_name}
                  </Typography>
                  <img src='/assets/images/isotipo-color.svg' alt='' width='32' />
                </Grid>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <div>
                    <Typography font='quinary' size='13px'>
                      {numberAccountBank}
                    </Typography>
                    <Typography size='13px'>
                      {generalInfo.info_operation.text_account_bank}
                    </Typography>
                  </div>
                  <CopyToClipboard text={numberAccountBank}>
                    <IconButton
                      type='button'
                      onClick={() => {
                        getCopyAccountCompany(numberAccountBank);
                      }}
                      size='13px'
                      shadow='soft'
                    >
                      <img src='/assets/images/file.svg' alt='' width='13' />
                    </IconButton>
                  </CopyToClipboard>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <div>
                    <Typography font='quinary' size='13px'>
                      {numberAccountInterBank}
                    </Typography>
                    <Typography size='13px'>
                      {generalInfo.info_operation.text_account_interbank}
                    </Typography>
                  </div>
                  <IconButton
                    type='button'
                    onClick={() => {
                      getCopyAccountCompany(numberAccountInterBank);
                    }}
                    size='13px'
                    shadow='soft'
                  >
                    <CopyToClipboard text={numberAccountInterBank}>
                      <img src='/assets/images/file.svg' alt='' width='13' />
                    </CopyToClipboard>
                  </IconButton>
                </Grid>
              </BoxShadow>
            )}

            <Form>
              <WrapInputFile className='wrap-input-file'>
                <LabelImage htmlFor='box-imagen-file'>
                  <span>Adjuntar Voucher</span>
                  {nameFile && <em>{nameFile}</em>}
                  <IconUpload className='icon-upload'>
                    <img src='/assets/images/icon-upload.svg' width={16} alt='' />
                  </IconUpload>
                </LabelImage>
                <input
                  id='box-imagen-file'
                  name='imagenFile'
                  type='file'
                  accept={FILES_ACEPTED.join(',')}
                  className='form-control'
                  onChange={(event) => {
                    if (FILES_ACEPTED.includes(event.currentTarget.files[0].type)) {
                      setFieldValue('imagenFile', event.currentTarget.files[0]);
                      setNameFile(event.currentTarget.files[0].name);
                    }
                  }}
                />
                <ErrorMessage name='imagenFile'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
              </WrapInputFile>

              {accountsUser && (
                <>
                  <SelectItem className='select-item'>
                    <Field component='select' name='bankOrigin'>
                      <option value=''>Banco Origen*</option>
                      {accountsUser.list_account.map((item, key) => {
                        if (operationSimulation.sent.currency === item.currency) {
                          return (
                            <option
                              key={key}
                              value={`${item.id_bank}//${item.number_account}//${item.name_bank}//${item.currency}`}
                            >
                              {item.name_bank} / {item.currency} - {item.number_account}
                            </option>
                          );
                        }
                        return (
                          <option key={key} className='option-no-data' value=''>
                            No hay data
                          </option>
                        );
                      })}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='bankOrigin'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </>
              )}
              <div className='input-bank-origen'>
                <Input name='bankOriginOp' placeholder='Nro. Operación' />
                <ErrorMessage name='bankOriginOp'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
              </div>

              {generalInfo && (
                <Typography size='15px' className='info-general info-trans' font='quaternary' center>
                  <Markdown escapeHtml={false}>
                    {generalInfo.info_operation.transfer_time_origin}
                  </Markdown>
                </Typography>
              )}
              <CntButton center>
                <Button type='submit' disabled={isSubmitting}>
                  YA TRANSFERÍ
                </Button>
              </CntButton>
            </Form>
          </>
        </WrapPage>
      ) : (
        <WrapPage className={`${desktop || ''} no-data`}>
          <NoData />
        </WrapPage>
      )}
    </>
  );
};

const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      // imagenFile: '',
      bankOriginOp: '',
      bankOrigin: '',
    }),
    handleSubmit(
      values,
      {
        setSubmitting,
        resetForm,
        props: { getUrlImage, user, urlImage, operationSimulation, sendSimulation },
      }
    ) {
      setSubmitting(false);

      const detectMobilIos = ['ios'].some(isPlatform);
      const detectMobilAndroid = ['android'].some(isPlatform);
      // const detectDesktop = ['desktop'].some(isPlatform);
      let platformCurrent = 'Navegador Web';

      if(detectMobilIos){
        platformCurrent = 'Dispositivo Movil iOS'
      }
      if(detectMobilAndroid){
        platformCurrent = 'Dispositivo Movil Android'
      }
     
      const dataSimulation = {
        operation_platform : platformCurrent,
        bankOriginOp: values.bankOriginOp,
        bankOrigin: values.bankOrigin,
        ...operationSimulation,
      };
      
      sendSimulation(dataSimulation);

      console.log('en formulario->>', dataSimulation)

      let formData = new FormData();
      formData.append('file', values.imagenFile);
      formData.append('title', values.bankOriginOp);
      formData.append('caption', values.bankOrigin);
      const dataUser = {
        token: user.info.token,
        file: formData,
      };

      getUrlImage(dataUser);
      // resetForm();
    },
    validate(values) {
      const errors = {};

      if (!values.bankOriginOp) {
        errors.bankOriginOp = 'Campo Obligatorio';
      }

      if (!values.bankOrigin) {
        errors.bankOrigin = 'Campo Obligatorio';
      }

      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);
export default enhacer(FormMakeTransfer);
