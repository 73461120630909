import styled from 'styled-components/macro';

export const WrapPage = styled.section`
  max-width: '480px';
  width: 94%;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  box-sizing: border-box;
  @media screen and (max-height: 565px) {
    justify-content: flex-start;
  }
  &.home {
    .SentAmount,
    .ReceivedAmount,
    .wrap-save {
      max-width: 400px;
    }
    .cnt-button {
      max-width: 400px;
      margin: 0 auto;
      button {
        width: 100%;
      }
    }
  }
  &.no-data {
    justify-content: center;
  }
  &.desktop {
    padding: 30px 0;
    min-height: 670px;
    height: auto;
    @media screen and (max-width: 768px) {
      padding-top: 100px;
    }
    @media screen and (max-width: 600px) {
      min-height: 0px;
    }
  }
  &.registration-completed {
    &.desktop {
      .wrap-message-register {
        padding: 20px 0;
      }
    }
  }
  &.simulador-page {
    &.desktop {
      justify-content: center;
      height: 100vh;
      min-height: 0px;
      @media screen and (max-width: 600px) {
        height: auto;
      }
    }
  }
  &.login-wrap {
    &.desktop {
      justify-content: center;
    }
  }
  &.password-recovery {
    &.desktop {
      justify-content: center;
    }
  }
  &.start-transfer {
    &.desktop {
      justify-content: center;
    }
  }
  &.transfer-done {
    justify-content: flex-start;
    .title-transfer-done {
      margin-bottom: 20px;
    }
    .box-shadow-transferdone {
      margin-bottom: 10px;
      padding: 10px 20px;
    }
    .large-text {
      margin: 20px auto;
      max-width: 220px;
    }
    @media screen and (max-height: 800px) {
      .large-text {
        margin: 10px auto;
      }
      .wrap-title-code {
        margin-bottom: 10px;
        padding: 10px 20px;
      }
      .tabs-container-resume {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    @media screen and (max-height: 720px) {
      .large-text {
        margin: 0px auto;
      }
      .tabs-container-up {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .title-transfer-done {
        margin-bottom: 10px;
        font-size: 22px;
      }
    }
    @media screen and (max-height: 680px) {
      .title-code {
        p {
          font-size: 12px;
        }
        h3 {
          font-size: 22px;
        }
      }
      .wrap-title-code {
        margin-bottom: 0px;
      }
      .title-transfer-done {
        margin-bottom: 5px;
        font-size: 18px;
      }
      .large-text {
        max-width: 280px;
        font-size: 12px;
      }
      .tabs-container-up {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .tabs-container-resume {
        > div {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .box-shadow-transferdone {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 8px;
      }
    }
  }
  &.start-transfer,
  &.make-transfer {
    justify-content: space-between;
    @media screen and (max-height: 735px) {
      .tabs-container {
        margin: 10px auto;
      }
    }
  }
  &.make-transfer {
    .info-general {
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
    }
    .title-make-transfer {
      margin-bottom: 20px;
    }
    .tab-container-maketransfer {
      margin: 10px auto;
      .tab-item {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    .box-shadow-maketransfer {
      margin-bottom: 10px;
      padding: 10px 20px;
    }
    .box-acount-company {
      padding: 10px 20px;
    }
    @media screen and (max-height: 800px) {
      .title-make-transfer {
        margin-bottom: 10px;
      }
      .tab-container-maketransfer-up {
        margin: 10px auto;
      }

      .box-acount-company {
        > div {
          margin-bottom: 5px;
        }
      }
    }
    @media screen and (max-height: 730px) {
      .box-acount-company {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .title-make-transfer {
        margin-bottom: 5px;
        font-size: 21px;
      }
      .wrap-input-file {
        label {
          height: 40px;
          span {
            font-size: 13px;
          }
          .icon-upload {
            width: 60px;
            height: 40px;
          }
        }
      }
      .info-general {
        font-size: 12px;
        p {
          margin: 5px 0;
        }
      }
      .info-trans {
        font-size: 15px !important;         
      }
      .input-bank-origen {
        input {
          font-size: 13px;
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
      .select-item {
        select {
          height: 40px;
          font-size: 13px;
        }
      }
    }
  }
  &.configure-alerts {
    justify-content: space-between;
    .title-greeen {
      margin-bottom: 20px;
    }
    @media screen and (max-height: 680px) {
      justify-content: flex-start;
      .title-greeen {
        margin: 0;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .card {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .tabs-tc {
        margin-bottom: 0;
      }
      .control-input {
        margin: 0;
        margin-bottom: 15px;
      }
    }
  }
  &.pending-operations,
  &.canceled-operations,
  &.bank-accounts,
  &.completed-operations {
    justify-content: space-between;
    padding-bottom: 10px;
  }
  &.profile-data {
    display: block;
    form {
      height: 100%;
    }
    .inner-page {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .required-fields {
      margin-bottom: 10px;
    }
  }
  &.add-bank-account {
    display: block;
    &.desktop {
      .required-fields {
        margin-bottom: 10px;
      }
    }
    form {
      height: 100%;
    }
    .inner-page {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    @media screen and (max-height: 620px) {
      .tabs-add-bank-account {
        margin: 10px auto;
      }
      .select-item {
        margin-bottom: 10px;
      }
    }
  }
  &.password-recovery,
  &.code-verification {
    justify-content: space-between;
    padding-top: 20px;
    &.desktop {
      justify-content: center;
      @media screen and (max-width: 600px) {
        padding-top: 100px;
      }
    }
  }
  &.edi-profile-data {
    justify-content: flex-start;
    &.profile-data-company {
      justify-content: space-between;
    }
    .title-edit-profile {
      margin-bottom: 20px;
    }
    .input-text,
    .input-date {
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .input-date {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .required-fields {
      margin-bottom: 10px;
    }

    @media screen and (max-height: 800px) {
      .title-edit-profile {
        margin-bottom: 10px;
        font-size: 20px;
      }
      .input-text,
      .input-date {
        margin-bottom: 10px;
      }
      .required-fields {
        margin-bottom: 5px;
      }
    }
    @media screen and (max-height: 730px) {
      .input-text {
        padding-top: 11px;
        padding-bottom: 11px;
      }
      .input-date {
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .tabs-edit-profiledata {
        margin: 10px auto;
      }
      .title-edit-profile {
        margin-bottom: 5px;
        font-size: 18px;
      }
    }
    @media screen and (max-height: 660px) {
      .input-text,
      .input-date {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 13px;
      }

      .input-date {
        .MuiInputBase-root {
          font-size: 13px;
        }
      }
      .wrap-selects {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        > div {
          width: 49%;
          select {
            font-size: 13px;
          }
        }
      }
    }
  }
  &.edit-identity-verification {
    display: block;
    &.desktop {
      .required-text {
        margin-bottom: 10px;
      }
    }
    .texto-intro {
      line-height: 18px;
    }
    .title-edit-identity {
      margin-bottom: 10px;
    }
    form {
      height: 100%;
    }
    .inner-page {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    @media screen and (max-height: 800px) {
      .camera-box {
        margin-top: 10px;
      }
      .title-edit-identity {
        font-size: 20px;
        margin-bottom: 5px;
      }
      .texto-intro {
        line-height: 16px;
      }
      .required-text {
        margin-bottom: 5px;
      }
    }
    @media screen and (max-height: 730px) {
      .camera-box {
        height: 95px;
      }
    }
    @media screen and (max-height: 660px) {
      .tabs-edit-profiledata {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .texto-intro {
        line-height: 14px;
        font-size: 12px;
      }
      .text-camera {
        margin-top: 5px;
      }
      .camera-box {
        height: 55px;
        svg {
          width: 8px;
          height: 8px;
        }
        img {
          width: 25px;
          height: auto;
        }
      }
      .upload-image {
        height: 45px;
      }
    }
  }
  &.edit-profile-company {
    &.desktop {
      .required-fields {
        margin-bottom: 10px;
      }
    }
    .title-edit-company {
      margin-bottom: 10px;
    }
    form {
      height: 100%;
    }
    .inner-page {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .input-text {
      margin: 0 auto;
      margin-bottom: 15px;
    }
    &.step2 {
      .input-text {
        padding-top: 11px;
        padding-bottom: 11px;
      }
      .small-input {
        input {
          height: 40px;
        }
      }
    }
  }
  /* web   */
  &.home {
    .SentAmount,
    .ReceivedAmount,
    .wrap-save {
      max-width: 400px;
    }
    .cnt-button {
      max-width: 400px;
      margin: 0 auto;
      button {
        width: 100%;
      }
    }
  }
  &.web-wrap {
    &.desktop {
      min-height: 735px;
    }
  }
  /* web   */
`;
