import { connect } from 'react-redux';
import { fetchCategories } from '../../../store/web/novedades';

import CategoriesList from './CategoriesList';

const mapStateToProps = (state) => {
  return {
    categories: state.novedadesState.categories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(fetchCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
