import {
  requestFooter,
  receiveFooter,
  requestSuscription,
  receiveSuscription,
  cleanSuscription,
} from './actions';
import { routesApi } from '../../routesApi';

export const fetchFooter = () => {
  return (dispatch) => {
    dispatch(requestFooter());
    return fetch(`${routesApi.pages}131`)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveFooter(json.acf));
      });
  };
};

export const fetchSuscription = (datos) => {
  return (dispatch) => {
    dispatch(requestSuscription());
    return fetch(routesApi.suscriptionFooter, {
      method: 'POST',
      body: datos,
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveSuscription(json));
      });
  };
};

export const getCleanSuscription = () => {
  return (dispatch) => {
    return dispatch(cleanSuscription());
  };
};
