import { connect } from 'react-redux';
import { fetchNewsCategory } from '../../../store/web/novedades';

import NewsCategory from './NewsCategory';

const mapStateToProps = (state) => {
  return {
    getNewsCategory: state.novedadesState.getNewsCategory,
    footerData: state.footerState.footerData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNewsCategory: (category) => dispatch(fetchNewsCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsCategory);
