import React, { useRef, useEffect, useState } from 'react';
import {
  CntButton,
  Button,
  WrapError,
  TextError,
  WrapSelectItem,
  SelectItem,
  InputWrap,
  ButtonVolverWrapper,
  ButtonVolver,
} from '../../theme/global';
import { Teeny, Typography, Strong, Title } from '../Text';
import { TabsContainer, TabItem } from '../NavTabs';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Input, { Container } from '../InputRounded';
import { WrapPage } from '../../theme/shared';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import moment from 'moment';
import PopUp from '../Popup';
import { useFormikContext } from 'formik';
import { ListItem, GroupRadio, TitleRadio, SubTitle, ItemRadio } from './styles';
const ProfileData = ({
  busisness = false,
  processRegister,
  desktop,
  history,
  values,
  ubigeoDepartamentos,
  getUbigeoProvincias,
  ubigeoProvincias,
  generalInfo,
}) => {
  const date_setup = moment().subtract(18, 'years');
  const [selectedDate, handleDateChange] = useState(date_setup);

  const popupRef = useRef(null);
  const { submitForm } = useFormikContext();

  const openOptionRadio = () => {
    popupRef.current.open();
  };
  const onSubmitForm = () => {
    submitForm();
  };

  function validateNumber(event) {
    var key = event.which;
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    if (!processRegister) {
      history.push('/simulador');
    }
  }, [processRegister, history]);
  return (
    <>
      <WrapPage className={`${desktop || ''} profile-data`}>
        <Form>
          <div className='inner-page'>
            <div>
              <TabsContainer>
                <ButtonVolverWrapper className='register'>
                  <ButtonVolver onClick={() => history.push('/create-account')}>
                    Volver
                  </ButtonVolver>
                </ButtonVolverWrapper>
                <TabItem className='active'>
                  Paso <Teeny color='primary'>01</Teeny>
                </TabItem>
                <TabItem>
                  Paso <Teeny color='primary'>02</Teeny>
                </TabItem>
              </TabsContainer>
              <Title>Datos de Perfil</Title>
              <Container>
                <Input name='names' placeholder='Nombres*' />
                <ErrorMessage name='names'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input name='lastNames' placeholder='Apellidos*' />
                <ErrorMessage name='lastNames'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  name='idDoc'
                  placeholder='DNI / Pasaporte*'
                />
                <ErrorMessage name='idDoc'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input name='nationality' placeholder='Nacionalidad*' />
                <ErrorMessage name='nationality'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <InputWrap>
                  <h3 className='label-input-field'>Fecha de nacimiento</h3>
                  <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                    <KeyboardDatePicker
                      format='DD/MM/YYYY'
                      name='dateBirth'
                      maxDate={date_setup}
                      value={selectedDate}
                      onChange={(e) => {
                        handleDateChange(e);
                        values.dateBirth = e;
                      }}
                      keyboardIcon={
                        <img src='/assets/images/calendar.svg' alt='calendar' />
                      }
                    />
                  </MuiPickersUtilsProvider>
                </InputWrap>
                <ErrorMessage name='dateBirth'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input name='civilStatus' placeholder='Estado Civil' />
                <ErrorMessage name='civilStatus'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  name='phone'
                  placeholder='Celular*'
                />
                <ErrorMessage name='phone'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                {busisness && (
                  <>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field component='select' name='ocupacion_user'>
                          <option value=''>Ocupación*</option>
                          {generalInfo.job_occupation.map((item, key) => (
                            <option key={key} data-id={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='ocupacion_user'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field component='select' name='relacion_laboral_user'>
                          <option value=''>Relación Laboral</option>
                          {generalInfo.employment_relationship.map((item, key) => (
                            <option key={key} data-id={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='relacion_laboral_user'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                  </>
                )}
                {/* dirección */}
                {ubigeoDepartamentos && (
                  <>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field
                          className='select-departamento'
                          component='select'
                          name='departamento_personal'
                        >
                          <option value=''>Departamento</option>
                          {ubigeoDepartamentos.departamentos.map((item, key) => (
                            <option
                              key={key}
                              data-ubigeo={item.id_ubigeo}
                              value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                            >
                              {item.nombre_ubigeo}
                            </option>
                          ))}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='departamento_personal'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field component='select' name='provincia_personal'>
                          <option value=''>Provincia*</option>
                          {ubigeoProvincias && (
                            <>
                              {ubigeoProvincias.map((item, key) => (
                                <option
                                  key={key}
                                  data-ubigeo={item.id_ubigeo}
                                  value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                                >
                                  {item.nombre_ubigeo}
                                </option>
                              ))}
                            </>
                          )}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='provincia_personal'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                  </>
                )}

                <Input
                  className='input-text'
                  name='distrito_personal'
                  placeholder='Distrito'
                />
                <ErrorMessage name='distrito_personal'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  className='input-text'
                  name='direccion_personal'
                  placeholder='Dirección'
                />
                <ErrorMessage name='direccion_personal'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                {/* dirección */}
              </Container>
              <Typography
                className='required-fields'
                color='primary'
                center
                size='13px'
                font='quaternary'
              >
                *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
              </Typography>
            </div>

            <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
              <Button
                round
                type='button'
                onClick={() => {
                  openOptionRadio();
                }}
              >
                Continuar
              </Button>
            </CntButton>
          </div>

          <PopUp className='profile-data' ref={popupRef} onClose={() => onSubmitForm()}>
            <GroupRadio>
              <TitleRadio>¿Es una Persona Expuesta Políticamente?</TitleRadio>
              <SubTitle>Declaración Jurada</SubTitle>
              <ListItem>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='politicallyExposed'
                    value='Si'
                    id='exposed-yes'
                  />
                  <label htmlFor='exposed-yes'>Si</label>
                </ItemRadio>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='politicallyExposed'
                    value='No'
                    id='exposed-no'
                  />
                  <label htmlFor='exposed-no'>No</label>
                </ItemRadio>
              </ListItem>
            </GroupRadio>
            <GroupRadio>
              <TitleRadio>¿Es un Sujeto Obligado a Informar a la UIF?</TitleRadio>
              <SubTitle>Declaración Jurada</SubTitle>
              <ListItem>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='informUIF'
                    value='Si'
                    id='inform-yes'
                  />
                  <label htmlFor='inform-yes'>Si</label>
                </ItemRadio>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='informUIF'
                    value='No'
                    id='inform-no'
                  />
                  <label htmlFor='inform-no'>No</label>
                </ItemRadio>
              </ListItem>
            </GroupRadio>
          </PopUp>
        </Form>
      </WrapPage>
    </>
  );
};

const formConfig = {
  validateOnBlur: false,
  // validateOnChange: true,
  // enableReinitialize: true,
  mapPropsToValues: ({ busisness }) => ({
    names: '',
    lastNames: '',
    idDoc: '',
    nationality: '',
    departamento_personal: '',
    provincia_personal: '',
    distrito_personal: '',
    direccion_personal: '',
    dateBirth: moment().subtract(18, 'years'),
    civilStatus: '',
    phone: '',
    politicallyExposed: 'No',
    informUIF: 'No',
    ...(busisness ? { ocupacion_user: '', relacion_laboral_user: '' } : {})

  }),
  handleSubmit(
    values,
    { setSubmitting, props: { processRegister, saveProcessRegister, history, busisness } }
  ) {
    let dataUser = {};
    dataUser.first_name = values.names;
    dataUser.last_name = values.lastNames;
    dataUser.doc_identidad = values.idDoc;
    dataUser.nationality = values.nationality;
    dataUser.departamento_personal = values.departamento_personal;
    dataUser.provincia_personal = values.provincia_personal;
    dataUser.distrito_personal = values.distrito_personal;
    dataUser.direccion_personal = values.direccion_personal;
    dataUser.fecha_nacimiento = moment(values.dateBirth);
    dataUser.estado_civil = values.civilStatus;
    dataUser.nro_celular = values.phone;
    dataUser.persona_expuesta_politicamente = values.politicallyExposed;
    dataUser.obligado_informar_uif = values.informUIF;
    dataUser.email = processRegister.email;
    dataUser.password = processRegister.password;
    saveProcessRegister(dataUser);
    setSubmitting(false);
    const nextUrl = busisness ? '/add-address' : '/identity-verification';
    history.push(nextUrl);
  },
  validate(values, { busisness, ubigeoDepartamentos, getUbigeoProvincias }) {
    const getProvinciaCurrent = () => {
      const selectDpto = document.querySelector('.select-departamento');
      const optionDpto = selectDpto.options[selectDpto.selectedIndex].getAttribute(
        'data-ubigeo'
      );
      getUbigeoProvincias(ubigeoDepartamentos.provincias[optionDpto]);
    };
    getProvinciaCurrent();
    const errors = {};
    if (!values.names) errors.names = 'Campo Obligatorio';
    if (!values.lastNames) errors.lastNames = 'Campo Obligatorio';
    if (!values.idDoc) errors.idDoc = 'Campo Obligatorio';
    if (!values.nationality) errors.nationality = 'Campo Obligatorio';
    if (!values.departamento_personal) errors.departamento_personal = 'Campo Obligatorio';
    if (!values.provincia_personal) errors.provincia_personal = 'Campo Obligatorio';
    if (!values.distrito_personal) errors.distrito_personal = 'Campo Obligatorio';
    if (!values.direccion_personal) errors.direccion_personal = 'Campo Obligatorio';

    if (!values.dateBirth) errors.dateBirth = 'Campo Obligatorio';
    if (!values.civilStatus) errors.civilStatus = 'Campo Obligatorio';
    if (!values.phone) errors.phone = 'Campo Obligatorio';
    if (busisness) {
      if (!values.ocupacion_user) errors.ocupacion_user = 'Campo Obligatorio';
      if (!values.relacion_laboral_user)
        errors.relacion_laboral_user = 'Campo Obligatorio';
    }

    return errors;
  },
};

export default withFormik(formConfig)(ProfileData);
