import React from 'react';
import { CntButton, Button, WrapError, TextError } from '../../theme/global';
import { TabsContainer, TabItem } from '../NavTabs';
import { Teeny, Typography, Strong, Title } from '../Text';
import Input, { Container } from '../InputRounded';
import { withFormik, Form, ErrorMessage } from 'formik';
import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
import { WrapPage } from '../../theme/shared';

const FormCompanyData = ({ desktop, processRegister, saveProcessRegister }) => {
  return (
    <>
      <WrapPage className={`${desktop || ''} edit-profile-company `}>
        <Form>
          <div className='inner-page'>
            <TabsContainer>
              <TabItem className='active'>
                Paso <Teeny color='primary'>01</Teeny>
              </TabItem>
              <TabItem>
                Paso <Teeny color='primary'>02</Teeny>
              </TabItem>
              <TabItem>
                Paso <Teeny color='primary'>03</Teeny>
              </TabItem>
            </TabsContainer>

            <div>
              <Title className='title-edit-company'>Datos de la Empresa</Title>
              <Container>
                <Input
                  className='input-text'
                  name='razon_social_company'
                  placeholder='Razón social*'
                />
                <ErrorMessage name='razon_social_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  className='input-text'
                  name='number_ruc'
                  placeholder='Número de Ruc*'
                />
                <ErrorMessage name='number_ruc'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  className='input-text'
                  name='actividad_principal_company'
                  placeholder='Actividad Principal*'
                />
                <ErrorMessage name='actividad_principal_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  className='input-text'
                  name='number_phone_company'
                  placeholder='Número Telefónico*'
                />
                <ErrorMessage name='number_phone_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
              </Container>
              <Typography
                color='primary'
                center
                size='13px'
                font='quaternary'
                style={{ marginTop: 8 }}
                className='required-fields'
              >
                *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
              </Typography>
            </div>
            <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
              <Button round>Continuar</Button>
            </CntButton>
          </div>
        </Form>
      </WrapPage>
    </>
  );
};

const enhancer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      razon_social_company: '',
      number_ruc: '',
      actividad_principal_company: '',
      number_phone_company: '',
    }),
    handleSubmit(values, { setSubmitting, props: { saveProcessRegister, history } }) {
      saveProcessRegister(values);
      setSubmitting(false);
      history.push('/profile-data-company');
    },
    validate(values) {
      const errors = {};

      if (!values.razon_social_company) {
        errors.razon_social_company = 'Campo Obligatorio';
      }

      if (!values.number_ruc) {
        errors.number_ruc = 'Campo Obligatorio';
      }

      if (!values.actividad_principal_company) {
        errors.actividad_principal_company = 'Campo Obligatorio';
      }

      if (!values.number_phone_company) {
        errors.number_phone_company = 'Campo Obligatorio';
      }

      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);

export default enhancer(FormCompanyData);
