import {
  REQUEST_INFO,
  RECEIVE_GENERAL_INFO,
  UBIGEO_PROVINCIAS,
  UBIGEO_DEPARTAMENTOS,
  CLEAN_UBIGEO_PROVINCIAS
} from './types';

export const requestInfo = () => ({
  type: REQUEST_INFO,
});

export const receiveGeneralInfo = (data) => ({
  type: RECEIVE_GENERAL_INFO,
  generalInfo: data,
});

export const ubigeoDepartamentos = (data) => ({
  type: UBIGEO_DEPARTAMENTOS,
  ubigeoDepartamentos: data,
});

export const ubigeoProvincias = (data) => ({
  type: UBIGEO_PROVINCIAS,
  ubigeoProvincias: data,
});

export const cleanUbigeoProvincias = () => ({
  type: CLEAN_UBIGEO_PROVINCIAS,
});

