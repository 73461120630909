import styled from 'styled-components/macro';
import Toggle from '../toggle';
export const InputBox = styled.div`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  ${Toggle} {
    margin-left: 10px;
  }
  div.vr {
    opacity: 0.15;
    border-left: 0.25px solid #000000;
    width: 0;
    height: 30px;
  }
  input {
    width: auto;
    max-width: 90px;
    border: 1px solid #eee;
    font-family: var(--ion-font-family-quinary);
    color: var(--ion-color-secondary);
    font-size: 16px;
    text-align: center;
    &:focus {
      color: var(--ion-color-primary);
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--ion-font-family-quaternary);
  color: var(--ion-color-primary);
  font-size: 13px;
  max-width: 300px;
  margin: 20px auto;
  p {
    margin: 0;
  }

  & > div {
    width: 50%;
  }
`;
