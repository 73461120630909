import {
  REQUEST_FOOTER,
  RECEIVE_FOOTER,
  REQUEST_SUSCRIPTION,
  RECEIVE_SUSCRIPTION,
  CLEAN_SUSCRIPTION,
} from './types';

export const requestFooter = () => ({
  type: REQUEST_FOOTER,
});

export const receiveFooter = (data) => ({
  type: RECEIVE_FOOTER,
  footerData: data,
});

export const requestSuscription = () => ({
  type: REQUEST_SUSCRIPTION,
});

export const receiveSuscription = (data) => ({
  type: RECEIVE_SUSCRIPTION,
  suscriptionData: data,
});
export const cleanSuscription = () => ({
  type: CLEAN_SUSCRIPTION,
});
