import { connect } from 'react-redux';
import { getCreateInfoCompany } from '../../store/users';
import { getUbigeoProvincias } from '../../store/info';
import FormAddAddress from './FormAddAddress';

const mapStateToProps = (state) => {
  return {
    processRegister: state.userState.processRegister,
    createInfoCompany: state.userState.createInfoCompany,
    ubigeoDepartamentos: state.infoState.ubigeoDepartamentos,
    ubigeoProvincias: state.infoState.ubigeoProvincias,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCreateInfoCompany: (data) => dispatch(getCreateInfoCompany(data)),
  getUbigeoProvincias: (data) => dispatch(getUbigeoProvincias(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAddAddress);
