import { connect } from 'react-redux';
import { fetchComoFunciona, fetchTrust } from '../../../store/web/home';
import TrustTemplate from './TrustTemplate';

const mapStateToProps = (state) => {
  return {
    trust: state.homeState.trust,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchComoFunciona: (data) => dispatch(fetchComoFunciona(data)),
  fetchTrust: (data) => dispatch(fetchTrust(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustTemplate);
