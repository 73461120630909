import React from 'react';
import { Title } from './styles';
const BigTitlePage = ({ className, data }) => {
  return (
    <>
      {data && (
        <Title className={className || ''}>
          <h2>
            {data.title} <strong>{data.titleBold} </strong>
          </h2>
          <span>{data.subtitle}</span>
        </Title>
      )}
    </>
  );
};

export default BigTitlePage;
