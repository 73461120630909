import {
  REQUEST_FOOTER,
  RECEIVE_FOOTER,
  REQUEST_SUSCRIPTION,
  RECEIVE_SUSCRIPTION,
  CLEAN_SUSCRIPTION,
} from './types';

const INITIAL_STATE = {
  footerData: null,
  loading: false,
  suscriptionData: null,
};

export const footerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_FOOTER:
      return {
        ...state,
        loading: false,
      };
    case RECEIVE_FOOTER:
      return {
        ...state,
        footerData: action.footerData,
        loading: false,
      };
    case REQUEST_SUSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_SUSCRIPTION:
      return {
        ...state,
        suscriptionData: action.suscriptionData,
        loading: false,
      };
    case CLEAN_SUSCRIPTION:
      return {
        ...state,
        suscriptionData: INITIAL_STATE.suscriptionData,
        loading: false,
      };
    default:
      return state;
  }
};
