import React, { useEffect, useState } from 'react';
import FormSuscription from './FooterForm';
import Navigation from './FooterNavigation';
import Credits from './FooterCredits';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { WrapMenuFoot, MenuFoot, Tooltip, CloseTooltip, TextTooltip } from './styles';
const Footer = ({
  fetchFooter,
  footerData,
  fetchSuscription,
  suscriptionData,
  getCleanSuscription,
  receiveRate,
}) => {
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    const suscriptionSent = () => {
      MySwal.fire({
        customClass: {
          popup: 'popup-suscription',
        },
        icon: 'success',
        title: 'Suscripción realizada con exito',
        showCloseButton: true,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      }).then((result) => {
        if (result && result.isDismissed) {
          getCleanSuscription();
        }
      });
    };
    if (suscriptionData && suscriptionData.status === 'mail_sent') {
      suscriptionSent();
    }
    if (!footerData) {
      fetchFooter();
    }
  }, [fetchFooter, MySwal, footerData, suscriptionData, getCleanSuscription]);

  const [hideTooltip, setHideTooltip] = useState(true);

  const suscripForm = (value) => {
    let formData = new FormData();
    formData.append('your-email', value.email);
    fetchSuscription(formData);
  };

  const handleCloseTooltip = () => {
    setHideTooltip(false);
  };

  return (
    <>
      {receiveRate && receiveRate.whatsapp_info && (
        <WrapMenuFoot>
          <MenuFoot>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`https://api.whatsapp.com/send?phone=51${receiveRate.whatsapp_info}text=`}
            >
              <img src='/assets/images/icon-menu-footer.png' width={80} alt='' />
            </a>
          </MenuFoot>
          {hideTooltip && (
            <Tooltip onClick={handleCloseTooltip}>
              <img
                src='/assets/images/wts-bg-tooltip.png'
                width='172'
                height='79'
                alt='fondo'
              />
              <CloseTooltip className='icon-close'></CloseTooltip>
              <TextTooltip>¿Necesitas Ayuda?</TextTooltip>
            </Tooltip>
          )}
        </WrapMenuFoot>
      )}
      {footerData && (
        <>
          <FormSuscription
            description={footerData.descripcion_suscripcion}
            title={footerData.titulo_suscripcion}
            label={footerData.titulo_formulario_suscripcion}
            suscripForm={suscripForm}
          />
          <Navigation
            email={footerData.correo_info}
            telephone={footerData.telefono_info}
            phone={footerData.celular_info}
            weekdayDays={footerData.dia_semana_grupo.dias_info}
            weekdayHours={footerData.dia_semana_grupo.horas_info}
            weekendDays={footerData.fin_semana_grupo.dias_info}
            weekendHours={footerData.fin_semana_grupo.horas_info}
            facebook={footerData.facebook_info}
            instagram={footerData.instagram_info}
            youtube={footerData.youtube_info}
          />
          <Credits
            copy={footerData.copy_info}
            address={footerData.direccion_info}
            ruc={footerData.ruc_info}
          />
        </>
      )}
    </>
  );
};

export default Footer;
