import styled from 'styled-components/macro';
export const ImagenLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  align-items: center;
  display: flex;
  width: 25vw;
  padding-bottom: 50px;
  box-sizing: border-box;
  figure {
    position: relative;
  }
  img {
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }
  @media screen and (max-width: 745px) {
    display: none;
  }
  &.simulador-page {
    figure {
      position: static;
    }
  }
`;
export const ImagenRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  align-items: center;
  display: flex;
  z-index: -1;
  width: 28vw;
  padding-bottom: 50px;
  box-sizing: border-box;
  img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width: 745px) {
    display: none;
  }
`;
