import { connect } from 'react-redux';
import { getUrlImage, requestCleanImageUrl } from '../../store/users';
import { getResulNewOperation, sendSimulation } from '../../store/operations';

import FormMakeTransfer from './FormMakeTransfer';

const mapStateToProps = (state) => {
  return {
    urlImage: state.userState.urlImage,
    perfilOperation: state.userState.perfilOperation,
    resultOperation: state.operationState.resultOperation,
    operationSimulation: state.operationState.operationSimulation,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUrlImage: (data) => dispatch(getUrlImage(data)),
  getResulNewOperation: (data) => dispatch(getResulNewOperation(data)),
  requestCleanImageUrl: () => dispatch(requestCleanImageUrl()),
  sendSimulation: (data) => dispatch(sendSimulation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormMakeTransfer);
