import { REQUEST_SLIDER, RECEIVE_SLIDER, REQUEST_SLIDER_FINISHED } from './types';

export const requestBanners = () => ({
  type: REQUEST_SLIDER,
});

export const receiveBanners = (json) => ({
  type: RECEIVE_SLIDER,
  banners: json,
});
export const requestBannersFinished = () => ({
  type: REQUEST_SLIDER_FINISHED,
});
