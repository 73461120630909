import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Wrap, Figura, Title, List, Item } from './styles';
import { Link } from 'react-router-dom';
import { WrapPage } from '../../theme/shared';
import { Typography } from '../Text';
import { sendPerfilOperation, getCleanUserInfo } from '../../store/users/operations';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
function ProfileMenu({
  desktop,
  perfilOperation,
  sendPerfilOperation,
  user,
  getCleanUserInfo,
  accountsUser,
}) {
  useEffect(() => {}, []);
  const chooseProfile = (perfil) => {
    const dataPerfil = {
      id: user.info.user_id,
      perfil: perfil,
    };
    sessionStorage.profile = JSON.stringify(dataPerfil);
    sendPerfilOperation(dataPerfil);
  };
  return (
    <WrapPage className={`${desktop || ''} profile-menu`}>
      <Wrap>
        <Figura>
          <img src='/assets/images/img-profile.png' alt='' />
        </Figura>
        <Title>Mi Perfil</Title>
        <List>
          {accountsUser && accountsUser.has_profile_company ? (
            <>
              {perfilOperation && (
                <Item
                  onClick={() =>
                    chooseProfile(
                      perfilOperation.perfil === 'empresa' ? 'personal' : 'empresa'
                    )
                  }
                >
                  Cambiar a Perfil{' '}
                  {perfilOperation.perfil === 'empresa' ? 'Personal' : 'Empresa'}
                  <span className='icon-arrow-select'></span>
                </Item>
              )}
            </>
          ) : (
            <Item>
              <Link to='/edit-profile-company-paso-1'>
                <Typography size='14px'>
                  No ha creado perfil de Empresa
                  <p>Agregar</p>
                </Typography>
                <span className='icon-arrow-select'></span>
              </Link>
            </Item>
          )}
          <Item>
            <Link to='/profile/configure-alerts'>
              Configurar Alertas
              <span className='icon-arrow-select'></span>
            </Link>
          </Item>
          <Item>
            <Link to='/password-recovery'>
              Cambiar Contraseña
              <span className='icon-arrow-select'></span>
            </Link>
          </Item>
          <Item>
            <Link to='/edit-profile-data'>
              Editar Perfil
              <span className='icon-arrow-select'></span>
            </Link>
          </Item>
          <Item
            onClick={() => {
              sessionStorage.clear();
              // getCleanUserInfo();
              Storage.remove({ key: 'currentUser' }).then( (data)=>{
                  window.location.replace('/login');
                }
              );
              
            }}
          >
            Cerrar Sesión
            <span className='icon-arrow-select'></span>
          </Item>
        </List>
      </Wrap>
    </WrapPage>
  );
}

const mapStateToProps = (state) => {
  return {
    perfilOperation: state.userState.perfilOperation,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendPerfilOperation: (data) => dispatch(sendPerfilOperation(data)),
  getCleanUserInfo: () => dispatch(getCleanUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
