import React from 'react';
import { BlockCredits, Content, Logo, LogoText, Info, Left, Right, Text } from './styles';

const Credits = ({ copy, address, ruc }) => {
  return (
    <>
      <BlockCredits>
        <Content className='content'>
          <Logo>
            <img
              src='/assets/images/footer-credits-logo.png'
              width='67'
              height='50'
              alt='logo'
            />
          </Logo>
          <LogoText>Sr. Cambio</LogoText>
          <Info>
            {copy && (
              <Left>
                <Text>{copy}</Text>
              </Left>
            )}
            {ruc && (
              <Right>
                <Text>{`RUC ${ruc}`}</Text>
              </Right>
            )}
          </Info>
        </Content>
      </BlockCredits>
    </>
  );
};

export default Credits;
