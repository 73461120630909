import styled from 'styled-components/macro';
import { fonts, colors } from '../../../theme/web/theme';

export const BlockOperationType = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1100px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 41.81818181818182%;
  @media screen and (max-width: 1024px) {
    width: 45%;
  }
  @media screen and (max-width: 960px) {
    max-width: 461px;
    width: 100%;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 54.54545454545455%;
  @media screen and (max-width: 1024px) {
    width: 54%;
  }
  @media screen and (max-width: 960px) {
    max-width: 604px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
  }
`;

export const Title = styled.div`
  line-height: 1.4;
  color: ${colors.primary};
  font-family: ${fonts.lato400};
  font-size: 13px;
  text-align: right;
  strong {
    letter-spacing: -0.03em;
    color: ${colors.secondary};
    font-family: ${fonts.lato800};
    font-weight: normal;
    font-size: 14px;
  }
  &.title_left {
    width: 37%;
    @media screen and (max-width: 960px) {
      width: 170px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  &.title_right {
    width: 28.4%;
    @media screen and (max-width: 1024px) {
      width: 32%;
    }
    @media screen and (max-width: 960px) {
      width: 170px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
`;

export const TitleText = styled.p`
  margin: 0;
`;

export const Image = styled.div`
  position: relative;
  margin-bottom: 10px;
  margin-right: 30px;
  @media screen and (max-width: 767px) {
    padding: 0;
    margin: 0 auto;
  }
  &:after {
    content: '';
    width: 0.25px;
    height: 38px;
    background: #000;
    opacity: 0.15;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
  }
`;

export const Partners = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  padding: 20px 0;
  margin: 0;
  &.partners_left {
    width: 59%;
    padding: 17.5px 10px 7.5px 10px;
    @media screen and (max-width: 960px) {
      width: calc(100% - 190px);
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: inherit;
      max-width: 250px;
      width: 100%;
      padding: 10px;
      margin: 0 auto;
    }
    ${Image} {
      @media screen and (min-width: 768px) {
        width: 47.808764940239044%;
        margin-right: 4.382470119521912%;
        &:after {
          left: calc(100% + 3.286852589641434%);
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0 auto 10px auto;
        img {
          width: 120px;
          margin: 0 auto 10px auto;
        }
        &:after {
          display: block;
          width: 100%;
          height: 0.25px;
          position: relative;
          bottom: inherit;
        }
        &:last-child {
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.partners_right {
    width: 69%;
    padding: 17.5px 10px 7.5px 10px;
    @media screen and (max-width: 1024px) {
      width: 66%;
    }
    @media screen and (max-width: 960px) {
      width: calc(100% - 190px);
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: inherit;
      max-width: 250px;
      width: 100%;
      padding: 10px;
      margin: 0 auto;
    }
    ${Image} {
      @media screen and (min-width: 768px) {
        width: 30.456852791878173%;
        margin-right: 4.314720812182742%;
        &:after {
          left: calc(100% + 6.472081218274113%);
        }
        &:nth-child(3n + 3) {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0 auto 10px auto;
        img {
          width: 120px;
          margin: 0 auto 10px auto;
        }
        &:after {
          display: block;
          width: 100%;
          height: 0.25px;
          position: relative;
          bottom: inherit;
        }
        &:last-child {
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
`;
