import { requestTermsConditions, receiveTermsConditions } from './actions';
import { routesApi } from '../../../routesApi';
export const fetchTermsConditions = () => {
  return (dispatch) => {
    dispatch(requestTermsConditions());
    return fetch(`${routesApi.termsConditions}terminos-y-condiciones`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveTermsConditions(json[0]));
      });
  };
};
