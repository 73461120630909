import React from 'react';
import FormMakeTransfer from '../components/FormMakeTransfer';
const MakeTransfer = ({ history, desktop, user, generalInfo }) => {
  return (
    <FormMakeTransfer
      generalInfo={generalInfo}
      history={history}
      user={user}
      desktop={desktop}
    />
  );
};

export default MakeTransfer;
