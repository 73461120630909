import { connect } from 'react-redux';
import { saveProcessRegister } from '../../store/users';

import FormAddCompanyProfileData from './FormAddCompanyProfileData';

const mapStateToProps = (state) => {
  return {
    processRegister: state.userState.processRegister,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveProcessRegister: (data) => dispatch(saveProcessRegister(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAddCompanyProfileData);
