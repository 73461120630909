import styled from 'styled-components/macro';
export const ListCardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
`;

export const ListCardContainer = styled.section`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;

  margin: 0px auto;
  margin-bottom: 10px;
  max-width: 300px;

  font-family: var(--ion-font-family);
  font-size: 13px;
  color: var(--ion-color-primary);

  & > div.vr {
    height: 35px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

export const ListCardContent = styled.div`
  padding: 12px 14px;
  padding-right: 0;
  min-width: 10px;
  width: 100%;
  min-height: 29px;
  p {
    margin: 0;
  }
`;
