import styled from 'styled-components/macro';

export const Title = styled.h4`
  font-size: 14px;
  color: var(--ion-color-secondary);
  margin: 0;
  margin-bottom: 10px;
  font-family: var(--ion-font-family-quinary);
`;
export const Redes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const RedesIcon = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  color: var(--ion-color-secondary);
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin: 0 10px 10px 0;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Wrap = styled.div`
  &.simulador-page {
    &.inBanners {
      bottom: 30px;
      margin-top: 15px;
      top: inherit;
    }
  }
  &.inBanners {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    ${Redes} {
      order: -1;
    }
    ${RedesIcon} {
      margin-bottom: 0;
    }
    ${Title} {
      margin: 0;
      margin-left: 15px;
      font-family: var(--ion-font-family);
    }
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  &.inNews,
  &.commonStyle {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    ${Redes} {
      order: -1;
    }
    ${RedesIcon} {
      margin-bottom: 0;
    }
    ${Title} {
      margin: 0;
      margin-left: 15px;
      font-family: var(--ion-font-family);
    }
  }
`;
