import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';

export const WrapClientes = styled.div`
  width: 100%;
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 22px 33px;
  box-sizing: border-box;
`;
export const TitleClientes = styled.h2`
  color: #717171;
  opacity: 0.7;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 17px;
`;

export const InputClientes = styled.div`
  position: relative;
  padding-bottom: 11px;
  input {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    height: 38px;
    width: 100%;
    font-size: 12px;
    border: none;
    padding: 0 30px 0px 20px;
    box-sizing: border-box;
    color: #717171;
    font-family: ${fonts.lato400};
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    display: inline-block;
    width: 14px;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
`;

export const WrapClientesScroll = styled.div`
  overflow: auto;
  height: 493px;
  padding-right: 30px;
  button {
    position: absolute;
    top: 15px;
    right: -20px;
    width: 25px;
    height: 28px;
    line-height: 33px;
    text-align: center;
    background-color: #3aa229;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
  }
  .wrap_clients.showButton {
    button {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const ClientesDatos = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 11px 10px 11px 20px;
  margin-bottom: 11px;
  position: relative;

  td {
    width: 150px;
    padding: 0;
    font-size: 12px;
    span {
      font-size: 12px;
      color: #717171;
      font-family: ${fonts.lato400};
    }
    strong {
      color: #3aa229;
      font-family: ${fonts.lato800};
      display: inline-block;
      font-size: 12px;
      font-weight: normal;
    }
    &:last-child {
      text-align: right;
    }
    button {
      background-color: #3aa229;
      width: 24px;
      height: 24px;
      border-radius: 5px;
      position: absolute;
      right: -20px;
      top: 15px;
      cursor: pointer;
      margin-left: 5px;
    }
  }
`;
