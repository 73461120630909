import React from 'react';

import SliderIntro from '../components/SliderIntro';
import { Plugins } from '@capacitor/core';

const { SplashScreen } = Plugins;

const onInit = () => SplashScreen.hide();

const Intro = () => {
  return <SliderIntro onInit={onInit} />;
};

export default Intro;
