import React, { useEffect } from 'react';
import { CntButton, Button } from '../theme/global';
import Tabs from '../components/OperationHistoryTabsButtons';
import { Title } from '../components/Text';
import ItemOperationCompleted from '../components/ItemOperationCompleted';
// import SearchInput from '../components/searchInput';
import { connect } from 'react-redux';
import { getOperationCompleted, getcleanOpCompleted } from '../store/operations';
import { useIonViewDidLeave } from '@ionic/react';
import { WrapPage } from '../theme/shared';
/*
const useFilter = (history, delay = 0) => {
  const [searchText, setSearchText] = useState('');
  const listFiltred = useMemo(
    () =>
      searchText
        ? history.filter(({ opNumber }) => opNumber.toString().startsWith(searchText))
        : history,
    [history, searchText]
  );

  const [temSearchText, setTempSearchText] = useState('');
  const searchTimeout = useRef(null);
  const onSearchInput = useCallback(
    ({ target }) => {
      const { value } = target;

      setTempSearchText(value);

      if (searchTimeout.current) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => setSearchText(value), delay);
    },
    [delay]
  );

  return [listFiltred, temSearchText, onSearchInput, searchText];
};
*/
const CompletedOperations = ({
  user,
  operationsCompleted,
  getOperationCompleted,
  getcleanOpCompleted,
  desktop,
  history,
}) => {
  // const [listFiltred, searchText, onSearchInput] = useFilter(history, 5e2);

  useEffect(() => {
    if (user && !operationsCompleted) {
      const dataCurrentUser = {
        id: user.info.user_id,
        token: user.info.token,
      };
      getOperationCompleted(dataCurrentUser);
      return;
    }
  }, [getOperationCompleted, user, operationsCompleted]);
  const goSimulator = () => {
    history.push('/start-transfer');
  };
  useIonViewDidLeave(() => {
    getcleanOpCompleted();
  });
  return (
    <>
      <WrapPage className={`${desktop || ''} completed-operations`}>
        <div>
          <Title marginBottom='15px'>Historial de Operaciones</Title>
          {/* <SearchInput
            placeholder='Buscar Número de Operación'
            defaultValue='Buscar Número de Operación'
            // onChange={onSearchInput}
          /> */}
          <Tabs />
          {operationsCompleted && operationsCompleted.status === 200 ? (
            <>
              {operationsCompleted.result.map((item, key) => (
                <ItemOperationCompleted key={key} dataKey={key} data={item} />
              ))}
            </>
          ) : (
            <h3 className='text-no-operation'>No tienes operaciones finalizadas</h3>
          )}
        </div>

        <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
          <Button
            type='button'
            onClick={() => {
              goSimulator();
            }}
          >
            NUEVA OPERACIÓN
          </Button>
        </CntButton>
      </WrapPage>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    operationsCompleted: state.operationState.operationsCompleted,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOperationCompleted: (id) => dispatch(getOperationCompleted(id)),
  getcleanOpCompleted: () => dispatch(getcleanOpCompleted()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompletedOperations);
