import React, { useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Swal from 'sweetalert2';
import { WrapLoading, Loading } from '../../../theme/global';
import withReactContent from 'sweetalert2-react-content';
import {
  Wrap,
  Container,
  LogoForm,
  TitleForm,
  FormInner,
  InputBox,
  WrapLink,
  LinkRecover,
  WrapButton,
} from './styles';
const FormCodeVerification = ({
  history,
  codeSecurity,
  validateCode,
  getValidateCode,
  getCleanValidateCode,
  loading,
}) => {
  const MySwal = withReactContent(Swal);
  const defaultValues = {
    codigo: '',
  };
  useEffect(() => {
    const errorLogin = () => {
      MySwal.fire({
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        focusConfirm: false,
        title: 'Código ingresado no corresponde al enviado.',
      }).then((result) => {
        if (result && result.isDismissed) {
          getCleanValidateCode();
        }
      });
    };

    if (!codeSecurity) {
      history.push('/login-dashboard');
    }
    if (validateCode && validateCode.status === 200) {
      history.push('/change-password');
    }
    if (validateCode && validateCode.status === 400) {
      errorLogin();
    }
  }, [MySwal, codeSecurity, history, validateCode, getCleanValidateCode]);
  return (
    <>
      {loading && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      <Wrap>
        <Container>
          <LogoForm>
            <img
              src='/static/img/login-form-logo.png'
              width='76'
              height='56'
              alt='logo'
            />
          </LogoForm>
          <TitleForm>Ingresar código de verificación</TitleForm>
          <Formik
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={(values) => {
              const errors = {};

              if (!values.codigo) {
                errors.codigo = 'Campo Obligatorio';
              }

              if (Object.keys(errors).length > 0) return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                const data = {
                  id: codeSecurity.id,
                  code: values.codigo,
                };
                getValidateCode(data);

                setSubmitting(false);
                // getCheckEmail(values);
                // resetForm();
              }, 500);
            }}
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form className='formularyLogin'>
                <FormInner>
                  <InputBox>
                    <Field
                      fullWidth
                      name='codigo'
                      type='text'
                      placeholder='Correo Electrónico'
                      component={TextField}
                    />
                  </InputBox>
                </FormInner>
                <WrapLink>
                  <LinkRecover>
                    Revise su correo e ingrese el
                    <strong>código enviado</strong>
                  </LinkRecover>
                </WrapLink>
                <WrapButton>
                  <button
                    className='ctaButton medium'
                    type='submit'
                    disabled={isSubmitting}
                    onClick={() => submitForm}
                  >
                    Enviar
                    {isSubmitting && <Loading />}
                  </button>
                </WrapButton>
              </Form>
            )}
          </Formik>
        </Container>
      </Wrap>
    </>
  );
};

export default FormCodeVerification;
