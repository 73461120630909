import { REQUEST_SLIDER, RECEIVE_SLIDER, REQUEST_SLIDER_FINISHED } from './types';

const INITIAL_STATE = {
  banners: null,
  loading: false,
};

export const sliderIntroReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_SLIDER:
      return {
        ...state,
        loading: false,
      };
    case RECEIVE_SLIDER:
      return {
        ...state,
        banners: action.banners,
        loading: true,
      };
    case REQUEST_SLIDER_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
