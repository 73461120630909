import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  border-radius: 15px;
  width: 30px;
  height: 15px;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--ion-color-primary)' : 'var(--ion-color-secondary)'};
  opacity: 0.1;
`;
const Button = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: 1;
  top: 0;
  position: absolute;
  ${({ checked }) => (checked ? 'transform: translateX(15px);' : '')}
  transition: transform 0.10s;

  background-color: ${({ disabled }) =>
    disabled ? 'var(--ion-color-secondary)' : 'var(--ion-color-primary)'};
`;

const Toggle = ({ checked, onClick, disabled, ...p }) => (
  <div {...p} style={{ position: 'relative' }} onClick={() => !disabled && onClick()}>
    <Container disabled={disabled} />
    <Button className='btn-toogle' checked={checked} disabled={disabled} />
  </div>
);

export default styled(Toggle)``;
