import React, { useRef, useEffect } from 'react';
import {
  CntButton,
  Button,
  TextError,
  WrapError,
  ButtonVolverWrapper,
  ButtonVolver,
} from '../../theme/global';
import { Typography, Strong, Title } from '../Text';
import Input from '../InputRounded';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
import { WrapPage } from '../../theme/shared';
import { WrapSelectItem, SelectItem } from '../OperationForm/styles';
import PopUp from '../Popup';
import Alert from '../Alert';
const FormAddBankAccount = ({
  asStep,
  getNewAccountBank,
  getAccountsUser,
  desktop,
  history,
  user,
  newAccount,
  newAccountBank,
  generalInfo,
  requestCleanProcessRegister,
  getCleanNewAccountBank,
}) => {
  const popupRef = useRef(null);
  const goSimulador = () => {
    const dataCurrentUser = {
      id: user.info.user_id,
      token: user.info.token,
    };
    getAccountsUser(dataCurrentUser);
    history.push('/start-transfer');
  };
  function validateNumber(event) {
    var key = event.which;
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }
  useEffect(() => {
    if (newAccountBank && newAccountBank.status === 200) {
      popupRef.current.open();
      return;
    }
  }, [newAccountBank, newAccount, requestCleanProcessRegister, history]);
  return (
    <>
      <WrapPage
        className={`${desktop || ''} add-bank-account`}
        padding={!asStep && '36px 0 0'}
      >
        <Form>
          <div className='inner-page'>
            <div>
              <ButtonVolverWrapper className='static'>
                <ButtonVolver onClick={() => history.push('/accounts/bank-accounts')}>
                  Volver
                </ButtonVolver>
              </ButtonVolverWrapper>
              <Title>Datos Bancarios</Title>

              <main style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  type='tel'
                  name='accountNumber'
                  placeholder='Numero de Cuenta*'
                />
                <ErrorMessage name='accountNumber'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                <>
                  {generalInfo && (
                    <>
                      <WrapSelectItem>
                        <SelectItem className='select-item'>
                          <Field component='select' name='bankingEntity'>
                            <option value=''>Entidad Bancaria*</option>
                            {generalInfo.banks.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='bankingEntity'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>

                      <WrapSelectItem>
                        <SelectItem className='select-item'>
                          <Field component='select' name='accountType'>
                            <option value=''>Tipo de Cuenta</option>
                            {generalInfo.account_type.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='accountType'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                      <WrapSelectItem>
                        <SelectItem className='select-item'>
                          <Field component='select' name='currencyType'>
                            <option value=''>Tipo de Moneda</option>
                            {generalInfo.currency.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='currencyType'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                    </>
                  )}
                </>

                <Typography
                  color='primary'
                  center
                  size='13px'
                  font='quaternary'
                  style={{ marginTop: 8 }}
                  className='required-fields'
                >
                  *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
                </Typography>
              </main>
            </div>

            <CntButton center>
              <Button type='submit' round>
                Agregar Cuenta
              </Button>
            </CntButton>
          </div>
        </Form>
        <PopUp
          ref={popupRef}
          onClose={() => {
            const dataCurrentUserPop = {
              id: user.info.user_id,
              token: user.info.token,
            };
            getAccountsUser(dataCurrentUserPop);
            getCleanNewAccountBank();
          }}
        >
          <Alert
            icon='success'
            title='Cuenta agregada con exito'
            subtitle='Si desea puede agregar otra cuenta o realizar una operación'
            description='Podra agregar, más cuentas desde su perfil'
          />
          <CntButton center style={{ marginTop: `10px` }}>
            <Button
              className='redirect'
              type='button'
              onClick={() => {
                popupRef.current.close();
                getCleanNewAccountBank();
                goSimulador();
              }}
              round
            >
              Nueva Operación
            </Button>
          </CntButton>
          <br />
        </PopUp>
      </WrapPage>
    </>
  );
};

const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      accountNumber: '',
      bankingEntity: '',
      accountType: '',
      currencyType: '',
    }),
    handleSubmit(
      values,
      { setSubmitting, resetForm, props: { getNewAccountBank, user } }
    ) {
      setSubmitting(false);
      const dataAccountBank = {
        client_id: user.info.user_id,
        token: user.info.token,
        numero_cuenta_banco: values.accountNumber,
        entidad_bancaria: values.bankingEntity,
        tipo_cuenta: values.accountType,
        tipo_moneda: values.currencyType,
      };
      getNewAccountBank(dataAccountBank);
      resetForm();
    },
    validate(values) {
      const errors = {};

      if (!values.accountNumber) {
        errors.accountNumber = 'Campo Obligatorio';
      } else if (values.accountNumber.length < 8) {
        errors.accountNumber = 'El número de cuenta debe tener minimo 8 caracteres';
      }
      if (!values.bankingEntity) {
        errors.bankingEntity = 'Campo Obligatorio';
      }

      if (!values.accountType) {
        errors.accountType = 'Campo Obligatorio';
      }

      if (!values.currencyType) {
        errors.currencyType = 'Campo Obligatorio';
      }

      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);
export default enhacer(FormAddBankAccount);
