import styled from 'styled-components/macro';

export const Section = styled.section`
  background: linear-gradient(to top, #247b17 0.68%, #83d500 100%);
`;

export const Wrap = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--ion-color-primary-contrast);
`;
export const Isotipo = styled.div``;
export const HelloText = styled.div`
  font-size: 20px;
  margin-bottom: 5px;
`;
export const WrapProfile = styled.div`
  position: absolute;
  max-width: 300px;
  width: 100%;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;
export const TextProfile = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`;
export const RowProfile = styled.div`
  background: var(--ion-color-primary-contrast);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
`;
export const Item = styled.div`
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
  font-size: 13px;
  text-align: center;
  justify-content: center;
  font-family: var(--ion-font-family-quinary);
  color: var(--ion-color-primary);
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    opacity: 0;
    border-color: transparent transparent var(--ion-color-secondary) transparent;
  }
  &.active {
    color: var(--ion-color-secondary);
    &:after {
      opacity: 1;
    }
  }
`;
export const Name = styled.h3`
  font-size: 25px;
  font-family: var(--ion-font-family-quinary);
  margin: 0;
`;
export const Line = styled.div`
  width: 160px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 auto;
  border-radius: 5px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 20px;
`;
