import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import { MenuContainer } from './styles';

import { ResizeObserver } from '@juggle/resize-observer';
export { MenuList, MenuListItem } from './styles';
export { default as MenuIcon } from './MenuIcon';

const Menu = React.memo(
  React.forwardRef(({ anchorEl, ...p }, ref) => {
    const boxRef = useRef(null);
    const [isOpen, seIsOpen] = useState(false);
    const [parentPosition, seParentPosition] = useState({ width: 0, height: 0 });
    const [boxSize, setBoxSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
      if (!isOpen) return;
      const handler = ({ target }) => !boxRef.current.contains(target) && seIsOpen(false);
      document.addEventListener('click', handler);
      return () => document.removeEventListener('click', handler);
    }, [boxRef, isOpen]);

    useImperativeHandle(
      ref,
      () => ({
        open: () => seIsOpen(true),
        close: () => seIsOpen(false),
        toggle: () => seIsOpen(v => !v)
      }),
      []
    );

    // observe attached element
    useEffect(() => {
      if (anchorEl.current) {
        const observer = new ResizeObserver(([{ borderBoxSize }]) => {
          const [{ inlineSize: width, blockSize: height }] = borderBoxSize;
          seParentPosition({ width, height });
        });
        observer.observe(anchorEl.current, { box: 'border-box' });
        return () => observer.disconnect();
      }
    }, [anchorEl]);

    // observe menu
    useEffect(() => {
      if (boxRef.current) {
        const observer = new ResizeObserver(([{ borderBoxSize }]) => {
          const [{ inlineSize: width, blockSize: height }] = borderBoxSize;
          setBoxSize({ width, height });
        });
        observer.observe(boxRef.current, { box: 'border-box' });
        return () => observer.disconnect();
      }
    }, [boxRef]);

    return (
      <MenuContainer
        {...p}
        open={isOpen}
        parentWidth={parentPosition.width}
        parentHeight={parentPosition.height}
        selfWidth={boxSize.width}
        selfheight={boxSize.height}
        ref={boxRef}
      />
    );
  })
);

export default Menu;
