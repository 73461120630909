export const REQUEST_COMO_FUNCIONA = 'REQUEST_COMO_FUNCIONA';
export const RECEIVE_COMO_FUNCIONA = 'RECEIVE_COMO_FUNCIONA';

export const REQUEST_TRUST = 'REQUEST_TRUST';
export const RECEIVE_TRUST = 'RECEIVE_TRUST';

export const REQUEST_CHANGE_DOLLAR = 'REQUEST_CHANGE_DOLLAR';
export const RECEIVE_CHANGE_DOLLAR = 'RECEIVE_CHANGE_DOLLAR';

export const REQUEST_YOUR_ALLY = 'REQUEST_YOUR_ALLY';
export const RECEIVE_YOUR_ALLY = 'RECEIVE_YOUR_ALLY';
export const REQUEST_OPERATION_TYPE = 'REQUEST_OPERATION_TYPE';
export const RECEIVE_OPERATION_TYPE = 'RECEIVE_OPERATION_TYPE';
