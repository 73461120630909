import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';

export const Section = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    right: 0;
    margin: 0 auto;
    background-image: url('/static/image/bg-change-current.png');
    background-size: cover;
    background-position: center;
    max-width: 1152px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const WrapContainer = styled.div`
  max-width: 325px;
  width: 93%;
  margin: auto;
  text-align: center;
  padding: 150px 0;
  position: relative;
  z-index: 2;
`;

export const BaseCelphone = styled.div`
  position: absolute;
  top: 200px;
  z-index: 1;
  left: 50%;
  max-width: 330px;
  width: 100%;
  transform: translateX(-50%);
`;
export const ChangeDollarImg = styled.div`
  position: relative;
  z-index: 2;
`;
export const Info = styled.div`
  margin-top: 30px;
  h2 {
    letter-spacing: -0.03em;
    color: #3aa229;
    font-family: ${fonts.lato800};
    font-style: normal;
    font-size: 30px;
    margin: 0;
    margin-bottom: 5px;
  }
  p {
    font-family: ${fonts.lato400};
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #717171;
    margin: 0;
    strong {
      color: #3aa229;
      display: block;
      font-family: ${fonts.lato800};
      display: block;
      margin-bottom: 20px;
    }
  }
`;

export const ItemFlex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .change_dollar_img1 {
    width: 45%;
    margin-right: 5%;
  }
  .change_dollar_img2 {
    width: 50%;
  }
`;
