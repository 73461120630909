import {
	REQUEST_CONTACT, 
	RECEIVE_CONTACT,
} from './types'


export const requestContact = () =>({
	type: REQUEST_CONTACT,
});

export const receiveContact = (dataContac) =>({
	type: RECEIVE_CONTACT,
	contact: dataContac
});