import { connect } from 'react-redux';
import { fetchFrecuentQuestion } from '../../../store/web/frequentsQuestions';

import FrequentQuestionsTemplate from './FrequentQuestionsTemplate';

const mapStateToProps = (state) => {
  return {
    frequentsQuestions: state.frequentsQuestionsState.frequentsQuestions,
    footerData: state.footerState.footerData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFrecuentQuestion: (data) => dispatch(fetchFrecuentQuestion(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequentQuestionsTemplate);
