import {
  requestNovedades,
  receiveNovedades,
  receiveCategories,
  receiveNews,
  receiveNews2,
  receiveNewsCategory,
  receiveFeatured,
} from './actions';
import { routesApi } from '../../../routesApi';

export const fetchNovedades = () => {
  return (dispatch) => {
    dispatch(requestNovedades);
    return fetch(routesApi.newslist)
      .then((response) => response.json())
      .then((json) => {
        const getData = json.slice(0, 6);
        dispatch(receiveNovedades(getData));
      });
  };
};
export const fetchFeatured = () => {
  return (dispatch) => {
    dispatch(requestNovedades);
    return fetch(routesApi.newsFeatured)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveFeatured(json));
      });
  };
};
export const fetchCategories = () => {
  return (dispatch) => {
    dispatch(requestNovedades);
    return fetch(routesApi.categoriesList)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveCategories(json));
      });
  };
};
export const fetchNews = (slugNews) => {
  return (dispatch) => {
    dispatch(requestNovedades);
    return fetch(routesApi.getPostBySlug + slugNews.slug)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveNews(json[0]));
      });
  };
};
export const fetchNews2 = (slugNews) => {
  return (dispatch) => {
    dispatch(requestNovedades);
    return fetch(routesApi.getPostBySlug2 + slugNews.slug)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveNews2(json[0]));
      });
  };
};
export const fetchNewsCategory = (category) => {
  return (dispatch) => {
    dispatch(requestNovedades);
    return fetch(routesApi.getPostByCategory + category.category)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveNewsCategory(json));
      });
  };
};
