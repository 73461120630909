import React, { useRef, useEffect } from 'react';
import { CntButton, Button, TextError, WrapError } from '../../theme/global';
import { TabsContainer, TabItem } from '../NavTabs';
import { Teeny, Typography, Strong, Title } from '../Text';
import Input from '../InputRounded';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import { WrapSelectItem, SelectItem } from '../OperationForm/styles';
import PopUp from '../Popup';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';
const FormEditBankAccount = ({
  asStep,
  getAccountsUser,
  accountsUser,
  desktop,
  history,
  user,
  newAccount,
  currentAccountBank,
  editAccountBank,
  getCleanCurrentAccountBank,
  generalInfo,
}) => {
  const popupRef = useRef(null);
  function validateNumber(event) {
    var key = event.which;
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }
  useEffect(() => {
    if (!currentAccountBank) {
      history.push('/accounts/bank-accounts');
    }
    if (editAccountBank && editAccountBank.status === 200) {
      popupRef.current.open();
      return;
    }
  }, [editAccountBank, newAccount, history, currentAccountBank]);
  return (
    <>
      <WrapPage
        className={`${desktop || ''} add-bank-account`}
        padding={!asStep && '36px 0 0'}
      >
        <Form>
          <div className='inner-page'>
            <div>
              <TabsContainer className='tabs-add-bank-account'>
                <TabItem className='strong'>
                  Paso <Teeny color='primary'>01</Teeny>
                </TabItem>
                <TabItem className='active'>
                  Paso <Teeny color='primary'>02</Teeny>
                </TabItem>
                <TabItem>
                  Paso <Teeny color='primary'>03</Teeny>
                </TabItem>
              </TabsContainer>

              <Title>Datos de Bancarios</Title>
              <main style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  type='tel'
                  name='accountNumber'
                  placeholder='Numero de Cuenta*'
                />
                <ErrorMessage name='accountNumber'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                <>
                  {generalInfo && (
                    <>
                      <WrapSelectItem>
                        <SelectItem className='select-item'>
                          <Field component='select' name='bankingEntity'>
                            <option value=''>Entidad Bancaria*</option>
                            {generalInfo.banks.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='bankingEntity'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>

                      <WrapSelectItem>
                        <SelectItem className='select-item'>
                          <Field component='select' name='accountType'>
                            <option value=''>Tipo de Cuenta</option>
                            {generalInfo.account_type.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='accountType'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                      <WrapSelectItem>
                        <SelectItem className='select-item'>
                          <Field component='select' name='currencyType'>
                            <option value=''>Tipo de Moneda</option>
                            {generalInfo.currency.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='currencyType'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                    </>
                  )}
                </>

                <Typography
                  color='primary'
                  center
                  size='13px'
                  font='quaternary'
                  style={{ marginTop: 8 }}
                >
                  *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
                </Typography>
              </main>
            </div>
            {/* {asStep && (
            <div slot='fixed' style={{ width: '100%', bottom: 0 }}>
              <CntButton center>
                <IconButtonContainer margin='30px 0'>
                  <IconButton round>+</IconButton>
                </IconButtonContainer>
              </CntButton>
            </div>
          )} */}
            <CntButton center>
              <Button type='submit' round>
                Editar Cuenta
              </Button>
            </CntButton>
          </div>
        </Form>
        <PopUp
          ref={popupRef}
          onClose={() => {
            const dataCurrentUser = {
              id: user.info.user_id,
              token: user.info.token,
            };
            getAccountsUser(dataCurrentUser);
            getCleanCurrentAccountBank();
            history.push('/accounts/bank-accounts');
          }}
        >
          <Alert icon='success' title='Cuenta editada con exito' />
        </PopUp>
      </WrapPage>
    </>
  );
};

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ currentAccountBank }) {
    const values = {};
    if (currentAccountBank) {
      values.accountNumber = currentAccountBank.number_account;
      values.bankingEntity = currentAccountBank.id_bank;
      values.accountType = currentAccountBank.id_type_account;
      values.currencyType = currentAccountBank.id_currency;
    }

    return values;
  },
  validate(values) {
    const errors = {};
    if (!values.accountNumber) {
      errors.accountNumber = 'Campo Obligatorio';
    } else if (values.accountNumber.length < 8) {
      errors.accountNumber = 'El número de cuenta debe tener minimo 8 caracteres';
    }
    if (!values.bankingEntity) {
      errors.bankingEntity = 'Campo Obligatorio';
    }
    if (!values.accountType) {
      errors.accountType = 'Campo Obligatorio';
    }
    if (!values.currencyType) {
      errors.currencyType = 'Campo Obligatorio';
    }
    return errors;
  },

  handleSubmit(
    values,
    { setSubmitting, resetForm, props: { getEditAccountBank, currentAccountBank, user } }
  ) {
    setSubmitting(false);
    const dataAccountBank = {
      token: user.info.token,
      id_cuenta: currentAccountBank.id_cuenta,
      numero_cuenta_banco: values.accountNumber,
      entidad_bancaria: values.bankingEntity,
      tipo_cuenta: values.accountType,
      tipo_moneda: values.currencyType,
    };
    getEditAccountBank(dataAccountBank);
    resetForm();
  },
};
export default withFormik(formConfig)(FormEditBankAccount);
