import styled from 'styled-components/macro';

export const Item = styled.div`
  width: ${({ width = '50%' }) => width};
  height: ${({ height = '70px' }) => height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.Currency {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-height: 625px) {
    height: 60px;
  }
`;

export const RequiredField = styled.div`
  margin-bottom: 25px;
  @media screen and (max-height: 735px) {
    margin-bottom: 15px;
  }
`;

export const Symbol = styled.div`
  min-width: 31px;
  font-size: 30px;
  font-family: var(--ion-font-family-quinary);
  margin-right: 5px;
`;
export const SymbolName = styled.div`
  font-size: 13px;
`;
export const Label = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-family: var(--ion-font-family-quaternary);
  text-align: right;
  transform: translateY(4px);
`;

export const WrapSave = styled.div`
  padding: 0 20px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 300px;
  justify-content: space-between;
  color: var(--ion-color-primary);
  figure {
    width: 140px;
  }
  img {
    vertical-align: middle;
    margin-right: 10px;
  }
  figcaption {
    font-size: 13px;
    display: inline-block;
    vertical-align: middle;
    strong {
      font-family: var(--ion-font-family-quinary);
    }
  }
  @media screen and (max-height: 660px) {
    margin-bottom: 15px;
  }
`;

export const SaveAmount = styled.div`
  text-align: right;
  font-family: var(--ion-font-family-quinary);
  font-size: 17px;
  color: var(--ion-color-primary);
`;
export const WrapButtonChange = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  width: 70px;
  height: 70px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -43px;
  left: 70px;
  z-index: 5;
`;

export const InputItem = styled.div`
  input {
    width: 100%;
    font-size: 28px;
    text-align: right;
    font-family: var(--ion-font-family-quinary);
    border: none;
    background: none;
  }
  && input ::placeholder {
    opacity: 0.5;
    color: var(--ion-color-primary) !important;
  }
`;

export const ButtonChange = styled.div`
  background-color: var(--ion-color-primary-contrast);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  ${({ reverse }) => (reverse ? 'transform: rotate(180deg);' : '')}
`;

export const FormRow = styled.div`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 20px;
  color: var(--ion-color-primary);
  flex-flow: row wrap;
  max-width: 300px;
  @media screen and (max-height: 660px) {
    margin-bottom: 15px;
  }
  &.RateExchange {
    padding: 0;
    text-align: center;
    font-size: 12px;
    font-family: var(--ion-font-family-quaternary);
    ${Item} {
      position: relative;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        opacity: 0;
        border-color: transparent transparent var(--ion-color-secondary) transparent;
      }
      &:not(:first-child)::before {
        content: '';
        display: block;
        background-color: var(--ion-color-primary);
        width: 1px;
        height: 60%;
        position: absolute;
        left: 0;
        top: 20%;
        opacity: 0.1;
      }
      &.active {
        color: var(--ion-color-secondary);
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &.ReceivedAmount {
    &.simulator {
      ${InputItem} {
        input {
          color: var(--ion-color-yellow);
        }
      }
      ${Symbol} {
        color: var(--ion-color-yellow);
      }
    }
    &:not(.simulator) {
      ${Symbol} {
        color: var(--ion-color-secondary);
      }
    }
  }
  &.SentAmount,
  &.ReceivedAmount {
    .Currency {
      padding-left: 10px;
      box-sizing: border-box;
    }
    ${InputItem} {
      input {
        &:focus {
          color: var(--ion-color-primary);
        }
      }
    }
  }
  &.SentAmount {
    position: relative;
    ${InputItem} {
      input {
        color: var(--ion-color-secondary);
      }
    }
    ${Symbol} {
      color: var(--ion-color-secondary);
    }
  }
`;
export const WrapSelectItem = styled.div``;
export const SelectItem = styled.div`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  position: relative;
  margin-bottom: 20px;
  color: #717171;
  &:after {
    content: '\\e90f';
    font-family: 'icomoon';
    position: absolute;
    top: 0;
    right: 13px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: var(--ion-color-secondary);
  }
  select {
    width: 100%;
    height: 50px;
    background: #fff;
    appearance: none;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 35px;
    box-sizing: border-box;

    &::-ms-expand {
      display: none;
    }
  }
`;
export const Wrap = styled.div`
  &.start-transfer {
    @media screen and (max-height: 735px) {
      .small-wrap-tab {
        margin: 10px auto;
      }
      ${Item} {
        height: 60px;
      }
      ${InputItem} {
        input {
          font-size: 22px;
        }
      }
    }
    @media screen and (max-height: 635px) {
      ${WrapSave} {
        margin-bottom: 10px;
      }
      ${SelectItem} {
        margin-bottom: 10px;
        select {
          height: 45px;
        }
      }
      ${Item} {
        height: 55px;
      }
      ${Symbol} {
        font-size: 25px;
      }
      ${RequiredField} {
        margin-bottom: 10px;
      }
    }
  }
`;
