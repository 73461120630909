import styled from 'styled-components';
import { fonts } from '../../../theme/web/theme';

export const Container = styled.div`
  max-width: 521px;
  width: 93%;
  text-align: center;
  margin: auto;
  padding-bottom: 50px;
  padding-top: 30px;
  position: relative;
  z-index: 10;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 24px;
  padding-bottom: 21px;
  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const Item = styled.div`
  background: #ffffff;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: calc(100% / 4 - 2%);
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2.5%;
  figure {
    width: 37px;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &:last-child {
    margin-right: 0px;
  }

  h3 {
    font-family: ${fonts.lato400};
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #717171;
    margin: 0;
  }

  @media (max-width: 480px) {
    width: 140px;
    margin: 0;
    margin: 2%;
  }
`;

export const Title = styled.div`
  max-width: 231px;
  width: 100%;
  margin: auto;
  h2 {
    margin: 0;
    font-family: ${fonts.lato800};
    font-size: 25px;
    line-height: 1;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    strong {
      font-weight: normal;
      color: #3aa229;
    }
  }
  span {
    font-family: ${fonts.lato800};
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fecf30;
    padding-top: 10px;
  }
`;

export const Text1 = styled.div`
  font-family: ${fonts.lato400};
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
  opacity: 0.9;
  padding-top: 63px;
  strong {
    color: #3aa229;
  }
  @media screen and (max-width: 480px) {
    padding-top: 15px;
    max-width: 260px;
    margin: 0 auto;
  }
`;

export const Text2 = styled.div`
  font-family: ${fonts.lato400};
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  color: #717171;
  span {
    display: inline-block;
    margin-bottom: 10px;
  }
  p {
    max-width: 300px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #717171;
    padding: 21px 0px;
    strong {
      font-family: ${fonts.lato800};
      color: #3aa229;
      display: block;
    }
  }
`;
