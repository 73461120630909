import React, { useRef } from 'react';
import {
  Wancho,
  SliderCtn,
  SliderBtn,
  SliderLink,
  Item,
  ItemParrafo,
  ItemLi,
  ArrowSlider,
} from './styles';
import TitlePages from '../TitlePages/Titulo';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

function SliderNews({ data, withTitle }) {
  //SLIDER
  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      425: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  };
  const swiperRef = useRef(null);
  const goNext2 = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev2 = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Wancho>
      {withTitle && (
        <TitlePages
          className='title_pregunta_frecuente'
          title='Nuestro Blog'
          subtitle='Últimas Novedades'
        />
      )}
      <SliderCtn>
        {data && data.length >= 1 && (
          <>
            <Swiper {...params} ref={swiperRef}>
              {data.map((item, key) => (
                <Item to={`/novedad/${item.slug_news}`} className='ItemHome' key={key}>
                  {item.image && <img src={item.image} width='295' height='172' alt='' />}
                  <ItemParrafo>
                    <p> {item.title} </p>
                    <ul>
                      <ItemLi>
                        <span> {item.category_name} </span>
                      </ItemLi>
                      <ItemLi>
                        <span> {item.last_date_post} </span>
                      </ItemLi>
                    </ul>
                  </ItemParrafo>
                </Item>
              ))}
            </Swiper>
            <ArrowSlider onClick={goPrev2} className='Prev'></ArrowSlider>
            <ArrowSlider onClick={goNext2} className='Next'></ArrowSlider>
          </>
        )}
        <SliderBtn>
          <SliderLink to='/novedades'> Ver todas </SliderLink>
        </SliderBtn>
      </SliderCtn>
    </Wancho>
  );
}
export default SliderNews;
