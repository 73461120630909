import {
	REQUEST_NOSOTROS, 
	RECEIVE_NOSOTROS
} from './types'


export const requestNosotros = () =>({
	type: REQUEST_NOSOTROS,
});

export const receiveNosotros = (data) =>({
	type: RECEIVE_NOSOTROS,
	nosotros: data
});