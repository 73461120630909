import {
  RECEIVE_OPERATION_SIMULATION,
  SET_OPERATION_HISTORY,
  REQUEST_ADD_OPERATION,
  RECEIVE_RESULT_ADD_OPERATION,
  CLEAN_OPERATIONS,
  OPERATIONS_PENDING,
  OPERATIONS_COMPLETED,
  OPERATIONS_CANCELED,
  REQUEST_HISTORY_OPERATIONS,
  CLEAN_HISTORY_PENDING,
  CLEAN_HISTORY_COMPLETED,
  CLEAN_HISTORY_CANCELED,
  RECEIVE_RATE,
  CLEAN_RATE,
} from './types';

export const requestAddOperation = () => ({
  type: REQUEST_ADD_OPERATION,
});

export const requestHistoryOperations = () => ({
  type: REQUEST_HISTORY_OPERATIONS,
});

export const cleanOperations = () => ({
  type: CLEAN_OPERATIONS,
});

export const cleanOpPending = () => ({
  type: CLEAN_HISTORY_PENDING,
});

export const cleanOpCompleted = () => ({
  type: CLEAN_HISTORY_COMPLETED,
});

export const cleanOpCanceled = () => ({
  type: CLEAN_HISTORY_CANCELED,
});

export const receiveOperationSimulation = (data) => ({
  type: RECEIVE_OPERATION_SIMULATION,
  operationSimulation: data,
});

export const setOperationHisroty = (history) => ({
  type: SET_OPERATION_HISTORY,
  history,
});

export const receiveResultAddOperation = (data) => ({
  type: RECEIVE_RESULT_ADD_OPERATION,
  resultOperation: data,
});

export const receiveOperationPending = (data) => ({
  type: OPERATIONS_PENDING,
  operationsPending: data,
});

export const receiveOperationCompleted = (data) => ({
  type: OPERATIONS_COMPLETED,
  operationsCompleted: data,
});

export const receiveOperationCanceled = (data) => ({
  type: OPERATIONS_CANCELED,
  operationsCanceled: data,
});

export const receiveRate = (data) => ({
  type: RECEIVE_RATE,
  receiveRate: data,
});

export const cleanRate = () => ({
  type: CLEAN_RATE,
});
