import React, { useEffect } from 'react';
import { Base64 } from 'js-base64';
import DashboardTemplate from '../../components/web/Dashboard';
import { connect } from 'react-redux';
import { getUserOffLineDashboard } from '../../store/web/users/operations';
const Dashboard = ({ userDashboard, history, getUserOffLineDashboard }) => {
  useEffect(() => {
    const dataCurrenUserDashboard = sessionStorage.loginDashboard;

    if (!userDashboard && dataCurrenUserDashboard) {
      const decodeDataUser = JSON.parse(Base64.decode(dataCurrenUserDashboard));
      getUserOffLineDashboard(decodeDataUser);
    }
    if (!userDashboard && !dataCurrenUserDashboard) {
      history.push('/login-dashboard');
    }
  }, [history, userDashboard, getUserOffLineDashboard]);
  return <>{userDashboard && <DashboardTemplate />}</>;
};

const mapStateToProps = (state) => ({
  userDashboard: state.userDashboardState.userDashboard,
});
const mapDispatchToProps = (dispatch) => ({
  getUserOffLineDashboard: (data) => dispatch(getUserOffLineDashboard(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
