import { connect } from 'react-redux';
import {
  getAccountsUser,
  sendPerfilOperation,
  getInforCurrentUser,
} from '../../store/users';

import WelcomeUser from './WelcomeUser';
const mapStateToProps = (state) => {
  return {
    accountsUser: state.userState.accountsUser,
    perfilOperation: state.userState.perfilOperation,
    infoCurrentUser: state.userState.infoCurrentUser,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getInforCurrentUser: (id) => dispatch(getInforCurrentUser(id)),
  sendPerfilOperation: (data) => dispatch(sendPerfilOperation(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeUser);
