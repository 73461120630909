import React from 'react';
import { Typography } from '../Text';
import Strong from '../Strong';
import { TabsContainerWithLabel, TabsContainer, TabItem } from '../NavTabs';

const Amount = ({ page, label, amount, labelSize, amountSize, ...p }) => {
  return (
    <TabItem shadow={false} {...p}>
      {page === 'start-transfer' ? (
        <>
          <Strong block font='quinary' size={labelSize} color='inherit'>
            {label}
          </Strong>
          <Strong block font='quinary' size={amountSize}>
            {amount}
          </Strong>
        </>
      ) : (
        <>
          <span className='item-label'>{label}</span>
          <div className='item-text-amount'>{amount}</div>
        </>
      )}
    </TabItem>
  );
};

const BuySaleIndicator = ({
  simulator,
  buyExangeRate,
  saleExangeRate,
  isSale,
  switchValues,
  className,
}) =>
  simulator ? (
    <TabsContainer autoCount className={className || ''}>
      <Amount
        label='COMPRA'
        amount={buyExangeRate}
        className={`${!isSale ? 'active' : ''} tab-item`}
        onClick={() => isSale && switchValues()}
        labelSize='11px'
        amountSize='18px'
      />
      <Amount
        label='VENTA'
        amount={saleExangeRate}
        className={`${isSale ? 'active' : ''} tab-item`}
        onClick={() => !isSale && switchValues()}
        labelSize='11px'
        amountSize='18px'
      />
    </TabsContainer>
  ) : (
    <TabsContainerWithLabel
      className='small-wrap-tab'
      label={
        <Typography size='13px' font='quinary'>
          Tipo de{' '}
          <Strong secondary font='inherit'>
            Cambio
          </Strong>
        </Typography>
      }
      autoCount
    >
      <Amount
        label='COMPRA'
        amount={buyExangeRate}
        className={!isSale ? 'active' : ''}
        onClick={() => isSale && switchValues()}
        labelSize='7px'
        amountSize='13px'
        indicator={false}
        page='start-transfer'
      />

      <Amount
        label='VENTA'
        amount={saleExangeRate}
        className={isSale ? 'active' : ''}
        onClick={() => !isSale && switchValues()}
        labelSize='7px'
        amountSize='13px'
        indicator={false}
        page='start-transfer'
      />
    </TabsContainerWithLabel>
  );

export default BuySaleIndicator;
