import {
  receiveOperationSimulation,
  receiveResultAddOperation,
  requestAddOperation,
  cleanOperations,
  receiveOperationPending,
  receiveOperationCompleted,
  receiveOperationCanceled,
  requestHistoryOperations,
  cleanOpPending,
  cleanOpCompleted,
  cleanOpCanceled,
  receiveRate,
  cleanRate,
 } from './actions';
import { showAlertError } from '../users/actions';
import { routesApi } from '../../routesApi';
const responseError = {
  status: 500,
  message: 'Error de servidor o conexión',
};
export const sendSimulation = (data) => {
  return (dispatch) => {
    return dispatch(receiveOperationSimulation(data));
  };
};

export const requestCleanOperation = () => {
  return (dispatch) => {
    return dispatch(cleanOperations());
  };
};

export const getcleanOpPending = () => {
  return (dispatch) => {
    return dispatch(cleanOpPending());
  };
};

export const getcleanOpCompleted = () => {
  return (dispatch) => {
    return dispatch(cleanOpCompleted());
  };
};

export const getcleanOpCanceled = () => {
  return (dispatch) => {
    return dispatch(cleanOpCanceled());
  };
};

export const getResulNewOperation = (data) => {
  const simulation = data.simulation;
  const dataOriginBank = data.simulation.bankOrigin.split('//');
  console.log("new ope",data);
  const dataOperation = {
    client_id: data.perfilOperation.id,
    exchange_rate: simulation.exchange_rate,
    currency_sent: simulation.sent.currency,
    amount_sent: simulation.sent.amount,
    currency_received: simulation.received.currency,
    amount_received: simulation.received.amount,
    origin_bank_id: dataOriginBank[0],
    origin_bank_account: dataOriginBank[1],
    origin_bank_currency: dataOriginBank[3],
    originbank_operation_number: data.simulation.bankOriginOp,
    destination_bank_id: simulation.destination_bank_id,
    destination_bank_account: simulation.destination_bank_account,
    destination_bank_currency: simulation.destination_bank_currency,
    status_id: '10',
    source_funds: simulation.sourceOfFunds,
    perfil_operation: data.perfilOperation.perfil,
    operation_id: data.operationId,
    voucher_operation: data.urlImage ? data.urlImage.source_url : data.urlImage,
    operation_platform: simulation.operation_platform
  };
  console.log('dataOperationxxx1',dataOperation);
  return (dispatch) => {
    dispatch(requestAddOperation());

    return fetch(routesApi.editOperation, {
      method: 'POST',
      body: JSON.stringify(dataOperation),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveResultAddOperation(data));
        });
      })
      .catch((error) => {
        dispatch(receiveResultAddOperation(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getResulNewOperationCreate = (data) => {
  const simulation = data.simulation; 

  const dataOperation = {
    client_id: data.perfilOperation.id,
    exchange_rate: simulation.exchange_rate,
    currency_sent: simulation.sent.currency,
    amount_sent: simulation.sent.amount,
    origin_bank_id: '404',
    origin_bank_account: "BCP",
    origin_bank_currency: "",
    currency_received: simulation.received.currency,
    amount_received: simulation.received.amount, 
    originbank_operation_number: "999999999",
    destination_bank_id: simulation.destination_bank_id,
    destination_bank_account: simulation.destination_bank_account,
    destination_bank_currency: simulation.destination_bank_currency,
    status_id: '26',
    source_funds: simulation.sourceOfFunds,
    perfil_operation: data.perfilOperation.perfil,
    voucher_operation: "",
  };
  console.log("cope_dispoath",dataOperation);
  return (dispatch) => {
    dispatch(requestAddOperation());

    return fetch(routesApi.storeOperation, {
      method: 'POST',
      body: JSON.stringify(dataOperation),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveResultAddOperation(data));
        });
      })
      .catch((error) => {
        dispatch(receiveResultAddOperation(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getOperationPending = (data) => {
  return (dispatch) => {
    dispatch(requestHistoryOperations());
    return fetch(`${routesApi.operationsPending}${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveOperationPending(data));
      })
      .catch((error) => {
        dispatch(receiveOperationPending(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getOperationCompleted = (data) => {
  return (dispatch) => {
    dispatch(requestHistoryOperations());
    return fetch(`${routesApi.operationsCompleted}${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveOperationCompleted(data));
      })
      .catch((error) => {
        dispatch(receiveOperationCompleted(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getOperationCanceled = (data) => {
  return (dispatch) => {
    dispatch(requestHistoryOperations());
    return fetch(`${routesApi.operationsCanceled}${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveOperationCanceled(data));
      })
      .catch((error) => {
        dispatch(receiveOperationCanceled(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getReceiveRate = () => {
  return (dispatch) => {
    dispatch(requestHistoryOperations());
    return fetch(routesApi.rate)
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveRate(data));
      });
  };
};
export const getCleanRate = () => {
  return (dispatch) => {
    return dispatch(cleanRate());
  };
};
