import { connect } from 'react-redux';
import { fetchNosotros } from '../../../store/web/nosotros';
import NosotrosTemplate from './NosotrosTemplate';

const mapStateToProps = (state) => {
  return {
    nosotros: state.nosotrosState.nosotros,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNosotros: () => dispatch(fetchNosotros()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NosotrosTemplate);
