import React from 'react';
export default (p) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...p}>
    <path
      d='M12.25 2V22.5'
      stroke='#3AA229'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.5 12.25L2 12.25'
      stroke='#3AA229'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
