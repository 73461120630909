import React, { useEffect, useState } from 'react';
import {
  Section,
  Content,
  Description,
  Figura,
  BgSection,
  WrapDescription,
} from './styles';
import BigTitlePage from '../BigTitlePage';
import Markdown from 'react-markdown/with-html';

const YourAlly = ({ yourAlly, fetchYourAlly }) => {
  const [titlePage, setTitlePage] = useState(false);
  useEffect(() => {
    if (yourAlly) {
      const dataTitle = {
        title: yourAlly.title_alias,
        titleBold: yourAlly.title_bold_alias,
        subtitle: yourAlly.subtitle_alias,
      };
      setTitlePage(dataTitle);
      return;
    }
    fetchYourAlly();
  }, [yourAlly, fetchYourAlly]);

  return (
    <>
      <Section>
        <BgSection>
          <img src='/static/image/bg-alias.png' alt='' />
        </BgSection>
        <Content>
          {yourAlly && (
            <>
              {titlePage && <BigTitlePage data={titlePage} />}

              {yourAlly.image_alias.url && (
                <Figura className='bigFigure'>
                  <img
                    src={yourAlly.image_alias.url}
                    width={640}
                    alt='Sr.Cambio'
                  />
                </Figura>
              )}
              <WrapDescription>
                <Figura>
                  <img
                    src='/static/image/icon-aliado.svg'
                    width={41}
                    alt='Sr.Cambio'
                  />
                </Figura>
                <Description>
                  <Markdown
                    source={yourAlly.description_alias}
                    escapeHtml={false}
                  />
                </Description>
              </WrapDescription>
            </>
          )}
        </Content>
      </Section>
    </>
  );
};

export default YourAlly;
