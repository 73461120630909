import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { publicRoutes } from './routes';
import Layout from './components/Layout';

import { ThemeProvider } from 'styled-components';
import { theme } from './theme/global';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const useConfigPushNotifications = () => {
  useEffect(() => {
    if (window['plugins']?.OneSignal) {
      window["plugins"].OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_ID);
      window["plugins"].OneSignal.setNotificationOpenedHandler(function(jsonData) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      });
      window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
          console.log("User accepted notifications: " + accepted);
      });
    }
  }, []);
};
const Router = () => {
  return (
    <IonRouterOutlet id='main-menu' animated='false'>
      {publicRoutes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          render={() => (
            <Layout
              Component={route.page}
              route={route}
              hideIconHelp={route.hideIconHelp}
              hideTextFooter={route.hideTextFooter}
              showBackButton={route.showBackButton}
              showMenu={route.showMenu}
              showHeader={route.showHeader}
              showFooter={route.showFooter}
              showNavFooter={route.showNavFooter}
              needLogin={route.needLogin}
              bodyClass={route.bodyClass ? route.bodyClass : ''}
              hideBannerSide={route.hideBannerSide}
              hideHeadDesktop={route.hideHeadDesktop}
              hideFootDesktop={route.hideFootDesktop}
              dashboard={route.dashboard}
              disabledBackButton={route.disabledBackButton}
            />
          )}
        />
      ))}
    </IonRouterOutlet>
  );
};

function App() {
  useConfigPushNotifications();

  return (
    <ThemeProvider theme={theme}>
      <IonApp>
        <IonReactRouter>
          <Router />
        </IonReactRouter>
      </IonApp>
    </ThemeProvider>
  );
}
export default App;
