import React, { useEffect, useRef } from 'react';
import { CntButton, Button, WrapError, TextError } from '../../theme/global';
import TextMotto from '../TextMotto';
import { withFormik, Form, ErrorMessage } from 'formik';
import withProps from 'recompose/withProps';
import PopUp from '../Popup';
import Alert from '../Alert';

import Input from '../InputRounded';
import { Typography, Strong, Title } from '../Text';
import compose from 'recompose/compose';
import { WrapPage } from '../../theme/shared';
const FormCodeVerification = ({
  onSubmit,
  desktop,
  isSubmitting,
  codeSecurity,
  history,
  getValidateCode,
  validateCode,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    if (!codeSecurity) {
      history.push('/login');
    }
    if (validateCode && validateCode.status === 200) {
      history.push('/new-password');
    }
    if (validateCode && validateCode.status === 400) {
      popupRef.current.open();
    }
  }, [codeSecurity, history, validateCode]);

  return (
    <WrapPage className={`${desktop || ''} code-verification`}>
      {desktop ? <TextMotto /> : <TextMotto logo />}
      <div>
        <Title marginBottom='30px'>Ingresar código de verificación</Title>

        <Form>
          <Input name='codigo' placeholder='Código' center='true' />
          <ErrorMessage name='codigo'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
          <Typography
            color='primary'
            center
            size='15px'
            font='primary'
            style={{ margin: '25px auto 34px', maxWidth: 220 }}
          >
            Revise su correo e ingrese el <Strong secondary>código enviado.</Strong>
          </Typography>
          <CntButton center>
            <Button round type='submit' disabled={isSubmitting}>
              Validar Código
            </Button>
          </CntButton>
        </Form>
      </div>
      <PopUp ref={popupRef} onClose={() => {}}>
        <Alert
          icon='error'
          title='Error en el código ingresado'
          subtitle='no corresponde al enviado'
          description='Verífiquelo y vuelva a intentarlo'
        />
      </PopUp>
    </WrapPage>
  );
};
const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      codigo: '',
    }),
    handleSubmit(values, { setSubmitting, props: { getValidateCode, codeSecurity } }) {
      const data = {
        id: codeSecurity.id,
        code: values.codigo,
      };

      getValidateCode(data);
      setSubmitting(false);
    },
    validate(values) {
      const errors = {};
      if (!values.codigo) errors.codigo = 'Campo Obligatorio';
      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);

export default enhacer(FormCodeVerification);
