import { connect } from 'react-redux';
import { fetchOperationType } from '../../../store/web/home';

import OperationType from './OperationType';

const mapStateToProps = (state) => {
  return {
    operationTypeData: state.homeState.operationTypeData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOperationType: () => dispatch(fetchOperationType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationType);
