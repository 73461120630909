import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { publicRoutesMap } from '../../routes';
import { TabItem, TabsContainer } from '../NavTabs';

const isPath = (name, pathname) => publicRoutesMap.get(name) === pathname;

const goToScreen = (name, history, location) => () => {
  const path = publicRoutesMap.get(name);
  if (path !== location.pathname) history.push(path);
};

const Tabs = () => {
  const history = useHistory();
  const location = useLocation();

  const goToCompletedOperations = useCallback(
    goToScreen('CompletedOperations', history, location),
    [history, location]
  );
  const goToOngoingOperations = useCallback(
    goToScreen('OngoingOperations', history, location),
    [history, location]
  );
  const goToCanceledOperations = useCallback(
    goToScreen('CanceledOperations', history, location),
    [history, location]
  );

  return (
    <TabsContainer my='15px' className='tabs-historial'>
      <TabItem
        onClick={goToOngoingOperations}
        className={isPath('OngoingOperations', location.pathname) ? 'active' : ''}
      >
        En curso
      </TabItem>
      <TabItem
        onClick={goToCompletedOperations}
        className={isPath('CompletedOperations', location.pathname) ? 'active' : ''}
      >
        Finalizado
      </TabItem>
      <TabItem
        onClick={goToCanceledOperations}
        className={isPath('CanceledOperations', location.pathname) ? 'active' : ''}
      >
        Cancelado
      </TabItem>
    </TabsContainer>
  );
};

export default Tabs;
