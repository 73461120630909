import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import {
  Form,
  Field,
  useField,
  useFormikContext,
  withFormik,
  ErrorMessage,
} from 'formik';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import { CntButton, Button, TextError, WrapError } from '../../theme/global';
import {
  FormRow,
  Symbol,
  Label,
  SymbolName,
  Wrap,
  Item,
  WrapSave,
  WrapButtonChange,
  SaveAmount,
  ButtonChange,
  InputItem,
  WrapSelectItem,
  SelectItem,
  RequiredField,
} from './styles';
import converter from '../../utils/currency-conversor';
import amountFormat from '../../utils/amount-format';
import Strong from '../Strong';
// import { IonSelectOption } from '@ionic/react';
// import {
//   Container as InputSingleContainer,
//   InputSelect as InputSelectSingle,
// } from '../InputRounded';
import CurrenciesSymbol from '../../utils/currencies';
import { Typography } from '../Text';
import BuySaleIndicator from './BuySaleIndicator';
import { amountRegex } from '../../utils/regexs';
import PopUp from '../Popup';
import Alert from '../Alert';
import { routesApi } from '../../routesApi';
import { Plugins } from '@capacitor/core'; 

const { Storage } = Plugins;
const ConverterContext = React.createContext();

const calcSavedAmount = ({
  sentAmount,
  receivedAmount,
  isSale,
  exchangeRate,
  saleExangeRate,
}) => {
  const ref = converter(sentAmount, exchangeRate, isSale);
  const saved = receivedAmount - ref;
  return isSale ? converter(saved, saleExangeRate) : saved;
};

async function verificarRate(){ 
    let getBlob = await fetch(routesApi.rate)
    .then((response) => response.json());
    return getBlob.official.buy;
};

// const InputSelect = React.memo((props) => {
//   // eslint-disable-next-line no-unused-vars
//   const [field, meta, helpers] = useField(props);
//   // TODO: show error
//   return (
//     <InputSelectSingle
//       leftArrowPosition='5px'
//       {...props}
//       value={field.value}
//       onIonChange={(e) => {
//         helpers.setValue(e.target.value);
//         helpers.setTouched(true);
//       }}
//     />
//   );
// });

const Input = ({ role, ...p }) => {
  const { convert } = useContext(ConverterContext);
  const [field, meta, helpers] = useField(
    role === 'sent' ? 'sentAmount' : 'receivedAmount'
  );
  const [{ value: currency }] = useField(
    role === 'sent' ? 'sentCurrency' : 'receivedCurrency'
  );

  const [focused, setFocused] = useState(false);
  const { setTouched } = useFormikContext();
  useEffect(() => {
    if (field.value.length > 0 && meta.error) helpers.setError('');
  }, [field.value, meta.error, helpers]);

  const onChange = useCallback(
    (e) => {
      e.preventDefault();
      const { value } = e.target;
      setTouched({
        sentAmount: role === 'sent',
        receivedAmount: role === 'receive',
      });
      if (focused) {
        convert(role, value);
      }
      if (amountRegex.test(value)) {
        field.onChange(e);
      }
    },
    [convert, field, focused, role, setTouched]
  );

  const onBlur = useCallback(
    (e) => {
      setFocused(false);
      field.onBlur(e);
      helpers.setValue(amountFormat(field.value));
    },
    [field, helpers]
  );

  return (
    <>
      <Item className='Currency'>
        <Symbol>{CurrenciesSymbol[currency].symbol}</Symbol>
        <SymbolName>{currency}</SymbolName>
      </Item>
      <Item>
        <Label>
          {role === 'sent' ? 'ENVIAS ' : 'RECIBES '}
          {CurrenciesSymbol[currency].spanishName}
        </Label>
        <InputItem className='inputItem'>
          <input
            {...field}
            {...p}
            onFocus={() => setFocused(true)}
            onBlur={onBlur}
            onChange={onChange}
            autoComplete='off'
          />
          <WrapError className='hidde'>
            {meta.error && <TextError>Campo Obligatorio</TextError>}
          </WrapError>
        </InputItem>
      </Item>
    </>
  );
};

const withFormConverter = (Component) => (p) => {
  const { values, touched, setFieldValue, setValues } = p;

  const {
    saleExangeRate,
    buyExangeRate,
    comercialBuyExchangeRate,
    comercialSaleExchangeRate,
    isSale,
  } = values;

  const timeoutRef = useRef();

  const convert = useCallback(
    async (role, amount) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (Number.isNaN(Number(amount))) return;
      timeoutRef.current = setTimeout(() => {
        const exchangeRate = isSale ? saleExangeRate : buyExangeRate;

        const field = role === 'sent' ? 'receivedAmount' : 'sentAmount';

        const value = amountFormat(
          converter(
            amount,
            exchangeRate,
            !((isSale && role !== 'sent') || (!isSale && role === 'sent'))
          )
        );
        setFieldValue(field, value);

        const saveValue = calcSavedAmount({
          isSale,
          sentAmount: Number(role !== 'sent' ? value : amount),
          receivedAmount: Number(role !== 'sent' ? amount : value),
          exchangeRate: isSale ? comercialSaleExchangeRate : comercialBuyExchangeRate,
          saleExangeRate,
        });

        setFieldValue('save', saveValue);
      }, 5e2);
    },
    [
      buyExangeRate,
      comercialBuyExchangeRate,
      comercialSaleExchangeRate,
      isSale,
      saleExangeRate,
      setFieldValue,
    ]
  );

  const switchValues = useCallback(() => {
    const newExange = isSale ? buyExangeRate : saleExangeRate;
    const newValues = {
      ...values,
      sentCurrency: values.receivedCurrency,
      receivedCurrency: values.sentCurrency,
      isSale: !isSale,
    };

    if (touched.receivedAmount) {
      newValues.receivedAmount = values.receivedAmount;
      newValues.sentAmount = amountFormat(
        converter(values.receivedAmount, newExange, isSale)
      );
    } else {
      newValues.sentAmount = values.sentAmount;
      newValues.receivedAmount = amountFormat(
        converter(values.sentAmount, newExange, !isSale)
      );
    }

    newValues.save = calcSavedAmount({
      isSale: !isSale,
      sentAmount: newValues.sentAmount,
      receivedAmount: newValues.receivedAmount,
      exchangeRate:
        values[!isSale ? 'comercialSaleExchangeRate' : 'comercialBuyExchangeRate'],
      saleExangeRate,
    });

    setValues(newValues);
  }, [buyExangeRate, isSale, saleExangeRate, setValues, touched.receivedAmount, values]);

  const valueContext = { convert, switchValues, isSale };

  return (
    <ConverterContext.Provider value={valueContext} {...p}>
      <Component {...p} {...valueContext} />
    </ConverterContext.Provider>
  );
};

const OperationForm = ({
  history,
  className,
  operationSimulation,
  sendSimulation,
  submitForm,
  isSubmitting,
  values,
  setValues,
  touched,
  accountsUser,
  switchValues,
  isSale,
  simulator = false,
  receiveRate
}) => {
  const popupRef = useRef(null);   
  const gotoAccounts = () => {
    history.push('/accounts/bank-accounts');
  };
  const validarRate = () => {
    verificarRate().then(rateAct =>{      
      if(rateAct !== receiveRate.official.buy){ 
        popupRef.current.open();
      }else{        
        submitForm();
      }

    });    
  };
  const isCero = !Number(values.sentAmount) || !Number(values.receivedAmount);
  useEffect(() => { 
    
  }, [simulator]);
  return (
    <Wrap className={className || ''}>
      <Form className='formularyOperation'>
        <BuySaleIndicator
          simulator={simulator}
          className={className || ''}
          buyExangeRate={values.buyExangeRate}
          saleExangeRate={values.saleExangeRate}
          isSale={isSale}
          switchValues={switchValues}
        />

        <FormRow className='SentAmount'>
          <Input type='text' role='sent' placeholder='0.00' />
          <WrapButtonChange>
            <ButtonChange onClick={switchValues} reverse={isSale}>
              <img
                src='/assets/images/icon-change-arrow.svg'
                width='22'
                alt='Sr. Cambio'
              />
            </ButtonChange>
          </WrapButtonChange>
        </FormRow>
        <FormRow
          className={['ReceivedAmount', simulator && 'simulator']
            .filter(Boolean)
            .join(' ')}
        >
          <Input type='text' role='receive' placeholder='0.00' />
        </FormRow>
        <WrapSave className='wrap-save'>
          <figure>
            <img src='/assets/images/save-pig.svg' alt='Sr. Cambio' width={21} />
            <figcaption>
              <Strong>Ahorro</Strong> Promedio
            </figcaption>
          </figure>
          <SaveAmount>
            {CurrenciesSymbol.PEN.symbol} {amountFormat(values.save, 2)}
          </SaveAmount>
        </WrapSave>

        {!simulator && (
          <>
            {accountsUser && (
              <>
                <WrapSelectItem>
                  <SelectItem>
                    <Field component='select' name='destinityAccount'>
                      <option value=''>Cuenta Destino*</option>
                      {accountsUser.list_account.map((item, key) => {
                        if (values.sentCurrency !== item.currency) {
                          return (
                            <option
                              key={key}
                              value={`${item.id_bank}//${item.number_account}//${item.name_bank}//${item.currency}`}
                            >
                              {item.name_bank} / {item.currency} - {item.number_account}
                            </option>
                          );
                        } else return null;
                      })}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='destinityAccount'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </WrapSelectItem>
                <WrapSelectItem>
                  <SelectItem>
                    <Field component='select' name='sourceOfFunds'>
                      <option value=''>Origen de Fondos</option>
                      {accountsUser.money_origin_list.map((item, key) => (
                        <option key={key} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='sourceOfFunds'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </WrapSelectItem>
              </>
            )}
            <RequiredField>
              <Typography
                color='primary'
                center
                size='13px'
                font='quaternary'
                style={{ marginTop: 8 }}
              >
                *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
              </Typography>
            </RequiredField>
          </>
        )}
        {simulator ? (
          <CntButton className='cnt-button' center>
            <Button type='button' disabled={isCero || isSubmitting} 
            onClick={() => { 
                validarRate();
              }}>
              CAMBIAR {CurrenciesSymbol[values.sentCurrency].spanishName}
              {isSubmitting && <Loading />}
            </Button>
          </CntButton>
        ) : (
          <>
            {accountsUser && accountsUser.has_account ? (
              <CntButton className='cnt-button' center>
                <Button type='button' disabled={isCero || isSubmitting} onClick={() => { 
                validarRate();
              }}>
                  CAMBIAR {CurrenciesSymbol[values.sentCurrency].spanishName}
                  {isSubmitting && <Loading />}
                </Button>
              </CntButton>
            ) : (
              <>
                <div className='no-account'>
                  Debe tener mínimo dos cuentas, una en soles <br /> y otra en dolares
                  para realizar una operación
                </div>
                <CntButton className='cnt-button' center>
                  <Button
                    type='button'
                    onClick={() => {
                      gotoAccounts();
                    }}
                  >
                    Agregar Cuentas
                  </Button>
                </CntButton>
              </>
            )}
          </>
        )}
      </Form>
      <PopUp ref={popupRef} onClose={() => { window.location.reload(); }}>
        <Alert
          icon='success'
          title='El tipo de cambio ha variado.'
          subtitle='Se actualizará nuevamente.'
        /> 
          <CntButton center style={{ marginTop: `10px` }}>
            <Button
              className='redirect'
              type='button'
              onClick={() => {   window.location.reload(); }}
              round
            >
              Aceptar
            </Button>
          </CntButton> 
      </PopUp>
    </Wrap>
  );
};

OperationForm.propTypes = {
  className: PropTypes.string,
};

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ operationSimulation, simulator, receiveRate }) {
    const defaultValues = {
      sentAmount: 1000,
      sentCurrency: 'USD',
      saleExangeRate: receiveRate
        ? amountFormat(receiveRate.official.sale, 4, true)
        : 0.0,
      buyExangeRate: receiveRate ? amountFormat(receiveRate.official.buy, 4, true) : 0.0,
      comercialSaleExchangeRate: receiveRate
        ? amountFormat(receiveRate.ref.sale, 4, true)
        : 0.0,
      comercialBuyExchangeRate: receiveRate
        ? amountFormat(receiveRate.ref.buy, 4, true)
        : 0.0,
    };
    const values = { ...defaultValues };
    
    if (operationSimulation) {
      if (operationSimulation.sent?.amount) {
        const value = Number(operationSimulation.sent.amount);
        if (!Number.isNaN(value)) values.sentAmount = amountFormat(value);
      }

      if (operationSimulation.sent?.currency)
        values.sentCurrency = operationSimulation.sent.currency;

      if (operationSimulation.saleExangeRate) {
        const value = Number(operationSimulation.saleExangeRate);
        if (!Number.isNaN(value)) values.saleExangeRate = value;
      }

      if (operationSimulation.buyExangeRate) {
        const value = Number(operationSimulation.buyExangeRate);
        if (!Number.isNaN(value)) values.buyExangeRate = value;
      }
    }
    
    if (!simulator) {
      values.destinityAccount = '';
      values.sourceOfFunds = '';
    }

    values.isSale = values.sentCurrency === 'PEN';

    if (values.sentCurrency === 'PEN') values.receivedCurrency = 'USD';
    else values.receivedCurrency = 'PEN';

    const receivedAmount = amountFormat(
      converter(
        values.sentAmount,
        values[values.isSale ? 'saleExangeRate' : 'buyExangeRate'],
        !!values.isSale
      )
    );

    values.receivedAmount = amountFormat(receivedAmount);
    values.save = calcSavedAmount({
      isSale: values.isSale,
      sentAmount: Number(values.sentAmount),
      receivedAmount: Number(receivedAmount),
      exchangeRate:
        values[values.isSale ? 'comercialSaleExchangeRate' : 'comercialBuyExchangeRate'],
      saleExangeRate: values.saleExangeRate,
    });

    return values;
  },
  async validate(values, { simulator}) {
    const errors = {};
    
    if (values.sentAmount === '') {
      errors.sentAmount = true;
    }
    if (values.receivedAmount === '') {
      errors.receivedAmount = true;
    } 
    
    if (!simulator) {
      if (!values.destinityAccount) {
        errors.destinityAccount = 'Debe seleccionar una cuenta';
      }

      if (!values.sourceOfFunds) {
        errors.sourceOfFunds = 'Debe seleccionar un origen de los fondos';
      }
    }

    if (Object.keys(errors).length > 0) return errors;
  },
  handleSubmit(values, { setSubmitting, resetForm, props: { onSubmit } }) {
    const splitDestinationBank = values.destinityAccount
      ? values.destinityAccount.split('//')
      : null;
    const operationData = {
      sent: {
        amount: Number(values.sentAmount),
        currency: values.sentCurrency,
      },
      received: {
        amount: Number(values.receivedAmount),
        currency: values.receivedCurrency,
      },
      exchange_rate: values[values.isSale ? 'saleExangeRate' : 'buyExangeRate'], // useful in getResulNewOperation store operation
      saleExangeRate: values.saleExangeRate,
      buyExangeRate: values.buyExangeRate,
      save: Number(values.save), // TODO: this value probably should be not saved in store because is only referencial
      destination_bank_id: values.destinityAccount ? splitDestinationBank[0] : null,
      destination_bank_account: values.destinityAccount ? splitDestinationBank[1] : null,
      destination_bank_name: values.destinityAccount ? splitDestinationBank[2] : null,
      destination_bank_currency: values.destinityAccount ? splitDestinationBank[3] : null,
      sourceOfFunds: values.sourceOfFunds,
    };

   
      onSubmit(operationData);
      // resetForm();
      setSubmitting(false);
    
  },
};

export default withFormik(formConfig)(withFormConverter(OperationForm));
