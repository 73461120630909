import React from 'react';
import ChangePassword from '../../components/web/ChangePassword';
const Change = ({ history }) => {
  return (
    <>
      <ChangePassword history={history} />
    </>
  );
};

export default Change;
