import {
  requestUser,
  receiveUser,
  requestAccountsUser,
  receiveAccountsUser,
  saveProcessRegisterUser,
  saveProcessUpdateCompany,
  checkEmailUser,
  receiveCodeSecurity,
  receiveValidateCode,
  setNewPassword,
  receiveUrlImage,
  receivePerfilOperation,
  cleanImageUrl,
  cleanCountImageCamera,
  receiveUrlImageDni,
  receiveUrlImageDniBack,
  receiveNewAccountBank,
  receiveCreateAccount,
  cleanImageDniFront,
  cleanImageDniBack,
  cleanProcessRegister,
  cleanEditInfoCompany,
  cleanEditInfoUser,
  cleanCurrentAccountBank,
  receiveCreateInfoCompany,
  receiveEditInfoPersonal,
  receiveEditInfoIdentity,
  receiveEditInfoCompany,
  receiveInfoCurrentUser,
  receiveDeleteAccountBank,
  saveCurrentAccountBank,
  receiveEditAccountBank,
  cleanUserInfo,
  cleanNewAccountBank,
  receiveAlertsConfig,
  countImageCamera,
  cleanAlertsConfig,
  showAlertError,
  cleanAlertError,
  receiveUserOffLine,
} from './actions';
import { Base64 } from 'js-base64';
import { routesApi } from '../../routesApi';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
const responseError = {
  status: 500,
  message: 'Error de servidor o conexión',
};
// export const fetchUser = (data) => async (dispatch) => {
//   dispatch(requestUser());
//   const response = await fetch(routesApi.login, {
//     method: 'POST',
//     body: JSON.stringify(data),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
//   const userInfo = await response.json();
//   const userData = {
//     status: response.status,
//     message: response.statusText,
//     info: userInfo ? userInfo : null,
//   };
//   dispatch(receiveUser(userData));
//   if (window['plugins']?.OneSignal && userInfo.OneSignalID) {
//     window['plugins'].OneSignal.setExternalUserId(userInfo.OneSignalID);
//   }
// };

export const fetchUser = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.login, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((userInfo) => {
          const data = {
            status: response.status,
            message: response.statusText,
            info: userInfo ? userInfo : null,
          };
          dispatch(receiveUser(data));
          if (window['plugins']?.OneSignal && userInfo.OneSignalID) {
            window['plugins'].OneSignal.setExternalUserId(userInfo.OneSignalID);
          }
        });
      })
      .catch((error) => {
        dispatch(receiveUser(null));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getAccountsUser = (data) => {
  return (dispatch) => {
    dispatch(requestAccountsUser());
    return fetch(`${routesApi.accountsClient}${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveAccountsUser(data));
      })
      .catch((error) => {
        dispatch(receiveAccountsUser(null));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getNewAccountBank = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.newAccountBank, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveNewAccountBank(data));
        });
      })
      .catch((error) => {
        dispatch(receiveNewAccountBank(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getCreateAccount = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.createAccount, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveCreateAccount(data));
        });
      })
      .catch((error) => {
        dispatch(receiveCreateAccount(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getCheckEmailUser = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.checkEmail, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(checkEmailUser(data));
        });
      })
      .catch((error) => {
        dispatch(checkEmailUser(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getCodeSecurity = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.requestCode, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveCodeSecurity(data));
        });
      })
      .catch((error) => {
        dispatch(receiveCodeSecurity(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getCheckEmail = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.checkEmail, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(checkEmailUser(data));
        });
      })
      .catch((error) => {
        dispatch(checkEmailUser(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getValidateCode = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.validateCode, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveValidateCode(data));
        });
      })
      .catch((error) => {
        dispatch(receiveValidateCode(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getNewPassword = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.newPassword, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(setNewPassword(data));
        });
      })
      .catch((error) => {
        dispatch(setNewPassword(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getUrlImage = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.uploadImage, {
      method: 'POST',
      body: data.file,
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          const dataResponseImage = {
            ...data,
            status: response.status,
          };
          dispatch(receiveUrlImage(dataResponseImage));
        });
      })
      .catch((error) => {
        dispatch(receiveUrlImage(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getUrlImageDni = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.uploadImageDni, {
      method: 'POST',
      body: data.file,
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveUrlImageDni(data));
        });
      })
      .catch((error) => {
        dispatch(receiveUrlImageDni(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getUrlImageDniBack = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.uploadImageDni, {
      method: 'POST',
      body: data.file,
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveUrlImageDniBack(data));
        });
      })
      .catch((error) => {
        dispatch(receiveUrlImageDniBack(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const saveProcessRegister = (data) => {
  return (dispatch) => {
    return dispatch(saveProcessRegisterUser(data));
  };
};
export const getCountImageCamera = (data) => {
  return (dispatch) => {
    return dispatch(countImageCamera(data));
  };
};
export const GetSaveProcessUpdateCompany = (data) => {
  return (dispatch) => {
    return dispatch(saveProcessUpdateCompany(data));
  };
};

export const sendPerfilOperation = (data) => {
  return (dispatch) => {
    return dispatch(receivePerfilOperation(data));
  };
};
export const requestCleanImageUrl = () => {
  return (dispatch) => {
    return dispatch(cleanImageUrl());
  };
};
export const getCleanCountImageCamera = () => {
  return (dispatch) => {
    return dispatch(cleanCountImageCamera());
  };
};
export const requestCleanImageDniFront = () => {
  return (dispatch) => {
    return dispatch(cleanImageDniFront());
  };
};
export const requestCleanImageDniBack = () => {
  return (dispatch) => {
    return dispatch(cleanImageDniBack());
  };
};
export const requestCleanProcessRegister = () => {
  return (dispatch) => {
    return dispatch(cleanProcessRegister());
  };
};
export const getCleanEditInfoCompany = () => {
  return (dispatch) => {
    return dispatch(cleanEditInfoCompany());
  };
};
export const getCleanEditInfoUser = () => {
  return (dispatch) => {
    return dispatch(cleanEditInfoUser());
  };
};

export const getCleanCurrentAccountBank = () => {
  return (dispatch) => {
    return dispatch(cleanCurrentAccountBank());
  };
};

export const getCreateInfoCompany = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.createInfoCompany, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveCreateInfoCompany(data));
        });
      })
      .catch((error) => {
        dispatch(receiveCreateInfoCompany(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getEditInfoPersonal = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.editInfoPersonal, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveEditInfoPersonal(data));
        });
      })
      .catch((error) => {
        dispatch(receiveEditInfoPersonal(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
export const getEditInfoIdentity = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.editInfoIdentity, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveEditInfoIdentity(data));
        });
      })
      .catch((error) => {
        dispatch(receiveEditInfoIdentity(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getEditInfoCompany = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.editInfoCompany, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveEditInfoCompany(data));
        });
      })
      .catch((error) => {
        dispatch(receiveEditInfoCompany(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getInforCurrentUser = (data) => {
  const id_device = data.id_user_device ? data.id_user_device : '';
  const from_login = data.from_login ? data.from_login : 0;
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(
      `${routesApi.infoCurrentUser}${data.id}&id_device=${id_device}&from_login=${from_login}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    )
      .then((response) => {
        return response.json().then((data) => {
          if (response.status === 403) {
            const dataResponse = {
              status: 403,
            };
            dispatch(receiveInfoCurrentUser(dataResponse));
          }
          if (response.status === 200) {
            dispatch(receiveInfoCurrentUser(data));
          }
        });
      })
      .catch((error) => {
        dispatch(receiveInfoCurrentUser(null));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getDeleteAccountBank = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.deleteAccountBank, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveDeleteAccountBank(data));
        });
      })
      .catch((error) => {
        dispatch(receiveDeleteAccountBank(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const saveInfoCurrentAccountBank = (data) => {
  return (dispatch) => {
    return dispatch(saveCurrentAccountBank(data));
  };
};

export const getEditAccountBank = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.editAccountBank, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveEditAccountBank(data));
        });
      })
      .catch((error) => {
        dispatch(receiveEditAccountBank(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
export const getCleanUserInfo = () => {
  return (dispatch) => {
    return dispatch(cleanUserInfo());
  };
};
export const getCleanNewAccountBank = () => {
  return (dispatch) => {
    return dispatch(cleanNewAccountBank());
  };
};

export const updateAlertsConfig = (data) => {
  return (dispatch) => {
    dispatch(requestUser());
    return fetch(routesApi.updateAlertsConfig, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: 'Bearer ' + data.token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveAlertsConfig(data));
        });
      })
      .catch((error) => {
        dispatch(receiveAlertsConfig(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
 
export const getCleanAlertsConfig = () => {
  return (dispatch) => {
    return dispatch(cleanAlertsConfig());
  };
};
export const getcleanAlertError = () => {
  return (dispatch) => {
    return dispatch(cleanAlertError());
  };
};

export const getUserOffLine = (data) => {
  return (dispatch) => {
    return dispatch(receiveUserOffLine(data));
  };
};

export const getUserOffLineLocal = (data) =>async (dispatch)=> {
  return await Storage.get({ key: 'currentUser' }).then((currentUser) =>{  
      if(  currentUser.value != null){  
        return dispatch(receiveUserOffLine(JSON.parse(Base64.decode(currentUser.value))));
      }else{
        return true;
      }
  });
};

export const getAccountsUserLocal = (data) =>async (dispatch)=> {
  return await Storage.get({ key: 'currentUser' }).then(currentUser =>{ 
    if(currentUser.value != null){
      const data = JSON.parse(Base64.decode(currentUser.value)).info; 
    
      dispatch(requestAccountsUser()); 
      return fetch(`${routesApi.accountsClient}${data.user_id}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => { 
          dispatch(receiveAccountsUser(data));
        })
        .catch((error) => {
          console.log("error",error);
          dispatch(receiveAccountsUser(null));
          dispatch(showAlertError(responseError));
        });
    }else{
        dispatch(receiveAccountsUser(null));
        return true;
    }
      
     
  })
 
};

export const getInforCurrentUserLocal = (data)  =>async (dispatch)=> {
  return await Storage.get({ key: 'currentUser' }).then(currentUser =>{ 
    if(currentUser.value != null){
      const data = JSON.parse(Base64.decode(currentUser.value)).info;
      const id_device = data.id_user_device ? data.id_user_device : '';
      const from_login = data.from_login ? data.from_login : 0;
      
      dispatch(requestUser());
        return fetch(
          `${routesApi.infoCurrentUser}${data.user_id}&id_device=${id_device}&from_login=${from_login}`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        )
          .then((response) => {
            return response.json().then((data) => {
              if (response.status === 403) {
                const dataResponse = {
                  status: 403,
                };
                dispatch(receiveInfoCurrentUser(dataResponse));
              }
              if (response.status === 200) {
                dispatch(receiveInfoCurrentUser(data));
              }
            });
          })
          .catch((error) => {
            dispatch(receiveInfoCurrentUser(null));
            dispatch(showAlertError(responseError));
          }); 
    }else{ 
      dispatch(receiveInfoCurrentUser(null));
    }
    
  }) 
};