import { REQUEST_TERMS_CONDITIONS, RECEIVE_TERMS_CONDITIONS } from './types';

const INITIAL_STATE = {
  termsConditions: null,
  loading: false,
};

export const termsConditionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_TERMS_CONDITIONS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_TERMS_CONDITIONS:
      return {
        ...state,
        termsConditions: action.termsConditions,
        loading: false,
      };
    default:
      return state;
  }
};
