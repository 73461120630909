import {
  REQUEST_DASHBOARD,
  RECEIVE_DASHBOARD,
  REQUEST_SEARCH_CLIENTS,
  RECEIVE_SEARCH_CLIENTS,
  SET_ACTIVE_BUTTON,
  REQUEST_SELECT_OPERATION,
  RECEIVE_SELECT_OPERATION,
  NEW_AMOUNT_RECEIVE,
  RECEIVE_EDIT_OPERATION,
  SINGLE_DETAIL_OPERATION,
  CLEAR_SINGLE_OPERATION,
  PROCESS_DASHBOARD,
  CLEAN_DASHBOAD,
  RECEIVE_COMPLETED_OPERATION,
  RECEIVE_CANCELED_OPERATION,
  RECEIVE_ARCHIVE_OPERATION,
  CLEAN_PROCESS_DASHBOARD,
  RECEIVE_INVOICE,
  CLEAN_RECEIVE_INVOICE,
} from './types';

const INITIAL_STATE = {
  dashboard: null,
  loading: false,
  searchClients: null,
  activeButton: 10,
  selectOperation: null,
  newAmountReceive: null,
  newEditOperation: null,
  singleDetailOperation: null,
  processDashboard: null,
  completedOperation: null,
  canceledOperation: null,
  archiveOperation: null,
  receiveInvoice: null,
};

export const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_DASHBOARD:
      return {
        ...state,
        dashboard: action.dashboard,
        loading: false,
      };
    case REQUEST_SEARCH_CLIENTS:
      return {
        ...state,
      };
    case RECEIVE_SEARCH_CLIENTS:
      return {
        ...state,
        searchClients: action.searchClients,
      };
    case SET_ACTIVE_BUTTON:
      return {
        ...state,
        activeButton: action.setActiveButton,
      };
    case REQUEST_SELECT_OPERATION:
      return {
        ...state,
      };
    case RECEIVE_SELECT_OPERATION:
      return {
        ...state,
        selectOperation: action.selectOperation,
      };
    case NEW_AMOUNT_RECEIVE:
      return {
        ...state,
        newAmountReceive: action.newAmountReceive,
      };
    case RECEIVE_EDIT_OPERATION:
      return {
        ...state,
        newEditOperation: action.newEditOperation,
      };
    case RECEIVE_COMPLETED_OPERATION:
      return {
        ...state,
        completedOperation: action.completedOperation,
      };
    case RECEIVE_CANCELED_OPERATION:
      return {
        ...state,
        canceledOperation: action.canceledOperation,
      };
    case RECEIVE_ARCHIVE_OPERATION:
      return {
        ...state,
        archiveOperation: action.archiveOperation,
      };
    case SINGLE_DETAIL_OPERATION:
      return {
        ...state,
        singleDetailOperation: action.singleDetailOperation,
        loading: false,
      };
    case PROCESS_DASHBOARD:
      return {
        ...state,
        processDashboard: action.processDashboard,
        loading: false,
      };
    case RECEIVE_INVOICE:
      return {
        ...state,
        receiveInvoice: action.receiveInvoice,
        loading: false,
      };
    case CLEAR_SINGLE_OPERATION:
      return {
        ...state,
        singleDetailOperation: INITIAL_STATE.singleDetailOperation,
      };
    case CLEAN_PROCESS_DASHBOARD:
      return {
        ...state,
        processDashboard: INITIAL_STATE.processDashboard,
      };
    case CLEAN_RECEIVE_INVOICE:
      return {
        ...state,
        receiveInvoice: INITIAL_STATE.receiveInvoice,
      };
    case CLEAN_DASHBOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
};
