import React, { useRef, useEffect } from 'react';
import {
  CntButton,
  Button,
  WrapError,
  TextError,
  WrapSelectItem,
  SelectItem,
} from '../../theme/global';
import { Teeny, Typography, Strong, Title } from '../Text';
import { TabsContainer, TabItem } from '../NavTabs';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Input, { Container } from '../InputRounded';
// import withProps from 'recompose/withProps';
// import compose from 'recompose/compose';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import PopUp from '../Popup';
import { useFormikContext } from 'formik';
import { WrapPage } from '../../theme/shared';
import { ListItem, GroupRadio, TitleRadio, SubTitle, ItemRadio } from './styles';
const FormAddCompanyProfileData = ({
  processRegister,
  desktop,
  history,
  generalInfo,
}) => {
  const popupRef = useRef(null);
  const { submitForm } = useFormikContext();

  const openOptionRadio = () => {
    popupRef.current.open();
  };
  const onSubmitForm = () => {
    submitForm();
  };
  useEffect(() => {
    if (!processRegister) {
      history.push('/simulador');
    }
  }, [processRegister, history]);

  return (
    <>
      <WrapPage className={`${desktop || ''} edi-profile-data profile-data-company`}>
        <TabsContainer>
          <TabItem className='active'>
            Paso <Teeny color='primary'>01</Teeny>
          </TabItem>
          <TabItem>
            Paso <Teeny color='primary'>02</Teeny>
          </TabItem>
          <TabItem>
            Paso <Teeny color='primary'>03</Teeny>
          </TabItem>
        </TabsContainer>

        <Form>
          <Title className='title-edit-profile'>Datos de Perfil</Title>
          <Container>
            <Input className='input-text' name='names' placeholder='Nombres*' />
            <ErrorMessage name='names'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <Input className='input-text' name='lastNames' placeholder='Apellidos*' />
            <ErrorMessage name='lastNames'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <Input name='idDoc' placeholder='DNI / Pasaporte*' />
            <ErrorMessage name='idDoc'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>

            <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
              <Input
                className='input-date'
                autoOk
                name='dateBirth'
                format='DD/MM/YYYY'
                component={KeyboardDatePicker}
                placeholder='Fecha de Nacimiento*'
                keyboardIcon={<img src='/assets/images/calendar.svg' alt='calendar' />}
                isdatepicker='true'
              />
            </MuiPickersUtilsProvider>
            <ErrorMessage name='dateBirth'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <Input className='input-text' name='civilStatus' placeholder='Estado Civil' />
            <ErrorMessage name='civilStatus'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>
            <Input className='input-text' name='phone' placeholder='Celular*' />
            <ErrorMessage name='phone'>
              {(message) => (
                <WrapError>
                  <TextError>{message}</TextError>
                </WrapError>
              )}
            </ErrorMessage>

            {generalInfo && (
              <div className='wrap-selects'>
                <WrapSelectItem>
                  <SelectItem>
                    <Field component='select' name='ocupacion_user'>
                      <option value=''>Ocupación*</option>
                      {generalInfo.job_occupation.map((item, key) => (
                        <option key={key} data-id={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='ocupacion_user'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </WrapSelectItem>
                <WrapSelectItem>
                  <SelectItem>
                    <Field component='select' name='relacion_laboral_user'>
                      <option value=''>Relación Laboral</option>
                      {generalInfo.employment_relationship.map((item, key) => (
                        <option key={key} data-id={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </SelectItem>
                  <ErrorMessage name='relacion_laboral_user'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                </WrapSelectItem>
              </div>
            )}
          </Container>
          <Typography
            className='required-fields'
            color='primary'
            center
            size='13px'
            font='quaternary'
          >
            *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
          </Typography>

          <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
            <Button
              round
              type='button'
              onClick={() => {
                openOptionRadio();
              }}
            >
              Continuar
            </Button>
          </CntButton>
          <PopUp className='profile-data' ref={popupRef} onClose={() => onSubmitForm()}>
            <GroupRadio>
              <TitleRadio>¿Es una Persona Expuesta Políticamente?</TitleRadio>
              <SubTitle>Declaración Jurada</SubTitle>
              <ListItem>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='politicallyExposed'
                    value='Si'
                    id='exposed-yes'
                  />
                  <label htmlFor='exposed-yes'>Si</label>
                </ItemRadio>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='politicallyExposed'
                    value='No'
                    id='exposed-no'
                  />
                  <label htmlFor='exposed-no'>No</label>
                </ItemRadio>
              </ListItem>
            </GroupRadio>
            <GroupRadio>
              <TitleRadio>¿Es un Sujeto Obligado a Informar a la UIF?</TitleRadio>
              <SubTitle>Declaración Jurada</SubTitle>
              <ListItem>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='informUIF'
                    value='Si'
                    id='inform-yes'
                  />
                  <label htmlFor='inform-yes'>Si</label>
                </ItemRadio>
                <ItemRadio>
                  <Field
                    type='radio'
                    className='input-radio'
                    name='informUIF'
                    value='No'
                    id='inform-no'
                  />
                  <label htmlFor='inform-no'>No</label>
                </ItemRadio>
              </ListItem>
            </GroupRadio>
          </PopUp>
        </Form>
      </WrapPage>
    </>
  );
};

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ accountsUser, business }) {
    const values = {};
    if (accountsUser) {
      const info = accountsUser.single_info;
      values.names = info.first_name;
      values.lastNames = info.last_name;
      values.idDoc = info.doc_identidad;
      values.dateBirth = moment(info.fecha_nacimiento);
      values.civilStatus = info.estado_civil;
      values.phone = info.nro_celular;
      values.politicallyExposed = info.persona_expuesta_politicamente;
      values.informUIF = info.obligado_informar_uif;
      values.ocupacion_user = accountsUser.single_info.ocupacion_user;
      values.relacion_laboral_user = accountsUser.single_info.relacion_laboral_user;
    }

    return values;
  },
  validate(values) {
    const errors = {};
    if (!values.names) errors.names = 'Campo Obligatorio';
    if (!values.lastNames) errors.lastNames = 'Campo Obligatorio';
    if (!values.idDoc) errors.idDoc = 'Campo Obligatorio';
    if (!values.dateBirth) errors.dateBirth = 'Campo Obligatorio';
    if (!values.civilStatus) errors.civilStatus = 'Campo Obligatorio';
    if (!values.phone) errors.phone = 'Campo Obligatorio';
    if (!values.ocupacion_user) errors.ocupacion_user = 'Campo Obligatorio';
    if (!values.relacion_laboral_user) errors.relacion_laboral_user = 'Campo Obligatorio';

    return errors;
  },
  handleSubmit(
    values,
    {
      setSubmitting,
      props: { processRegister, saveProcessRegister, history, accountsUser },
    }
  ) {
    const dataUser = {
      ...processRegister,
      first_name: values.names,
      last_name: values.lastNames,
      doc_identidad: values.idDoc,
      fecha_nacimiento: values.dateBirth,
      estado_civil: values.civilStatus,
      nro_celular: values.phone,
      persona_expuesta_politicamente: values.politicallyExposed,
      obligado_informar_uif: values.informUIF,
      ocupacion_user: values.ocupacion_user,
      relacion_laboral_user: values.relacion_laboral_user,
    };
    saveProcessRegister(dataUser);
    setSubmitting(false);
    history.push('/add-address');
  },
};
export default withFormik(formConfig)(FormAddCompanyProfileData);
