import React, { useEffect } from 'react';
import { Container, Box, Item, Title, Text1, Text2 } from './styles';
import Markdown from 'react-markdown/with-html';

const NosotrosTemplate = ({ fetchNosotros, nosotros }) => {
  const nosotrosItem = nosotros ? nosotros.caracteristicas_list : null;

  useEffect(() => {
    fetchNosotros();
  }, [fetchNosotros]);

  return (
    <div>
      {nosotros && (
        <Container className='nosotros_container'>
          <Title className='nosotros_title'>
            <h2>
              {nosotros.titulo_us} <strong> {nosotros.subtitulo_us} </strong>
            </h2>
            <span> {nosotros.subtitulo_2_us} </span>
          </Title>
          <Text1 className='nosotros_text'>
            <Markdown escapeHtml={false}>{nosotros.descripcion_us}</Markdown>
          </Text1>
          <Box className='nosotros_wrap_itm'>
            {nosotrosItem &&
              nosotrosItem.length &&
              nosotrosItem.map((itm, key) => (
                <Item key={key} className='nosotros_itm'>
                  <figure>
                    <img src={itm.icon_caract.url} alt='' />
                  </figure>
                  <h3> {itm.nombre_caract} </h3>
                </Item>
              ))}
          </Box>
          <Text2 className='nosotros_text2'>
            <span> {nosotros.titulo_registro} </span>
            <Markdown
              source={nosotros.descripcion_registro}
              escapeHtml={false}
            />
          </Text2>
        </Container>
      )}
    </div>
  );
};

export default NosotrosTemplate;
