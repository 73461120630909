import { connect } from 'react-redux';
import {
  getNewAmountReceive,
  getNewEditOperation,
  getClearSingleOperation,
  fetchDashboard,
  getCleanDashboard,
  getCompletedOperation,
  getCanceledOperation,
  getArchiveOperation,
  getInvoice,
  getCleanReceiveInvoice,
} from '../../../store/web/dashboard';
import DashboardDetalle from './DashboardDetalle';

const mapStateToProps = (state) => {
  return {
    loading: state.dashboardState.loading,
    newEditOperation: state.dashboardState.newEditOperation,
    completedOperation: state.dashboardState.completedOperation,
    canceledOperation: state.dashboardState.canceledOperation,
    singleDetailOperation: state.dashboardState.singleDetailOperation,
    archiveOperation: state.dashboardState.archiveOperation,
    userDashboard: state.userDashboardState.userDashboard,
    receiveInvoice: state.dashboardState.receiveInvoice,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewAmountReceive: (data) => dispatch(getNewAmountReceive(data)),
  getNewEditOperation: (data) => dispatch(getNewEditOperation(data)),
  getClearSingleOperation: () => dispatch(getClearSingleOperation()),
  fetchDashboard: (id, status, token) => dispatch(fetchDashboard(id, status, token)),
  getCompletedOperation: (data) => dispatch(getCompletedOperation(data)),
  getCanceledOperation: (data) => dispatch(getCanceledOperation(data)),
  getArchiveOperation: (data) => dispatch(getArchiveOperation(data)),
  getInvoice: (data) => dispatch(getInvoice(data)),
  getCleanDashboard: () => dispatch(getCleanDashboard()),
  getCleanReceiveInvoice: () => dispatch(getCleanReceiveInvoice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetalle);
