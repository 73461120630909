import styled from 'styled-components/macro';

export const Wrap = styled.div`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 35px;
  /* &.simulador-page {
    padding-top: 50px;
  } */
  @media screen and (max-height: 660px) {
    margin-bottom: 30px;
  }
  @media screen and (max-height: 625px) {
    margin-bottom: 20px;
  }
`;
export const Isotipo = styled.figure`
  text-align: center;
  margin-bottom: 25px;
  img {
    display: block;
    margin: 0 auto;
  }
  @media screen and (max-height: 660px) {
    margin-bottom: 15px;
  }
`;
export const TextBig = styled.h3`
  color: var(--ion-color-primary);
  text-transform: uppercase;
  font-size: 25px;
  font-family: var(--ion-font-family-secondary);
  font-weight: normal;
  line-height: 110%;
  letter-spacing: -1px;
  margin: 0;
  @media screen and (max-height: 660px) {
    font-size: 22px;
  }
  @media screen and (max-height: 625px) {
    font-size: 20px;
  }
`;
export const TextSmall = styled.p`
  color: var(--ion-color-yellow);
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--ion-font-family-quinary);
  line-height: 17px;
  font-weight: normal;
  letter-spacing: 0.03em;
  padding-top: 5px;
`;
