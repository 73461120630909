import { requestInfo, receiveGeneralInfo, ubigeoProvincias, cleanUbigeoProvincias } from './actions';
import { routesApi } from '../../routesApi';

export const getGeneralInfo = () => {
  return (dispatch) => {
    dispatch(requestInfo());
    return fetch(routesApi.generalInfo)
      .then((response) => response.json())
      .then((data) => {
        dispatch(receiveGeneralInfo(data));
      });
  };
};
export const getUbigeoProvincias = (data) => {
  return (dispatch) => {
    return dispatch(ubigeoProvincias(data));
  };
};

export const getCleanUbigeoProvincias = () => {
  return (dispatch) => {
    return dispatch(cleanUbigeoProvincias());
  };
};