import React from 'react';
import FormCodeVerification from '../../components/web/FormCodeVerification';
const CodeVerification = ({ history }) => {
  return (
    <>
      <FormCodeVerification history={history} />
    </>
  );
};

export default CodeVerification;
