import React from 'react';
import {
  BlockNavigation,
  Content,
  Left,
  Right,
  ColumnOne,
  ColumnTwo,
  ColumnThree,
  ColumnFour,
  ColumnFive,
  Wrap,
  Title,
  TitleText,
  Nav,
  Item,
  LinkUrl,
  Contact,
  ContactText,
  Envolver,
  Horary,
  HoraryText,
  Redes,
  RedesIcon,
} from './styles';

const Navigation = ({
  email,
  telephone,
  phone,
  weekdayDays,
  weekdayHours,
  weekendDays,
  weekendHours,
  facebook,
  instagram,
  youtube,
}) => {
  const phoneText = phone;
  const phoneHref = phoneText.replace(/ /g, '');
  const telephoneText = telephone;
  const telephoneHref = telephoneText.replace(/ /g, '');
  return (
    <>
      <BlockNavigation>
        <Content className='content'>
          <Left>
            <ColumnOne>
              <Wrap>
                <Title>
                  <TitleText>Servicios</TitleText>
                </Title>
                <Nav>
                  <Item>
                    <LinkUrl to='/'>Cambio de Moneda</LinkUrl>
                  </Item>
                </Nav>
              </Wrap>
              <Wrap>
                <Title>
                  <TitleText>Soporte</TitleText>
                </Title>
                <Nav>
                  <Item>
                    <LinkUrl to='/pregunta-frecuente'>Preguntas Frecuentes</LinkUrl>
                  </Item>
                </Nav>
              </Wrap>
            </ColumnOne>
            <ColumnTwo>
              <Title>
                <TitleText>Recursos</TitleText>
              </Title>
              <Nav>
                <Item>
                  <LinkUrl to='/terminos-condiciones'>Términos y Condiciones</LinkUrl>
                </Item>
                <Item>
                  <LinkUrl to='/politicas-privacidad'>Políticas de Privacidad</LinkUrl>
                </Item>
                {/* <Item>
                  <LinkUrl>Política de Privacidad</LinkUrl>
                </Item> */}
                {/* <Item>
                  <LinkUrl>Libro de Reclamaciones</LinkUrl>
                </Item> */}
                <Item>
                  <LinkUrl to='/novedades'>Blog</LinkUrl>
                </Item>
              </Nav>
            </ColumnTwo>
          </Left>
          <Right>
            <ColumnThree>
              <Title>
                <TitleText>Contacto</TitleText>
              </Title>
              <Contact>
                {email && (
                  <ContactText>
                    <a href={`mailto:${email}`}>{email}</a>
                  </ContactText>
                )}
                {telephone && (
                  <ContactText>
                    <a href={`tel:${telephoneHref}`}>{`T. ${telephone}`}</a>
                  </ContactText>
                )}
                {phone && (
                  <ContactText>
                    <a href={`tel:${phoneHref}`}>{`C. ${phone}`}</a>
                  </ContactText>
                )}
              </Contact>
            </ColumnThree>
            <ColumnFour>
              <Envolver>
                {weekdayDays && (
                  <Title>
                    <TitleText>{weekdayDays}</TitleText>
                  </Title>
                )}
                {weekdayHours && (
                  <Horary>
                    <HoraryText>{weekdayHours}</HoraryText>
                  </Horary>
                )}
              </Envolver>
              <Envolver>
                {weekendDays && (
                  <Title>
                    <TitleText>{weekendDays}</TitleText>
                  </Title>
                )}
                {weekendHours && (
                  <Horary>
                    <HoraryText>{weekendHours}</HoraryText>
                  </Horary>
                )}
              </Envolver>
            </ColumnFour>
            <ColumnFive>
              <Title>
                <TitleText>Síguenos</TitleText>
              </Title>
              <Redes>
                {facebook && (
                  <RedesIcon
                    href={facebook}
                    target='_blank'
                    className='icon-fb'
                  ></RedesIcon>
                )}
                {instagram && (
                  <RedesIcon
                    href={instagram}
                    target='_blank'
                    className='icon-instagram1'
                  ></RedesIcon>
                )}
                {youtube && (
                  <RedesIcon
                    href={youtube}
                    target='_blank'
                    className='icon-play'
                  ></RedesIcon>
                )}
              </Redes>
            </ColumnFive>
          </Right>
        </Content>
      </BlockNavigation>
    </>
  );
};

export default Navigation;
