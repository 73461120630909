import {
  REQUEST_INFO,
  RECEIVE_GENERAL_INFO,
  UBIGEO_PROVINCIAS,
  UBIGEO_DEPARTAMENTOS,
  CLEAN_UBIGEO_PROVINCIAS
} from './types';
import { ubigeo } from '../../ubigeo';
const INITIAL_STATE = {
  generalInfo: null,
  loading: false,
  ubigeoDepartamentos: ubigeo,
  ubigeoProvincias: null,
};

export const infoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_INFO:
      return {
        ...state,
        loading: false,
      };
    case RECEIVE_GENERAL_INFO:
      return {
        ...state,
        generalInfo: action.generalInfo,
        loading: false,
      };
    case UBIGEO_PROVINCIAS:
      return {
        ...state,
        ubigeoProvincias: action.ubigeoProvincias,
      };
    case UBIGEO_DEPARTAMENTOS:
      return {
        ...state,
        ubigeoDepartamentos: action.ubigeoDepartamentos,
      };
    case CLEAN_UBIGEO_PROVINCIAS:
      return {
        ...state,
        ubigeoProvincias: INITIAL_STATE.ubigeoProvincias,
      };
    default:
      return state;
  }
};
