import React from 'react';

import ProfileMenu from '../components/ProfileMenu';

const Profile = ({ history, desktop, user }) => {
  return (
    <>
      <ProfileMenu user={user} history={history} desktop={desktop} />
    </>
  );
};

export default Profile;
