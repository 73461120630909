import React, { useState } from 'react';
import { Field } from 'formik';
import styled, { css } from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import { IonSelect } from '@ionic/react';

const inputSelectMixin = css`
  &::part(icon) {
    display: none;
  }
  background: no-repeat calc(100% - 15px) 45% url('/assets/images/icon-arrow-select.svg');
`;

export const PasswordInput = styled(({ className, field, placeholder, ...p }) => {
  const [showPassword, setShowPass] = useState(false);
  return (
    <div className={className}>
      <input
        type={showPassword ? 'text' : 'password'}
        {...field}
        placeholder={placeholder}
      />
      <button type="button" onClick={() => setShowPass((v) => !v)}>
        <svg width='25' height='25' viewBox='0 0 32 32' fill='none'>
          <path d='M16 7C9.934 7 4.798 10.776 3 16C4.798 21.224 9.934 25 16 25C22.066 25 27.202 21.224 29 16C27.202 10.776 22.066 7 16 7Z' />
          <path d='M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z' />
          {!showPassword && <path d='M3 3L29 29' />}
        </svg>
      </button>
    </div>
  );
})`
  input {
    border: none;
    width: 100%;
    text-align: inherit;
  }
  button {
    margin-left: 5px;
  }
  &&& {
    display: flex;
  }

  svg path {
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--ion-color-primary);
  }
`;

const Input = styled(Field)`
  -webkit-appearance: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  border: none;
  display: block;
  font-size: 15px;
  width: 100%;
  max-width: 300px;
  ${({ center }) => (center ? 'text-align: center;' : '')}
  && {
    margin: 10px auto;
    padding: 0 30px;
    height: 50px;
    ${({ isdatepicker }) => (isdatepicker ? 'display: flex;' : '')}
  }
  &.disabled-write{
    .MuiInputBase-root{
      .MuiInputAdornment-root{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        /* background-color:gray; */
        z-index:5;
        max-height:inherit;
        margin:0;
        justify-content:flex-end;
      }
    }
  }
  &.input-datepicker {
    flex-direction: row;
    .MuiInputBase-root {
      width: 100%;
    }
    .MuiButtonBase-root {
      transform: translateX(15px);
    }
  }
  &.no-margin-top {
    margin-top: 0 !important;
  }
  &,
  input,
  input.MuiInputBase-input {
    color: var(--ion-color-primary);
    caret-color: var(--ion-color-secondary);
    ::placeholder,
    ::part(placeholder) {
      opacity: 0.8;
    }
    :focus::placeholder {
      opacity: 0.5;
    }
  }
  .MuiFormHelperText-root.Mui-error {
    display: none;
  }
  ${({ isSelect }) => (isSelect ? inputSelectMixin : '')}
  @media screen and (max-height: 730px) {
    height: 40px !important;
  }
`;

export const InputSelect = styled(IonSelect)`
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  border: none;
  display: block;
  font-size: 15px;
  width: 100%;
  max-width: 300px;
  ${({ center }) => (center ? 'text-align: center;' : '')}
  && {
    margin: 10px auto;
    padding: 15px 30px;
  }

  &,
  input,
  input.MuiInputBase-input {
    color: var(--ion-color-primary);
    caret-color: var(--ion-color-secondary);
    ::placeholder,
    ::part(placeholder) {
      opacity: 0.8;
    }
    :focus::placeholder {
      opacity: 0.5;
    }
  }
  ${inputSelectMixin}
`;

export const HorizontalContainer = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: 300,
})`
  ${Input}, ${InputSelect} {
    --count: ${({ children }) => React.Children.count(children)};
    margin: 0px 5px !important;
    width: calc(100% / var(--count));
    padding: 15px 5px;
    text-align: center;
    :first-child,
    :last-child {
      width: calc(100% / var(--count) - 10px);
    }

    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
`;

export const Container = styled.section`
  ${Input}, ${HorizontalContainer} {
    margin: 20px auto;
  }
`;

export default Input;
