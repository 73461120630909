import React, { useEffect, Fragment, useState } from "react";
import {
  Wancho,
  OpenCategoriesList,
  WrappList,
  ListItem,
  ListLink,
} from "./styles";

function ListCategories({ categories, fetchCategories, location }) {
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  const valUrl = location.pathname;
  const valSplit = valUrl.split("/");

  const [openCategories, setOpenCategories] = useState(false);
  return (
    <>
      <Wancho className="wrappBox">
        <OpenCategoriesList
          onClick={() =>
            setOpenCategories(openCategories === false ? true : false)
          }
        >
          {location.pathname === `/novedades` ? "Más Recientes" : valSplit[2]}
        </OpenCategoriesList>
        {categories && categories.length > 0 && (
          <WrappList className={openCategories === true ? "active" : ""}>
            <ListItem
              className={location.pathname === `/novedades` ? "active" : null}
            >
              <ListLink to={`/novedades`}>Más Recientes</ListLink>
            </ListItem>

            {categories.map((item, key) => (
              <Fragment key={key}>
                {item.slug === "sin-categoria" ? null : (
                  <ListItem
                    className={
                      location.pathname === `/novedades/${item.slug}`
                        ? "active"
                        : null
                    }
                  >
                    <ListLink
                      to={`/novedades/${item.slug}`}
                      onClick={() => setOpenCategories(false)}
                    >
                      {item.name}
                    </ListLink>
                  </ListItem>
                )}
              </Fragment>
            ))}
          </WrappList>
        )}
      </Wancho>
    </>
  );
}

export default ListCategories;
