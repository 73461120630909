import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ComoFunciona from '../../components/web/ComoFuncionaHome';
import OperationForm from '../../components/OperationForm';
import SliderNews from '../../components/web/SliderNews';
import TrustTemplate from '../../components/web/Trust';
import OperationType from '../../components/web/OperationsType';
import ChangeDollar from '../../components/web/ChangeDollar';
import YourAlly from '../../components/web/YourAlly';
import PopUp from '../../components/Popup';
import { Typography } from '../../components/Text';
import Markdown from 'react-markdown/with-html';
import { sendSimulation } from '../../store/operations/operations';
import { fetchNovedades } from '../../store/web/novedades';
import BigTitlePage from '../../components/web/BigTitlePage/';
import { WrapPage } from '../../theme/shared';
import { WrapRegister, RegisterTitle, DescriptionRegister } from '../../theme/global';
const Home = ({
  novedades,
  fetchNovedades,
  generalInfo,
  sendSimulation,
  history,
  desktop,
  receiveRate,
  footerData,
}) => {
  const [titlePage, setTitlePage] = useState(false);
  useEffect(() => {
    if (footerData && footerData.title_simulator_group) {
      const dataTitle = {
        title: footerData.title_simulator_group.title_simulator,
        titleBold: footerData.title_simulator_group.title_bold_simulator,
        subtitle: footerData.title_simulator_group.subtitle_simulator,
      };
      setTitlePage(dataTitle);
    }

    fetchNovedades();
  }, [fetchNovedades, footerData]);

  const popupRef = useRef(null);
  const temp = useRef(null);

  const closedProcess = () => {
    sendSimulation(temp.current);
    sessionStorage.seeHours = true;
    history.push('/login');
  };

  return (
    <>
      <PopUp ref={popupRef} onClose={() => closedProcess()}>
        <img
          src='/assets/images/icon-big-clock.svg'
          alt=''
          style={{ width: 65, margin: '0 auto', display: 'block', marginBottom: 20 }}
        />
        {generalInfo && (
          <Typography className='info-general' size='14px' center>
            <Markdown escapeHtml={false}>
              {generalInfo.info_operation.business_hours}
            </Markdown>
          </Typography>
        )}
      </PopUp>

      <WrapPage className={`simulador-page home ${desktop || ''}`}>
        {titlePage && <BigTitlePage data={titlePage} className='simulator-home' />}
        <OperationForm
          history={history}
          className={`simulador-page home ${desktop || ''}`}
          simulator
          receiveRate={receiveRate}
          onSubmit={(p) => {
            temp.current = p;
            if (sessionStorage.seeHours) {
              sendSimulation(temp.current);
              history.push('/login');
            } else {
              popupRef.current.open();
            }
          }}
        />
        {footerData && (
          <WrapRegister>
            <RegisterTitle>{footerData.title_register}</RegisterTitle>
            <DescriptionRegister>
              <Markdown escapeHtml={false}>{footerData.description_register}</Markdown>
            </DescriptionRegister>
          </WrapRegister>
        )}
      </WrapPage>

      <OperationType />
      <ComoFunciona />
      <YourAlly />
      {novedades && <SliderNews withTitle data={novedades}></SliderNews>}
      <ChangeDollar />
      <TrustTemplate />
    </>
  );
};

const mapStateToProps = (state) => ({
  operationSimulation: state.operationState.operationSimulation,
  novedades: state.novedadesState.novedades,
  footerData: state.footerState.footerData,
});
const mapDispatchToProps = (dispatch) => ({
  sendSimulation: (data) => dispatch(sendSimulation(data)),
  fetchNovedades: () => dispatch(fetchNovedades()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
