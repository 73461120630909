import React from 'react';
import styled from 'styled-components/macro';

const Campana = (p) => (
  <svg
    width='27'
    height='31'
    viewBox='0 0 27 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...p}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 16.1537C0 8.93154 5.23076 3.07681 13.0769 3.07681C20.923 3.07681 26.1538 8.93154 26.1538 16.1537V23.4754C26.1538 25.3794 24.6103 26.9229 22.7062 26.9229H3.44755C1.54352 26.9229 0 25.3794 0 23.4754V16.1537ZM22.7062 24.3075C23.1658 24.3075 23.5384 23.935 23.5384 23.4754V16.1537C23.5384 10.376 18.8546 5.69219 13.0769 5.69219C7.29916 5.69219 2.61538 10.376 2.61538 16.1537V23.4754C2.61538 23.935 2.98795 24.3075 3.44755 24.3075H22.7062Z'
    />
    <path d='M13.0769 30.6155C15.5202 30.6155 17.5834 29.2206 18.2418 27.3092C18.4816 26.613 17.8645 26.0001 17.1281 26.0001H9.0256C8.28922 26.0001 7.67212 26.613 7.91194 27.3092C8.57032 29.2206 10.6336 30.6155 13.0769 30.6155Z' />
    <path d='M13.0766 1.51319e-05C15.2008 1.51319e-05 16.9228 0.956049 16.9228 3.84616H9.23047C9.23047 0.956049 10.9524 1.51319e-05 13.0766 1.51319e-05Z' />
  </svg>
);
export default styled(Campana)`
  ${({ color }) => (color ? `fill: var(--ion-color-${color});` : '')}
  flex-shrink: ${({ shrink }) => shrink ?? 1};
`;
