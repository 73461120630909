import React, { useEffect } from 'react';
import {
  BlockOperationType,
  Content,
  Left,
  Right,
  Title,
  TitleText,
  Partners,
  Image,
} from './styles';

const OperationType = ({ fetchOperationType, operationTypeData }) => {
  useEffect(() => {
    fetchOperationType();
  }, [fetchOperationType]);
  const titleInmediate = operationTypeData
    ? operationTypeData.title_inmediate
    : null;
  const titleBoldInmediate = operationTypeData
    ? operationTypeData.title_bold_inmediate
    : null;
  const titleInterbank = operationTypeData
    ? operationTypeData.title_interbank
    : null;
  const titleBoldInterbank = operationTypeData
    ? operationTypeData.title_bold_interbank
    : null;
  const imgItemInmediate = operationTypeData
    ? operationTypeData.bank_list_inmediate
    : null;
  const imgItemInterbank = operationTypeData
    ? operationTypeData.interbank_list
    : null;

  return (
    <>
      <BlockOperationType>
        <Content className='content'>
          <Left>
            <Title className='title_left'>
              {operationTypeData && <TitleText>{titleInmediate}</TitleText>}
              {operationTypeData && (
                <TitleText>
                  <strong>{titleBoldInmediate}</strong>
                </TitleText>
              )}
            </Title>
            {operationTypeData && imgItemInmediate.length > 0 && (
              <Partners className='partners_left'>
                {imgItemInmediate.map((item, key) => (
                  <Image key={key}>
                    <img
                      src={item.logo_bank.url}
                      width='120'
                      height='35'
                      alt=''
                    />
                  </Image>
                ))}
              </Partners>
            )}
          </Left>
          <Right>
            <Title className='title_right'>
              {operationTypeData && <TitleText>{titleInterbank}</TitleText>}
              <TitleText>
                {operationTypeData && <strong>{titleBoldInterbank}</strong>}
              </TitleText>
            </Title>
            {operationTypeData && imgItemInterbank.length > 0 && (
              <Partners className='partners_right'>
                {imgItemInterbank.map((item, key) => (
                  <Image key={key}>
                    <img
                      src={item.logo_bank.url}
                      width='53'
                      height='14'
                      alt='bbva'
                    />
                  </Image>
                ))}
              </Partners>
            )}
          </Right>
        </Content>
      </BlockOperationType>
    </>
  );
};

export default OperationType;
