import { connect } from 'react-redux';
import { fetchBanners } from '../../store/intro';

import SliderIntro from './SliderIntro';

const mapStateToProps = (state) => {
  return {
    banners: state.introState.banners,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBanners: () => dispatch(fetchBanners()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderIntro);
