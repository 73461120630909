import { connect } from 'react-redux';
import {
  fetchSearchClients,
  isActiveButton,
  fetchSelectOperation,
} from '../../../store/web/dashboard';
import DashboardTemplate from './DashboardTemplate';

const mapStateToProps = (state) => {
  return {
    searchClients: state.dashboardState.searchClients,
    activeButton: state.dashboardState.activeButton,
    selectOperation: state.dashboardState.selectOperation,
    userDashboard: state.userDashboardState.userDashboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSearchClients: (token) => dispatch(fetchSearchClients(token)),
  isActiveButton: (id) => dispatch(isActiveButton(id)),
  fetchSelectOperation: (data) => dispatch(fetchSelectOperation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTemplate);
