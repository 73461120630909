import React from 'react';
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
  return {
    newAmountReceive: state.dashboardState.newAmountReceive,
  };
};
const AmountReceived = ({ newAmountReceive, newSentAmound }) => {
  newSentAmound(newAmountReceive);

  return <>{newAmountReceive ? newAmountReceive.amount_received : '--'}</>;
};

export default connect(mapStateToProps)(AmountReceived);
