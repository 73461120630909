import styled from 'styled-components/macro';

export const Wrap = styled.div`
  padding-bottom: 30px;
`;

export const Icon = styled.div`
  text-align: center;
  font-size: 60px;
  color: var(--ion-color-secondary);
  &.hour {
    &:before {
      content: '\\e906';
      font-family: 'icomoon';
    }
  }
  &.error {
    &:before {
      content: '\\e904';
      font-family: 'icomoon';
    }
  }
  &.success {
    &:before {
      content: '\\e90e';
      font-family: 'icomoon';
    }
  }
`;
