import React from 'react';
import { useEffect } from 'react';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import Markdown from 'react-markdown/with-html';
import {
  Figure,
  WrapItem,
  SliderWrap,
  ItemSlider,
  TitleSlider,
  ItemImage,
  ItemParent,
  ItemTxt,
  ItemContenido,
} from './style';

const TrustTemplate = ({ fetchTrust, trust }) => {
  useEffect(() => {
    fetchTrust();
  }, [fetchTrust]);

  const params = {
    effect: 'fade',
    pagination: {
      el: '.swiper-pages',
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: '.slider_next_swiper',
      prevEl: '.slider_prev_swiper',
    },
  };
  const starsNumber = (number) => {
    const numberValue = Number(number);
    let htmlStar = '';
    for (let i = 0; i < numberValue; i++) {
      htmlStar =
        htmlStar +
        "<img src='static/image/Star.png' width='16' height='16' alt=''/>";
    }
    return htmlStar;
  };
  return (
    <div>
      {trust && Object.keys(trust).length && (
        <WrapItem>
          <Figure>
            <img
              src={trust.imagen_destacada.url}
              width='519'
              height=''
              alt=''
            />
          </Figure>
          <SliderWrap>
            <TitleSlider>
              <h2>{trust.titulo_review}</h2>
              <p>
                {trust.subtitulo_review}{' '}
                <strong>{trust.subtitulo_bold_review}</strong>
              </p>
            </TitleSlider>
            <ItemSlider>
              <ul>
                {trust.testimonio_list.length && (
                  <Swiper {...params}>
                    {trust.testimonio_list.map((list, key) => (
                      <li key={key}>
                        <div className='item'>
                          <ItemImage className='responsive_show'>
                            <img src={list.foto_review.url} alt='' />
                          </ItemImage>
                          <ItemTxt>
                            <p>{list.description_review}</p>
                          </ItemTxt>
                          <ItemParent>
                            <ItemImage>
                              <img src={list.foto_review.url} alt='' />
                            </ItemImage>
                            <ItemContenido>
                              <div className='valoracion'>
                                <Markdown
                                  source={starsNumber(list.estrellas_number)}
                                  escapeHtml={false}
                                />
                              </div>
                              <h3>{list.nombre_review}</h3>
                              <span>{list.fecha_review}</span>
                            </ItemContenido>
                          </ItemParent>
                        </div>
                      </li>
                    ))}
                  </Swiper>
                )}
              </ul>
            </ItemSlider>
          </SliderWrap>
        </WrapItem>
      )}
    </div>
  );
};

export default TrustTemplate;
