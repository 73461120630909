import styled from 'styled-components/macro';

export const Wrap = styled.div`
  max-width: 307px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  /* &.simulador-page {
    padding-top: 50px;
  } */
`;
export const Figura = styled.figure`
  text-align: center;
  margin-bottom: 25px;
  @media screen and (max-height: 780px) {
    width: 250px;
    margin: 0 auto;
  }
`;
export const Title = styled.h3`
  font-size: 23px;
  line-height: 28px;
  color: #717171;
  text-align: center;
  margin-bottom: 22px;
`;
export const List = styled.div``;
export const Item = styled.div`
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  height: 60px;
  color: #717171;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding-left: 30px;
  padding-right: 16px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 13px;
  p {
    margin: 0;
    color: var(--ion-color-secondary);
    text-align: left;
  }
  span {
    font-size: 9px;
    display: block;
    position: absolute;
    right: 12px;
    height: 100%;
    top: 0;
    align-items: center;
    display: flex;
    margin-left: 10px;
    &:before {
      transform: rotate(270deg);
    }
  }
  &.active {
    color: #3aa229;
    font-family: var(--ion-font-family-quinary);
  }
  @media screen and (max-height: 735px) {
    margin-bottom: 8px;
    height: 50px;
    font-size: 14px;
  }
  @media screen and (max-height: 650px) {
    margin-bottom: 5px;
    height: 45px;
  }
`;
