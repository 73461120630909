import React from 'react';
import styled from 'styled-components/macro';
import { useField } from 'formik';
const UploadImageField = styled(({ children, className, ...p }) => {
  return (
    <div className={className}>
      <button {...p}>
        <img src='/assets/images/icon-upload.svg' alt='upload' />
      </button>
      <div>{children}</div>
    </div>
  );
})`
  max-width: 300px;
  width: 100%;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  display: flex;
  flex-direction: row-reverse;
  padding-left: 28px;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  & > div {
    width: 100%;
  }

  margin-bottom: 10px;
  button {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    padding: 10px 20px;
    background-color: #fff;
    height: 50px;
    width: 70px;
  }
`;

export const WrapPreview = styled.div`
  display: flex;
  justify-content: center;
`;
export const ImagePreview = styled(({ name, className }) => {
  const field = useField(name);
  const { value } = field[0];
  const { setValue } = field[2];
  if (!value) return null;
  return (
    <div className={className}>
      <img src={value.src || value} alt={name} />
      <button className='remove' onClick={() => setValue(null)} />
    </div>
  );
})`
  position: relative;
  margin-top: -20px;
  max-width: 50px;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
  img {
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
    display: block;
  }
  button.remove {
    --wdth: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: var(--wdth);
    height: var(--wdth);
    border-radius: 20px;
    background-color: #fff5;
    display: none;

    ::after {
      display: block;
      content: '';
      width: 40%;
      height: 2px;
      background-color: #fff8;
      transform: rotate(45deg);
      border-radius: 5px;
      position: relative;
      top: -1px;
      left: calc(30%); /* 50% - 20% -> la mitad del botón menos la mitad de la barra */
    }
    ::before {
      display: block;
      content: '';
      width: 40%;
      height: 2px;
      background-color: #fff8;
      transform: rotate(-45deg);
      border-radius: 5px;
      position: relative;
      top: 1px;
      left: calc(30%); /* 50% - 20% -> la mitad del botón menos la mitad de la barra */
    }
  }
`;

export default UploadImageField;
