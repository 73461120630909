import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Strong } from '../Text';
import {
  NavList,
  MenuItem,
  HeaderCnt,
  HeaderLogo,
  HeaderWrappOpen,
  HeaderOpenText,
  HeaderOpen,
  HeaderSideBar,
  HeaderPerfil,
  HeaderMenu,
  HeaderName,
  HeaderIniciar,
  WrapNavLists,
  Wrap,
} from './styles';
import { sendPerfilOperation, getCleanUserInfo } from '../../store/users/operations';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

function Header({
  location,
  className,
  user,
  infoUser,
  perfilOperation,
  sendPerfilOperation,
  getCleanUserInfo,
  accountsUser,
}) {
  const currentUrl = location.pathname;
  const [openMenu, setOpenMenu] = useState(false);
  const [activeMenuList, setActiveMenuList] = useState('menu-2');

  const chooseProfile = (perfil) => {
    const dataPerfil = {
      id: user.info.user_id,
      perfil: perfil,
    };
    sessionStorage.profile = JSON.stringify(dataPerfil);
    sendPerfilOperation(dataPerfil);
  };

  return (
    <Wrap className={className || ''}>
      <HeaderCnt>
        <HeaderLogo to={`/`}>
          <img
            src={`/assets/images/logo-sr-cambio-desktop.png`}
            width={204}
            height={33}
            alt=''
          />
          <img
            className='mobil'
            src={`/assets/images/logo-sr-cambio-mobil.png`}
            width={75}
            height={56}
            alt=''
          />
        </HeaderLogo>

        {!user && (
          <HeaderWrappOpen>
            <HeaderOpenText to='/create-account'>
              <span>
                ¡Registrate <strong>Aquí!</strong>
              </span>
            </HeaderOpenText>
          </HeaderWrappOpen>
        )}
      </HeaderCnt>
      <HeaderOpen
        className={openMenu === true ? 'active' : ''}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <i className='icon-menu'> </i>
      </HeaderOpen>
      <HeaderSideBar className={openMenu === true ? 'active' : ''}>
        <HeaderMenu>
          <HeaderName>
            <span> Hola, </span>

            {user && infoUser && infoUser.info ? (
              <>
                <h2> {`${infoUser.info.first_name} ${infoUser.info.last_name}`} </h2>
                <h3 className='label-email'>{infoUser.info.email}</h3>
                <div>
                  <HeaderPerfil
                    className={'menu-1' === activeMenuList ? 'active' : ''}
                    onClick={() => {
                      setActiveMenuList('menu-1');
                    }}
                  >
                    <em>Ver</em> Perfil
                  </HeaderPerfil>
                  <HeaderPerfil
                    className={'menu-2' === activeMenuList ? 'active' : ''}
                    onClick={() => {
                      setActiveMenuList('menu-2');
                    }}
                  >
                    <em>Ver</em> Menú
                  </HeaderPerfil>
                </div>
              </>
            ) : (
              <Link to='/login'>
                <h2>Inicie Sesión</h2>
              </Link>
            )}
          </HeaderName>
          <WrapNavLists>
            <NavList className={'menu-1' === activeMenuList ? 'active' : ''}>
              {accountsUser && accountsUser.has_profile_company ? (
                <>
                  {perfilOperation && (
                    <MenuItem
                      onClick={() =>
                        chooseProfile(
                          perfilOperation.perfil === 'empresa' ? 'personal' : 'empresa'
                        )
                      }
                    >
                      <span className='MenuLink'>
                        Cambiar a Perfil{' '}
                        {perfilOperation.perfil === 'empresa' ? 'Personal' : 'Empresa'}
                      </span>
                    </MenuItem>
                  )}
                </>
              ) : (
                <>
                  <MenuItem>
                    <Link className='MenuLink' to='/edit-profile-company-paso-1'>
                      <Typography size='14px'>
                        No ha creado perfil de Empresa
                        <br />
                        <Strong secondary size='inherit'>
                          Agregar
                        </Strong>
                      </Typography>
                    </Link>
                  </MenuItem>
                </>
              )}
              <MenuItem>
                <Link
                  className={`MenuLink ${
                    currentUrl === '/operations/ongoing-operations' ? 'active' : null
                  }`}
                  to='/operations/ongoing-operations'
                >
                  Mis Operaciones
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={`MenuLink ${
                    currentUrl === '/accounts/bank-accounts' ? 'active' : null
                  }`}
                  to='/accounts/bank-accounts'
                >
                  Cuentas Bancarias
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={`MenuLink ${
                    currentUrl === '/edit-profile-data' ? 'active' : null
                  }`}
                  to='/edit-profile-data'
                >
                  Datos de Perfil
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  className={`MenuLink ${
                    currentUrl === '/profile/configure-alerts' ? 'active' : null
                  }`}
                  to='/profile/configure-alerts'
                >
                  Notificaciones
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  sessionStorage.clear(); 
                  Storage.remove({ key: 'currentUser' }).then( (data)=>{
                      window.location.replace('/login');
                    }
                  );  
                  // getCleanUserInfo();
                }}
              >
                <span className='MenuLink'>Cerrar Sesión</span>
              </MenuItem>
            </NavList>
            <NavList className={'menu-2' === activeMenuList ? 'active' : ''}>
              <MenuItem>
                <a
                  className={`MenuLink ${currentUrl === '/' ? 'active' : null}`}
                  href='/'
                >
                  Inicio
                </a>
              </MenuItem>

              <MenuItem>
                <a
                  className={`MenuLink ${currentUrl === '/nosotros' ? 'active' : null}`}
                  href='/nosotros'
                >
                  Nosotros
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  className={`MenuLink ${currentUrl === '/novedades' ? 'active' : null}`}
                  href='/novedades'
                >
                  Novedades
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  className={`MenuLink ${
                    currentUrl === '/pregunta-frecuente' ? 'active' : null
                  }`}
                  href='/pregunta-frecuente'
                >
                  ¿Preguntas Frecuentes?
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  className={`MenuLink ${currentUrl === '/contacto' ? 'active' : null}`}
                  href='/contacto'
                >
                  Contacto
                </a>
              </MenuItem>
              {user && infoUser && infoUser.info && (
                <MenuItem
                  onClick={() => {
                    sessionStorage.clear();
                    Storage.remove({ key: 'currentUser' }).then( (data)=>{
                        window.location.replace('/login');
                      }
                    ); 
                    // getCleanUserInfo();
                  }}
                >
                  <span className='MenuLink'>Cerrar Sesión</span>
                </MenuItem>
              )}
            </NavList>
          </WrapNavLists>
          <HeaderIniciar href='/home'> Iniciar Operación </HeaderIniciar>
        </HeaderMenu>
      </HeaderSideBar>
    </Wrap>
  );
}

const mapStateToProps = (state) => {
  return {
    perfilOperation: state.userState.perfilOperation,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendPerfilOperation: (data) => dispatch(sendPerfilOperation(data)),
  getCleanUserInfo: () => dispatch(getCleanUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
