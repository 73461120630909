import React, { useEffect, useState, useRef } from 'react';
import { CntButton, Button } from '../../theme/global';

import PopUp from '../Popup';
import Alert from '../Alert';
import {
  Section,
  Isotipo,
  HelloText,
  WrapProfile,
  RowProfile,
  Item,
  Wrap,
  Name,
  TextProfile,
  Line,
} from './styles';

const WelComeUser = ({
  user,
  infoCurrentUser,
  history,
  accountsUser,
  getAccountsUser,
  perfilOperation,
  getInforCurrentUser,
  sendPerfilOperation,
}) => {
  const [pefilUser, setPerfilUser] = useState('');
  const popupNoAccounts = useRef(null);
  const popupNoCompany = useRef(null);

  const chooseProfile = (perfil) => {
    const dataPerfil = {
      id: user.info.user_id,
      perfil: perfil,
    };

    setPerfilUser(perfil);
    sessionStorage.profile = JSON.stringify(dataPerfil);
    const dataCurrentUser = {
      id: user.info.user_id,
      token: user.info.token,
    };
    getAccountsUser(dataCurrentUser);
    sendPerfilOperation(dataPerfil);
  };
  const gotoAccounts = () => {
    popupNoAccounts.current.close();
    history.push('/accounts/bank-accounts');
  };
  const goCompanyRegister = () => {
    popupNoAccounts.current.close();
    popupNoCompany.current.close();
    history.push('/edit-profile-company-paso-1');
  };
  useEffect(() => {
    // if (user) {
    //   const dataCurrentUser = {
    //     id: user.info.user_id,
    //     token: user.info.token,
    //   };
    //   getInforCurrentUser(dataCurrentUser);
    // }
    if (accountsUser && accountsUser.has_account && pefilUser === 'personal') {
      history.push('/start-transfer');
    }
    if (
      accountsUser &&
      accountsUser.has_account &&
      accountsUser.has_profile_company &&
      pefilUser === 'empresa'
    ) {
      history.push('/start-transfer');
    }
    if (accountsUser && !accountsUser.has_account && pefilUser === 'personal') {
      popupNoAccounts.current.open();
    }
    if (accountsUser && !accountsUser.has_account && pefilUser === 'empresa') {
      popupNoAccounts.current.open();
    }
    if (accountsUser && !accountsUser.has_profile_company && pefilUser === 'empresa') {
      popupNoCompany.current.open();
    }
  }, [accountsUser, history, pefilUser, getInforCurrentUser, user]);
  return (
    <Section>
      {user && user.status === 200 && (
        <Wrap>
          <Isotipo>
            <img
              className='isotipo-white'
              src='/assets/images/isotipo.svg'
              width={78}
              alt=''
            />
          </Isotipo>
          <HelloText>Hola,</HelloText>
          {infoCurrentUser && infoCurrentUser.status === 200 && (
            <Name>{`${infoCurrentUser.info.first_name} ${infoCurrentUser.info.last_name}`}</Name>
          )}
          <WrapProfile>
            <TextProfile>Elige un perfil para ingresar</TextProfile>
            <RowProfile>
              <Item className='active' onClick={() => chooseProfile('personal')}>
                PERSONAL
              </Item>
              <Item onClick={() => chooseProfile('empresa')}>EMPRESA</Item>
            </RowProfile>
          </WrapProfile>
        </Wrap>
      )}
      <Line></Line>
      <PopUp ref={popupNoAccounts}>
        <Alert
          icon='error'
          title='Debe tener mínimo dos cuentas, una en soles y otra en dolares para realizar una operación'
          subtitle='agregue una cuenta'
        />
        <CntButton center>
          <Button
            className='redirect'
            onClick={() => {
              gotoAccounts();
            }}
          >
            Agregar Cuentas
          </Button>
        </CntButton>
        <br />
      </PopUp>
      <PopUp ref={popupNoCompany}>
        <Alert
          icon='error'
          title='No ha creado perfil de Empresa'
          subtitle='agregue un Perfil'
        />
        <CntButton center>
          <Button
            className='redirect'
            onClick={() => {
              goCompanyRegister();
            }}
          >
            Agregar Perfil Empresa
          </Button>
        </CntButton>
        <br />
      </PopUp>
    </Section>
  );
};

export default WelComeUser;
