import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import OperationForm from '../components/OperationForm';
import { sendSimulation } from '../store/operations/operations';
import { TabsContainer, TabItem } from '../components/NavTabs';
import { Teeny, Title } from '../components/Text';
import { WrapPage } from '../theme/shared';
import NoDataBankAccount from '../components/NoDataBankAccount';
import { getResulNewOperationCreate } from '../store/operations';
const mapStateToProps = (state) => {
  return {
    operationSimulation: state.operationState.operationSimulation,
    accountsUser: state.userState.accountsUser,
    perfilOperation: state.userState.perfilOperation,
  };
};
const mapDispatchToProps = (dispatch) => ({
  sendSimulation: (data) => dispatch(sendSimulation(data)),
  getResulNewOperationCreate: (data) => dispatch(getResulNewOperationCreate(data)), 
});
const StartTransfer = ({
  history,
  operationSimulation,
  sendSimulation,
  receiveRate,
  desktop,
  accountsUser,getResulNewOperationCreate,perfilOperation, user
}) => {
  const temp = useRef(null);
  const makeTransfer = () => {
    sendSimulation(temp.current); 
    const dataOperation = {
      simulation: temp.current,
      perfilOperation: perfilOperation,
      token: user.info.token,
    };
    console.log("TESTSE",dataOperation);
    getResulNewOperationCreate(dataOperation);
    history.push('/make-transfer');
  };

  const [haveAccounts, setHaveAcounts] = useState(null);

  useEffect(() => {
    setHaveAcounts(null);
    if (accountsUser && accountsUser.list_account.length >= 1) {
      let currency_pen = 0;
      let currency_usd = 0;


      for (let i = 0; i < accountsUser.list_account.length; i++) {
        let account = accountsUser.list_account[i];
        if (account.currency === 'USD') {
          currency_usd++;
        } else {
          currency_pen++;
        }
      }
      if (currency_pen >= 1 && currency_usd >= 1) {
        setHaveAcounts(true);
      }

      return;
    }
  }, [accountsUser,getResulNewOperationCreate,perfilOperation]);

  return (
    <>
      {haveAccounts ? (
        <WrapPage className={`${desktop || ''} start-transfer`}>
          <>
            <TabsContainer className='tabs-container'>
              <TabItem className='strong active '>
                Paso <Teeny color='primary'>01</Teeny>
              </TabItem>
              <TabItem className=''>
                Paso <Teeny color='primary'>02</Teeny>
              </TabItem>
              <TabItem className=''>
                Paso <Teeny color='primary'>03</Teeny>
              </TabItem>
            </TabsContainer>
            <Title className={desktop || ''} marginBottom='0'>
              Inicia tu Operación
            </Title>
            <OperationForm
              className='start-transfer'
              onSubmit={(p) => {
                temp.current = p;
                
                makeTransfer();
              }}
              receiveRate={receiveRate}
              operationSimulation={operationSimulation}
              accountsUser={accountsUser}
              history={history}
            />
          </>
        </WrapPage>
      ) : (
        <WrapPage className={`${desktop || ''} no-data`}>
          <NoDataBankAccount />
        </WrapPage>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StartTransfer);
