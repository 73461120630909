import React from 'react';
import FormProfileData from '../components/FormProfileData';
const ProfileData = ({ busisness = false, onSubmit, desktop, history, generalInfo }) => {
  return (
    <>
      <FormProfileData generalInfo={generalInfo} desktop={desktop} history={history} />
    </>
  );
};

export default ProfileData;
