import React from 'react';
import TitlePages from '../../components/web/TitlePages/Titulo';
import { WrapPage } from '../../theme/shared';
import { SliderBtn, SliderLink } from '../../components/web/SliderNews/styles';
const Error404 = ({ desktop }) => {
  return (
    <WrapPage className={`${desktop || ''} web-wrap`}>
      <>
      <TitlePages
        className='title_pregunta_frecuente'
        title='Error 404'
        subtitle='Página no encontrada'
      />
    <SliderBtn className='buttonNews'>
        <SliderLink to='/'>Ir a Home</SliderLink>
    </SliderBtn>      
    </>
    </WrapPage>
  );
};

export default Error404;
