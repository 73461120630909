import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CntButton, Button, WrapError, TextError } from '../../theme/global';
import { Typography, Strong, Title } from '../Text';
import UploadImageField, { ImagePreview, WrapPreview } from '../InputImage';
import CameraBox from '../CameraBox';
import { withFormik, Form, ErrorMessage } from 'formik';
import { Plugins, CameraResultType } from '@capacitor/core';
import PopUp from '../Popup';
import Alert from '../Alert';
import TabProfile from '../TabProfile';
import { WrapPage } from '../../theme/shared';
const { Camera } = Plugins;

const FormIdentityVerification = React.memo(
  ({
    desktop,
    processRegister,
    cleanImageDniFront,
    cleanImageDniBack,
    newAccount,
    getCreateAccount,
    history,
    urlImageDni,
    urlImageDniBack,
    values,
    setFieldValue,
    accountsUser,
    getInforCurrentUser,
    editInfoIdentity,
    getEditInfoIdentity,
    countImageCamera,
    getCountImageCamera,
    getCleanCountImageCamera,
    user,
    ...p
  }) => {
    const [editFront, setEditFront] = useState(true);
    const isFront = editFront;
    const onImageUpload = useCallback(
      async (field) => {
        try {
          const { webPath, path } = await Camera.getPhoto({
            quality: 60,
            targetWidth: 600,
            targetHeight: 300,
            saveToPhotoAlbum: false,
            allowEdit: false,
            sourceType: 1,
            resultType: CameraResultType.Uri,
          });
          getCountImageCamera(webPath);
          setFieldValue(field, { src: webPath, path });
        } catch (e) {
          console.error(e);
        }
      },
      [setFieldValue, getCountImageCamera]
    );
    const popupRef = useRef(null);
    useEffect(() => {
      if (
        urlImageDni &&
        urlImageDni.status === 200 &&
        urlImageDniBack &&
        urlImageDniBack.status === 200
      ) {
        const dataUser = {
          user_id: user.info.user_id,
          token: user.info.token,
          imagen_dni_adelante: urlImageDni.url,
          imagen_dni_atras: urlImageDniBack.url,
        };
        getEditInfoIdentity(dataUser);
        cleanImageDniFront();
        cleanImageDniBack();
        getCleanCountImageCamera();
      }
      if (editInfoIdentity && editInfoIdentity.status === 200) {
        popupRef.current.open();
      }
    }, [
      urlImageDni,
      urlImageDniBack,
      cleanImageDniFront,
      cleanImageDniBack,
      getCleanCountImageCamera,
      editInfoIdentity,
      getEditInfoIdentity,
      history,
      user,
    ]);
    return (
      <>
        <WrapPage className={`${desktop || ''} edit-identity-verification`}>
          <Form>
            <div className='inner-page'>
              <TabProfile />
              <div>
                <Title className='title-edit-identity'>
                  Verificación de Identidad <br />
                </Title>
                <div style={{ maxWidth: 280, margin: '0 auto' }}>
                  <Typography className='texto-intro' color='primary' size='13px' center>
                    Se requiere una Foto ó adjunta una imagen:{' '}
                    <Strong secondary>Adelante y Atrás</Strong> del{' '}
                    <Strong secondary>DNI o Pasaporte</Strong>, para la validación de la
                    identidad según la{' '}
                    <Strong secondary>
                      normativa de la Superintendencia de Banca, Seguros y AFP (SBS).
                    </Strong>
                  </Typography>
                </div>
                <main
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <CameraBox
                    className='camera-box'
                    onClick={() => {
                      setEditFront(!editFront);
                      onImageUpload(isFront ? 'dniFront' : 'dniBack');
                    }}
                  />
                  <Typography color='primary' center size='10px' style={{ marginTop: 5 }}>
                    DNI <Strong secondary>{isFront ? 'Adelante' : 'Atrás'}</Strong>
                  </Typography>
                  <Typography
                    color='primary'
                    center
                    size='12px'
                    className='text-camera'
                    style={{ marginTop: 16, maxWidth: 260, marginBottom: 10 }}
                  >
                    *La foto no debe ser borrosa y debe mostrarse claramente todo el
                    documento
                  </Typography>
                  <WrapPreview>
                    <ImagePreview name='dniFront' />
                    <ImagePreview name='dniBack' />
                  </WrapPreview>
                  <ErrorMessage name='dniFront'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                  <ErrorMessage name='dniBack'>
                    {(message) => (
                      <WrapError>
                        <TextError>{message}</TextError>
                      </WrapError>
                    )}
                  </ErrorMessage>
                  <UploadImageField
                    className='upload-image'
                    onClick={() => onImageUpload('dniFront')}
                  >
                    <Typography color='primary' size='15px' font='quaternary'>
                      Imagen DNI <Strong secondary>Adelante*</Strong>
                    </Typography>
                  </UploadImageField>

                  <UploadImageField
                    className='upload-image'
                    onClick={() => onImageUpload('dniBack')}
                  >
                    <Typography color='primary' size='15px' font='quaternary'>
                      Imagen DNI <Strong secondary>Atrás*</Strong>
                    </Typography>
                  </UploadImageField>

                  <Typography
                    color='primary'
                    center
                    size='13px'
                    font='quaternary'
                    className='required-text'
                  >
                    *Todos los <Strong secondary>Campos son Obligatorios</Strong>
                  </Typography>
                </main>
              </div>

              <CntButton
                center
                slot='fixed'
                style={{ width: '100%', bottom: 0, zIndex: 999 }}
              >
                <Button
                  disabled={countImageCamera.length >= 2 ? false : true}
                  type='submit'
                  round
                >
                  Guardar
                </Button>
              </CntButton>
            </div>
          </Form>

          <PopUp ref={popupRef} onClose={() => {}}>
            <Alert icon='success' title='Datos actualizados con exito' />
          </PopUp>
        </WrapPage>
      </>
    );
  }
);

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ accountsUser, business }) {
    const values = {};

    if (accountsUser) {
      const info = accountsUser.single_info;
      values.dniFront = info.imagen_dni_adelante;
      values.dniBack = info.imagen_dni_atras;
    }

    return values;
  },
  validate(values) {
    const errors = {};
    if (!values.dniFront) {
      errors.dniFront = 'Campo Obligatorio';
    }
    if (!values.dniBack) {
      errors.dniBack = 'Campo Obligatorio';
    }

    return errors;
  },
  handleSubmit(values, { setSubmitting, props: { getUrlImageDni, getUrlImageDniBack } }) {
    setSubmitting(false);

    async function getObjetBlob(data, type) {
      let getBlob = await fetch(data).then((r) => r.blob());
      let reader = new FileReader();
      reader.readAsDataURL(getBlob);
      reader.onloadend = function () {
        let base64data = reader.result;
        let arr = base64data.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let fileEnd = new File([u8arr], 'imagen', { type: mime });

        let formData = new FormData();
        formData.append('file', fileEnd);

        const dataUser = {
          file: formData,
        };
        if (type === 'front') {
          getUrlImageDni(dataUser);
        }
        if (type === 'back') {
          getUrlImageDniBack(dataUser);
        }
      };
    }
    getObjetBlob(values.dniFront.src, 'front');
    getObjetBlob(values.dniBack.src, 'back');
  },
};

export default withFormik(formConfig)(FormIdentityVerification);
