import { connect } from 'react-redux';
import { saveProcessRegister } from '../../store/users/operations';
import { getCheckEmail } from '../../store/users';
import RegisterClient from './RegisterClient';

const mapStateToProps = (state) => {
  return {
    processRegister: state.userState.processRegister,
    statusCheckEmail: state.userState.statusCheckEmail,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCheckEmail: (data) => dispatch(getCheckEmail(data)),
  saveProcessRegister: (data) => dispatch(saveProcessRegister(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RegisterClient);
