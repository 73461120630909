import { connect } from 'react-redux';
import {
  getEditInfoCompany,
  getAccountsUser,
  getCleanEditInfoCompany,
} from '../../store/users';
import { getUbigeoProvincias, getCleanUbigeoProvincias } from '../../store/info';
import FormEditAddress from './FormEditAddress';

const mapStateToProps = (state) => {
  return {
    processUpdateCompany: state.userState.processUpdateCompany,
    editInfoCompany: state.userState.editInfoCompany,
    ubigeoDepartamentos: state.infoState.ubigeoDepartamentos,
    ubigeoProvincias: state.infoState.ubigeoProvincias,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEditInfoCompany: (data) => dispatch(getEditInfoCompany(data)),
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getUbigeoProvincias: (data) => dispatch(getUbigeoProvincias(data)),
  getCleanEditInfoCompany: () => dispatch(getCleanEditInfoCompany()),
  getCleanUbigeoProvincias: () => dispatch(getCleanUbigeoProvincias()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormEditAddress);
