import { connect } from 'react-redux';
import { fetchChangeDollar } from '../../../store/web/home';
import ChangeDollar from './changeDollar';

const mapStateToProps = (state) => {
  return {
    changeDollar: state.homeState.changeDollar,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchChangeDollar: (data) => dispatch(fetchChangeDollar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDollar);
