import {
	REQUEST_CONTACT,
	RECEIVE_CONTACT,
 } from './types';
 
 const INITIAL_STATE = {
	contact: null,
	loading: false
 };
 
 export const contactReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_CONTACT:
			return {
				...state,
				loading: true,
			};
		case RECEIVE_CONTACT:
			return {
				...state,
				contact: action.contact,
				loading: false,
			};
		default:
			return state;
	}
};