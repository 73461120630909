import React, { useRef, useCallback } from 'react';
import Menu, { MenuList, MenuListItem, MenuIcon } from '../Menu';
import { Strong, Span } from '../Text';
import { ListCardButton, ListCardContainer, ListCardContent } from '../ListCard';
import CurrenciesSymbol from '../../utils/currencies';

const ListCardItem = ({
  id_cuenta,
  number_account,
  name_bank,
  id_bank,
  type_account,
  client_info,
  currency,
  onDelete,
  onEdit,
}) => {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const onClickMenu = useCallback(() => {
    menuRef.current.toggle();
  }, []);

  const onDeleteCallback = useCallback(() => {
    menuRef.current.close();
    if (onDelete) onDelete();
  }, [onDelete]);

  const onEditCallback = useCallback(() => {
    menuRef.current.close();
    if (onEdit) onEdit();
  }, [onEdit]);

  return (
    <ListCardContainer>
      <ListCardContent>
        <Strong>{client_info.name}</Strong>
        <p>
          <Strong secondary>
            <Span capitalice color='inherit' font='inherit'>
              {CurrenciesSymbol[currency].spanishName.toLowerCase()}
            </Span>{' '}
            | {name_bank}{' '}
          </Strong>
          {number_account}
        </p>
        <p style={{ fontSize: '11px' }}>{type_account}</p>
      </ListCardContent>
      <div className='vr' />
      <div style={{ position: 'relative' }}>
        <ListCardButton ref={buttonRef} onClick={onClickMenu}>
          <MenuIcon />
        </ListCardButton>
        <Menu anchorEl={buttonRef} ref={menuRef}>
          <MenuList>
            <MenuListItem className='primary' onClick={onEditCallback}>
              Editar
            </MenuListItem>
            <MenuListItem onClick={onDeleteCallback}>Eliminar</MenuListItem>
          </MenuList>
        </Menu>
      </div>
    </ListCardContainer>
  );
};
// export const ListCardItemPresentational = ({ name, accountNumber, coin }) => (
//   <ListCardContainer>
//     <ListCardContent>
//       <Strong>{name}xxxx</Strong>
//       <p>
//         <Strong secondary>
//           <Span capitalice>{CurrenciesSymbol[coin].spanishName.toLowerCase()}</Span> | BCP{' '}
//         </Strong>
//         {accountNumber}
//       </p>
//     </ListCardContent>
//   </ListCardContainer>
// );

export default ListCardItem;
