import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const BlockNavigation = styled.div`
  padding: 37px 0 30px 0;
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 50%;
  @media screen and (max-width: 960px) {
    width: 47%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: inherit;
    width: 55%;
    box-sizing: border-box;
    padding-left: 15px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 50%;
  @media screen and (max-width: 960px) {
    width: 53%;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: inherit;
    width: 45%;
  }
`;

export const ColumnOne = styled.div`
  width: 52%;
  box-sizing: border-box;
  padding-left: 37px;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
  }
  @media screen and (max-width: 960px) {
    width: 45%;
    padding-left: 0;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 35px;
  }
`;

export const ColumnTwo = styled.div`
  width: 48%;
  @media screen and (max-width: 960px) {
    width: 55%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ColumnThree = styled.div`
  width: 39%;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ColumnFour = styled.div`
  width: 33%;
  @media screen and (max-width: 960px) {
    width: 35%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ColumnFive = styled.div`
  width: 28%;
  @media screen and (max-width: 960px) {
    width: 26%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Wrap = styled.div`
  margin-bottom: 23px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }
`;

export const Title = styled.div`
  margin-bottom: 10px;
`;

export const TitleText = styled.h5`
  line-height: 1;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  font-weight: normal;
  font-size: 14px;
  margin: 0;
`;

export const Nav = styled.ul`
  display: block;
  margin: 0;
`;

export const Item = styled.li`
  display: block;
  line-height: 1;
  font-size: 0;
  margin: 0 0 5px 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const LinkUrl = styled(Link)`
  display: inline-block;
  line-height: 1;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 14px;
  margin: 0;
`;

export const Contact = styled.div`
  line-height: 1;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 14px;
  a {
    color: inherit;
  }
`;

export const ContactText = styled.p`
  margin: 0 0 10px 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Envolver = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Horary = styled.div`
  line-height: 1;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 14px;
`;

export const HoraryText = styled.p`
  margin: 0;
`;

export const Redes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const RedesIcon = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  color: var(--ion-color-secondary);
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin: 0 10px 10px 0;
  &:hover {
    transform: translateY(-5px);
  }
  &:last-child {
    margin-right: 0;
  }
`;
