import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import {
  BlockForm,
  Content,
  Info,
  Image,
  Title,
  TitleText,
  Description,
  DescriptionText,
  Formulary,
  Input,
  Submit,
} from './styles';
import { WrapError, TextError } from '../../../theme/global';
const FormSuscription = ({ description, title, label, suscripForm }) => {
  const [hideFocus, setHideFocus] = useState(true);
  const [errorEmail, setErrorEmail] = useState(false);
  const labelInputData = label;
  const labelInput = labelInputData.split('//');
  const defaultValues = {
    email: '',
  };

  return (
    <>
      <BlockForm>
        <Content className='content'>
          <Info>
            <Image>
              <img
                src='/assets/images/footer-form-comment.png'
                width='43'
                height='36'
                alt='comment'
              />
            </Image>
            {title && (
              <Title>
                <TitleText>{title}</TitleText>
              </Title>
            )}
            {description && (
              <Description>
                <DescriptionText>{description}</DescriptionText>
              </Description>
            )}
          </Info>
          <Formulary>
            <Formik
              validateOnBlur={false}
              initialValues={defaultValues}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = true;
                  setErrorEmail(true);
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                  errors.email = true;
                  setErrorEmail(true);
                }
                if (Object.keys(errors).length > 0) return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  resetForm();
                  const element = document.querySelector('.inputSuscription');
                  element.value = '';
                  setHideFocus(true);
                  suscripForm(values);
                }, 500);
              }}
            >
              {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form className='formularyFormik'>
                  <Input>
                    <input
                      autoComplete='off'
                      type='text'
                      name='email'
                      id='form_suscribete2'
                      className='inputSuscription'
                      onBlur={(e) => {
                        if (values.email.length <= 0) {
                          setHideFocus(true);
                        }
                      }}
                      onFocus={(e) => {
                        setHideFocus(false);
                      }}
                      onChange={(event) => {
                        if (event.currentTarget.value.length >= 1) {
                          values.email = event.currentTarget.value;
                          setErrorEmail(false);
                        } else {
                          values.email = '';
                        }
                      }}
                    />
                    {label && hideFocus && (
                      <label htmlFor='form_suscribete2'>
                        <strong>{labelInput[0]}</strong> {labelInput[1]}
                      </label>
                    )}
                    {errorEmail && (
                      <WrapError>
                        <TextError>Email es obligatorio</TextError>
                      </WrapError>
                    )}
                  </Input>
                  <Submit
                    className='icon-arrow-right'
                    type='submit'
                    onClick={() => submitForm}
                  ></Submit>
                </Form>
              )}
            </Formik>
          </Formulary>
        </Content>
      </BlockForm>
    </>
  );
};

export default FormSuscription;
