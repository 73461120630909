import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { fonts, colors } from '../../../theme/web/theme';
export const Wancho = styled.div`
  max-width: 1024px;
  width: 86%;
  margin: auto;
`;

export const WrappItem = styled.div`
  padding-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ItemParrafo = styled.div`
  padding: 15px 8px 16px 16px;
  border-radius: 0 0 4px 4px;
  p {
    margin: 0;
    line-height: 1;
    letter-spacing: 0.2px;
    font-family: ${fonts.lato700};
    font-size: 16px;
    color: ${colors.primary};
    min-height: 32px;
  }
  ul {
    padding: 18px 0 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

export const ItemLi = styled.li`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;
  flex: 1;
  span {
    font-size: 11px;
    color: ${colors.secondary};
    font-family: ${fonts.lato400};
    line-height: 1.85;
  }
  &:nth-child(2) {
    justify-content: flex-end;
    span {
      color: ${colors.primary};
    }
  }
`;

export const Item = styled(Link)`
  display: block;
  margin-bottom: 23px;
  background-color: #fff;
  border-radius: 9px;
  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 9px;
  }
  &:hover {
    ${ItemParrafo} {
      p {
        font-family: ${fonts.lato900};
        letter-spacing: 0.1px;
      }
    }
    img {
      box-shadow: 7px 4px 10px rgba(0, 0, 0, 0.15),
        inset 3px 6px 10px rgba(255, 255, 255, 0.25);
    }
    p {
      color: ${colors.secondary};
    }
  }
  @media screen and (min-width: 1025px) {
    width: calc(33.3333% - 16.6666666px);
    margin-left: 25px;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: calc(50% - 12.5px);
    margin-left: 25px;
    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
  }
`;
