import React from 'react';
import FormAddCompanyProfileData from '../components/FormAddCompanyProfileData';
const ProfileDataCompany = ({ desktop, history, generalInfo }) => {
  return (
    <>
      <FormAddCompanyProfileData
        generalInfo={generalInfo}
        busisness={true}
        desktop={desktop}
        history={history}
      />
    </>
  );
};

export default ProfileDataCompany;
