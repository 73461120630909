import styled from 'styled-components/macro';
export const TitleForm = styled.h3`
  font-family: var(--ion-font-family-tertiary);
  font-size: 23px;
  text-align: center;
  margin: 0;
  margin-bottom: 25px;
`;
export const PolicyPrivacity = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  input {
    position: absolute;
    top: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    left: 0;
    &:checked {
      ~ label {
        span {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
  label {
    font-size: 14px;
    font-family: var(--ion-font-family);
    a,
    em {
      text-decoration: none;
      color: var(--ion-color-secondary);
      font-family: var(--ion-font-family-secondary);
      font-style: normal;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
      background: #ffffff;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      text-align: center;
      line-height: 22px;
      color: var(--ion-color-secondary);
      margin-right: 10px;
      font-size: 11px;
      &:before {
        content: '\\e90e';
        font-family: 'icomoon';
        opacity: 0;
        transition: all 0.3s;
      }
    }
  }
`;

// export const Wrap = styled.div`
//   .MuiFormControl-root {
//     margin-bottom: 25px;
//     &:last-child {
//       margin-bottom: 0px;
//     }
//   }
//   @media screen and (max-height: 610px) {
//     padding-top: 80px;
//   }
// `;
// export const WrapButton = styled.div`
//   display: flex;
//   justify-content: center;
//   @media screen and (max-width: 360px) {
//     .ctaButton {
//       max-width: 230px;
//     }
//   }
// `;
// export const WrapError = styled.div`
//   max-width: 260px;
//   width: 100%;
//   margin: 0 auto;
//   text-align: center;
//   position: relative;
// `;
// export const FormInner = styled.div`
//   margin-bottom: 50px;
// `;

// export const InputItem = styled.div`
//   input {
//     width: 100%;
//     border: none;
//     height: 55px;
//     box-sizing: border-box;
//     font-size: 15px;
//     background-color: #ffffff;
//     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
//     border-radius: 30px;
//     text-align: center;
//     color: var(--ion-color-primary);
//     caret-color: var(--ion-color-secondary);
//     ::-webkit-input-placeholder {
//       color: var(--ion-color-primary);
//     }
//     &:-webkit-autofill {
//       -webkit-text-fill-color: var(--ion-color-primary);
//       -webkit-background-color: transparent !important;
//     }
//   }
//   &.password {
//     input {
//       font-size: 25px;
//       color: var(--ion-color-secondary);
//     }
//   }
// `;
// export const LabelInput = styled.div`
//   position: absolute;
//   top: 0;
//   pointer-events: none;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   opacity: 0.4;
//   font-size: 16px;
//   color: var(--ion-color-primary);
//   z-index: 10;
// `;

// export const FieldInput = styled.div`
//   position: relative;
//   max-width: 300px;
//   margin: 0 auto;
//   margin-bottom: 15px;
//   width: 100%;
// `;
