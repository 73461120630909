import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';

export const Title = styled.div`
  max-width: 231px;
  width: 100%;
  margin: 0 auto;
  h2 {
    margin: 0;
    font-family: ${fonts.lato900};
    font-size: 25px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -1px;

    strong {
      font-weight: normal;
      color: #3aa229;
    }
  }
  span {
    font-family: ${fonts.lato800};
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fecf30;
    padding-top: 5px;
  }
  &.simulator-home {
    margin-bottom: 35px;
    h2,
    span {
      text-transform: uppercase;
    }
  }
`;
