import React, { useState } from 'react';
import {
  Wrap,
  WrapMenuFoot,
  MenuFoot,
  NavFooter,
  ItemNav,
  IconNav,
  NamePage,
  Tooltip,
  CloseTooltip,
  TextTooltip,
} from './styles';
import { IonFooter } from '@ionic/react';
import { tabs } from '../../routes';

function Footer({ hideIconHelp, hideTextFooter, showNavFooter, receiveRate }) {
  const [hideTooltip, setHideTooltip] = useState(true);
  const handleCloseTooltip = () => {
    setHideTooltip(false);
  };
  return (
    <>
      {receiveRate && receiveRate.whatsapp_info && (
        <Wrap>
          {!hideIconHelp && (
            <WrapMenuFoot>
              <MenuFoot>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`https://api.whatsapp.com/send?phone=51${receiveRate.whatsapp_info}text=`}
                >
                  <img src='/assets/images/icon-menu-footer.png' width={80} alt='' />
                </a>
              </MenuFoot>
              {hideTooltip && (
                <Tooltip onClick={handleCloseTooltip}>
                  <img
                    src='/assets/images/wts-bg-tooltip.png'
                    width='172'
                    height='79'
                    alt='fondo'
                  />
                  <CloseTooltip className='icon-close'></CloseTooltip>
                  <TextTooltip>¿Necesitas Ayuda?</TextTooltip>
                </Tooltip>
              )}
            </WrapMenuFoot>
          )}
        </Wrap>
      )}

      <IonFooter className='ion-no-border' style={{ zIndex: 100 }}>
        {showNavFooter && (
          <NavFooter>
            {tabs.tabs.map(({ label, path, icon }, key) => (
              <ItemNav href={path} key={key}>
                <IconNav>{icon}</IconNav>
                <NamePage>{label}</NamePage>
              </ItemNav>
            ))}
          </NavFooter>
        )}
      </IonFooter>
    </>
  );
}

export default Footer;
