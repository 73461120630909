export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const RECEIVE_CREATE_ACCOUNT = 'RECEIVE_CREATE_ACCOUNT';
export const REQUEST_ACCOUNTS_USER = 'REQUEST_ACCOUNTS_USER';
export const RECEIVE_ACCOUNTS_USER = 'RECEIVE_ACCOUNTS_USER';
export const SAVE_PROCESS_REGISTER_USER = 'SAVE_PROCESS_REGISTER_USER';
export const CHECK_EMAIL_USER = 'CHECK_EMAIL_USER';
export const RECEIVE_CODE_VERIFICATION = 'RECEIVE_CODE_VERIFICATION';
export const VALIDATE_CODE_VERIFICATION = 'VALIDATE_CODE_VERIFICATION';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const RECEIVE_URL_IMAGE = 'RECEIVE_URL_IMAGE';
export const RECEIVE_URL_IMAGE_DNI = 'RECEIVE_URL_IMAGE_DNI';
export const RECEIVE_URL_IMAGE_DNI_BACK = 'RECEIVE_URL_IMAGE_DNI_BACK';
export const RECEIVE_PERFIL_OPERATION = 'RECEIVE_PERFIL_OPERATION';
export const RECEIVE_NEW_ACCOUNT_BANK = 'RECEIVE_NEW_ACCOUNT_BANK';
export const CLEAN_IMAGE_URL = 'CLEAN_IMAGE_URL';
export const CLEAN_IMAGE_DNI_FRONT = 'CLEAN_IMAGE_DNI_FRONT';
export const CLEAN_IMAGE_DNI_BACK = 'CLEAN_IMAGE_DNI_BACK';
export const CLEAN_PROCESS_REGISTER = 'CLEAN_PROCESS_REGISTER';
export const CREATE_INFO_COMPANY = 'CREATE_INFO_COMPANY';
export const EDIT_INFO_PERSONAL = 'EDIT_INFO_PERSONAL';
export const EDIT_INFO_IDENTITY = 'EDIT_INFO_IDENTITY';
export const EDIT_INFO_COMPANY = 'EDIT_INFO_COMPANY';
export const INFO_CURRENT_USER = 'INFO_CURRENT_USER';
export const DELETE_ACCOUNT_BANK = 'DELETE_ACCOUNT_BANK';
export const SAVE_CURRENT_ACCOUNT_BANK = 'SAVE_CURRENT_ACCOUNT_BANK';
export const CLEAN_CURRENT_ACCOUNT_BANK = 'CLEAN_CURRENT_ACCOUNT_BANK';
export const EDIT_ACCOUNT_BANK = 'EDIT_ACCOUNT_BANK';
export const SAVE_PROCESS_UPDATE_COMPANY = 'SAVE_PROCESS_UPDATE_COMPANY';
export const CLEAN_EDIT_INFO_COMPANY = 'CLEAN_EDIT_INFO_COMPANY';
export const CLEAN_EDIT_INFO_USER = 'CLEAN_EDIT_INFO_USER';
export const CLEAN_USER_INFO = 'CLEAN_USER_INFO';
export const CLEAN_NEW_ACCOUNT_BANK = 'CLEAN_NEW_ACCOUNT_BANK';
export const RECEIVE_ALERTS_CONFIG = 'RECEIVE_ALERTS_CONFIG';
export const COUNT_IMAGE_CAMERA = 'COUNT_IMAGE_CAMERA';
export const CLEAN_COUNT_IMAGE_CAMERA = 'CLEAN_COUNT_IMAGE_CAMERA';
export const CLEAN_ALERTS_CONFIG = 'CLEAN_ALERTS_CONFIG';
export const SHOW_ALERT_ERROR = 'SHOW_ALERT_ERROR';
export const CLEAN_ALERT_ERROR = 'CLEAN_ALERT_ERROR';
export const USER_OFF_LINE = 'USER_OFF_LINE';
