import {
	REQUEST_FREQUENTS_QUESTIONS,
	RECEIVE_FREQUENTS_QUESTIONS,
} from "./types";

const INITIAL_STATE = {
   frequentsQuestions: [],
   loading: false,
};

export const frequentQuestionsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_FREQUENTS_QUESTIONS:
			return {
				...state,
				loading: true,
			};
		case RECEIVE_FREQUENTS_QUESTIONS:
			return {
				...state,
				frequentsQuestions: action.frequentsQuestions,
				loading: false,
			};
		default:
			return state;
	}
};