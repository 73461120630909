import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LoginForm from '../components/LoginForm';
import TextMotto from '../components/TextMotto';
import { useLocation } from 'react-router-dom';
import { sendSimulation } from '../store/operations/operations';
import { WrapPage } from '../theme/shared';
const withUrlQueryParser = (Component) =>
  React.memo((p) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      const exangeValues = { sent: {} };
      let save = false;

      const search = new URLSearchParams(location.search);

      if (search.has('amount-sent')) {
        const value = Number(search.get('amount-sent'));
        if (value && !Number.isNaN(value)) {
          exangeValues.sent.amount = value;
          save = true;
        }
      }

      if (search.has('currency-sent')) {
        const value = search.get('currency-sent')?.toUpperCase();
        if (value && ['USD', 'PEN'].includes(value)) {
          exangeValues.sent.currency = value;
          save = true;
        }
      }

      if (save) dispatch(sendSimulation(exangeValues));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <Component {...p} />;
  });

const Login = ({ history, desktop }) => {
  return (
    <>
      <WrapPage className={`${desktop || ''} login-wrap`}>
        <TextMotto logo={desktop ? false : true} />
        <LoginForm history={history}></LoginForm>
      </WrapPage>
    </>
  );
};

export default withUrlQueryParser(Login);
