import styled from "styled-components/macro";

export const MenuContainer = styled.div`
  ${({ selfheight, selfWidth }) => `
    --width: ${selfWidth}px;
    --height: ${selfheight}px;
  `}
  max-width: 120px;
  min-height: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;

  ${({ parentWidth, parentHeight, selfWidth, selfheight }) => {
    const xPos = parentWidth / 2 - selfWidth;
    const yPos = parentHeight / 2 - (selfheight + 8);
    return `transform: translate(${xPos}px, ${yPos}px );`;
  }}
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 8px;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 8px;
    left: calc(var(--width) - 20px);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  }
  ${({ open }) => (!open ? "display: none;" : "")}
`;

export const MenuList = styled.ol`
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
`;

export const MenuListItem = styled.li`
  font-size: 12px;
  padding: 10px 0;
  display: block;
  width: 100%;
  text-align: center;
  font-family: var(--ion-font-family-quinary);
  color: var(--ion-color-primary);
  &:not(:last-child) {
    border-bottom: 1px solid #0000000c;
  }
  &.primary {
    color: var(--ion-color-secondary);
  }
`;
