import { connect } from 'react-redux';
import { getValidateCodeDash, getCleanValidateCodeDash } from '../../../store/web/users/';

import FormCodeVerification from './FormCodeVerification';

const mapStateToProps = (state) => {
  return {
    validateCode: state.userDashboardState.validateCodeDashboard,
    codeSecurity: state.userDashboardState.codeSecurityDashboard,
    loading: state.userDashboardState.loadingDashboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getValidateCodeDash: (data) => dispatch(getValidateCodeDash(data)),
  getCleanValidateCodeDash: (data) => dispatch(getCleanValidateCodeDash(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FormCodeVerification);
