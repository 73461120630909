import styled from 'styled-components/macro';

export const Wrap = styled.div`
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 100%;
`;
export const CodeOperation = styled.div`
  font-size: 11px;
  color: var(--ion-color-secondary);
  span {
    text-transform: uppercase;
  }
  strong {
    margin-left: 3px;
    font-family: var(--ion-font-family-quinary);
    font-size: 13px;
  }
`;
export const Head = styled.div`
  position: relative;
  padding-right: 50px;
`;

export const InfoWrap = styled.div`
  position: relative;
`;
export const Amounts = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
export const Status = styled.div`
  font-size: 12px;
  strong,
  span {
    letter-spacing: -0.03em;
    font-family: var(--ion-font-family-quinary);
  }
  span {
    color: #de1111;
  }
`;
export const AmountItem = styled.div`
  padding-right: 14px;
  &:first-child {
    margin-right: 35px;
    position: relative;
    &:after {
      content: '\\e903';
      font-family: 'icomoon';
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #e7be0e;
    }
  }
  span {
    letter-spacing: -0.03em;
    font-size: 13px;
    font-family: var(--ion-font-family-quaternary);
    display: block;
    margin-bottom: 3px;
  }
  p {
    margin: 0;
    letter-spacing: -0.03em;
    font-family: var(--ion-font-family-quinary);
    font-size: 16px;
    color: var(--ion-color-secondary);
  }
`;
export const DateOperation = styled.div`
  font-size: 13px;
  letter-spacing: -0.03em;
  strong {
    font-family: var(--ion-font-family-quinary);
  }
`;
export const Arrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(113, 113, 113, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
`;
export const ContentItem = styled.div`
  padding: 15px 0;
  padding-right: 20px;
  display: none;
  position: relative;
  span {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    right: 15px;
    top: 0;
    color: #de1111;
  }
`;

export const Item = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 10px;

  &.active {
    ${Arrow} {
      color: var(--ion-color-secondary);
      transform: rotate(180deg);
    }
    ${ContentItem} {
      display: block;
    }
  }
`;
export const TitleDescription = styled.h3`
  color: var(--ion-color-secondary);
  letter-spacing: -0.03em;
  margin: 0;
  font-size: 12px;
  font-family: var(--ion-font-family-quinary);
`;
export const Text = styled.div`
  letter-spacing: -0.03em;
  font-size: 12px;
`;
