import {
  REQUEST_COMO_FUNCIONA,
  RECEIVE_COMO_FUNCIONA,
  REQUEST_TRUST,
  RECEIVE_TRUST,
  REQUEST_CHANGE_DOLLAR,
  RECEIVE_CHANGE_DOLLAR,
  REQUEST_OPERATION_TYPE,
  RECEIVE_OPERATION_TYPE,
  REQUEST_YOUR_ALLY,
  RECEIVE_YOUR_ALLY,
} from './types';

const INITIAL_STATE = {
  comoFunciona: [],
  loading: false,
  trust: null,
  changeDollar: [],
  operationTypeData: null,
  yourAlly: null,
};

export const comoFuncionaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_COMO_FUNCIONA:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_COMO_FUNCIONA:
      return {
        ...state,
        comoFunciona: action.comoFunciona,
        loading: false,
      };
    case REQUEST_TRUST:
      return {
        ...state,
      };
    case RECEIVE_TRUST:
      return {
        ...state,
        trust: action.trust,
      };
    case REQUEST_OPERATION_TYPE:
      return {
        ...state,
      };
    case RECEIVE_OPERATION_TYPE:
      return {
        ...state,
        operationTypeData: action.operationTypeData,
      };
    case REQUEST_YOUR_ALLY:
      return {
        ...state,
      };
    case RECEIVE_YOUR_ALLY:
      return {
        ...state,
        yourAlly: action.yourAlly,
      };
    case REQUEST_CHANGE_DOLLAR:
      return {
        ...state,
      };
    case RECEIVE_CHANGE_DOLLAR:
      return {
        ...state,
        changeDollar: action.changeDollar,
      };
    default:
      return state;
  }
};
