import { Wrap, ButtonVolver, Isotipo, ButtonMenu, ButtonVolverWrapper } from './styles';
import React from 'react';
import { IonHeader, IonMenuToggle } from '@ionic/react';
import { useHistory } from 'react-router-dom';

const Header = ({ showBackButton, showMenu }) => {
  const history = useHistory();
  return (
    <IonHeader className='ion-no-border'>
      <Wrap className='headerxx'>
        {showBackButton && (
          <ButtonVolverWrapper>
            <ButtonVolver onClick={() => history.push('/simulador')}>Volver</ButtonVolver>
          </ButtonVolverWrapper>
        )}
        <Isotipo className='iso-tipo'>
          <img
            className='isotipo-color'
            src='/assets/images/isotipo-color.svg'
            width={60}
            alt=''
          />
        </Isotipo>
        {showMenu && (
          <>
            <ButtonMenu>
              <IonMenuToggle>
                <img src='/assets/images/icon-menu.svg' width={22} alt='' />
              </IonMenuToggle>
            </ButtonMenu>
          </>
        )}
      </Wrap>
    </IonHeader>
  );
};

export default Header;
