export const RECEIVE_OPERATION_SIMULATION = 'RECEIVE_OPERATION_SIMULATION';
export const SET_OPERATION_HISTORY = 'SET_OPERATION_HISTORY';
export const REQUEST_ADD_OPERATION = 'REQUEST_ADD_OPERATION';
export const REQUEST_HISTORY_OPERATIONS = 'REQUEST_HISTORY_OPERATIONS';
export const RECEIVE_RESULT_ADD_OPERATION = 'RECEIVE_RESULT_ADD_OPERATION';
export const CLEAN_OPERATIONS = 'CLEAN_OPERATIONS';
export const OPERATIONS_PENDING = 'OPERATIONS_PENDING';
export const OPERATIONS_COMPLETED = 'OPERATIONS_COMPLETED';
export const OPERATIONS_CANCELED = 'OPERATIONS_CANCELED';
export const CLEAN_HISTORY_PENDING = 'CLEAN_HISTORY_PENDING';
export const CLEAN_HISTORY_COMPLETED = 'CLEAN_HISTORY_COMPLETED';
export const CLEAN_HISTORY_CANCELED = 'CLEAN_HISTORY_CANCELED';
export const RECEIVE_RATE = 'RECEIVE_RATE';
export const CLEAN_RATE = 'CLEAN_RATE'; 

