import React, { useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { LinearProgress } from '@material-ui/core';
import { WrapForm } from './style';
import { CntButton, Button } from '../../../theme/global';
import TitlePages from '../TitlePages/Titulo';

const ContactTemplate = ({ fetchContact, history, contact }) => {
  const defaultValues = {
    nameClient: '',
    lastName: '',
    phone: '',
    message: '',
    email: '',
  };

  useEffect(() => {
    if (contact) {
      history.push('/mensaje-enviado');
    }
  }, [contact, history]);
  return (
    <WrapForm>
      <TitlePages title='contacto' subtitle='Atención al Cliente' />
      <Formik
        validateOnBlur={false}
        initialValues={defaultValues}
        validate={(values) => {
          const errors = {};
          if (values.nameClient.trim() === '') {
            errors.nameClient = 'Campo Obligatorio';
          }

          if (values.lastName.trim() === '') {
            errors.lastName = 'Campo Obligatorio';
          }

          if (values.phone.trim() === '') {
            errors.phone = 'Campo Obligatorio';
          }

          if (values.email.trim() === '') {
            errors.email = 'Campo Obligatorio';
          }
          if (values.message.trim() === '') {
            errors.message = 'Campo Obligatorio';
          }

          if (Object.keys(errors).length > 0) return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let formData = new FormData();
          formData.append('nameClient', values.nameClient);
          formData.append('lastName', values.lastName);
          formData.append('phone', values.phone);
          formData.append('message', values.message);
          formData.append('email', values.email);

          fetchContact(formData);

          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        }}
        render={({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form className='formulary-contact' encType='multipart/form-data'>
            <div>
              <div className='contact_from_input'>
                <Field
                  fullWidth
                  name='nameClient'
                  className='form-control form_input_contact'
                  type='text'
                  placeholder='Nombre*'
                  component={TextField}
                />
                <Field
                  fullWidth
                  name='lastName'
                  type='text'
                  className='form-control form_input_contact'
                  placeholder='Apellido*'
                  component={TextField}
                />
              </div>
              <div className='contact_from_input'>
                <Field
                  fullWidth
                  name='email'
                  type='text'
                  className='form-control form_input_contact'
                  placeholder='Email*'
                  component={TextField}
                />
                <Field
                  fullWidth
                  name='phone'
                  type='text'
                  className='form-control form_input_contact'
                  placeholder='Celular*'
                  component={TextField}
                />
              </div>
              <div>
                <Field
                  fullWidth
                  name='message'
                  placeholder='Mensaje'
                  multiline
                  rows='5'
                  type='text'
                  className='field-type-textarea form_input_contact'
                  component={TextField}
                />
              </div>
            </div>
            <div className='contacto_text'>
              <p>(*) Todos los campos son obligatorios</p>
            </div>
            {isSubmitting && <LinearProgress />}
            <CntButton center>
              <Button
                round
                type='submit'
                className='btn_contact'
                disabled={isSubmitting}
                onClick={() => submitForm}
              >
                Enviar
                <span className='contactIcono'></span>
              </Button>
            </CntButton>
          </Form>
        )}
      />
    </WrapForm>
  );
};

export default ContactTemplate;
