import React from 'react';
import ContactTemplate from '../../components/web/Contact';
import { WrapPage } from '../../theme/shared';
function Contacto({ history, desktop }) {
  return (
    <WrapPage className={`${desktop || ''} web-wrap`}>
      <ContactTemplate history={history} />
    </WrapPage>
  );
}

export default Contacto;
