import styled from 'styled-components/macro';

export const GroupRadio = styled.div`
  margin-bottom: 20px;
`;
export const TitleRadio = styled.h3`
  font-family: var(--ion-font-family-quinary);
  color: var(--ion-color-secondary);
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 198px;
`;
export const SubTitle = styled.h4`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #192232;
  margin: 0;
  margin-bottom: 15px;
`;
export const ItemRadio = styled.div`
  .input-radio {
    display: none;
    &:checked + label {
      &:after {
        opacity: 1;
      }
    }
  }
  label {
    position: relative;
    display: flex;
    margin-right: 20px;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #192232;
    &:before {
      content: '';
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      width: 25px;
      height: 25px;
      border-radius: 100%;
      display: block;
      margin-right: 10px;
    }
    &:after {
      content: '';
      border-radius: 100%;
      display: block;
      width: 10px;
      height: 10px;
      background: #3aa229;
      opacity: 0;
      transition: all 0.3s;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 7px;
      left: 7px;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: center;
`;
