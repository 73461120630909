import React from 'react';
import { Redes, RedesIcon, Wrap, Title } from './styles';
const SocialNetworks = ({ data, className }) => {
  return (
    <>
      {data && (
        <Wrap className={className || ''}>
          <Title>Síguenos</Title>
          <Redes>
            {data.facebook_info && (
              <RedesIcon
                href={data.facebook_info}
                target='_blank'
                className='icon-fb'
              ></RedesIcon>
            )}
            {data.instagram_info && (
              <RedesIcon
                href={data.instagram_info}
                target='_blank'
                className='icon-instagram1'
              ></RedesIcon>
            )}
            {data.youtube_info && (
              <RedesIcon
                href={data.youtube_info}
                target='_blank'
                className='icon-play'
              ></RedesIcon>
            )}
          </Redes>
        </Wrap>
      )}
    </>
  );
};

export default SocialNetworks;
