import { requestNosotros, receiveNosotros } from './actions';
import { routesApi } from '../../../routesApi';

export const fetchNosotros = () => {
  return (dispatch) => {
    dispatch(requestNosotros());
    return fetch(`${routesApi.urlApiAcf}pages/210`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveNosotros(json.acf));
      });
  };
};
