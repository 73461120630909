import React from 'react';
import {Title} from './style'
const TitlePages = ({className,subtitle, title}) => {
   return (
      <Title className={className || ''}>
         <h2>{title} <strong>{subtitle}</strong></h2>
      </Title>
   );
}
 
export default TitlePages;