import styled from 'styled-components/macro';

export const Wrapp = styled.div`
  max-width: 480px;
  width: 93%;
  margin: auto;
  padding: 100px 0;
`;

export const EnvolverImg = styled.div`
  width: 158px;
  height: 158px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  margin-bottom: 94px;
  border-radius: 50%;
  position: relative;
  figure {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
`;

export const InfoMessage = styled.div`
  text-align: center;
  max-width: 283px;
  width: 100%;
  margin: auto;
  h2 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 30px;
    color: #717171;
    letter-spacing: -0.02em;
    font-weight: normal;
    font-family: var(--ion-font-family-tertiary);
  }
  p {
    color: #717171;
    opacity: 0.7;
    letter-spacing: -0.02em;
    font-size: 16px;
  }
`;
