import styled from 'styled-components/macro';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { fonts } from '../../../theme/web/theme';

export const Wrapp = styled.div`
  max-width: 600px;
  width: 94%;
  margin: auto;
  padding-bottom: 35px;
  .MuiExpansionPanel-root:before {
    display: none;
  }
  .title_pregunta_frecuente {
    margin-bottom: 51px;
  }
`;

export const Encabezado = styled.div`
  color: #717171;
  font-family: ${fonts.lato800};
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  padding-right: 40px;
  width: 100%;
  opacity: 0.7;
  box-sizing: border-box;
`;

export const Accordion = styled(MuiAccordion)`
  background: #ffffff !important;
  box-shadow: 0px 0px 15px rgba(58, 162, 41, 0.15) !important;
  border-radius: 5px !important;
  margin-bottom: 20px;
`;

export const FatherHeadIcono = styled(MuiAccordionSummary)`
  position: relative;

  &:before {
    content: '';
    background: url('/static/image/flecha-default.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  &.Mui-expanded {
    .Mui-expanded {
      /* border-bottom: 1px solid rgba(58, 162, 41, 0.2); */
      margin: 0;
      padding: 0;
    }
    ${Encabezado} {
      color: #3aa229;
      opacity: 1;
    }
    &:before {
      background: url('/static/image/flecha.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
`;

export const Texto = styled.div`
  width: 525px;
  font-family: ${fonts.lato400};
  font-size: 14px;
  line-height: 110%;
  color: #717171;
  opacity: 0.7;
`;
