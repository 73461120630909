import { connect } from 'react-redux';
import { fetchYourAlly } from '../../../store/web/home';
import YourAlly from './YourAlly';

const mapStateToProps = (state) => {
  return {
    yourAlly: state.homeState.yourAlly,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchYourAlly: () => dispatch(fetchYourAlly()),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourAlly);
