import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';

export const Section = styled.div`
  position: relative;
`;
export const ImageTop = styled.div`
  height: 290px;
  width: 100%;
  top: 90px;
  left: 0;
  position: absolute;
  z-index: -1;
  background-image: url('/static/image/head-top.png');
  background-size: cover;
  background-position: bottom center;
`;
export const WrapItem = styled.div`
  max-width: 1086px;
  width: 94%;
  margin: auto;
  text-align: center;
  padding-top: 60px;
  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fecf30;
    transform: scale(1);
    opacity: 1;
    left: 0 !important;
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .swiper-pagination-bullet-active-next,
  .swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullet-active-prev-prev,
  .swiper-pagination-bullet-active-next-next {
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    background: #3aa229;
    width: 10px;
    height: 10px;
  }

  .swiper-pages {
    width: 100% !important;
    text-align: center;
    left: 0px !important;
    transform: inherit !important;
    z-index: 120;
    bottom: 0px !important;
    padding: 30px 0 41px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 320px) {
      padding-bottom: 35px;
    }
  }
  .slider_next_swiper,
  .slider_prev_swiper {
    width: 80px;
    height: 60px;
    box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
    background-color: #fff;
    border-radius: 15px;
    opacity: 1;
    position: absolute;
    bottom: 15px;
    margin: auto;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (min-width: 769px) {
      display: none;
    }
    @media screen and (max-width: 320px) {
      width: 70px;
      height: 50px;
      border-radius: 10px;
      &:before {
        line-height: 50px !important;
        font-size: 14px;
      }
    }
    span {
      display: none;
    }
    &:before {
      content: '\\e903';
      font-family: 'icomoon';
      color: #3aa229;
      font-size: 17px;
      display: block;
    }
    &.slider_prev_swiper {
      left: 15px;
      &:before {
        transform: rotate(-180deg);
      }
    }
    &.slider_next_swiper {
      right: 15px;
      &:before {
        transform: rotate(0deg);
      }
    }
  }
`;

export const ItemPreguntas = styled.div`
  text-align: center;
  margin: 0;
  width: 100%;
  position: relative;
  padding-top: 20px;
  span {
    left: 0;
    position: absolute;
    display: inline-block;
    top: 15px;
    left: 50px;
    font-family: ${fonts.lato800};
    font-size: 35px;
    line-height: 42px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #ffe872;
    &.item_size_2 {
      font-size: 45px;
      left: 30px;
      @media screen and (max-width: 480px) {
        left: 45px;
      }
    }
    &.item_size_3 {
      left: 35px;
      font-size: 65px;
    }

    @media screen and (max-width: 480px) {
      left: 70px;
    }
  }
  p {
    font-family: ${fonts.lato400};
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #717171;
    margin: 0;
    strong {
      color: #3aa229;
    }
    @media screen and (max-width: 767px) {
      max-width: 260px;
      width: 100%;
      margin: auto;
    }
  }
  figure {
    img {
      width: auto;
      height: 260px;
      margin: auto;
      display: block;
    }
  }
`;
export const TitleItem = styled.h3`
  font-family: ${fonts.lato800};
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #3aa229;
  margin-bottom: 5px;
`;
export const Title = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 50px;
  h2 {
    font-family: ${fonts.lato800};
    font-size: 25px;
    line-height: 1;
    color: #3aa229;
    letter-spacing: -0.03em;
    margin: 0;
  }
  p {
    font-family: ${fonts.lato400};
    font-size: 15px;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 0;
    color: #717171;
    strong {
      color: #3aa229;
      display: block;
      font-weight: normal;
      font-family: ${fonts.lato800};
    }
  }
`;
