import { connect } from 'react-redux';
import { getNewPasswordDash } from '../../../store/web/users/operations';

import ChangePassword from './ChangePassword';

const mapStateToProps = (state) => {
  return {
    newPassword: state.getNewPassword.newPasswordDashbooard,
    codeSecurity: state.getNewPassword.codeSecurityDashboard,
    loading: state.getNewPassword.loadingDashboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewPasswordDash: (data) => dispatch(getNewPasswordDash(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
