import React from 'react';
import FormAddAddress from '../components/FormAddAddress';
const AddAddress = ({ desktop, user, history }) => {
  return (
    <>
      <FormAddAddress desktop={desktop} user={user} history={history} />
    </>
  );
};

export default AddAddress;
