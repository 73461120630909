import { connect } from 'react-redux';
import {
  getUrlImageDni,
  getUrlImageDniBack,
  saveProcessRegister,
  getCreateAccount,
  cleanImageDniFront,
  fetchUser,
  getInforCurrentUser,
  cleanImageDniBack,
} from '../../store/users';

import FormIdentityVerification from './FormIdentityVerification';

const mapStateToProps = (state) => {
  return {
    urlImageDni: state.userState.urlImageDni,
    urlImageDniBack: state.userState.urlImageDniBack,
    processRegister: state.userState.processRegister,
    newAccount: state.userState.newAccount,
    infoCurrentUser: state.userState.infoCurrentUser,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (data) => dispatch(fetchUser(data)),
  getUrlImageDni: (data) => dispatch(getUrlImageDni(data)),
  getUrlImageDniBack: (data) => dispatch(getUrlImageDniBack(data)),
  cleanImageDniFront: () => dispatch(cleanImageDniFront()),
  cleanImageDniBack: () => dispatch(cleanImageDniBack()),
  saveProcessRegister: (data) => dispatch(saveProcessRegister(data)),
  getCreateAccount: (data) => dispatch(getCreateAccount(data)),
  getInforCurrentUser: (data) => dispatch(getInforCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormIdentityVerification);
