import { REQUEST_TERMS_CONDITIONS, RECEIVE_TERMS_CONDITIONS } from './types';

export const requestTermsConditions = () => ({
  type: REQUEST_TERMS_CONDITIONS,
});

export const receiveTermsConditions = (data) => ({
  type: RECEIVE_TERMS_CONDITIONS,
  termsConditions: data,
});
