import {
  REQUEST_NOVEDADES,
  RECEIVE_NOVEDADES,
  RECEIVE_CATEGORIES,
  RECEIVE_NEWS,
  RECEIVE_NEWS2,
  RECEIVE_NEWS_CATEGORY,
  RECEIVE_NEWS_FEATURED,
} from "./types";
const INITIAL_STATE = {
  novedades: null,
  featured: null,
  categories: null,
  getNews: null,
  getNews2: null,
  getNewsCategory: null,
};

export const novedadesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_NOVEDADES:
      return {
        ...state,
      };
    case RECEIVE_NOVEDADES:
      return {
        ...state,
        novedades: action.novedades,
      };
    case RECEIVE_NEWS_FEATURED:
      return {
        ...state,
        featured: action.featured,
      };
    case RECEIVE_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case RECEIVE_NEWS:
      return {
        ...state,
        getNews: action.getNews,
      };
    case RECEIVE_NEWS2:
      return {
        ...state,
        getNews2: action.getNews,
      };

    case RECEIVE_NEWS_CATEGORY:
      return {
        ...state,
        getNewsCategory: action.getNewsCategory,
      };

    default:
      return state;
  }
};
