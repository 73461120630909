import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../Header';
import HeaderDesktop from '../HeaderDesktop';
import { GlobalStyle } from '../../theme/global';
import { IonPage, IonContent, isPlatform, IonToast } from '@ionic/react';
import { Base64 } from 'js-base64';
import Footer from '../Footer';

import FooterDesktop from '../FooterDesktop';
import BannersSide from '../BannersSide';
import {
  getAccountsUser,
  getInforCurrentUser,
  getAccountsUserLocal,
  getInforCurrentUserLocal,
  sendPerfilOperation,
  getcleanAlertError,
  getUserOffLine,
  getUserOffLineLocal,
} from '../../store/users/operations';
import { getReceiveRate } from '../../store/operations';
import { getGeneralInfo } from '../../store/info';
import { WrapLoading, Loading } from './styles';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
export const LayoutsContext = React.createContext({});

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    loadingUser: state.userState.loading,
    loadingOperation: state.operationState.loading,
    loadingFooter: state.footerState.loading,
    loadingDashboard: state.dashboardState.loading,
    infoCurrentUser: state.userState.infoCurrentUser,
    showAlertError: state.userState.showAlertError,
    receiveRate: state.operationState.receiveRate,
    generalInfo: state.infoState.generalInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getInforCurrentUser: (id) => dispatch(getInforCurrentUser(id)),
  getAccountsUserLocal: (id) => dispatch(getAccountsUserLocal(id)),
  getInforCurrentUserLocal: (id) => dispatch(getInforCurrentUserLocal(id)),
  getcleanAlertError: () => dispatch(getcleanAlertError()),
  getReceiveRate: () => dispatch(getReceiveRate()),
  getGeneralInfo: () => dispatch(getGeneralInfo()),
  sendPerfilOperation: (data) => dispatch(sendPerfilOperation(data)),
  getUserOffLine: (data) => dispatch(getUserOffLine(data)),
  getUserOffLineLocal: (data) => dispatch(getUserOffLineLocal(data)),
});

const Toast = () => {
  const showToast = useSelector(({ uiState }) => uiState.showApiError);
  return <IonToast isOpen={showToast} message='Error de conexión!' />;
};

function Layout({
  loadingUser,
  loadingOperation,
  loadingDashboard,
  Component,
  route,
  infoCurrentUser,
  bodyClass,
  showFooter,
  showHeader,
  showBackButton,
  hideIconHelp,
  hideTextFooter,
  history,
  location,
  showNavFooter,
  showMenu,
  user,
  needLogin,
  hideBannerSide,
  hideHeadDesktop,
  hideFootDesktop,
  getAccountsUser,
  getInforCurrentUser,
  getAccountsUserLocal,
  getInforCurrentUserLocal,
  receiveRate,
  loadingFooter,
  getReceiveRate,
  generalInfo,
  getGeneralInfo,
  sendPerfilOperation,
  dashboard,
  showAlertError,
  getcleanAlertError,
  getUserOffLine,
  getUserOffLineLocal,
  disabledBackButton,
}) {
  useEffect(() => {
    let currentPathname = '';
    let currentSearch = '';
    if (showAlertError && showAlertError.status === 500) {
      const SwalError = withReactContent(Swal);
      const alertError = () => {
        SwalError.fire({
          icon: 'warning',
          showCloseButton: true,
          allowOutsideClick: false,
          text: 'Lo sentimos ha ocurrido un error, intentelo de Nuevo',
          confirmButtonText: 'continuar',
          focusConfirm: false,
        }).then((result) => {
          if (result) {
            getcleanAlertError();
          }
        });
      };
      alertError();
      return;
    }
    if (!dashboard) {
      const dataProfile = sessionStorage.profile;
      const dataCurrenUser = sessionStorage.currentUser;
      const dataCurrenUserLocal = 'eyJzdGF0dXMiOjIwMCwibWVzc2FnZSI6Ik9LIiwiaW5mbyI6eyJ0b2tlbiI6ImV5SjBlWEFpT2lKS1YxUWlMQ0poYkdjaU9pSklVekkxTmlKOS5leUpwYzNNaU9pSm9kSFJ3Y3pwY0wxd3ZZWEJwTG5OeVkyRnRZbWx2TG5CbElpd2lhV0YwSWpveE5qWTFOelV4TWpVekxDSnVZbVlpT2pFMk5qVTNOVEV5TlRNc0ltVjRjQ0k2TVRZMk5qTTFOakExTXl3aVpHRjBZU0k2ZXlKMWMyVnlJanA3SW1sa0lqb2lNekV6SW4xOWZRLlFqVHE2bmhuWVYxcTh1YmlXM0prY3VaN1V1ZDRfM181eUU2YzhKaVdfMHMiLCJ1c2VyX2VtYWlsIjoibmZlcm5hbmRvb2NAZ21haWwuY29tIiwidXNlcl9uaWNlbmFtZSI6Im5mZXJuYW5kb29jZ21haWwtY29tIiwidXNlcl9kaXNwbGF5X25hbWUiOiJOZXN0b3IgT2plZGEiLCJ1c2VyX2lkIjoiMzEzIiwiZmlyc3RfbmFtZSI6Ik5lc3RvciIsImxhc3RfbmFtZSI6Ik9qZWRhIn19';

      if (!user && dataCurrenUser) {
        const decodeDataUser = JSON.parse(Base64.decode(dataCurrenUser));

        getUserOffLine(decodeDataUser);

        if (dataProfile) {
          const profile = JSON.parse(sessionStorage.profile);
          const dataCurrentUser = {
            id: decodeDataUser.info.user_id,
            token: decodeDataUser.info.token,
          };
          getAccountsUser(dataCurrentUser);
          getInforCurrentUser(dataCurrentUser);
          sendPerfilOperation(profile);
          return;
        } else {
          const dataCurrentUser = {
            id: decodeDataUser.info.user_id,
            token: decodeDataUser.info.token,
          };
          getAccountsUser(dataCurrentUser);
          getInforCurrentUser(dataCurrentUser);
        }
        return;
      }
      

      if (!receiveRate) {
        getReceiveRate();
        return;
      }
      if (needLogin && !user) {
        sessionStorage.clear();
        window.location.replace('/login');
      }

      if ((needLogin && user && user.status === 200) || disabledBackButton) {
        history.listen((newLocation, action) => {
          if (action === 'PUSH') {
            if (
              newLocation.pathname !== currentPathname ||
              newLocation.search !== currentSearch
            ) {
              currentPathname = newLocation.pathname;
              currentSearch = newLocation.search;

              history.push({
                pathname: newLocation.pathname,
                search: newLocation.search,
              });
            }
          } else {
            history.go(1);
          }
        });
      }
      if (!generalInfo) {
        getGeneralInfo();
      }

      if (!user && dataCurrenUserLocal) {
        const decodeDataUser = JSON.parse(Base64.decode(dataCurrenUserLocal));

       const dataCurrentUser = {
         id: decodeDataUser.info.user_id,
         token: decodeDataUser.info.token,
       };
       getUserOffLineLocal(dataCurrentUser);
       if (dataProfile) {
         const profile = JSON.parse(sessionStorage.profile);
         const dataCurrentUser = {
           id: decodeDataUser.info.user_id,
           token: decodeDataUser.info.token,
         };
         getAccountsUser(dataCurrentUser);
         getInforCurrentUser(dataCurrentUser);
         sendPerfilOperation(profile);
         return;
       } else {
         const dataCurrentUser = {
           id: decodeDataUser.info.user_id,
           token: decodeDataUser.info.token,
         };
         getAccountsUserLocal(dataCurrentUser);
         getInforCurrentUserLocal(dataCurrentUser);
       }
       return;
      


     }
    }
    return () => {
      if (needLogin && user && user.status === 200) {
        window.onpopstate = null;
      }
    };
  }, [
    dashboard,
    history,
    needLogin,
    user,
    receiveRate,
    getReceiveRate,
    generalInfo,
    getGeneralInfo,
    getAccountsUser,
    getInforCurrentUser,
    getAccountsUserLocal,
    getInforCurrentUserLocal,
    sendPerfilOperation,
    getUserOffLine,
    getUserOffLineLocal,
    getcleanAlertError,
    showAlertError,
    disabledBackButton,
  ]);
  const detectDesktop = ['desktop', 'mobileweb'].some(isPlatform);
  const infoUser = infoCurrentUser ? infoCurrentUser : null;
  const [fixNav, setfixNav] = useState(0);

  return (
    <div
      className={`layout ${bodyClass ? bodyClass : ''} ${fixNav === 1 ? 'fixed' : ''}`}
    >
      {loadingOperation && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      {loadingUser && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      {loadingFooter && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      {loadingDashboard && (
        <WrapLoading>
          <Loading>
            <img src='/assets/images/loading-green.svg' width={200} alt='' />
          </Loading>
        </WrapLoading>
      )}
      <GlobalStyle />
      <IonPage>
        {dashboard ? (
          <Component history={history} route={route} user={user} />
        ) : (
          <>
            {detectDesktop ? (
              <>
                <div className='contentDesktop'>
                  {!hideBannerSide && <BannersSide className={bodyClass || ''} />}
                  {!hideHeadDesktop && (
                    <HeaderDesktop infoUser={infoUser} user={user} location={location} />
                  )}
                  <Component
                    desktop='desktop'
                    history={history}
                    location={location}
                    route={route}
                    receiveRate={receiveRate}
                    generalInfo={generalInfo}
                    user={user}
                  />
                  <Toast />
                  {!hideFootDesktop && (
                    <FooterDesktop receiveRate={receiveRate}></FooterDesktop>
                  )}
                </div>
              </>
            ) : (
              <>
                {showHeader && (
                  <Header
                    user={user}
                    infoUser={infoUser}
                    showBackButton={showBackButton}
                    showMenu={showMenu}
                  />
                )}
                <IonContent
                  scrollEvents={true}
                  onIonScroll={(event) => {
                    let scrollBar = event.detail.scrollTop;
                    if (scrollBar >= 60) {
                      setfixNav(1);
                    } else {
                      setfixNav(0);
                    }
                  }}
                >
                  <Component
                    history={history}
                    location={location}
                    route={route}
                    generalInfo={generalInfo}
                    receiveRate={receiveRate}
                    user={user}
                  />
                  <Toast />
                </IonContent>
                {showFooter && (
                  <Footer
                    receiveRate={receiveRate}
                    showNavFooter={showNavFooter}
                    hideIconHelp={hideIconHelp}
                    hideTextFooter={hideTextFooter}
                  />
                )}
              </>
            )}
          </>
        )}
      </IonPage>
    </div>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
