import React, { useEffect, useState } from 'react';

import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Encabezado, Texto, Accordion, Wrapp, FatherHeadIcono } from './styles';
import TitlePages from '../../../components/web/TitlePages/Titulo';
import SocialNetworks from '../../../components/web/SocialNetworks';
import { Content } from '../../../theme/global';
const FrequentQuestionsTemplate = ({
  frequentsQuestions,
  fetchFrecuentQuestion,
  footerData,
}) => {
  useEffect(() => {
    fetchFrecuentQuestion();
  }, [fetchFrecuentQuestion]);

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Content maxWidth='1045px'>
      <Wrapp>
        <TitlePages
          className='title_pregunta_frecuente'
          title='PREGUNTAS FRECUENTES'
          subtitle='¿Necesitas Ayuda?'
        />
        {frequentsQuestions && Object.keys(frequentsQuestions).length >= 1 && (
          <div>
            {frequentsQuestions.map((pregunta, key) => (
              <Accordion
                key={key + 1}
                expanded={expanded === `panel${key + 1}`}
                onChange={handleChange(`panel${key + 1}`)}
              >
                <FatherHeadIcono
                  aria-controls={`panel${key + 1}bh-content`}
                  id={`panel${key + 1}bh-header`}
                >
                  <Encabezado className='pregunta_frecuente_head'>
                    {pregunta.faq_title}
                  </Encabezado>
                </FatherHeadIcono>
                <MuiAccordionDetails>
                  <Texto>{pregunta.faq_descripcion}</Texto>
                </MuiAccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </Wrapp>
      <SocialNetworks data={footerData} className='commonStyle' />
    </Content>
  );
};

export default FrequentQuestionsTemplate;
