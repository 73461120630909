import { connect } from 'react-redux';
import { fetchNews } from '../../../store/web/novedades';

import newDetail from './NewDetail';

const mapStateToProps = (state) => {
  return {
    getNews: state.novedadesState.getNews,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNews: (slugNews) => dispatch(fetchNews(slugNews)),
});

export default connect(mapStateToProps, mapDispatchToProps)(newDetail);
