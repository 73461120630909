import {
	REQUEST_FREQUENTS_QUESTIONS, 
	RECEIVE_FREQUENTS_QUESTIONS
} from './types'


export const requestFrequentsQuestions = () =>({
	type: REQUEST_FREQUENTS_QUESTIONS,
});

export const receiveFrequentsQuestions = (data) =>({
	type: RECEIVE_FREQUENTS_QUESTIONS,
	frequentsQuestions: data
});