import React from 'react';
import { connect } from 'react-redux';
import { CntButton, Button } from '../theme/global';
import Message from '../components/Message';
import { requestCleanProcessRegister, getAccountsUser } from '../store/users';
import { WrapPage } from '../theme/shared';
import NoData from '../components/NoData';
const mapStateToProps = (state) => {
  return {
    newAccount: state.userState.newAccount,
    createInfoCompany: state.userState.createInfoCompany,
  };
};
const mapDispatchToProps = (dispatch) => ({
  requestCleanProcessRegister: () => dispatch(requestCleanProcessRegister()),
  getAccountsUser: (data) => dispatch(getAccountsUser(data)),
});

const RegistrationCompleted = ({
  desktop,
  history,
  newAccount,
  user,
  createInfoCompany,
  getAccountsUser,
  requestCleanProcessRegister,
}) => {
  const goSimulator = () => {
    if (newAccount && newAccount.status === 200) {
      requestCleanProcessRegister();
    }
    if (user && createInfoCompany) {
      const dataCurrentUser = {
        id: user.info.user_id,
        token: user.info.token,
      };
      getAccountsUser(dataCurrentUser);
    }
  };
  return (
    <>
      {newAccount || createInfoCompany ? (
        <WrapPage className={`${desktop || ''} registration-completed`}>
          <Message
            title='¡Registro Completado!'
            description='Te llegará una confirmación a tu correo electrónico'
          />
          <CntButton center>
            <Button type='button' onClick={() => goSimulator()} round>
              Iniciar Operación
            </Button>
          </CntButton>
        </WrapPage>
      ) : (
        <WrapPage className={`${desktop || ''} no-data`}>
          <NoData />
        </WrapPage>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationCompleted);
