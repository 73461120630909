import React from 'react';
import RecoverPassword from '../../components/web/RecoverPassword';
const Recover = ({ history }) => {
  return (
    <>
      <RecoverPassword history={history} />
    </>
  );
};

export default Recover;
