import styled from 'styled-components/macro';

export const BoxShadowRounded = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.soft};
  border-radius: 30px;
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  padding-right: 5px;
  margin-bottom: 20px;
  background-color: #fff;
`;

const BoxShadow = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.middle};
  border-radius: 10px;
  padding: 26px;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  ${({ margin }) => (margin ? 'margin-bottom: 20px;' : '')}
  /* & > *:not(:last-child) {
    margin-bottom: 20px;
  } */
  background-color: #fff;
`;

export default BoxShadow;
