import styled from "styled-components/macro";

export const Wrap = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  height: 100%;
  top: 0;
  img {
    max-width: 100%;
    height: auto;
  }
`;
