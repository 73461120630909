import React from 'react';
import FormAddBankAccount from '../components/FormAddBankAccount';
const AddBankAccount = ({ asStep = true, desktop, history, user, generalInfo }) => {
  return (
    <>
      <FormAddBankAccount
        user={user}
        asStep={asStep}
        desktop={desktop}
        history={history}
        generalInfo={generalInfo}
      />
    </>
  );
};

export default AddBankAccount;
