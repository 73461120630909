import { connect } from 'react-redux';
import {
  getEditAccountBank,
  getAccountsUser,
  getCleanCurrentAccountBank,
} from '../../store/users';
import FormEditBankAccount from './FormEditBankAccount';

const mapStateToProps = (state) => {
  return {
    editAccountBank: state.userState.editAccountBank,
    currentAccountBank: state.userState.currentAccountBank,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getEditAccountBank: (data) => dispatch(getEditAccountBank(data)),
  getCleanCurrentAccountBank: () => dispatch(getCleanCurrentAccountBank()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormEditBankAccount);
