import { connect } from 'react-redux';
import {
  getNewAccountBank,
  getAccountsUser,
  getCleanNewAccountBank,
} from '../../store/users';
import FormAddBankAccount from './FormAddBankAccount';

const mapStateToProps = (state) => {
  return {
    newAccountBank: state.userState.newAccountBank,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getNewAccountBank: (data) => dispatch(getNewAccountBank(data)),
  getCleanNewAccountBank: () => dispatch(getCleanNewAccountBank()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAddBankAccount);
