import React from 'react';
import FormIdentityVerification from '../components/FormIdentityVerification';
const IdentityVerification = React.memo(({ desktop, history, user }) => {
  return (
    <>
      <FormIdentityVerification desktop={desktop} history={history} user={user} />
    </>
  );
});

export default IdentityVerification;
