import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  LinkBtn,
  WrapBtns,
  WrapOperations,
  InputOperations,
  HeadTitle,
  WrappHead,
  SelectElement,
  ItemInner,
  WrapFather,
  BoxText,
  SelectOperations,
  TextAviso,
} from './styles';
import {
  saveSingleDetailOperation,
  fetchDashboard,
  getProcessDashboard,
  getClearSingleOperation,
  getCleanProcessDashboard,
} from '../../../store/web/dashboard';

const DashboardOperation = ({
  activeButton,
  isActiveButton,
  dashboard,
  getStatusId,
  userDashboard,
  idClientCurrent,
  fetchDashboard,
  fetchSelectOperation,
  getProcessDashboard,
  selectOperation,
  saveSingleDetailOperation,
  processDashboard,
  getCleanProcessDashboard,
  getClearSingleOperation
}) => {
  useEffect(() => {
    fetchSelectOperation();
    if (!dashboard && userDashboard && userDashboard.status === 200) {
      const token = userDashboard.info.token;
      fetchDashboard('10', null, token);
    } else {
      getProcessDashboard(dashboard);
    }
  }, [
    fetchSelectOperation,
    fetchDashboard,
    getProcessDashboard,
    dashboard,
    userDashboard,
  ]);

  const datos = [
    {
      name: 'Archivado',
      id: 11,
    },
    {
      name: 'Verificando',
      id: 10,
    },
    // {
    //   name: 'Finalizado',
    //   id: 12,
    // },
    {
      name: 'Anulados',
      id: 13,
    },
    {
      name: 'No Facturado',
      id: 20,
    },
    {
      name: 'Facturado',
      id: 21,
    }, 
    {
      name: 'Registrado',
      id: 26,
    },
  ];

  const getIdOperation = (id, evt) => {
    if (id) {
      const operationId = processDashboard.filter((item) => item.id === id);
      saveSingleDetailOperation(operationId);
    }
    const elements = document.querySelectorAll('.elementOperation');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
    }
    evt.currentTarget.classList.add('active');
  };

  const isActive = (selected) => {
    const token2 = userDashboard.info.token;
    getClearSingleOperation();
    if (idClientCurrent) {
      fetchDashboard(selected, idClientCurrent, token2);
      isActiveButton(selected);

    } else {
      if (selected !== activeButton) {
        isActiveButton(selected);
        fetchDashboard(selected, null, token2);

      }
    }
  };

  const getNumberOperation = (e) => {
    if (e.target.value.trim() === '') {
      getProcessDashboard(dashboard);
      return;
    }
    const resultNumberOperation = dashboard.filter((item) =>
      item.code_operation.includes(e.target.value)
    );
    getProcessDashboard(resultNumberOperation);
  };

  const getBanksFilter = (e) => {
    const resultSelectOperation = dashboard.filter((item) =>
      item.origin_bank.name.includes(e.currentTarget.value)
    );
    getProcessDashboard(resultSelectOperation);
  };
  const getCurrencyFilter = (e) => {
    const resultSelectOperation = dashboard.filter((item) =>
      item.currency_sent.includes(e.currentTarget.value)
    );
    getProcessDashboard(resultSelectOperation);
  };

  function sortFunction(a, b) {
    var dateA = new Date(a.full_date).getTime();
    var dateB = new Date(b.full_date).getTime();
    return dateA > dateB ? 1 : -1;
  }

  const changeClass = (e) => {
    const val = e.target.value;
    const token3 = userDashboard.info.token;

    if (val === 'ultimos') {
      getCleanProcessDashboard();
      getProcessDashboard(processDashboard.sort(sortFunction));
    }
    if (val === 'recientes') {
      getCleanProcessDashboard();
      fetchDashboard('10', null, token3);
    }
  };

  return (
    <WrapOperations>
      <WrapBtns>
        {datos &&
          datos.map((item) => (
            <LinkBtn
              onClick={() => {
                isActive(item.id);
              }}
              key={item.id}
              className={item.id === activeButton ? 'active' : ''}
            >
              {item.name}
            </LinkBtn>
          ))}
      </WrapBtns>

      {dashboard && dashboard.length >= 1 ? (
        <>
          <InputOperations>
            <input
              onChange={(e) => {
                getNumberOperation(e);
              }}
              type='text'
              placeholder='Código de Operación'
              name='buscar'
            />
            <span>
              <img src='/static/image/searchimg.png' width='14' height='' alt='' />
            </span>
          </InputOperations>
          <div>
            <SelectOperations>
              <SelectElement>
                <select
                  onChange={(e) => {
                    getBanksFilter(e);
                  }}
                >
                  <option> Bancos </option>
                  {selectOperation &&
                    selectOperation.banks &&
                    selectOperation.banks.length &&
                    selectOperation.banks.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </select>
                <span></span>
              </SelectElement>
              <SelectElement>
                <select
                  onChange={(e) => {
                    getCurrencyFilter(e);
                  }}
                >
                  <option>Moneda</option>

                  {selectOperation &&
                    selectOperation.currency &&
                    selectOperation.currency.length &&
                    selectOperation.currency.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </select>
                <span></span>
              </SelectElement>
              <SelectElement>
                <select onChange={(e) => changeClass(e)}>
                  <option value=''>Ordenar:</option>
                  <option value='recientes'>Recientes</option>
                  <option value='ultimos'>Últimos</option>
                </select>
                <span></span>
              </SelectElement>
            </SelectOperations>
            <WrapFather>
              {processDashboard && (
                <>
                  {processDashboard.map((item, key) => (
                    <div
                      className='parent'
                      data-client={item.client_info.name}
                      key={item.id}
                      data-bank={item.origin_bank.name}
                    >
                      <WrappHead
                        className='elementOperation'
                        onClick={(e) => {
                          getIdOperation(item.id, e);
                        }}
                      >
                        <HeadTitle>
                          <span>Código</span>
                          <strong>{item.code_operation}</strong>
                        </HeadTitle>
                        <HeadTitle className='right'>
                          <span>Tipo de Cambio:</span>
                          <strong>{item.exchange_rate}</strong>
                        </HeadTitle>
                      </WrappHead>
                      <ItemInner>
                        <BoxText>
                          <p>Enviado:</p>
                          <p>
                            <strong>
                              {item.currency_sent === 'USD' ? '$' : 'S/'}{' '}
                              {item.amount_sent}
                            </strong>
                          </p>
                        </BoxText>
                        <BoxText>
                          <p>Recibido:</p>
                          <p>
                            <strong>
                              {item.currency_received === 'USD' ? '$' : 'S/'}{' '}
                              {item.amount_received}
                            </strong>
                          </p>
                        </BoxText>
                      </ItemInner>
                      <ItemInner>
                        <BoxText className='changeStyle'>
                          <p>
                            Banco: <strong>{item.origin_bank.name}</strong>
                          </p>
                          <p>
                            Fecha Registro: <strong>{item.date_registrado}</strong>
                          </p>
                          <p>
                            Tiempo: <strong>{item.timer}</strong>
                          </p>
                        </BoxText>
                        <BoxText className='changeStyle'>
                          <p>
                            Estado: <strong>{item.status}</strong>
                          </p>
                          <p>
                            Fecha Depósito:{' '}
                            <strong>
                              {item.date_verificando} 
                            </strong>
                          </p>
                        </BoxText>
                      </ItemInner>
                    </div>
                  ))}
                </>
              )}
              {processDashboard ? '' : <TextAviso>Operación No Encontrada</TextAviso>}
            </WrapFather>
          </div>
        </>
      ) : (
        <TextAviso>Operación No Encontrada</TextAviso>
      )}
    </WrapOperations>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.dashboardState.loading,
    dashboard: state.dashboardState.dashboard,
    processDashboard: state.dashboardState.processDashboard,
    userDashboard: state.userDashboardState.userDashboard,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCleanProcessDashboard: () => dispatch(getCleanProcessDashboard()),
  fetchDashboard: (id, status, token) => dispatch(fetchDashboard(id, status, token)),
  getProcessDashboard: (data) => dispatch(getProcessDashboard(data)),
  saveSingleDetailOperation: (data) => dispatch(saveSingleDetailOperation(data)),
  getClearSingleOperation: () => dispatch(getClearSingleOperation()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardOperation);
