import styled from 'styled-components/macro';

import { colors, fonts } from '../../../theme/web/theme';
export const Wancho = styled.div`
  max-width: 1024px;
  width: 86%;
  margin: auto;
`;

export const GetBackBox = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const GetBackButton = styled.button`
  display: flex;
  min-width: 150px;
  height: 35px;
  /* width: 100%; */
  padding: 4px 15px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  color: ${colors.secondary};
  font-family: ${fonts.lato700};
  line-height: 1;
  border-radius: 5px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    transform: rotateZ(-180deg);
    margin-right: 10px;
  }
  @media screen and (min-width: 1025px) {
    &:hover {
      box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
      color: ${colors.secondary};
    }
  }
  @media screen and (max-width: 480px) {
    min-width: 120px;
    font-size: 13px;
    height: 40px;
  }
`;

export const NewWrapper = styled.div`
  display: flex;
  padding-top: 30px;
  padding-bottom: 18px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const NewBoxLeft = styled.div`
  flex: 1;
  padding-right: 22px;
  @media screen and (max-width: 960px) {
    padding-right: 0;
    width: 100%;
  }
  .SocialNewDetail {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    padding-top: 30px;
    h4 {
      margin-left: 15px;
      font-weight: normal;
      font-family: ${fonts.lato400};
    }
  }
`;

export const NewDate = styled.ul`
  display: flex;
  justify-content: space-between;
  li {
    font-size: 13px;
    letter-spacing: -0.03em;
    font-family: ${fonts.lato400};
    &:nth-child(1) {
      color: ${colors.secondary};
    }
    &:nth-child(2) {
      color: ${colors.primary};
    }
  }
`;
export const NewTitle = styled.h2`
  font-size: 25px;
  letter-spacing: -0.03em;
  color: ${colors.secondary};
  font-family: ${fonts.lato800};
  width: 100%;
  padding-bottom: 10px;
  margin: 0;

  @media screen and (max-width: 960px) {
    font-size: 23px;
  }
  @media screen and (max-width: 480px) {
    line-height: 23px;
    margin-top: 10px;
  }
`;
export const NewBody = styled.div`
  padding-top: 12px;
  border-top: 1px solid rgba(58, 162, 41, 0.15);
  img {
    border-radius: 9px;
    box-shadow: inset 7px 4px 10px rgba(0, 0, 0, 0.15);
    display: block;
    max-width: 100%;
    height: auto;
  }
`;
export const NewText = styled.div`
  padding-top: 26px;
  font-size: 16px;
  p {
    font-family: ${fonts.lato400};
    margin-top: 0;
  }
`;
export const NewBoxRight = styled.div`
  width: 250px;
  .ItemHome {
    width: 100%;
    margin-left: 0;
  }
  .buttonNews {
    padding-top: 0;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const NewBoxRightBtn = styled.div`
  box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
  border-radius: 10px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  span {
    color: ${colors.secondary};
    font-family: ${fonts.lato800};
    font-size: 17px;
  }
`;
export const NewBoxCenter = styled.div`
  box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
  border-radius: 10px;
  background-color: #fff;
  padding: 18px 15px;
  .ItemHome {
    margin-bottom: 0;
    p {
      font-size: 14px;
    }
    ul {
      padding-top: 14px;
    }
  }
`;
