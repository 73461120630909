import {
  requestComoFunciona,
  receiveComoFunciona,
  requestTrust,
  receiveTrust,
  requestChangeDollar,
  receiveChangeDollar,
  requestOperationType,
  receiveOperationType,
  requestYourAlly,
  receiveYourAlly,
} from './actions';

import { routesApi } from '../../../routesApi';

export const fetchComoFunciona = () => {
  return (dispatch) => {
    dispatch(requestComoFunciona());
    return fetch(`${routesApi.urlApiAcf}pages/99`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveComoFunciona(json.acf));
      });
  };
};

export const fetchTrust = () => {
  return (dispatch) => {
    dispatch(requestTrust());
    return fetch(`${routesApi.urlApiAcf}pages/238`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveTrust(json.acf));
      });
  };
};

export const fetchOperationType = () => {
  return (dispatch) => {
    dispatch(requestOperationType);
    return fetch(routesApi.operationType)
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveOperationType(json.acf));
      });
  };
};

export const fetchYourAlly = () => {
  return (dispatch) => {
    dispatch(requestYourAlly());
    return fetch(`${routesApi.urlApiAcf}pages/305`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveYourAlly(json.acf));
      });
  };
};

export const fetchChangeDollar = () => {
  return (dispatch) => {
    dispatch(requestChangeDollar());
    return fetch(`${routesApi.urlApiAcf}pages/336`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveChangeDollar(json.acf));
      });
  };
};
