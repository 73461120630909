const amountFormat = (amount, maxCentsCount = 3, forceCents = false) => {
  const amountNumber = Number(amount);
  if (Number.isNaN(amountNumber)) return amount;
  if (amountNumber !== (amountNumber | 0)) {
    const cents = amountNumber.toString().split('.')[1];
    if (forceCents) return amountNumber.toFixed(maxCentsCount);
    if (cents.length < 2) return amountNumber.toFixed(2);
    if (cents.length > maxCentsCount) return amountNumber.toFixed(maxCentsCount);
    return amountNumber.toString();
  }
  return amountNumber.toString();
};

export default amountFormat;
