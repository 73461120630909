import { connect } from 'react-redux';
import { getCodeSecurity, getCheckEmail } from '../../store/users';

import FormPasswordRecovery from './FormPasswordRecovery';

const mapStateToProps = (state) => {
  return {
    codeSecurity: state.userState.codeSecurity,
    statusCheckEmail: state.userState.statusCheckEmail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCheckEmail: (data) => dispatch(getCheckEmail(data)),
  getCodeSecurity: (data) => dispatch(getCodeSecurity(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPasswordRecovery);
