import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const HeaderCnt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
  @media screen and (max-width: 768px) {
    padding-left: 84px;
  }
`;

export const HeaderLogo = styled(Link)`
  display: flex;
  width: 228px;
  margin: 0;
  img {
    display: block;
    max-width: 100%;
    height: auto;
    &:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    width: 74px;
    margin: 0 auto;
    img {
      &:nth-child(1) {
        display: none;
      }
      &.mobil {
        display: block;
        width: 75px;
        height: 56px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    margin-top: 30px;
  }
`;

export const HeaderWrappOpen = styled.div`
  box-shadow: 0px 0px 15px rgba(58, 162, 41, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  margin: 40px 0;
  border-radius: 5px 0 0 5px;
  padding-right: 84px;
`;
export const HeaderOpenText = styled(Link)`
  height: 50px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family);
  transition: all 0.3s;
  letter-spacing: -0.3px;
  strong {
    font-weight: normal;
    font-family: var(--ion-font-family-quinary);
  }
  @media screen and (min-width: 1025px) {
    &:hover {
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const HeaderOpen = styled.a`
  width: 84px;
  height: 50px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  box-shadow: 0px 0px 15px rgba(58, 162, 41, 0.15);
  padding-right: 20px;
  background-color: #fff;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  top: 40px;
  z-index: 1000;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    top: 25px;
  }
  .icon-menu {
    font-size: 18px;
    color: #3aa229;
    position: relative;
    &:before {
      display: inline-block;
      transition: 250ms all;
    }
    &:after {
      content: '\\e90c';
      font-family: 'icomoon';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      transition: 250ms all;
      display: flex;
      transform: rotateZ(90deg) scale(0.8);
      opacity: 0;
      font-size: 22px;
    }
  }
  &.active {
    .icon-menu {
      &:before {
        transform: rotateZ(180deg) scale(0.8);
        opacity: 0;
      }
      &:after {
        transform: rotateZ(0deg) scale(1);
        opacity: 1;
      }
    }
  }
`;

export const HeaderSideBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(58, 162, 41, 0.15);
  border-radius: 35px 0 0 35px;
  visibility: hidden;
  right: -250px;
  transition: 350ms all;
  z-index: 999;
  &.active {
    visibility: visible;
    right: 0px;
  }
  .Close {
    right: 0;
    position: absolute;
    top: 40px;
    margin: 0;
  }
`;
export const HeaderMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  box-sizing: border-box;
`;
export const HeaderName = styled.div`
  padding-top: 66px;
  padding-left: 36px;
  padding-bottom: 23px;
  padding-right: 90px;
  span {
    display: block;
    font-size: 13px;
    font-family: var(--ion-font-family);
    color: var(--ion-color-primary);
  }
  h2 {
    margin-top: 2px;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: var(--ion-font-family-quinary);
    color: var(--ion-color-secondary);
    line-height: 1;
  }
`;
export const HeaderPerfil = styled.div`
  color: var(--ion-color-primary);
  cursor: pointer;
  font-size: 11px;
  font-family: var(--ion-font-family-quinary);
  display: inline-block;
  line-height: 1;
  @media screen and (min-width: 1025px) {
    &:hover,
    &.active {
      color: var(--ion-color-secondary);
    }
    &.active {
      em {
        display: none;
      }
    }
  }
  em {
    font-style: normal;
  }
  &:nth-child(1) {
    padding-right: 8px;
    border-right: 1px solid rgba(113, 113, 113, 0.22);
  }
  &:nth-child(2) {
    padding-left: 8px;
  }
`;

export const HeaderIniciar = styled.a`
  font-size: 16px;
  font-family: var(--ion-font-family-quinary);
  line-height: 1;
  width: 200px;
  height: 50px;
  color: #fff;
  background-color: var(--ion-color-secondary);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 3px 35px rgba(58, 162, 41, 0.2);
  margin: 0 auto;
  margin-top: auto;
`;
export const WrapNavLists = styled.div`
  position: relative;
`;
export const NavList = styled.ul`
  margin-top: 0px;
  display: none;
  &.active {
    display: block;
  }
`;
export const MenuItem = styled.li`
  margin: 0;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  &:first-child {
    border-top: 1px solid #eaeaea;
  }
  .MenuLink {
    padding-left: 40px;
    color: var(--ion-color-primary);
    font-size: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: 6px solid transparent;
    &:hover,
    &.active {
      color: var(--ion-color-secondary);
      font-family: var(--ion-font-family-quinary);
      border-left-color: var(--ion-color-secondary);
    }
  }
`;
export const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  ${HeaderLogo} {
    position: absolute;
    top: 41px;
    left: 30px;
    @media screen and (max-width: 768px) {
      left: 0;
      right: 0;
      top: 30px;
    }
    @media screen and (max-width: 480px) {
      margin-top: 0px;
    }
  }
  ${HeaderWrappOpen} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
