import { connect } from 'react-redux';
import { getNewPassword } from '../../store/users';

import FormNewPassword from './FormNewPassword';

const mapStateToProps = (state) => {
  return {
    newPassword: state.userState.newPassword,
    codeSecurity: state.userState.codeSecurity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewPassword: (data) => dispatch(getNewPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormNewPassword);
