import React, { useImperativeHandle, useState, useCallback } from 'react';
import { Button } from '../../theme/global';
import { IonBackdrop } from '@ionic/react';
import BoxShadow from '../BoxShadow';
import styled from 'styled-components/macro';

const Tlb = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  ${BoxShadow} {
    width: 300px;
    min-height: 50px;
    padding: 50px 30px;
    border-radius: 20px;
  }
  ${Button} {
    &:not(.redirect) {
      background-color: #fff;
      width: 100px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 50px);
      color: var(--ion-color-secondary);
    }
  }
`;
const ButtonContainer = styled.div`
  background: black;
  position: relative;
  width: 100%;
  flex-shrink: 0;
`;
const Wrap = styled.div`
  .check {
    display: none;
  }
  &.profile-data {
    .btn-popup {
      background-color: var(--ion-color-secondary);
    }
    .check {
      display: block;
    }
    .close {
      display: none;
    }
  }
`;
const PopUp = ({ onClose, children, className }, ref) => {
  const [visible, setVisible] = useState(false);

  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => {
    setVisible(false);
    if (onClose) onClose();
  }, [onClose]);

  useImperativeHandle(ref, () => ({ open, close }), [close, open]);

  if (!visible) return null;

  return (
    <Wrap className={className || ''}>
      <IonBackdrop
        visible={visible}
        ionBackdropTap={() => setVisible(false)}
        dataType='custom-pop-up'
      />
      <Tlb visible={visible}>
        <BoxShadow>{children}</BoxShadow>
        <ButtonContainer>
          <Button className='btn-popup' onClick={close}>
            <img className='close' src='/assets/images/icon-x.svg' alt='' />
            <img className='check' src='/assets/images/check.svg' alt='' />
          </Button>
        </ButtonContainer>
      </Tlb>
    </Wrap>
  );
};

export default React.memo(React.forwardRef(PopUp));
