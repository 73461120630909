import React, { useCallback, useEffect, useRef } from 'react';
import {
  CntButton,
  Button,
  WrapError,
  TextError,
  ButtonVolverWrapper,
  ButtonVolver,
} from '../../theme/global';
import { TabsContainer, TabItem } from '../NavTabs';
import { Teeny, Typography, Strong, Title } from '../Text';
import UploadImageField, { ImagePreview, WrapPreview } from '../InputImage';
import CameraBox from '../CameraBox';
import { withFormik, Form, ErrorMessage } from 'formik';
import { Plugins, CameraResultType } from '@capacitor/core';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import PopUp from '../Popup';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';
import { Base64 } from 'js-base64';
const { Camera } = Plugins;

const FormIdentityVerification = React.memo(
  ({
    desktop,
    processRegister,
    cleanImageDniFront,
    cleanImageDniBack,
    newAccount,
    getCreateAccount,
    history,
    fetchUser,
    infoCurrentUser,
    getInforCurrentUser,
    urlImageDni,
    urlImageDniBack,
    values,
    setFieldValue,
    user,
    ...p
  }) => {
    const isFront = !values.dniFront;
    const onImageUpload = useCallback(
      async (field) => {
        try {
          const { webPath, path } = await Camera.getPhoto({
            quality: 60,
            targetWidth: 600,
            targetHeight: 300,
            saveToPhotoAlbum: false,
            allowEdit: false,
            sourceType: 1,
            resultType: CameraResultType.Uri,
          });
          setFieldValue(field, { src: webPath, path });
        } catch (e) {
          console.error(e);
        }
      },
      [setFieldValue]
    );
    const popupRef = useRef(null);
    useEffect(() => {
      if (!processRegister) {
        history.push('/simulador');
        return;
      }
      if (
        urlImageDni &&
        urlImageDni.status === 200 &&
        urlImageDniBack &&
        urlImageDniBack.status === 200
      ) {
        const dataUser = {
          imagen_dni_adelante: urlImageDni.url,
          imagen_dni_atras: urlImageDniBack.url,
          ...processRegister,
        };
        getCreateAccount(dataUser);
        cleanImageDniFront();
        cleanImageDniBack();
      }
      if (newAccount && newAccount.status === 200) {
        popupRef.current.open();
      }
      if (user && user.status === 200 && !infoCurrentUser) {
        const dataCurrentUser = {
          id: user.info.user_id,
          token: user.info.token,
        };
        getInforCurrentUser(dataCurrentUser);
        return;
      }
      if (
        infoCurrentUser &&
        infoCurrentUser.status === 200 &&
        infoCurrentUser.info.role[0] === 'cliente'
      ) {
        const dataCurrent = JSON.stringify(user);
        sessionStorage.currentUser = Base64.encode(dataCurrent);
        history.push('/select-profile');
      }
    }, [
      urlImageDni,
      urlImageDniBack,
      cleanImageDniFront,
      cleanImageDniBack,
      getCreateAccount,
      newAccount,
      processRegister,
      history,
      user,
      infoCurrentUser,
      getInforCurrentUser,
    ]);
    return (
      <>
        <WrapPage className={`${desktop || ''} edit-identity-verification`}>
          {processRegister && (
            <Form>
              <div className='inner-page'>
                <TabsContainer>
                  <ButtonVolverWrapper className='register'>
                    <ButtonVolver onClick={() => history.push('/profile-data')}>
                      Volver
                    </ButtonVolver>
                  </ButtonVolverWrapper>
                  <TabItem className='strong'>
                    Paso <Teeny color='primary'>01</Teeny>
                  </TabItem>
                  <TabItem className='strong active'>
                    Paso <Teeny color='primary'>02</Teeny>
                  </TabItem>
                </TabsContainer>
                <div>
                  <Title className='title-edit-identity'>
                    Verificación de Identidad <br />
                  </Title>
                  <div style={{ maxWidth: 280, margin: '0 auto' }}>
                    <Typography
                      className='texto-intro'
                      color='primary'
                      size='13px'
                      center
                    >
                      Se requiere una Foto ó adjunta una imagen:{' '}
                      <Strong secondary>Adelante y Atrás</Strong> del{' '}
                      <Strong secondary>DNI o Pasaporte</Strong>, para la validación de la
                      identidad según la{' '}
                      <Strong secondary>
                        normativa de la Superintendencia de Banca, Seguros y AFP (SBS).
                      </Strong>
                    </Typography>
                  </div>
                  <main
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <CameraBox
                      className='camera-box'
                      onClick={() => onImageUpload(isFront ? 'dniFront' : 'dniBack')}
                    />
                    <Typography
                      color='primary'
                      center
                      size='10px'
                      style={{ marginTop: 5 }}
                    >
                      DNI <Strong secondary>{isFront ? 'Adelante' : 'Atrás'}</Strong>
                    </Typography>
                    <Typography
                      color='primary'
                      center
                      size='12px'
                      style={{ marginTop: 16, maxWidth: 260, marginBottom: 10 }}
                    >
                      *La foto no debe ser borrosa y debe mostrarse claramente todo el
                      documento
                    </Typography>
                    <WrapPreview>
                      <ImagePreview name='dniFront' />
                      <ImagePreview name='dniBack' />
                    </WrapPreview>
                    <ErrorMessage name='dniFront'>
                      {(message) => (
                        <WrapError>
                          <TextError>{message}</TextError>
                        </WrapError>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name='dniBack'>
                      {(message) => (
                        <WrapError>
                          <TextError>{message}</TextError>
                        </WrapError>
                      )}
                    </ErrorMessage>
                    <UploadImageField
                      className='upload-image'
                      onClick={() => onImageUpload('dniFront')}
                    >
                      <Typography color='primary' size='15px' font='quaternary'>
                        Imagen DNI <Strong secondary>Adelante*</Strong>
                      </Typography>
                    </UploadImageField>

                    <UploadImageField
                      className='upload-image'
                      onClick={() => onImageUpload('dniBack')}
                    >
                      <Typography color='primary' size='15px' font='quaternary'>
                        Imagen DNI <Strong secondary>Atrás*</Strong>
                      </Typography>
                    </UploadImageField>

                    <Typography
                      color='primary'
                      center
                      size='13px'
                      font='quaternary'
                      className='required-text'
                    >
                      *Todos los <Strong secondary>Campos son Obligatorios</Strong>
                    </Typography>
                  </main>
                </div>
                <CntButton center style={{ width: '100%', bottom: 0, zIndex: 999 }}>
                  <Button type='submit' round>
                    Continuar
                  </Button>
                </CntButton>
              </div>
            </Form>
          )}

          <PopUp
            ref={popupRef}
            onClose={() => {
              const infoLogin = {
                username: processRegister.email,
                password: processRegister.password,
              };
              fetchUser(infoLogin);
              // history.push('/accounts/new-bank-account');
            }}
          >
            <Alert
              icon='success'
              title='Cuenta creada con exito'
              subtitle='bievenido a Sr. Cambio'
              description='Por favor complete su registro agregando cuentas bancarias'
            />
          </PopUp>
        </WrapPage>
      </>
    );
  }
);

const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      dniFront: null,
      dniBack: null,
      imagenFile: '',
    }),
    handleSubmit(
      values,
      { setSubmitting, props: { getUrlImageDni, getUrlImageDniBack } }
    ) {
      setSubmitting(false);

      async function getObjetBlob(data, type) {
        let getBlob = await fetch(data).then((r) => r.blob());
        let reader = new FileReader();
        reader.readAsDataURL(getBlob);
        reader.onloadend = function () {
          let base64data = reader.result;
          let arr = base64data.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          let fileEnd = new File([u8arr], 'imagen', { type: mime });

          let formData = new FormData();
          formData.append('file', fileEnd);

          const dataUser = {
            file: formData,
          };
          if (type === 'front') {
            getUrlImageDni(dataUser);
          }
          if (type === 'back') {
            getUrlImageDniBack(dataUser);
          }
        };
      }
      getObjetBlob(values.dniFront.src, 'front');
      getObjetBlob(values.dniBack.src, 'back');
    },
    validate(values) {
      const errors = {};
      if (!values.dniFront) {
        errors.dniFront = 'Campo Obligatorio';
      }
      if (!values.dniBack) {
        errors.dniBack = 'Campo Obligatorio';
      }

      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);

export default enhacer(FormIdentityVerification);
