import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { fonts, colors } from '../../../theme/web/theme';

export const Wancho = styled.div`
  max-width: 1024px;
  width: 90%;
  margin: auto;
  position: relative;
`;
export const OpenCategoriesList = styled.div`
  display: none;
  min-width: 162px;
  height: 40px;
  width: 100%;
  padding: 4px 15px;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  font-size: 14px;
  color: rgba(113, 113, 113, 0.72);
  font-family: ${fonts.lato700};
  line-height: 1;
  border-radius: 5px;
  box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 22px;
  text-transform: capitalize;
  @media screen and (max-width: 960px) {
    display: flex;
    padding-left: 25px;
  }
  &:before,
  &:after {
    content: '';
    width: 0;
    height: 0;
  }
  &:after {
    content: '\\e90d';
    font-family: 'icomoon';
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.secondary};
    font-size: 10px;
    box-shadow: 0px 3px 15px rgba(58, 162, 41, 0.15);
  }
  &:before {
    border-left: 7px solid #f0ad4e;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    bottom: 0;
  }
`;
export const WrappList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow:row wrap;
  padding-bottom: 22px;
  @media screen and (max-width: 960px) {
    position absolute;
    left:0;
    right: 0;
    margin auto;
    top: 40px;
    flex-direction:column;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
    border : 1px solid rgba(0,0,0,0.1) ;
    border-radius: 0px 0px 10px 10px;
    transform: scale(0.95);
    max-height: 300px;
    visibility:hidden;
    width: 100%;
    max-height: 280px;
    overflow:auto;
    transition: 160ms all;
    background-color:#fff;
    opacity: 0;
    padding: 0;
    li{
      border-bottom: 1px solid rgba(216,216,216,0.26);
      &:last-child{
        border:none;
      }
    }
    &.active{
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      li{
        opacity: 1;
      }
    }
  }
`;

export const ListLink = styled(Link)`
  display: flex;
  min-width: 162px;
  height: 35px;
  width: 100%;
  padding: 4px 15px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: rgba(113, 113, 113, 0.72);
  font-family: ${fonts.lato700};
  line-height: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (max-width: 480px) {
    padding-left: 25px;
  }
  @media screen and (min-width: 960px) {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    background-color: #fff;
    &:hover {
      box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
      color: ${colors.secondary};
    }
    &.active {
      box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
      color: ${colors.secondary};
      &:before {
        opacity: 1;
      }
    }
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #f0ad4e;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    opacity: 0;
  }
  @media screen and (max-width: 960px) {
    justify-content: flex-start;
    height: 45px;
  }
`;

export const ListItem = styled.li`
  @media screen and (min-width: 960px) {
    margin: 0 4px;
    margin-bottom: 10px;
    /* &:nth-child(6n + 1) {
      margin-left: 0;
    } */
    &.active {
      ${ListLink} {
        box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
        color: ${colors.secondary};
        &:before {
          opacity: 1;
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    &.active {
      ${ListLink} {
        color: ${colors.secondary};
      }
    }
  }
  @media screen and (min-width: 1025px) {
    &:hover {
      ${ListLink} {
        box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
        color: ${colors.secondary};
      }
    }
  }
`;
