import { connect } from 'react-redux';
import { getEditInfoPersonal, getInforCurrentUser, getAccountsUser, getCleanEditInfoUser } from '../../store/users';

import FormEditProfileData from './FormEditProfileData';
import { getUbigeoProvincias, getCleanUbigeoProvincias } from '../../store/info';

const mapStateToProps = (state) => {
  return {
    editInfoPersonal: state.userState.editInfoPersonal,
    infoCurrentUser: state.userState.infoCurrentUser,
    ubigeoDepartamentos: state.infoState.ubigeoDepartamentos,
    ubigeoProvincias: state.infoState.ubigeoProvincias,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInforCurrentUser: (id) => dispatch(getInforCurrentUser(id)),
  getEditInfoPersonal: (data) => dispatch(getEditInfoPersonal(data)),
  getUbigeoProvincias: (data) => dispatch(getUbigeoProvincias(data)),
  getAccountsUser: (id) => dispatch(getAccountsUser(id)),
  getCleanEditInfoUser: () => dispatch(getCleanEditInfoUser()),
  getCleanUbigeoProvincias: () => dispatch(getCleanUbigeoProvincias()),

});

export default connect(mapStateToProps, mapDispatchToProps)(FormEditProfileData);
