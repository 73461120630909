import styled from 'styled-components/macro';
import { colors, fonts } from '../../../theme/web/theme';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .MuiInputBase-root {
    width: 100%;
    height: 50px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 0;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: ${colors.primary};
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  .MuiInputBase-input {
    height: 100%;
    color: ${colors.primary};
    font-family: ${fonts.lato400};
    font-size: 14px;
    text-align: center;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 0 20px;
    ::-webkit-input-placeholder {
      color: ${colors.primary};
      font-family: ${fonts.lato400};
      opacity: 1 !important;
    }
  }
  .MuiInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
`;

export const Container = styled.div`
  max-width: 450px;
  width: 94%;
  border-radius: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 66px 75px 44px 75px;
  margin: 0 auto;
`;

export const LogoForm = styled.figure`
  width: 76px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const TitleForm = styled.h2`
  line-height: 1;
  color: ${colors.primary};
  font-family: ${fonts.lato400};
  font-weight: normal;
  text-align: center;
  font-size: 23px;
  margin: 24px 0 13px 0;
`;

export const FormInner = styled.div`
  margin-bottom: 21px;
`;

export const InputBox = styled.div`
  position: relative;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
    .MuiInputBase-input {
      padding: 0 50px;
    }
  }
`;

export const WrapLink = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const LinkRecover = styled.div`
  max-width: 210px;
  line-height: 1;
  letter-spacing: -0.03em;
  color: ${colors.primary};
  font-family: ${fonts.lato700};
  font-size: 14px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 29px;
  strong {
    color: ${colors.secondary};
    font-weight: normal;
  }
`;

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: ${colors.secondary};
    color: #fff;
    font-family: ${fonts.lato800};
    font-size: 16px;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 3px 35px rgba(58, 162, 41, 0.2);
    padding: 0 67px;
    margin: 0;
  }
`;
