import React from 'react';
import NosotrosTemplate from '../../components/web/Nosotros';
import { WrapPage } from '../../theme/shared';
const Nosotros = ({ desktop }) => {
  return (
    <WrapPage className={`${desktop || ''} web-wrap`}>
      <NosotrosTemplate />
    </WrapPage>
  );
};

export default Nosotros;
