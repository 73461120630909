const UrlFont = '/assets/fonts/';

const Fonts = `
    @font-face {
        font-family: 'Lato-Regular';
        src: url('${UrlFont}Lato-Regular.eot');
        src: url('${UrlFont}Lato-Regular.eot?#iefix') format('embedded-opentype'),
            url('${UrlFont}Lato-Regular.svg#Lato-Regular') format('svg'),
            url('${UrlFont}Lato-Regular.ttf') format('truetype'),
            url('${UrlFont}Lato-Regular.woff') format('woff'),
            url('${UrlFont}Lato-Regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Lato-Thin';
        src: url('${UrlFont}Lato-Thin.svg#Lato-Thin') format('svg'),
          url('${UrlFont}Lato-Thin.ttf') format('truetype'),
          url('${UrlFont}Lato-Thin.woff') format('woff'), url('${UrlFont}Lato-Thin.eot'),
          url('${UrlFont}Lato-Thin.eot?#iefix') format('embedded-opentype'),
          url('${UrlFont}Lato-Thin.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Lato-Light';
        src: url('${UrlFont}Lato-Light.eot');
        src: url('${UrlFont}Lato-Light.eot?#iefix') format('embedded-opentype'),
             url('${UrlFont}Lato-Light.svg#Lato-Light') format('svg'),
             url('${UrlFont}Lato-Light.ttf') format('truetype'),
             url('${UrlFont}Lato-Light.woff') format('woff'),
             url('${UrlFont}Lato-Light.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }        
    @font-face {
        font-family: 'Lato-Black';
        src: url('${UrlFont}Lato-Black.eot');
        src: url('${UrlFont}Lato-Black.eot?#iefix') format('embedded-opentype'),
             url('${UrlFont}Lato-Black.svg#Lato-Black') format('svg'),
             url('${UrlFont}Lato-Black.ttf') format('truetype'),
             url('${UrlFont}Lato-Black.woff') format('woff'),
             url('${UrlFont}Lato-Black.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
      font-family: 'Lato-ExtraBold';
      src: url('${UrlFont}Lato-ExtraBold.eot');
      src: url('${UrlFont}Lato-ExtraBold.eot?#iefix') format('embedded-opentype'),
           url('${UrlFont}Lato-ExtraBold.woff2') format('woff2'),
           url('${UrlFont}Lato-ExtraBold.svg#Lato-ExtraBold') format('svg'),
           url('${UrlFont}Lato-ExtraBold.ttf') format('truetype'),
           url('${UrlFont}Lato-ExtraBold.woff') format('woff');
      font-weight: normal;
      font-style: normal;
    }    
    @font-face {
        font-family: 'Lato-Bold';
        src: url('${UrlFont}Lato-Bold.eot');
        src: url('${UrlFont}Lato-Bold.eot?#iefix') format('embedded-opentype'),
            url('${UrlFont}Lato-Bold.svg#Lato-Bold') format('svg'),
            url('${UrlFont}Lato-Bold.ttf') format('truetype'),
            url('${UrlFont}Lato-Bold.woff') format('woff'),
            url('${UrlFont}Lato-Bold.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }    
    @font-face {
        font-family: 'Lato-Semibold';
        src: url('${UrlFont}Lato-Semibold.svg#Lato-Semibold') format('svg'),
             url('${UrlFont}Lato-Semibold.ttf') format('truetype'),
             url('${UrlFont}Lato-Semibold.woff') format('woff'),
             url('${UrlFont}LatoSemibold.eot'),
             url('${UrlFont}LatoSemibold.eot?#iefix') format('embedded-opentype'),
             url('${UrlFont}LatoSemibold.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }    

      


    @font-face {
        font-family: 'icomoon';
        src:  url('${UrlFont}icomoon.eot?vhryx6');
        src:  url('${UrlFont}icomoon.eot?vhryx6#iefix') format('embedded-opentype'),
            url('${UrlFont}icomoon.ttf?vhryx6') format('truetype'),
            url('${UrlFont}icomoon.woff?vhryx6') format('woff'),
            url('${UrlFont}icomoon.svg?vhryx6#icomoon') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    [class^="icon-"], [class*=" icon-"] {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .icon-linked-in:before {
      content: "\\e910";
    }
    .icon-arrow-r:before {
      content: "\\e911";
    }    
    .icon-arrow-right:before {
        content: "\\e903";
      }
      .icon-car:before {
        content: "\\e905";
      }
      .icon-heart:before {
        content: "\\e906";
      }
      .icon-point:before {
        content: "\\e907";
      }
      .icon-search:before {
        content: "\\e908";
      }
      .icon-arrow-thin-right:before {
        content: "\\e901";
      }
      .icon-facebook:before {
        content: "\\e902";
      }
      .icon-instagram:before {
        content: "\\e900";
      }
      .icon-social-twitter:before {
        content: "\\e604";
      }
      .icon-close:before {
        content: "\\e90c";
      }
      .icon-menu:before {
        content: "\\e90d";
      }
      .icon-error_outline:before {
        content: "\\e904";
      }      
      .icon-play:before {
        content: "\\e909";
      }
      .icon-fb:before {
        content: "\\e90a";
      }
      .icon-instagram1:before {
        content: "\\e90b";
      }    
      .icon-check:before {
        content: "\\e90e";
      }
      .icon-arrow-select:before {
        content: "\\e90f";
      }              
`;
export default Fonts;
