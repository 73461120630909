import {
  REQUEST_DASHBOARD,
  RECEIVE_DASHBOARD,
  REQUEST_SEARCH_CLIENTS,
  RECEIVE_SEARCH_CLIENTS,
  SET_ACTIVE_BUTTON,
  REQUEST_SELECT_OPERATION,
  RECEIVE_SELECT_OPERATION,
  NEW_AMOUNT_RECEIVE,
  RECEIVE_EDIT_OPERATION,
  SINGLE_DETAIL_OPERATION,
  CLEAR_SINGLE_OPERATION,
  PROCESS_DASHBOARD,
  CLEAN_DASHBOAD,
  RECEIVE_COMPLETED_OPERATION,
  RECEIVE_CANCELED_OPERATION,
  RECEIVE_ARCHIVE_OPERATION,
  CLEAN_PROCESS_DASHBOARD,
  RECEIVE_INVOICE,
  CLEAN_RECEIVE_INVOICE,
} from './types';

export const requestDashboard = () => ({
  type: REQUEST_DASHBOARD,
});

export const receiveDashboard = (dataDashboard) => ({
  type: RECEIVE_DASHBOARD,
  dashboard: dataDashboard,
});

export const setActiveButton = (id) => ({
  type: SET_ACTIVE_BUTTON,
  setActiveButton: id,
});

export const requestSearchClients = () => ({
  type: REQUEST_SEARCH_CLIENTS,
});

export const receiveSearchClients = (searchClients) => ({
  type: RECEIVE_SEARCH_CLIENTS,
  searchClients: searchClients,
});

export const requestSelectOperation = () => ({
  type: REQUEST_SELECT_OPERATION,
});

export const receiveSelectOperation = (data) => ({
  type: RECEIVE_SELECT_OPERATION,
  selectOperation: data,
});

export const newAmountReceive = (data) => ({
  type: NEW_AMOUNT_RECEIVE,
  newAmountReceive: data,
});

export const newEditOperation = (edit) => ({
  type: RECEIVE_EDIT_OPERATION,
  newEditOperation: edit,
});

export const singleDetailOperation = (data) => ({
  type: SINGLE_DETAIL_OPERATION,
  singleDetailOperation: data,
});

export const clearSingleOperation = () => ({
  type: CLEAR_SINGLE_OPERATION,
});

export const cleanDashboard = () => ({
  type: CLEAN_DASHBOAD,
});

export const processDashboard = (data) => ({
  type: PROCESS_DASHBOARD,
  processDashboard: data,
});

export const receiveCompletedOperation = (data) => ({
  type: RECEIVE_COMPLETED_OPERATION,
  completedOperation: data,
});

export const receiveCanceledOperation = (data) => ({
  type: RECEIVE_CANCELED_OPERATION,
  canceledOperation: data,
});

export const receiveArchiveOperation = (data) => ({
  type: RECEIVE_ARCHIVE_OPERATION,
  archiveOperation: data,
});

export const receiveInvoice = (data) => ({
  type: RECEIVE_INVOICE,
  receiveInvoice: data,
});

export const cleanProcessDashboard = () => ({
  type: CLEAN_PROCESS_DASHBOARD,
});
export const cleanReceiveInvoice = () => ({
  type: CLEAN_RECEIVE_INVOICE,
});
