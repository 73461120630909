import React, { useEffect, useRef } from 'react';
import {
  CntButton,
  Button,
  WrapError,
  TextError,
  WrapSelectItem,
  SelectItem,
} from '../../theme/global';
import { Typography, Strong, Title } from '../Text';
import Input, { Container } from '../InputRounded';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import TabProfile from '../TabProfile';
import PopUp from '../Popup';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';
const FormEditAddress = ({
  processUpdateCompany,
  getAccountsUser,
  accountsUser,
  getCleanUbigeoProvincias,
  user,
  desktop,
  setFieldValue,
  editInfoCompany,
  ubigeoDepartamentos,
  getUbigeoProvincias,
  ubigeoProvincias,
  getCleanEditInfoCompany,
  history,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    getCleanUbigeoProvincias();
    if (
      accountsUser &&
      accountsUser.status === 200 && accountsUser.single_info.departamento_company &&
      ubigeoDepartamentos
    ) {
      let ubigeoData = accountsUser.single_info.departamento_company;
      let ubigeo = ubigeoData.split('-');
      getUbigeoProvincias(ubigeoDepartamentos.provincias[ubigeo[0]]);
    }

    if (!processUpdateCompany) {
      history.push('/edit-profile-company-paso-1');
    }
    if (editInfoCompany && editInfoCompany.status === 200) {
      popupRef.current.open();
    }
  }, [
    getAccountsUser,
    editInfoCompany,
    processUpdateCompany,
    history,
    user,
    accountsUser,
    getUbigeoProvincias,
    ubigeoDepartamentos,
  ]);

  return (
    <>
      <WrapPage className={`${desktop || ''} edit-profile-company step2`}>
        <Form>
          <div className='inner-page'>
            <TabProfile />
            <div>
              <Title className='title-edit-company'>Domicilio Fiscal</Title>
              <Container>
                {ubigeoDepartamentos && (
                  <>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field
                          className='select-departamento'
                          component='select'
                          name='departamento_company'
                          onChange={(e) => {
                            let current_value = e.currentTarget.value;

                            setFieldValue('departamento_company', current_value)
                            const getProvinciaCurrent = () => {
                              let ubigeoData = current_value;
                              let ubigeo = ubigeoData.split('-');
                              getUbigeoProvincias(ubigeoDepartamentos.provincias[ubigeo[0]]);

                            };
                            getProvinciaCurrent();
                          }
                          }
                        >
                          <option value=''>Departamento</option>
                          {ubigeoDepartamentos.departamentos.map((item, key) => {

                            return (
                              <option
                                key={key}
                                data-ubigeo={item.id_ubigeo}
                                value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                              >
                                {item.nombre_ubigeo}
                              </option>
                            )
                          })}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='departamento_company'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field component='select' name='provincia_company'
                          onChange={(e) => {
                            let current_value_prov = e.currentTarget.value;
                            setFieldValue('provincia_company', current_value_prov)
                          }
                          }

                        >
                          <option value=''>Provincia*</option>
                          {ubigeoProvincias && (
                            <>
                              {ubigeoProvincias.map((item, key) => (
                                <option
                                  key={key}
                                  data-ubigeo={item.id_ubigeo}
                                  value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                                >
                                  {item.nombre_ubigeo}
                                </option>
                              ))}
                            </>
                          )}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='provincia_company'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                    <Input
                      className='input-text'
                      name='distrito_company'
                      placeholder='Distrito'
                    />
                    <ErrorMessage name='distrito_company'>
                      {(message) => (
                        <WrapError>
                          <TextError>{message}</TextError>
                        </WrapError>
                      )}
                    </ErrorMessage>

                  </>


                )}

                <Input
                  className='input-text'
                  name='direccion_company'
                  placeholder='Dirección'
                />
                <ErrorMessage name='direccion_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
              </Container>

              <Typography
                color='primary'
                center
                size='13px'
                font='quaternary'
                style={{ marginTop: 8 }}
              >
                *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
              </Typography>
            </div>
            <br />
            <CntButton
              center
              slot='fixed'
              style={{ width: '100%', bottom: 0, zIndex: 999 }}
            >
              <Button round>Continuar</Button>
            </CntButton>
          </div>
        </Form>
        <PopUp
          ref={popupRef}
          onClose={() => {
            getCleanEditInfoCompany();
            const dataCurrentUser = {
              id: user.info.user_id,
              token: user.info.token,
            };
            getAccountsUser(dataCurrentUser);
            history.push('/edit-profile-company-paso-1');
          }}
        >
          <Alert icon='success' title='Datos actualizados con exito' />
        </PopUp>
      </WrapPage>
    </>
  );
};

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ accountsUser }) {
    const values = {};
    if (accountsUser && accountsUser.status === 200) {
      const info = accountsUser.single_info;
      values.departamento_company = info.departamento_company
        ? info.departamento_company
        : '';
      values.provincia_company = info.provincia_company ? info.provincia_company : '';
      values.distrito_company = info.distrito_company ? info.distrito_company : '';
      values.direccion_company = info.direccion_company ? info.direccion_company : '';

    }

    return values;
  },
  validate(values, { getUbigeoProvincias, ubigeoDepartamentos }) {

    const errors = {};
    if (!values.departamento_company) errors.departamento_company = 'Campo Obligatorio';
    if (!values.provincia_company) errors.provincia_company = 'Campo Obligatorio';
    if (!values.distrito_company) errors.distrito_company = 'Campo Obligatorio';
    if (!values.direccion_company) errors.direccion_company = 'Campo Obligatorio';


    return errors;
  },
  handleSubmit(
    values,
    { setSubmitting, props: { processUpdateCompany, getEditInfoCompany, history, user } }
  ) {
    const dataUser = {
      pais_company: 'Perú',
      departamento_company: values.departamento_company,
      provincia_company: values.provincia_company,
      distrito_company: values.distrito_company,
      direccion_company: values.direccion_company,
      user_id: user.info.user_id,
      token: user.info.token,
      ...processUpdateCompany,
    };

    getEditInfoCompany(dataUser);
    setSubmitting(false);
  },
};

export default withFormik(formConfig)(FormEditAddress);
