import React from "react";
import styled from "styled-components/macro";

const MenuIcon = styled(({ className }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="29"
    viewBox="0 0 7 29"
    className={className}
  >
    <circle r="2.5" cy="2.5" cx="3.5" />
    <circle r="3.5" cy="14.5" cx="3.5" />
    <circle r="2.5" cy="25.5" cx="3.5" />
  </svg>
))`
  circle {
    fill: var(--ion-color-primary);
    opacity: 0.35;
    &:nth-child(2) {
      fill: var(--ion-color-secondary);
      opacity: 1;
    }
  }
`;

export default MenuIcon;
