import React from 'react';
import FormEditIdentity from '../components/FormEditIdentity';
const EditProfileIdentity = ({ desktop, history, user }) => {
  return (
    <>
      <FormEditIdentity user={user} desktop={desktop} history={history} />
    </>
  );
};

export default EditProfileIdentity;
