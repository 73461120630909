import { connect } from 'react-redux';
import { GetSaveProcessUpdateCompany } from '../../store/users';
import FormEditCompanyData from './FormEditCompanyData';

const mapStateToProps = (state) => {
  return {
    processUpdateCompany: state.userState.processUpdateCompany,
    accountsUser: state.userState.accountsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  GetSaveProcessUpdateCompany: (data) => dispatch(GetSaveProcessUpdateCompany(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormEditCompanyData);
