import styled from 'styled-components/macro';

export const InputItem = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  .MuiFormHelperText-root {
    &.Mui-error {
      text-align: center;
    }
  }
`;
export const FormInner = styled.div``;
export const WrapLink = styled.div`
  font-size: 15px;
  font-family: var(--ion-font-family-600);
  margin: 25px 0;
  text-align: center;
`;
export const TitleForm = styled.h3`
  font-family: var(--ion-font-family-tertiary);
  font-size: 23px;
  text-align: center;
  margin: 0;
  margin-bottom: 25px;
`;
export const CreateAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-size: 17px;
  font-family: var(--ion-font-family-quinary);
  color: var(--ion-color-secondary);
  a {
    color: var(--ion-color-secondary);
  }
  &:after,
  &:before {
    content: '';
    width: 12px;
    height: 15px;
    background-color: var(--ion-color-primary-contrast);
    display: inline-block;
    background-size: cover;
    background-image: url('/assets/images/icon-arrow-round.png');
  }
  &:after {
    margin-left: 10px;
    transform: rotate(180deg);
  }
  &:before {
    margin-right: 10px;
  }
`;

export const ShowPassword = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 5;
`;
export const Wrap = styled.div`
  padding-top: 40px;
  @media screen and (max-height: 625px) {
    padding-top: 20px;
  }
`;
