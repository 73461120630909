import React from 'react';
import FormEditBankAccount from '../components/FormEditBankAccount';
const EditBankAccount = ({ asStep = true, desktop, history, user, generalInfo }) => {
  return (
    <>
      <FormEditBankAccount
        user={user}
        generalInfo={generalInfo}
        asStep={asStep}
        desktop={desktop}
        history={history}
      />
    </>
  );
};

export default EditBankAccount;
