import React from 'react';
import styled from 'styled-components/macro';

export const TabsContainer = styled.nav`
  width: 300px;
  display: grid;
  margin: 0;
  grid-template-columns: repeat(
    ${({ count = 3, autoCount = false, children }) => {
      if (autoCount) return React.Children.count(children);
      return count;
    }},
    auto
  );
  width: 100%;
  max-width: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  margin: ${({ my = '20px' }) => my} auto;

  &.simulador-page {
    height: 70px;
    margin-top: 0;
    @media screen and (max-height: 660px) {
      margin-bottom: 15px;
    }
    @media screen and (max-height: 625px) {
      height: 60px;
    }
    .tab-item {
      padding: 0;
      font-size: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      &:first-child {
        &:before {
          content: '';
          display: block;
          background-color: var(--ion-color-primary);
          width: 1px;
          height: 60%;
          position: absolute;
          right: 0px;
          top: 20%;
          opacity: 0.1;
        }
      }
      &.active {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0px;
          right: 0px;
          margin: 0px auto;
          bottom: 0px;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0px 8px 8px;
          opacity: 1;
          border-color: transparent transparent var(--ion-color-secondary) transparent;
        }
      }
    }
    .item-label {
      font-size: 12px;
      font-family: var(--ion-font-family-quaternary);
      font-weight: normal;
    }
    .item-text-amount {
      font-size: 18px;
      font-family: var(--ion-font-family-quinary);
      color: var(--ion-color-primary);
    }
    &.home {
      max-width: 400px;
    }
  }
`;

export const TabItem = styled.div`
  padding: ${({ padding = '6px' }) => padding};
  text-align: center;
  font-size: 13px;
  font-family: var(--ion-font-family-quaternary);
  color: var(--ion-color-primary);
  position: relative;
  cursor: pointer;

  &:not(:first-child)::before {
    content: '';
    display: inline-block;
    background-color: var(--ion-color-primary);
    width: 1px;
    height: calc(100% - 24px);
    position: absolute;
    left: 0;
    top: 12px;
    opacity: 0.1;
  }

  &.active + &,
  &.active {
    &::before {
      display: none;
    }
  }

  &.active,
  &.strong {
    font-family: var(--ion-font-family-quinary);
    color: var(--ion-color-secondary);
  }

  &.active {
    border-radius: 5px;
    box-shadow: ${({ shadow = true }) =>
      shadow ? '0px 0px 8px rgba(58, 162, 41, 0.2)' : 'none'};
    padding-bottom: 0;
    &:not(.tab-item) {
      &::after {
        content: '';
        display: ${({ indicator = true }) => (indicator ? 'block' : 'none')};
        background-color: var(--ion-color-secondary);
        width: 14px;
        height: 6px;
        clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
        margin: 0 auto;
        margin-top: 5px;
      }
    }
  }
`;
