import React from 'react';
import { CntButton, Button, WrapError, TextError } from '../../theme/global';
import { Typography, Strong, Title } from '../Text';
import Input, { Container } from '../InputRounded';
import { withFormik, Form, ErrorMessage } from 'formik';
import TabProfile from '../TabProfile';
import { WrapPage } from '../../theme/shared';
const FormEditCompanyData = ({ desktop, accountsUser }) => {
  const hasCompany = accountsUser ? accountsUser.has_profile_company : null;
  function validateNumber(event) {
    var key = event.which;
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }
  return (
    <>
      <WrapPage className={`${desktop || ''} edit-profile-company `}>
        <Form>
          <div className='inner-page'>
            <TabProfile />

            <div>
              <Title className='title-edit-company'>Datos de la Empresa</Title>
              <Container>
                {hasCompany && <h3 className='label-input-field'>Razón social</h3>}
                <Input
                  className='input-text'
                  name='razon_social_company'
                  placeholder='Razón social*'
                />
                <ErrorMessage name='razon_social_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                {hasCompany && <h3 className='label-input-field'>Número de Ruc</h3>}
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  className='input-text'
                  name='number_ruc'
                  placeholder='Número de Ruc*'
                />
                <ErrorMessage name='number_ruc'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                {hasCompany && <h3 className='label-input-field'>Representante Legal</h3>}
                <Input
                  className='input-text'
                  name='legal_representative'
                  placeholder='Representante Legal*'
                />
                <ErrorMessage name='legal_representative'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                {hasCompany && <h3 className='label-input-field'>Doc. Identidad Repr. Legal</h3>}
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  className='input-text'
                  name='dni_repr_legal'
                  placeholder='Doc. Identidad Repr. Legal*'
                />
                <ErrorMessage name='dni_repr_legal'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>


                {hasCompany && <h3 className='label-input-field'>Actividad Principal</h3>}
                <Input
                  className='input-text'
                  name='actividad_principal_company'
                  placeholder='Actividad Principal*'
                />
                <ErrorMessage name='actividad_principal_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                {hasCompany && <h3 className='label-input-field'>Número Telefónico</h3>}
                <Input
                  className='input-text'
                  name='number_phone_company'
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  placeholder='Número Telefónico*'
                />
                <ErrorMessage name='number_phone_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
              </Container>
              <Typography
                color='primary'
                center
                size='13px'
                font='quaternary'
                style={{ marginTop: 8 }}
                className='required-fields'
              >
                *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
              </Typography>
            </div>

            <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
              <Button round>Continuar</Button>
            </CntButton>
          </div>
        </Form>
      </WrapPage>
    </>
  );
};

const formConfig = {
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues({ accountsUser, business }) {
    const values = {};

    const info = accountsUser ? accountsUser.single_info : '';

    values.razon_social_company = info.razon_social_company
      ? info.razon_social_company
      : '';

    values.number_ruc = info.number_ruc ? info.number_ruc : '';
    values.legal_representative = info.legal_representative ? info.legal_representative : '';
    values.dni_repr_legal = info.dni_repr_legal ? info.dni_repr_legal : '';
    values.actividad_principal_company = info.actividad_principal_company
      ? info.actividad_principal_company
      : '';
    values.number_phone_company = info.number_phone_company
      ? info.number_phone_company
      : '';

    return values;
  },
  validate(values) {
    const errors = {};
    if (!values.razon_social_company) {
      errors.razon_social_company = 'Campo Obligatorio';
    }

    if (!values.number_ruc) {
      errors.number_ruc = 'Campo Obligatorio';
    }

    if (!values.actividad_principal_company) {
      errors.actividad_principal_company = 'Campo Obligatorio';
    }

    if (!values.number_phone_company) {
      errors.number_phone_company = 'Campo Obligatorio';
    }
    return errors;
  },
  handleSubmit(
    values,
    { setSubmitting, props: { GetSaveProcessUpdateCompany, history } }
  ) {
    GetSaveProcessUpdateCompany(values);
    setSubmitting(false);
    history.push('/edit-profile-company-paso-2');
  },
};

export default withFormik(formConfig)(FormEditCompanyData);
