export const routesApi = {
  pages: `${process.env.REACT_APP_API_ACF_URL}pages/`,
  login: process.env.REACT_APP_JWT_LOGIN_URL,
  urlApi: process.env.REACT_APP_API_URL,
  urlApiAcf: process.env.REACT_APP_API_ACF_URL,
  accountsClient: `${process.env.REACT_APP_CUSTOM_API_URL}bank-accounts?id-cliente=`,
  suscriptionFooter: `${process.env.REACT_APP_FORMULARY}contact-forms/275/feedback`,
  checkEmail: `${process.env.REACT_APP_CUSTOM_API_URL}check-email`,
  requestCode: `${process.env.REACT_APP_CUSTOM_API_URL}request-code`,
  validateCode: `${process.env.REACT_APP_CUSTOM_API_URL}validate-code-verification`,
  newPassword: `${process.env.REACT_APP_CUSTOM_API_URL}new_password`,
  uploadImage: `${process.env.REACT_APP_API_URL}media`,
  newOperation: `${process.env.REACT_APP_CUSTOM_API_URL}new-operation`,
  editOperation: `${process.env.REACT_APP_CUSTOM_API_URL}edit-operation-v3`,
  storeOperation: `${process.env.REACT_APP_CUSTOM_API_URL}store-operation`,
  uploadImageDni: `${process.env.REACT_APP_CUSTOM_API_URL}upload-media`,
  newAccountBank: `${process.env.REACT_APP_CUSTOM_API_URL}new-account-bank`,
  editAccountBank: `${process.env.REACT_APP_CUSTOM_API_URL}edit-account_bank`,
  createAccount: `${process.env.REACT_APP_CUSTOM_API_URL}new-user`,
  createInfoCompany: `${process.env.REACT_APP_CUSTOM_API_URL}create-info-company`,
  editInfoPersonal: `${process.env.REACT_APP_CUSTOM_API_URL}edit-info-personal`,
  editInfoIdentity: `${process.env.REACT_APP_CUSTOM_API_URL}edit-info-identity`,
  editInfoCompany: `${process.env.REACT_APP_CUSTOM_API_URL}edit-info-company`,
  operationsPending: `${process.env.REACT_APP_CUSTOM_API_URL}operations-client-status?status=10&id=`,
  operationsCompleted: `${process.env.REACT_APP_CUSTOM_API_URL}operations-client-status?status=21&id=`,
  operationsCanceled: `${process.env.REACT_APP_CUSTOM_API_URL}operations-client-status?status=13&id=`,
  infoCurrentUser: `${process.env.REACT_APP_CUSTOM_API_URL}info-current-user?user-id=`,
  deleteAccountBank: `${process.env.REACT_APP_CUSTOM_API_URL}delete-posts`,
  rate: `${process.env.REACT_APP_CUSTOM_API_URL}rate`,
  generalInfo: `${process.env.REACT_APP_CUSTOM_API_URL}general-info`,
  updateAlertsConfig: `${process.env.REACT_APP_CUSTOM_API_URL}configure-alerts`, // TODO: update url
  // web
  customApi: process.env.REACT_APP_CUSTOM_API_URL,
  newslist: `${process.env.REACT_APP_URL_BASE}wp-json/api/news`,
  termsConditions: `${process.env.REACT_APP_URL_BASE}wp-json/wp/v2/pages?slug=`,
  newsFeatured: `${process.env.REACT_APP_URL_BASE}wp-json/api/featured-news`,
  categoriesList: `${process.env.REACT_APP_API_URL}categories`,
  getPostByCategory: `${process.env.REACT_APP_URL_BASE}wp-json/api/news-list?category=`,
  getPostBySlug: `${process.env.REACT_APP_API_URL}posts?slug=`,
  getPostBySlug2: `${process.env.REACT_APP_URL_BASE}wp-json/api/news-detail?slug=`,
  operationType: `${process.env.REACT_APP_API_ACF_URL}pages/284`,
  contactForm: `${process.env.REACT_APP_URL_BASE}wp-json/contact-form-7/v1/contact-forms/202/feedback`,
  completedOperation: `${process.env.REACT_APP_CUSTOM_API_URL}completed-operation`,
  canceledOperation: `${process.env.REACT_APP_CUSTOM_API_URL}canceled-operation`,
  archiveOperation: `${process.env.REACT_APP_CUSTOM_API_URL}archive-operation`,
  invoices: `${process.env.REACT_APP_CUSTOM_API_URL}request-invoice`,
};
