import { REQUEST_NOSOTROS, RECEIVE_NOSOTROS } from './types';

const INITIAL_STATE = {
  nosotros: null,
  loading: false,
};

export const nosotrosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_NOSOTROS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_NOSOTROS:
      return {
        ...state,
        nosotros: action.nosotros,
        loading: false,
      };
    default:
      return state;
  }
};
