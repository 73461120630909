import styled from 'styled-components/macro';

export const BlockCredits = styled.div`
  padding-bottom: 19px;
`;

export const Content = styled.div`
  /* color: green; */
`;

export const Logo = styled.figure`
  width: 67px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const LogoText = styled.h5`
  line-height: 1;
  letter-spacing: -0.03em;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0 0 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 18px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: inherit;
    align-items: center;
    max-width: 220px;
    width: 100%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Left = styled.div`
  line-height: 1.1;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 12px;
  @media screen and (max-width: 767px) {
    order: 3;
    font-size: 14px;
  }
`;

export const Center = styled.div`
  line-height: 1.1;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 12px;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  @media screen and (max-width: 767px) {
    order: 2;
    font-size: 14px;
    transform: none;
    position: relative;
    top: inherit;
    left: inherit;
    margin-bottom: 15px;
  }
`;

export const Right = styled.div`
  line-height: 1.1;
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family);
  font-size: 12px;
  @media screen and (max-width: 767px) {
    order: 1;
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

export const Text = styled.p`
  margin: 0;
`;
