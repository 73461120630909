import React, { useState } from 'react';
import {
  Wrap,
  Item,
  InfoWrap,
  Amounts,
  AmountItem,
  DateOperation,
  Arrow,
  Head,
  ContentItem,
  ContentInfo,
  Status,
  CodeOperation,
} from './styles';

const ItemOperationCompleted = ({ data, dataKey }) => {
  const [openDetail, setOpenDetail] = useState(null);

  const currencySent = data ? (data.currency_sent === 'PEN' ? 'S/ ' : '$ ') : null;
  const originBankCurrency = data
    ? data.origin_bank.currency === 'PEN'
      ? 'Soles '
      : 'Dólares '
    : null;
  const currencyReceived = data
    ? data.currency_received === 'PEN'
      ? 'S/ '
      : '$ '
    : null;
  const DestinationBankCurrency = data
    ? data.destination_bank.currency === 'PEN'
      ? 'Soles '
      : 'Dólares '
    : null;
  const amountReceived = data
    ? data.amount_received
      ? data.amount_received
      : '0.00'
    : null;
  return (
    <>
      <Wrap>
        {data && (
          <Item key={dataKey} className={dataKey === openDetail ? 'active' : ''}>
            <Head>
              <Status>
                Nro. de Operación:
                <strong>{data.destination_bank.operation_number}</strong>
                <span>| Verificado</span>
              </Status>
              <InfoWrap>
                <Amounts>
                  <AmountItem data-id={data.id}>
                    <p>
                      {currencySent} {data.amount_sent}
                    </p>
                  </AmountItem>
                  <AmountItem>
                    <p>
                      {currencyReceived} {amountReceived}
                    </p>
                  </AmountItem>
                </Amounts>
                <DateOperation>
                  {data.created_date} - <strong>{data.created_hour}</strong>
                </DateOperation>
                <CodeOperation>
                  <span>Código:</span>
                  <strong>#{data.code_operation}</strong>
                </CodeOperation>
              </InfoWrap>
              <Arrow
                onClick={() => {
                  setOpenDetail(dataKey);
                }}
              >
                <span className='icon-arrow-select'></span>
              </Arrow>
            </Head>
            <ContentItem>
              <ContentInfo>
                <span>Origen</span>
                <p>
                  <strong>
                    {originBankCurrency} | {data.origin_bank.name}
                  </strong>
                  {data.origin_bank.number_account}
                </p>
              </ContentInfo>
              <ContentInfo>
                <span>Destino</span>
                <p>
                  <strong>
                    {DestinationBankCurrency} | {data.destination_bank.name}
                  </strong>
                  {data.destination_bank.number_account}
                </p>
              </ContentInfo>
              {data.pdf_boleta && (
                <ContentInfo>
                  <span>Boleta de Venta</span>
                  <p>
                    <strong>
                      <a href={data.pdf_boleta} rel='noopener noreferrer' target='_blank'>
                        Ver Boleta
                      </a>
                    </strong>
                  </p>
                </ContentInfo>
              )}
            </ContentItem>
          </Item>
        )}
      </Wrap>
    </>
  );
};

export default ItemOperationCompleted;
