import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { colors, fonts } from '../../../theme/web/theme';

export const Wancho = styled.div`
  max-width: 1024px;
  width: 86%;
  position: relative;
  z-index: 2;
  margin: auto;
`;
export const SliderCtn = styled.div`
  padding: 28px 30px 32px 30px;
  box-shadow: 3px 6px 25px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  .ItemHome {
    margin-left: 0;
    margin-bottom: 0;
  }
`;
export const ArrowSlider = styled.button`
  width: 45px;
  height: 45px;
  box-shadow: 3px 3px 15px rgba(58, 162, 41, 0.15);
  background-color: #fff;
  border-radius: 5px;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 80px;
  margin: auto;
  z-index: 10;
  cursor: pointer;
  &:after {
    display: none;
  }
  &:before {
    content: '\\e90f';
    font-family: 'icomoon';
    color: #717171;
    font-size: 10px;
    display: block;
  }
  &.Prev {
    left: -22px;
    &:before {
      transform: rotate(90deg);
    }
    &.Alternate {
      left: 15px;
      bottom: 200px;
      @media screen and (min-width: 769px) {
        display: none;
      }
    }
  }
  &.Next {
    right: -22px;
    &:before {
      transform: rotate(-90deg);
    }
    &.Alternate {
      right: 15px;
      bottom: 200px;
      @media screen and (min-width: 769px) {
        display: none;
      }
    }
  }
  @media screen and (min-width: 1025px) {
    &:hover {
      &:before {
        color: ${colors.secondary};
      }
    }
  }
  @media screen and (max-width: 768px) {
    top: initial;
    bottom: 25px;
    &.Prev {
      left: -12px;
    }
    &.Next {
      right: -12px;
    }
  }
`;

export const SliderBtn = styled.div`
  display: flex;
  justify-content: center;
`;
export const SliderLink = styled(Link)`
  color: ${colors.secondary};
  font-size: 18px;
  font-family: ${fonts.lato800};
  transition: all 0.3s;
  letter-spacing: -0.3px;
  &:hover {
    transform: scale(1.1);
  }
`;

export const Item = styled(Link)`
  display: block;
  margin-bottom: 23px;
  background-color: #fff;
  border-radius: 9px;
  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 9px;
  }
  &:hover {
    img {
      box-shadow: 7px 4px 10px rgba(0, 0, 0, 0.15),
        inset 3px 6px 10px rgba(255, 255, 255, 0.25);
    }
    p {
      color: ${colors.secondary};
    }
  }
  @media screen and (min-width: 1025px) {
    width: calc(33.3333% - 16.6666666px);
    margin-left: 25px;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: calc(50% - 12.5px);
    margin-left: 25px;
    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 767px) {
    width: 100%;
  }
`;
export const ItemParrafo = styled.div`
  padding: 8px 8px 16px 16px;
  border-radius: 0 0 4px 4px;
  p {
    margin: 0;
    line-height: 1;
    letter-spacing: 0.2px;
    font-family: ${fonts.lato700};
    font-size: 16px;
    color: ${colors.primary};
    min-height: 32px;
  }
  ul {
    padding: 18px 0 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

export const ItemLi = styled.li`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;
  flex: 1;
  span {
    font-size: 11px;
    color: ${colors.secondary};
    font-family: ${fonts.lato400};
    line-height: 1.85;
  }
  &:nth-child(2) {
    justify-content: flex-end;
    span {
      color: ${colors.primary};
    }
  }
`;
