import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';
export const WrapItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1204px;
  width: 93%;
  margin: auto;
  padding-bottom: 40px;
`;

export const Figure = styled.figure`
  width: 519px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  @media (max-width: 960px) {
    width: 480px;
  }
  @media (max-width: 880px) {
    width: 350px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const SliderWrap = styled.div`
  width: calc(100% - 519px);
  margin: 0;
  text-align: center;
  padding-left: 80px;
  box-sizing: border-box;
  @media (max-width: 960px) {
    padding-left: 40px;
    width: calc(100% - 480px);
  }
  @media (max-width: 880px) {
    width: calc(100% - 350px);
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`;
export const TitleSlider = styled.div`
  max-width: 400px;
  width: 100%;
  margin: auto;
  margin-bottom: 25px;
  h2 {
    font-family: ${fonts.lato800};
    font-style: normal;
    font-size: 30px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #3aa229;
    margin: 0;
    margin-bottom: 5px;
  }
  p {
    margin: 0;
    font-family: ${fonts.lato400};
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #717171;
    strong {
      color: #3aa229;
      display: block;
    }
  }
  @media (max-width: 767px) {
    max-width: 600px;
  }
`;

export const ItemTxt = styled.div`
  text-align: center;
  max-width: 255px;
  margin: auto;
  width: 100%;
  margin-bottom: 20px;
  p {
    font-family: ${fonts.lato400};
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #717171;
    margin: 0;
  }
  @media (max-width: 767px) {
    max-width: 240px;
  }
`;

export const ItemImage = styled.figure`
  width: 58px;
  height: 58px;
  box-sizing: border-box;
  margin: 0;
  box-shadow: 0px 0px 25px rgba(58, 162, 41, 0.1);
  border-radius: 50%;
  padding: 10px;
  img {
    width: 100%;
    margin: auto;
    border-radius: 50%;

    display: block;
  }
  @media (max-width: 767px) {
    display: none;
  }
  &.responsive_show {
    margin: auto;
    margin-bottom: 10px;
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
`;

export const ItemSlider = styled.div`
  max-width: 400px;
  width: 100%;
  margin: auto;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 17px;
    left: 0;
    height: 65%;
    background-color: #fff;
    border-radius: 20px;
    left: 0;
    right: 0;
    max-width: 366px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 3px 3px 35px rgba(113, 113, 113, 0.1);
  }
  @media (max-width: 767px) {
    max-width: 600px;
    &:after {
      max-width: none;
      height: 80%;
      width: 85%;
    }
  }
  ul {
    /* padding: 0 40px; */
    box-sizing: border-box;
  }
  li {
    padding: 17px;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 100px;
    @media screen and (max-width: 480px) {
      padding-bottom: 60px;
    }
  }
  .item {
    padding-bottom: 30px;
    padding-top: 38px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .swiper-slide {
    opacity: 0 !important;
    transition: opacity 1s !important;
    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fecf30;
    transform: scale(1);
    opacity: 1;
    left: 0 !important;
  }
  .swiper-pagination-bullet-active-next,
  .swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullet-active-prev-prev,
  .swiper-pagination-bullet-active-next-next {
    transform: scale(1) !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    background: #3aa229;
    width: 10px;
    height: 10px;
  }

  .swiper-pages {
    width: 100% !important;
    text-align: center;
    position: absolute;
    bottom: 45px !important;
    left: 0px !important;
    transform: inherit !important;
    z-index: 120;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      bottom: 25px !important;
    }
  }
  .slider_next_swiper,
  .slider_prev_swiper {
    width: 81px;
    height: 61px;
    position: absolute;
    bottom: 20px;
    background: #ffffff;
    box-shadow: 3px 6px 25px rgba(113, 113, 113, 0.15);
    border-radius: 15px;
    z-index: 150;
    @media screen and (max-width: 767px) {
      bottom: 85px;
    }
    @media screen and (max-width: 320px) {
      width: 70px;
      height: 50px;
      border-radius: 10px;
      &:before {
        line-height: 50px !important;
        font-size: 14px;
      }
    }
    cursor: pointer;
    &.swiper-button-disabled {
      opacity: 0.3;
    }
    &:before {
      content: '';
      display: block;
      font-family: 'icomoon';
      line-height: 61px;
      color: #3aa229;
      font-size: 17px;
      text-align: center;
      transition: all 150ms ease-in;
    }
    @media (min-width: 1025px) {
      &:hover {
        &:before {
          color: #fecf30;
        }
      }
    }
  }
  .slider_next_swiper {
    right: 15px;
    &:before {
      content: '\\e911';
    }
    @media screen and (max-width: 767px) {
      right: 10px;
    }
  }
  .slider_prev_swiper {
    left: 15px;
    &:before {
      content: '\\e911';
      transform: rotate(-180deg);
    }
    &.swiper-button-disabled {
      opacity: 1;
    }
    @media screen and (max-width: 767px) {
      left: 10px;
    }
  }
`;

export const ItemContenido = styled.div`
  @media (max-width: 767px) {
    padding-left: 0;
  }
  h3 {
    margin: 0px;
    letter-spacing: -0.03em;
    font-family: ${fonts.lato800};
    font-style: normal;
    font-size: 14px;
    color: #3aa229;
    line-height: 0;
    margin-top: 10px;
  }
  p {
    margin: 0;
  }
  span {
    font-family: ${fonts.lato400};
    font-style: normal;
    font-size: 13px;
    letter-spacing: -0.03em;
    color: #717171;
  }
`;
export const ItemParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 255px;
  margin: 0 auto;
  position: relative;
  ${ItemImage} {
    position: absolute;
    top: 0;
    left: -30px;
    @media screen and (max-width: 1200px) {
      position: static;
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 50px;
  }
`;
