import React, { useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Markdown from 'react-markdown/with-html';
import { WrapItem, ItemPreguntas, TitleItem, Title, Section, ImageTop } from './style';
const ComoFunciona = ({ comoFunciona, fetchComoFunciona }) => {
  const comoFuncionaList = comoFunciona.slides_list;

  useEffect(() => {
    fetchComoFunciona();
  }, [fetchComoFunciona]);

  const params = {
    slidesPerView: 3,
    spaceBetween: 100,
    pagination: {
      el: '.swiper-pages',
      clickable: true,
      dynamicBullets: true,
    },

    navigation: {
      nextEl: '.slider_next_swiper',
      prevEl: '.slider_prev_swiper',
    },
    breakpoints: {
      960: {
        slidesPerView: 3,
        spaceBetween: 100,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 50,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  };

  return (
    <Section>
      <ImageTop />
      <WrapItem className='comofunciona_wrap'>
        <Title className='comofunciona_title'>
          <h2> {comoFunciona.title_funciona} </h2>
          <p>
            {comoFunciona.subtitulo_funciona}
            <strong> {comoFunciona.subtitulo_bold_funciona} </strong>
          </p>
        </Title>
        {comoFuncionaList && comoFuncionaList.length && (
          <>
            <Swiper {...params}>
              {comoFuncionaList.slice(0, -1).map((item, key) => (
                <ItemPreguntas className='comofunciona_itm' key={key}>
                  <span className={`item_size_${key + 1}`}>0{key + 1}</span>
                  <figure>
                    <img src={item.imagen_intro.url} alt='' />
                  </figure>
                  <TitleItem> {item.titulo_intro_slide} </TitleItem>
                  <Markdown escapeHtml={false}>{item.descripcion_slide}</Markdown>
                </ItemPreguntas>
              ))}
            </Swiper>
          </>
        )}
      </WrapItem>
    </Section>
  );
};

export default ComoFunciona;
