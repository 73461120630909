import React, { useCallback, useEffect, useRef } from 'react';
import { CntButton, Button, WrapError, TextError } from '../theme/global';
import Card from '../components/ConfigureAlertCard';
import { withFormik, useField, ErrorMessage } from 'formik';
import { Strong, TitleGreen, Title, Typography } from '../components/Text';
import GreenBell from '../components/GreenBell';
import Hr from '../components/Hr';
import BuyAndSalePrice from '../components/BuyAndSalePrice';
import Input from '../components/InputWithCheck';
import { WrapPage } from '../theme/shared';
import PopUp from '../components/Popup';
import Alert from '../components/Alert';
import { connect } from 'react-redux';
import {
  updateAlertsConfig,
  getInforCurrentUser,
  getCleanAlertsConfig,
} from '../store/users';
const CheckInput = ({ checkName, inputName, ...p }) => {
  // eslint-disable-next-line no-unused-vars
  const [checkField, checkMeta, checkHelpers] = useField(checkName);
  const [inputField] = useField(inputName);
  const onCheck = useCallback(
    (checked) => {
      checkHelpers.setValue(checked);
    },
    [checkHelpers]
  );

  return <Input checked={checkField.value} onCheck={onCheck} {...p} {...inputField} />;
};

const ConfigureAlerts = ({
  desktop,
  handleSubmit,
  user,
  alertsConfig,
  receiveRate,
  getInforCurrentUser,
  getCleanAlertsConfig,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    if (alertsConfig && alertsConfig.status === 200) {
      popupRef.current.open();
      return;
    }
  }, [alertsConfig]);

  return (
    <>
      <WrapPage className={`${desktop || ''} configure-alerts`}>
        <Card className='card'>
          <GreenBell />
          <Title marginBottom='0'>Configurar Alertas</Title>
          <Typography center>
            Te notificaremos al
            <Strong secondary> Correo Electrónico </Strong>
            para que recibas tu tipo de cambio ideal.
          </Typography>
        </Card>
        <section>
          <TitleGreen className='title-greeen'>Tipo de Cambio</TitleGreen>
          {receiveRate && (
            <BuyAndSalePrice
              className='tabs-tc'
              buyPrice={receiveRate.official.buy}
              sellPrice={receiveRate.official.sale}
            />
          )}
          <CheckInput
            className='control-input'
            label='Compra sea mayor a:'
            checkName='notifyBuy'
            inputName='thresholdBuy'
          />
          <ErrorMessage name='notifyBuy'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
          <ErrorMessage name='thresholdBuy'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
          <Hr />
          <CheckInput
            className='control-input'
            label='Venta sea menor a:'
            checkName='notifySale'
            inputName='thresholdSale'
          />
          <ErrorMessage name='thresholdSale'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
          <ErrorMessage name='notifySale'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
        </section>
        <CntButton center onClick={handleSubmit}>
          <Button type='submit'>ACTIVAR ALERTAS</Button>
        </CntButton>
      </WrapPage>
      <PopUp
        ref={popupRef}
        onClose={() => {
          const dataCurrentUser = {
            id: user.info.user_id,
            token: user.info.token,
          };

          getInforCurrentUser(dataCurrentUser);
          getCleanAlertsConfig();
        }}
      >
        <Alert
          icon='success'
          title='Notificación Actualizada con exito'
        // subtitle='debe iniciar sesión o iniciar el proceso de recuperación de cuenta. '
        />
      </PopUp>
    </>
  );
};

const formForm = withFormik({
  validateOnBlur: false,
  enableReinitialize: true,
  mapPropsToValues({ infoCurrentUser }) {
    return {
      notifyBuy: infoCurrentUser ? infoCurrentUser.info.notifyBuy : false,
      thresholdBuy: infoCurrentUser ? infoCurrentUser.info.thresholdBuy : '',
      notifySale: infoCurrentUser ? infoCurrentUser.info.notifySale : false,
      thresholdSale: infoCurrentUser ? infoCurrentUser.info.thresholdSale : '',
    };
  },
  handleSubmit(values, { setSubmitting, props: { onSave, user } }) {
    const dataUser = {
      token: user.info.token,
      user_id: user.info.user_id,
      ...values,
    };

    if (onSave) onSave(dataUser);
    setSubmitting(false);
  },
  validate(values) {

    const errors = {};
    if (!values.notifyBuy) errors.notifyBuy = 'Debe Activar la notificación';
    if (!values.notifySale) errors.notifySale = 'Debe Activar la notificación';
    if (!values.thresholdBuy)
      errors.thresholdBuy = 'Debe poner el tipo de cabio que desea';
    if (!values.thresholdSale)
      errors.thresholdSale = 'Debe poner el tipo de cabio que desea';

    return errors;
  },
});

const Enhanced = connect(
  (state) => ({
    alertsConfig: state.userState.alertsConfig,
    infoCurrentUser: state.userState.infoCurrentUser,
    receiveRate: state.operationState.receiveRate,
  }),
  (dispatch) => ({
    onSave: (values) => dispatch(updateAlertsConfig(values)),
    getInforCurrentUser: (id) => dispatch(getInforCurrentUser(id)),
    getCleanAlertsConfig: () => dispatch(getCleanAlertsConfig()),
  })
)(formForm(ConfigureAlerts));

export default Enhanced;
