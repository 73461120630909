import React from 'react';
import { Text, SubTitle, Title, WrapDisclaimer } from './styles';
const Disclaimer = () => {
  return (
    <WrapDisclaimer>
      <Text>
        <SubTitle>Registrados en</SubTitle>
        <Title>La Superintendencia de Banca, seguros y AFP</Title>
      </Text>
    </WrapDisclaimer>
  );
};

export default Disclaimer;
