import styled from 'styled-components/macro';

export const Wrap = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonMenu = styled.div`
  width: 85px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(58, 162, 41, 0.25);
  border-radius: 5px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  z-index: 2;
`;
export const ButtonVolverWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50%;
  width: 50vw;
  max-width: 150px;
  z-index: 2;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonVolver = styled.div`
  width: 90px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--ion-color-secondary);
  font-family: var(--ion-font-family-quinary);
  justify-content: center;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 8px 7px 0;
    border-color: transparent var(--ion-color-secondary) transparent transparent;
  }
`;
export const Isotipo = styled.div`
  text-align: center;
  padding: 5px 0;
  position: relative;
  z-index: 1;
`;
