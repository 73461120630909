import React, { useState, useEffect, useRef } from 'react';
import { Base64 } from 'js-base64';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { CntButton, Button } from '../../theme/global';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import PopUp from '../Popup';
import Alert from '../Alert';
import { Plugins } from '@capacitor/core';
import {
  Wrap,
  FormInner,
  CreateAccount,
  TitleForm,
  InputItem,
  WrapLink,
  ShowPassword,
} from './styles';
const { Storage } = Plugins;
const LoginForm = ({
  user,
  fetchUser,
  defaultValues,
  history,
  desktop,
  infoCurrentUser,
  getInforCurrentUser,
  getCleanUserInfo,

}) => {
  const popupRef = useRef(null);
  const popupTypeUser = useRef(null);


  useEffect(() => {
    if (user && user.status === 200 && !infoCurrentUser) {
      // const dataCurrent = JSON.stringify(user);
      // sessionStorage.currentUser = Base64.encode(dataCurrent);
      // history.push('/select-profile');

      if (window["plugins"]?.OneSignal) {

        
          window['plugins'].OneSignal.getDeviceState(function(stateChanges) {
            console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));

            const dataCurrentUser = {
              id: user.info.user_id,
              token: user.info.token,
              id_user_device: stateChanges.userId,
              from_login: 1
            };
  
            getInforCurrentUser(dataCurrentUser);
          });
      } else {
        const dataCurrentUser = {
          id: user.info.user_id,
          token: user.info.token,
          id_user_device: 'no-mobil'
        };
        getInforCurrentUser(dataCurrentUser);
      }

      return;
    }
    if (
      infoCurrentUser &&
      infoCurrentUser.status === 200 &&
      infoCurrentUser.info.role[0] === 'cliente'
    ) {
      const dataCurrent = JSON.stringify(user);
      sessionStorage.currentUser = Base64.encode(dataCurrent);
      Storage.set({key: 'currentUser', value:  Base64.encode(dataCurrent)});  //Save Storage
      
      history.push('/select-profile');
    }
    if (infoCurrentUser && infoCurrentUser.status === 403) {
      //popupTypeUser.current.open();
      getCleanUserInfo();
      sessionStorage.clear();
      Storage.remove({ key: 'currentUser' }).then( (data)=>{
          window.location.replace('/login');
        }
      );
    }
    if (user && user.status === 403) {
      popupRef.current.open();
    }
  }, [getInforCurrentUser, history, infoCurrentUser, user, getCleanUserInfo]);
  const [showPasswod, setShowPassword] = useState(true);
  return (
    <>
      <Wrap className={desktop || ''}>
        <TitleForm>Ingresa a tu Cuenta</TitleForm>
        <Formik
          validateOnBlur={false}
          initialValues={defaultValues}
          validate={(values) => {
            const errors = {};

            if (!values.username) {
              errors.username = 'Campo Obligatorio';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
            ) {
              errors.username = 'Email invalido';
            }
            if (values.password.trim() === '') {
              errors.password = 'Campo Obligatorio';
            }

            if (Object.keys(errors).length > 0) return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              setSubmitting(false);
              fetchUser(values);
              // resetForm();
            }, 500);
          }}
        >
          {({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form className='formulary-share'>
              <FormInner>
                <InputItem>
                  <Field
                    fullWidth
                    name='username'
                    type='text'
                    autoComplete='off'
                    placeholder='Correo'
                    component={TextField}
                  />
                </InputItem>
                <InputItem>
                  <ShowPassword onClick={() => setShowPassword(!showPasswod)}>
                    <img
                      src={`/assets/images/icon-eyes-${showPasswod ? 'on' : 'off'}.svg`}
                      width={25}
                      alt=''
                    />
                  </ShowPassword>
                  <Field
                    fullWidth
                    name='password'
                    type={showPasswod ? 'password' : 'text'}
                    placeholder='Contraseña'
                    component={TextField}
                  />
                </InputItem>
              </FormInner>
              <WrapLink>
                <Link to='/password-recovery'>¿Olvidaste tu Contraseña?</Link>
              </WrapLink>

              <CntButton center>
                <Button
                  round
                  className='ctaButton medium'
                  type='submit'
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Iniciar Sesión
                  {isSubmitting && <Loading />}
                </Button>
              </CntButton>
            </Form>
          )}
        </Formik>
        <CreateAccount>
          <Link to='/create-account'>Registrate Aquí</Link>
        </CreateAccount>
      </Wrap>
      <PopUp ref={popupRef} onClose={() => { }}>
        <Alert
          icon='error'
          title='Error al iniciar sesión'
          subtitle='error de usuario o clave'
          description='Verifique sus datos y vuelva a intentarlo'
        />
      </PopUp>
      <PopUp
        ref={popupTypeUser}
        onClose={() => {
          getCleanUserInfo();
        }}
      >
        <Alert
          icon='error'
          title='Error al iniciar sesión'
          subtitle='Su tipo de cuenta no esta autorizado para crear operaciones'
        />
      </PopUp>
    </>
  );
};

export default LoginForm;
