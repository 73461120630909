import {
  requestDashboard,
  receiveDashboard,
  requestSearchClients,
  receiveSearchClients,
  setActiveButton,
  requestSelectOperation,
  receiveSelectOperation,
  newAmountReceive,
  newEditOperation,
  singleDetailOperation,
  clearSingleOperation,
  processDashboard,
  cleanDashboard,
  receiveCompletedOperation,
  receiveCanceledOperation,
  receiveArchiveOperation,
  cleanProcessDashboard,
  receiveInvoice,
  cleanReceiveInvoice,
} from './actions';
import { routesApi } from '../../../routesApi';
import { showAlertError } from '../../users/actions';
const responseError = {
  status: 500,
  message: 'Error de servidor o conexión',
};
export const fetchDashboard = (idStatus, idClient, token) => {
  const typeApi = idClient
    ? `${routesApi.customApi}operations-client-status?id=${idClient}&status=${idStatus}`
    : `${routesApi.customApi}operations-filter?status=${idStatus}`;

  return (dispatch) => {
    dispatch(requestDashboard());
    return fetch(typeApi, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.status === 200) {
          dispatch(receiveDashboard(json.result));
        } else {
          dispatch(receiveDashboard([]));
        }
      })
      .catch((error) => {
        dispatch(receiveDashboard(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const fetchSearchClients = (token) => {
  return (dispatch) => {
    dispatch(requestSearchClients());
    return fetch(`${routesApi.customApi}clients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveSearchClients(json));
      })
      .catch((error) => {
        dispatch(receiveSearchClients(null));
        dispatch(showAlertError(responseError));
      });
  };
};

export const isActiveButton = (id) => {
  return (dispatch) => {
    return dispatch(setActiveButton(id));
  };
};

export const fetchSelectOperation = () => {
  return (dispatch) => {
    dispatch(requestSelectOperation());
    return fetch(`${routesApi.customApi}selects-dashboard`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(receiveSelectOperation(json));
      });
  };
};

export const getNewAmountReceive = (data) => {
  return (dispatch) => {
    return dispatch(newAmountReceive(data));
  };
};

export const getCleanProcessDashboard = () => {
  return (dispatch) => {
    return dispatch(cleanProcessDashboard());
  };
};

export const saveSingleDetailOperation = (data) => {
  return (dispatch) => {
    return dispatch(singleDetailOperation(data));
  };
};

export const getProcessDashboard = (data) => {
  return (dispatch) => {
    return dispatch(processDashboard(data));
  };
};

export const getClearSingleOperation = () => {
  return (dispatch) => {
    return dispatch(clearSingleOperation());
  };
};

export const getCleanDashboard = () => {
  return (dispatch) => {
    return dispatch(cleanDashboard());
  };
};

export const getCleanReceiveInvoice = () => {
  return (dispatch) => {
    return dispatch(cleanReceiveInvoice());
  };
};

export const getCompletedOperation = (data) => {
  return (dispatch) => {
    dispatch(requestDashboard());
    return fetch(routesApi.completedOperation, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveCompletedOperation(data));
        });
      })
      .catch((error) => {
        dispatch(receiveCompletedOperation(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getCanceledOperation = (data) => {
  return (dispatch) => {
    dispatch(requestDashboard());
    return fetch(routesApi.canceledOperation, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveCanceledOperation(data));
        });
      })
      .catch((error) => {
        dispatch(receiveCanceledOperation(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
export const getArchiveOperation = (data) => {
  return (dispatch) => {
    dispatch(requestDashboard());
    return fetch(routesApi.archiveOperation, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveArchiveOperation(data));
        });
      })
      .catch((error) => {
        dispatch(receiveArchiveOperation(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getNewEditOperation = (data) => {
  return (dispatch) => {
    dispatch(requestDashboard());
    return fetch(`${routesApi.customApi}edit-operation`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(newEditOperation(data));
        });
      })
      .catch((error) => {
        dispatch(newEditOperation(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};

export const getInvoice = (data) => {
  return (dispatch) => {
    dispatch(requestDashboard());
    return fetch(routesApi.invoices, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json().then((data) => {
          dispatch(receiveInvoice(data));
        });
      })
      .catch((error) => {
        dispatch(receiveInvoice(responseError));
        dispatch(showAlertError(responseError));
      });
  };
};
