import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Item from '../../../components/web/Items';
import CategoriesList from '../../../components/web/CategoriesList';
import { Wancho, WrappItem } from '../../../components/web/Items/styles';
import TitlePages from '../../../components/web/TitlePages/Titulo';
import SocialNetworks from '../../../components/web/SocialNetworks';
import { WrapNews } from '../../../theme/global';
const NewsCategory = ({ getNewsCategory, fetchNewsCategory, location, footerData }) => {
  const category = useParams('category');
  useEffect(() => {
    if (category) {
      fetchNewsCategory(category);
    }
  }, [fetchNewsCategory, category]);
  return (
    <WrapNews>
      <TitlePages
        className='title_pregunta_frecuente'
        title='Nuestro Blog'
        subtitle='Últimas Novedades'
      />
      <CategoriesList location={location} />
      <Wancho>
        {getNewsCategory && getNewsCategory.length > 0 && (
          <WrappItem>
            {getNewsCategory && (
              <>
                {getNewsCategory.map((item, key) => (
                  <Item data={item} url='' idCategory='' key={key} />
                ))}
              </>
            )}
          </WrappItem>
        )}
        <SocialNetworks data={footerData} className='inNews' />
      </Wancho>
    </WrapNews>
  );
};

export default NewsCategory;
