import React, { useState } from 'react';
import {
  Wrap,
  Item,
  InfoWrap,
  Amounts,
  AmountItem,
  DateOperation,
  Arrow,
  Head,
  ContentItem,
  TitleDescription,
  Status,
  Text,
  CodeOperation,
} from './styles';

const ItemOperationCanceled = ({ data, dataKey }) => {
  const [openDetail, setOpenDetail] = useState(null);

  const currencySent = data ? (data.currency_sent === 'PEN' ? 'S/ ' : '$ ') : null;
  const currencyReceived = data
    ? data.currency_received === 'PEN'
      ? 'S/ '
      : '$ '
    : null;
  const amountReceived = data
    ? data.amount_received
      ? data.amount_received
      : '0.00'
    : null;
  return (
    <>
      <Wrap>
        {data && (
          <Item key={dataKey} className={dataKey === openDetail ? 'active' : ''}>
            <Head>
              <Status>
                Nro. de Operación: <strong>{data.origin_bank.operation_number}</strong>{' '}
                <span>| Cancelado</span>
              </Status>
              <InfoWrap>
                <Amounts>
                  <AmountItem data-id={data.id}>
                    <p>
                      {currencySent} {data.amount_sent}
                    </p>
                  </AmountItem>
                  <AmountItem>
                    <p>
                      {currencyReceived} {amountReceived}
                    </p>
                  </AmountItem>
                </Amounts>
                <DateOperation>
                  {data.created_date} - <strong>{data.created_hour}</strong>
                </DateOperation>
                <CodeOperation>
                  <span>Código:</span>
                  <strong>#{data.code_operation}</strong>
                </CodeOperation>
              </InfoWrap>
              <Arrow
                onClick={() => {
                  setOpenDetail(dataKey);
                }}
              >
                <span className='icon-arrow-select'></span>
              </Arrow>
            </Head>
            <ContentItem>
              <span className='icon-close'></span>
              {data.reason_canceled && (
                <>
                  <TitleDescription>Motivo</TitleDescription>
                  <Text>{data.reason_canceled}</Text>
                </>
              )}
            </ContentItem>
          </Item>
        )}
      </Wrap>
    </>
  );
};

export default ItemOperationCanceled;
