import styled from 'styled-components/macro';
import { fonts, colors } from '../../../theme/web/theme';

export const Section = styled.div`
  padding-top: 95px;
  padding-bottom: 80px;
  position: relative;
  @media screen and (max-width: 960px) {
    padding-bottom: 50px;
  }
`;
export const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 90%;
  margin: 0 auto;
`;
export const BgSection = styled.div`
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  left: 0;
  img {
    max-width: 100%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const Figura = styled.div`
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  &.bigFigure {
    margin-top: 35px;
  }
`;

export const Description = styled.div`
  width: 340px;
  font-size: 15px;
  letter-spacing: -0.01em;
  line-height: 1.2;
  strong {
    font-weight: normal;
    font-family: ${fonts.lato800};
    color: ${colors.secondary};
  }
  @media screen and (max-width: 480px) {
    text-align: center;
    font-size: 14px;
  }
`;

export const WrapDescription = styled.div`
  display: flex;
  flex-flow: row wrap;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(58, 162, 41, 0.15);
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 10px;
  justify-content: center;
  margin-top: 45px;
  position: relative;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    ${Figura} {
      margin-right: 0;
    }
    ${Description} {
      width: 250px;
    }
  }
  ${Figura} {
    width: 41px;
    margin-right: 40px;
    @media screen and (max-width: 480px) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -43px;
      margin: 0 auto;
      width: 120px;
      height: 86px;
      box-shadow: 0px 3px 15px rgba(58, 162, 41, 0.07);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 15px;
    }
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 50px;
  }
`;
