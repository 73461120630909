import React, { useState } from 'react';
import {
  Wrap,
  Item,
  InfoWrap,
  Amounts,
  AmountItem,
  DateOperation,
  Arrow,
  Head,
  ContentItem,
  ContentInfo,
  CodeOperation,
} from './styles';

const ItemOperationPending = ({ data }) => {
  const [openDetail, setOpenDetail] = useState(null);
  return (
    <>
      <Wrap>
        {data.map((item, key) => {
          const currencySent = item.currency_sent === 'PEN' ? 'S/ ' : '$ ';
          const originBankCurrency =
            item.origin_bank.currency === 'PEN' ? 'Soles ' : 'Dólares ';
          const DestinationBankCurrency =
            item.destination_bank.currency === 'PEN' ? 'Soles ' : 'Dólares ';
          const currencyReceived = item.currency_received === 'PEN' ? 'S/ ' : '$ ';
          const amountReceived = item.amount_received ? item.amount_received : '0.00';

          return (
            <Item key={key} className={key === openDetail ? 'active' : ''}>
              <Head>
                <InfoWrap>
                  <Amounts>
                    <AmountItem data-id={item.id}>
                      <span>Enviaste</span>
                      <p>
                        {currencySent} {item.amount_sent}
                      </p>
                    </AmountItem>
                    <AmountItem>
                      <span>Recibirás</span>
                      <p>
                        {currencyReceived} {amountReceived}
                      </p>
                    </AmountItem>
                  </Amounts>
                  <DateOperation>
                    {item.created_date} - <strong>{item.created_hour}</strong>
                  </DateOperation>
                  <CodeOperation>
                    <span>Código:</span>
                    <strong>#{item.code_operation}</strong>
                  </CodeOperation>
                </InfoWrap>
                <Arrow
                  onClick={() => {
                    setOpenDetail(key);
                  }}
                >
                  <span className='icon-arrow-select'></span>
                </Arrow>
              </Head>
              <ContentItem>
                <ContentInfo>
                  <span>Origen</span>
                  <p>
                    <strong>
                      {originBankCurrency} | {item.origin_bank.name}
                    </strong>
                    {item.origin_bank.number_account}
                  </p>
                </ContentInfo>
                <ContentInfo>
                  <span>Destino</span>
                  <p>
                    <strong>
                      {DestinationBankCurrency} | {item.destination_bank.name}
                    </strong>
                    {item.destination_bank.number_account}
                  </p>
                </ContentInfo>
              </ContentItem>
            </Item>
          );
        })}
      </Wrap>
    </>
  );
};

export default ItemOperationPending;
