import React from 'react';
import FormEditProfileData from '../components/FormEditProfileData';
const EditProfileData = ({ desktop, history, user, generalInfo }) => {
  return (
    <>
      <FormEditProfileData
        generalInfo={generalInfo}
        user={user}
        desktop={desktop}
        history={history}
      />
    </>
  );
};

export default EditProfileData;
