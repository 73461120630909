import React from 'react';
import { TabItem, TabsContainer } from '../NavTabs';
import { useHistory, useLocation } from 'react-router-dom';
const TabProfile = () => {
  const history = useHistory();
  const currentUrl = useLocation();
  return (
    <TabsContainer className='tabs-edit-profiledata' my='15px'>
      <TabItem
        className={currentUrl.pathname === '/edit-profile-data' ? 'active' : null}
      // onClick={() => {
      //   history.push('/edit-profile-data');
      // }}
      >
        <a href="/edit-profile-data">Personal</a>
      </TabItem>
      <TabItem
        className={currentUrl.pathname === '/edit-profile-identity' ? 'active' : null}
        onClick={() => {
          history.push('/edit-profile-identity');
        }}
      >
        Identidad
      </TabItem>
      <TabItem
        className={
          currentUrl.pathname === '/edit-profile-company-paso-1' ||
            currentUrl.pathname === '/edit-profile-company-paso-2'
            ? 'active'
            : null
        }
        onClick={() => {
          history.push('/edit-profile-company-paso-1');
        }}
      >
        Empresa
      </TabItem>
    </TabsContainer>
  );
};

export default TabProfile;
