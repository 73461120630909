import { connect } from 'react-redux';
import { getValidateCode } from '../../store/users';

import FormCodeVerification from './FormCodeVerification';

const mapStateToProps = (state) => {
  return {
    validateCode: state.userState.validateCode,
    codeSecurity: state.userState.codeSecurity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getValidateCode: (data) => dispatch(getValidateCode(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(FormCodeVerification);
