import React, { useRef } from 'react';
import { WrapPage } from '../theme/shared';
import TextMotto from '../components/TextMotto';
import OperationForm from '../components/OperationForm';
import PopUp from '../components/Popup';
import { Typography } from '../components/Text';
import { connect } from 'react-redux';
import { sendSimulation } from '../store/operations/operations';
import Disclaimer from '../components/Disclaimers';
import Markdown from 'react-markdown/with-html';
const Simulador = ({ history, sendSimulation, desktop, receiveRate, generalInfo }) => {
  const popupRef = useRef(null);
  const temp = useRef(null);

  const closedProcess = () => {
    sendSimulation(temp.current);
    sessionStorage.seeHours = true;
    history.push('/login');
  };
  return (
    <>
      <PopUp ref={popupRef} onClose={() => closedProcess()}>
        <img
          src='/assets/images/icon-big-clock.svg'
          alt=''
          style={{ width: 65, margin: '0 auto', display: 'block', marginBottom: 20 }}
        />
        {generalInfo && (
          <Typography className='info-general' size='14px' center>
            <Markdown escapeHtml={false}>
              {generalInfo.info_operation.business_hours}
            </Markdown>
          </Typography>
        )}
      </PopUp>
      <WrapPage className={`simulador-page ${desktop || ''}`}>
        <TextMotto className='simulador-page' />
        <OperationForm
          history={history}
          className={`simulador-page ${desktop || ''}`}
          simulator
          receiveRate={receiveRate}
          onSubmit={(p) => {
            temp.current = p;
            if (sessionStorage.seeHours) {
              sendSimulation(temp.current);
              history.push('/login');
            } else {
              popupRef.current.open();
            }
          }}
        />
        <Disclaimer />
      </WrapPage>
    </>
  );
};

const mapStateToProps = ({ operationState }) => ({
  operationSimulation: operationState.operationSimulation,
});
const mapDispatchToProps = (dispatch) => ({
  sendSimulation: (data) => dispatch(sendSimulation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Simulador);
