import React, { useEffect, useRef } from 'react';
import { CntButton, Button, WrapError, TextError } from '../../theme/global';
import { withFormik, Form, ErrorMessage } from 'formik';
import withProps from 'recompose/withProps';
import Input, { PasswordInput, Container } from '../InputRounded';
import compose from 'recompose/compose';
import PopUp from '../Popup';
import Alert from '../Alert';
import { emailRegex } from '../../utils/regexs';
import { PolicyPrivacity, TitleForm } from './styles';

const FormNewPassword = ({
  onSubmit,
  statusCheckEmail,
  setFieldValue,
  desktop,
  history,
  processRegister,
  saveProcessRegister,
  resetForm,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    if (statusCheckEmail && statusCheckEmail.status === 303) {
      popupRef.current.open();
    }
    if (statusCheckEmail && statusCheckEmail.status === 404) {
      resetForm();
      history.push('/profile-data');
    }
  }, [statusCheckEmail, history, resetForm]);
  return (
    <>
      <TitleForm>Crear Cuenta</TitleForm>
      <Form>
        <Container>
          <Input
            name='email'
            className='no-margin-top'
            placeholder='Correo Electrónico'
            autoComplete='off'
            center='true'
          />
          <ErrorMessage name='email'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
          <Input
            name='password'
            placeholder='Contraseña Nueva'
            center
            component={PasswordInput}
            password
          />
          <ErrorMessage name='password'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
          <Input
            name='passConfirm'
            placeholder='Repetir Contraseña'
            center
            component={PasswordInput}
            password
          />
          <ErrorMessage name='passConfirm'>
            {(message) => (
              <WrapError>
                <TextError>{message}</TextError>
              </WrapError>
            )}
          </ErrorMessage>
        </Container>

        <CntButton center>
          <Button round onClick={onSubmit}>
            Continuar
          </Button>
        </CntButton>
        <PolicyPrivacity>
          <input
            type='checkbox'
            name='agreeTerms'
            id='acepta-terminos'
            defaultChecked
            onChange={(event) => {
              if (event.currentTarget.checked) {
                setFieldValue('agreeTerms', 'agree');
              } else {
                setFieldValue('agreeTerms', '');
              }
            }}
          />
          <label htmlFor='acepta-terminos'>
            <span></span>
            Acepto los{' '}
            <em>
              <a target='_blank' rel='noopener noreferrer' href='/terminos-condiciones'>
                Términos y Condiciones
              </a>
            </em>
          </label>
        </PolicyPrivacity>
        <ErrorMessage name='agreeTerms'>
          {(message) => (
            <WrapError>
              <TextError>{message}</TextError>
            </WrapError>
          )}
        </ErrorMessage>
      </Form>
      <PopUp ref={popupRef}>
        <Alert
          icon='error'
          title='Email ya existe'
          subtitle='debe iniciar sesión o iniciar el proceso de recuperación de cuenta. '
        />
      </PopUp>
    </>
  );
};

const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      email: '',
      password: '',
      passConfirm: '',
      agreeTerms: 'agree',
    }),
    handleSubmit(
      values,
      { setSubmitting, resetForm, props: { getCheckEmail, saveProcessRegister } }
    ) {
      const data = {
        email: values.email,
        password: values.password,
      };
      saveProcessRegister(data);
      getCheckEmail(data);
      setSubmitting(false);
      // resetForm();
    },
    validate(values) {
      const errors = {};
      if (!emailRegex.test(values.email)) errors.email = 'Email invalido';
      if (!values.password.trim()) {
        errors.password = 'La contraseña no debe estar vacía';
      } else if (values.password.length < 8) {
        errors.password = 'La contraseña debe tener minimo 8 caracteres';
      }
      if (values.password !== values.passConfirm)
        errors.passConfirm = 'Las contraseñas no coinciden';

      if (!values.agreeTerms)
        errors.agreeTerms = 'Debe Aceptar los Términos y condiciones';
      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);

export default enhacer(FormNewPassword);
