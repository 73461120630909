import React from 'react';
import { Item, ItemParrafo, ItemLi } from './styles';

function ItemsNews({ data }) {
  return (
    <>
      {data && (
        <Item to={`/novedad/${data.slug_news}`} className='ItemHome'>
          {data.image && (
            <img src={data.image} width='295' height='172' alt='' />
          )}
          <ItemParrafo>
            <p> {data.title} </p>
            <ul>
              {data.category_name && (
                <ItemLi>
                <span> {data.category_name} </span>
              </ItemLi>
              )}
              
              <ItemLi>
                <span>{data.last_date_post}</span>
              </ItemLi>
            </ul>
          </ItemParrafo>
        </Item>
      )}
    </>
  );
}

export default ItemsNews;
