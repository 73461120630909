import { connect } from 'react-redux';
import {
  getUrlImageDni,
  getUrlImageDniBack,
  saveProcessRegister,
  getCreateAccount,
  cleanImageDniFront,
  cleanImageDniBack,
  getInforCurrentUser,
  getEditInfoIdentity,
  getCountImageCamera,
  getCleanCountImageCamera,
} from '../../store/users';

import FormIdentityVerification from './FormIdentityVerification';

const mapStateToProps = (state) => {
  return {
    urlImageDni: state.userState.urlImageDni,
    urlImageDniBack: state.userState.urlImageDniBack,
    processRegister: state.userState.processRegister,
    countImageCamera: state.userState.countImageCamera,
    newAccount: state.userState.newAccount,
    accountsUser: state.userState.accountsUser,
    editInfoIdentity: state.userState.editInfoIdentity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUrlImageDni: (data) => dispatch(getUrlImageDni(data)),
  getUrlImageDniBack: (data) => dispatch(getUrlImageDniBack(data)),
  cleanImageDniFront: () => dispatch(cleanImageDniFront()),
  cleanImageDniBack: () => dispatch(cleanImageDniBack()),
  saveProcessRegister: (data) => dispatch(saveProcessRegister(data)),
  getCreateAccount: (data) => dispatch(getCreateAccount(data)),
  getInforCurrentUser: (id) => dispatch(getInforCurrentUser(id)),
  getEditInfoIdentity: (data) => dispatch(getEditInfoIdentity(data)),
  getCountImageCamera: (data) => dispatch(getCountImageCamera(data)),
  getCleanCountImageCamera: () => dispatch(getCleanCountImageCamera()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormIdentityVerification);
