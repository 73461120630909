import React from 'react';
import FrequentQuestionsTemplate from '../../components/web/FrequentsQuestions';
import { WrapNews } from '../../theme/global';
const FrequentsQuestions = ({ desktop }) => {
  return (
    <WrapNews className={`${desktop || ''} web-wrap`}>
      <FrequentQuestionsTemplate />
    </WrapNews>
  );
};

export default FrequentsQuestions;
