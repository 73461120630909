import {
  RECEIVE_OPERATION_SIMULATION,
  SET_OPERATION_HISTORY,
  RECEIVE_RESULT_ADD_OPERATION,
  REQUEST_ADD_OPERATION,
  CLEAN_OPERATIONS,
  OPERATIONS_PENDING,
  OPERATIONS_COMPLETED,
  OPERATIONS_CANCELED,
  REQUEST_HISTORY_OPERATIONS,
  CLEAN_HISTORY_PENDING,
  CLEAN_HISTORY_COMPLETED,
  CLEAN_HISTORY_CANCELED,
  RECEIVE_RATE,
  CLEAN_RATE
} from './types';
import moment from 'moment';

/*mocks*/
const canceledTransactionListsMock = [
  {
    opNumber: 3952850,
    date: moment(),
    from: {
      coin: 'USD',
      amount: 1000,
      nAccount: '00000',
    },
    to: {
      coin: 'PEN',
      amount: 3327.7,
      nAccount: '00000',
    },
    reason: 'Tiempo de Operación Finalizado',
  },
  {
    opNumber: 3952851,
    date: moment(),
    from: {
      coin: 'USD',
      amount: 1000,
      nAccount: '00000',
    },
    to: {
      coin: 'PEN',
      amount: 3327.7,
      nAccount: '00000',
    },
    reason: 'Tiempo de Operación Finalizado',
  },
];
const completeTransactionListsMock = [
  {
    opNumber: 3952850,
    date: moment(),
    from: {
      coin: 'USD',
      amount: 1000,
      nAccount: '191 943859285482 1 59',
    },
    to: {
      coin: 'PEN',
      amount: 3327.7,
      nAccount: '191 943859285482 1 57',
    },
  },
  {
    opNumber: 3952851,
    date: moment(),
    from: {
      coin: 'USD',
      amount: 1000,
      nAccount: '191 943859285482 1 59',
    },
    to: {
      coin: 'PEN',
      amount: 3327.7,
      nAccount: '191 943859285482 1 57',
    },
  },
];
const ongoinTransactionListsMock = [
  {
    opNumber: 3952850,
    date: moment(),
    from: {
      coin: 'USD',
      amount: 1000,
      nAccount: '191 943859285482 1 59',
    },
    to: {
      coin: 'PEN',
      amount: 3327.7,
      nAccount: '191 943859285482 1 57',
    },
  },
  {
    opNumber: 3952851,
    date: moment(),
    from: {
      coin: 'USD',
      amount: 1000,
      nAccount: '191 943859285482 1 59',
    },
    to: {
      coin: 'PEN',
      amount: 3327.7,
      nAccount: '191 943859285482 1 57',
    },
  },
];

const INITIAL_STATE = {
  operationSimulation: null,
  resultOperation: null,
  loading: false,
  operationHistory: {
    canceled: canceledTransactionListsMock, // [],
    completed: completeTransactionListsMock, // [],
    ongoing: ongoinTransactionListsMock, // []
    $meta: {
      // used in requset time
      lastFetch: null,
      cached: false,
    },
  },
  operationsPending: null,
  operationsCompleted: null,
  operationsCanceled: null,
  receiveRate: null,
};

export const operationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_OPERATION_SIMULATION:
      return {
        ...state,
        operationSimulation: action.operationSimulation,
      };
    case SET_OPERATION_HISTORY:
      return {
        ...state,
        operationHistory: action.history,
      };
    case REQUEST_ADD_OPERATION:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_HISTORY_OPERATIONS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_RESULT_ADD_OPERATION:
      return {
        ...state,
        resultOperation: action.resultOperation,
        loading: false,
      };
    case OPERATIONS_PENDING:
      return {
        ...state,
        operationsPending: action.operationsPending,
        loading: false,
      };
    case OPERATIONS_COMPLETED:
      return {
        ...state,
        operationsCompleted: action.operationsCompleted,
        loading: false,
      };
    case OPERATIONS_CANCELED:
      return {
        ...state,
        operationsCanceled: action.operationsCanceled,
        loading: false,
      };
    case RECEIVE_RATE:
      return {
        ...state,
        receiveRate: action.receiveRate,
        loading: false,
      };
    
    case CLEAN_OPERATIONS:
      return INITIAL_STATE;

    case CLEAN_HISTORY_PENDING:
      return {
        ...state,
        operationsPending: INITIAL_STATE.operationsPending,
      };

    case CLEAN_RATE:
      return {
        ...state,
        receiveRate: INITIAL_STATE.receiveRate,
      };
    case CLEAN_HISTORY_COMPLETED:
      return {
        ...state,
        operationsCompleted: INITIAL_STATE.operationsCompleted,
      };
    case CLEAN_HISTORY_CANCELED:
      return {
        ...state,
        operationsCanceled: INITIAL_STATE.operationsCanceled,
      };

    default:
      return state;
  }
};
