import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';
export const WrapDetalle = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 22px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  box-sizing: border-box;
`;

export const Title = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  h2,
  p {
    width: 50%;
    font-weight: normal;
    font-family: ${fonts.lato400};
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: #717171;
    opacity: 0.7;
    margin: 0;
    display: inline-block;
  }
  strong {
    font-family: ${fonts.lato800};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #3aa229;
  }
`;

export const ShowVoucher = styled.button`
  position: absolute;
  cursor: pointer;
  z-index: 10;
  width: 80px;
  height: 30px;
  right: 10px;
  top: 10px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
  letter-spacing: -0.05em;
  font-family: ${fonts.lato800};
  color: #3aa229;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ShowBoleta = styled.a`
  cursor: pointer;
  z-index: 10;
  width: 150px;
  height: 30px;
  padding: 0 5px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
  letter-spacing: -0.05em;
  font-family: ${fonts.lato800};
  color: #3aa229;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #3aa229;
    color: #ffffff;
  }
`;
export const EnvolverItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 15px;
  & > * {
    width: 48%;
    margin: 1%;
    margin-bottom: 11px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 16px;
  }
`;

export const ItemDetalle = styled.div`
  position: relative;
  &.full {
    width: 100%;
    strong {
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
  p {
    margin: 0;
    display: inline-block;
    line-height: 1;
    padding: 0;
  }
  span {
    font-family: ${fonts.lato400};
    font-size: 12px;
    letter-spacing: -0.05em;
    color: #717171;
    opacity: 0.7;
    margin-bottom: 3px;
  }
  strong,
  input {
    border: none;
    font-family: ${fonts.lato800};
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.05em;
    color: #3aa229;
    display: block;
  }
`;

export const DetalleCuenta = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 11px;
  position: relative;
  h2 {
    margin: 0;
    font-family: ${fonts.lato400};
    font-size: 16px;
    letter-spacing: -0.03em;
    color: #717171;
    opacity: 0.7;
  }
  p {
    margin: 0;
    span {
      font-family: ${fonts.lato400};
      font-size: 13px;
      letter-spacing: -0.02em;
      color: #717171;
      opacity: 0.7;
    }
    strong {
      font-family: ${fonts.lato800};
      font-size: 13px;
      letter-spacing: -0.03em;
      color: #3aa229;
    }
  }
`;

export const DetalleOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const DetalleLink = styled.button`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 36px;
  color: #717171;
  width: 47%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  font-size: 13px;
  margin-bottom: 5px;
  padding: 0 10px;
  text-align: center;
  &:disabled {
    background-color: #eeeeee;
    cursor: inherit;
    &:hover {
      background-color: #eeeeee;
      color: #717171;
    }
  }
  @media (min-width: 1025px) {
    transition: all 0.2s ease-in;
    &:hover {
      color: white;
      background-color: #3aa229;
      cursor: pointer;
    }
  }
`;

export const EditarInput = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 10px 18px;
  margin-bottom: 10px;
  label {
    font-family: ${fonts.lato400};
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.05em;
    color: #717171;
    opacity: 0.7;
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    height: 30px;
    color: #3aa229;
    border: none;
    box-sizing: border-box;
  }
  input:disabled {
    background-color: #ccc;
    opacity: 0.5;
  }
`;
