import React, { useEffect } from 'react';
import Markdown from 'react-markdown/with-html';
import {
  WrapContainer,
  ItemFlex,
  Info,
  Section,
  BaseCelphone,
  ChangeDollarImg,
} from './style';

const ChangeDollar = ({ fetchChangeDollar, changeDollar }) => {
  useEffect(() => {
    fetchChangeDollar();
  }, [fetchChangeDollar]);

  return (
    <Section>
      <WrapContainer>
        <BaseCelphone>
          <img src='/static/image/base-celular.svg' width='330' alt='' />
        </BaseCelphone>
        {changeDollar && Object.keys(changeDollar).length && (
          <ChangeDollarImg>
            <figure>
              <img src={changeDollar.image_change.url} width='260' alt='' />
            </figure>
            <Info>
              <h2>{changeDollar.title_change}</h2>
              <Markdown source={changeDollar.description_change} escapeHtml={false} />
            </Info>
            <ItemFlex>
              <span className='change_dollar_img1'>
                <img src={changeDollar.image_ios.url} width='130' alt='' />
              </span>
              <span className='change_dollar_img2'>
                <img src={changeDollar.image_android.url} width='149' alt='' />
              </span>
            </ItemFlex>
          </ChangeDollarImg>
        )}
      </WrapContainer>
    </Section>
  );
};

export default ChangeDollar;
