import {
  REQUEST_USER_DASHBOARD,
  RECEIVE_USER_DASHBOARD,
  INFO_CURRENT_USER_DASHBOARD,
  CLEAN_USER_ROLE_DASHBOARD,
  CHECK_EMAIL_USER_DASHBOARD,
  RECEIVE_CODE_VERIFICATION_DASHBOARD,
  VALIDATE_CODE_VERIFICATION_DASHBOARD,
  SET_NEW_PASSWORD_DASHBOARD,
  CLEAN_VALIDATE_CODE_DASHBOARD,
  CLEAN_CHECK_EMAIL_DASHBOARD,
  USER_OFF_LINE_DASHBOARD,
} from './types';
const INITIAL_STATE = {
  userDashboard: null,
  createAccountDashboard: null,
  loadingDashboard: false,
  infoCurrentUserDashboard: null,
  statusCheckEmailDashboard: null,
  codeSecurityDashboard: null,
  validateCodeDashboard: null,
  newPasswordDashboard: null,
};

export const userDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_USER_DASHBOARD:
      return {
        ...state,
        loadingDashboard: true,
      };
    case RECEIVE_USER_DASHBOARD:
      return {
        ...state,
        userDashboard: action.userDashboard,
        loadingDashboard: false,
      };
    case USER_OFF_LINE_DASHBOARD:
      return {
        ...state,
        userDashboard: action.userOffLine,
        loading: false,
      };
    case INFO_CURRENT_USER_DASHBOARD:
      return {
        ...state,
        infoCurrentUserDashboard: action.infoCurrentUserDashboard,
        loadingDashboard: false,
      };
    case CHECK_EMAIL_USER_DASHBOARD:
      return {
        ...state,
        statusCheckEmailDashboard: action.checkEmailDashboard,
        loadingDashboard: false,
      };
    case RECEIVE_CODE_VERIFICATION_DASHBOARD:
      return {
        ...state,
        codeSecurityDashboard: action.codeSecurityDashboard,
        loadingDashboard: false,
      };
    case VALIDATE_CODE_VERIFICATION_DASHBOARD:
      return {
        ...state,
        validateCodeDashboard: action.validateCodeDashboard,
        loadingDashboard: false,
      };
    case SET_NEW_PASSWORD_DASHBOARD:
      return {
        ...state,
        newPasswordDashboard: action.newpasswordDashboard,
        loadingDashboard: false,
      };
    case CLEAN_USER_ROLE_DASHBOARD:
      return {
        INITIAL_STATE,
      };
    case CLEAN_VALIDATE_CODE_DASHBOARD:
      return {
        ...state,
        validateCodeDashboard: INITIAL_STATE.validateCodeDashboard,
      };
    case CLEAN_CHECK_EMAIL_DASHBOARD:
      return {
        ...state,
        statusCheckEmailDashboard: INITIAL_STATE.statusCheckEmailDashboard,
      };
    default:
      return state;
  }
};
