import React from 'react';
import FormEditCompanyData from '../components/FormEditCompanyData';
const EditCompanyData = ({ desktop, history }) => {
  return (
    <>
      <FormEditCompanyData desktop={desktop} history={history} />
    </>
  );
};

export default EditCompanyData;
