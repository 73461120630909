import React, { useEffect } from 'react';
import { CntButton, Button } from '../theme/global';
import Tabs from '../components/OperationHistoryTabsButtons';
import { Title } from '../components/Text';
import ItemOperationCanceled from '../components/ItemOperationCanceled';
import { connect } from 'react-redux';
import { getOperationCanceled, getcleanOpCanceled } from '../store/operations';
import { useIonViewDidLeave } from '@ionic/react';
import { WrapPage } from '../theme/shared';
const CanceledOperations = ({
  user,
  desktop,
  operationsCanceled,
  getOperationCanceled,
  getcleanOpCanceled,
  history,
}) => {
  useEffect(() => {
    if (user && !operationsCanceled) {
      const dataCurrentUser = {
        id: user.info.user_id,
        token: user.info.token,
      };
      getOperationCanceled(dataCurrentUser);
      return;
    }
  }, [getOperationCanceled, user, operationsCanceled]);
  const goSimulator = () => {
    history.push('/start-transfer');
  };
  useIonViewDidLeave(() => {
    getcleanOpCanceled();
  });
  return (
    <>
      <WrapPage className={`${desktop || ''} canceled-operations`}>
        <div>
          <Title marginBottom='0'>Historial de Operaciones</Title>
          <Tabs />

          {operationsCanceled && operationsCanceled.status === 200 ? (
            <>
              {operationsCanceled.result.map((item, key) => (
                <ItemOperationCanceled key={key} dataKey={key} data={item} />
              ))}
            </>
          ) : (
            <h3 className='text-no-operation'>No tienes operaciones finalizadas</h3>
          )}
        </div>
        <CntButton center slot='fixed' style={{ width: '100%', bottom: 0 }}>
          <Button
            type='button'
            onClick={() => {
              goSimulator();
            }}
          >
            NUEVA OPERACIÓN
          </Button>
        </CntButton>
      </WrapPage>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    operationsCanceled: state.operationState.operationsCanceled,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOperationCanceled: (id) => dispatch(getOperationCanceled(id)),
  getcleanOpCanceled: () => dispatch(getcleanOpCanceled()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CanceledOperations);
