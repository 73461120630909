import {
  REQUEST_USER,
  RECEIVE_USER,
  RECEIVE_CREATE_ACCOUNT,
  RECEIVE_ACCOUNTS_USER,
  REQUEST_ACCOUNTS_USER,
  SAVE_PROCESS_REGISTER_USER,
  CHECK_EMAIL_USER,
  RECEIVE_CODE_VERIFICATION,
  VALIDATE_CODE_VERIFICATION,
  SET_NEW_PASSWORD,
  RECEIVE_URL_IMAGE,
  RECEIVE_PERFIL_OPERATION,
  CLEAN_IMAGE_URL,
  RECEIVE_URL_IMAGE_DNI,
  RECEIVE_URL_IMAGE_DNI_BACK,
  RECEIVE_NEW_ACCOUNT_BANK,
  CLEAN_IMAGE_DNI_FRONT,
  CLEAN_IMAGE_DNI_BACK,
  CLEAN_PROCESS_REGISTER,
  CREATE_INFO_COMPANY,
  EDIT_INFO_PERSONAL,
  EDIT_INFO_IDENTITY,
  EDIT_INFO_COMPANY,
  INFO_CURRENT_USER,
  DELETE_ACCOUNT_BANK,
  SAVE_CURRENT_ACCOUNT_BANK,
  EDIT_ACCOUNT_BANK,
  CLEAN_CURRENT_ACCOUNT_BANK,
  SAVE_PROCESS_UPDATE_COMPANY,
  CLEAN_EDIT_INFO_COMPANY,
  CLEAN_EDIT_INFO_USER,
  CLEAN_USER_INFO,
  CLEAN_NEW_ACCOUNT_BANK,
  RECEIVE_ALERTS_CONFIG,
  COUNT_IMAGE_CAMERA,
  CLEAN_COUNT_IMAGE_CAMERA,
  CLEAN_ALERTS_CONFIG,
  SHOW_ALERT_ERROR,
  CLEAN_ALERT_ERROR,
  USER_OFF_LINE,
} from './types';

export const requestUser = () => ({
  type: REQUEST_USER,
});

export const cleanImageUrl = () => ({
  type: CLEAN_IMAGE_URL,
});
export const cleanEditInfoCompany = () => ({
  type: CLEAN_EDIT_INFO_COMPANY,
});
export const cleanEditInfoUser = () => ({
  type: CLEAN_EDIT_INFO_USER,
});

export const cleanProcessRegister = () => ({
  type: CLEAN_PROCESS_REGISTER,
});

export const cleanCurrentAccountBank = () => ({
  type: CLEAN_CURRENT_ACCOUNT_BANK,
});

export const cleanImageDniFront = () => ({
  type: CLEAN_IMAGE_DNI_FRONT,
});

export const cleanImageDniBack = () => ({
  type: CLEAN_IMAGE_DNI_BACK,
});

export const receiveUser = (user) => ({
  type: RECEIVE_USER,
  user: user,
});

export const receiveCreateAccount = (data) => ({
  type: RECEIVE_CREATE_ACCOUNT,
  newAccount: data,
});

export const requestAccountsUser = () => ({
  type: REQUEST_ACCOUNTS_USER,
});

export const receiveAccountsUser = (data) => ({
  type: RECEIVE_ACCOUNTS_USER,
  data: data,
});

export const saveProcessRegisterUser = (data) => ({
  type: SAVE_PROCESS_REGISTER_USER,
  process_register: data,
});

export const countImageCamera = (data) => ({
  type: COUNT_IMAGE_CAMERA,
  countImageCamera: data,
});

export const checkEmailUser = (email) => ({
  type: CHECK_EMAIL_USER,
  checkEmail: email,
});

export const receiveCodeSecurity = (code) => ({
  type: RECEIVE_CODE_VERIFICATION,
  codeSecurity: code,
});

export const receiveValidateCode = (code) => ({
  type: VALIDATE_CODE_VERIFICATION,
  validateCode: code,
});

export const setNewPassword = (data) => ({
  type: SET_NEW_PASSWORD,
  newpassword: data,
});

export const receiveUrlImage = (data) => ({
  type: RECEIVE_URL_IMAGE,
  urlImage: data,
});

export const receiveUrlImageDni = (data) => ({
  type: RECEIVE_URL_IMAGE_DNI,
  urlImageDni: data,
});

export const receiveNewAccountBank = (data) => ({
  type: RECEIVE_NEW_ACCOUNT_BANK,
  newAccountBank: data,
});

export const receiveUrlImageDniBack = (data) => ({
  type: RECEIVE_URL_IMAGE_DNI_BACK,
  urlImageDniBack: data,
});
export const receivePerfilOperation = (data) => ({
  type: RECEIVE_PERFIL_OPERATION,
  perfilOperation: data,
});
export const receiveCreateInfoCompany = (data) => ({
  type: CREATE_INFO_COMPANY,
  createInfoCompany: data,
});
export const receiveEditInfoPersonal = (data) => ({
  type: EDIT_INFO_PERSONAL,
  editInfoPersonal: data,
});
export const receiveEditInfoIdentity = (data) => ({
  type: EDIT_INFO_IDENTITY,
  editInfoIdentity: data,
});
export const receiveEditInfoCompany = (data) => ({
  type: EDIT_INFO_COMPANY,
  editInfoCompany: data,
});
export const receiveInfoCurrentUser = (data) => ({
  type: INFO_CURRENT_USER,
  infoCurrentUser: data,
});

export const receiveDeleteAccountBank = (data) => ({
  type: DELETE_ACCOUNT_BANK,
  deleteAccountBank: data,
});

export const saveCurrentAccountBank = (data) => ({
  type: SAVE_CURRENT_ACCOUNT_BANK,
  currentAccountBank: data,
});

export const receiveEditAccountBank = (data) => ({
  type: EDIT_ACCOUNT_BANK,
  editAccountBank: data,
});

export const saveProcessUpdateCompany = (data) => ({
  type: SAVE_PROCESS_UPDATE_COMPANY,
  processUpdateCompany: data,
});
export const cleanUserInfo = () => ({
  type: CLEAN_USER_INFO,
});
export const cleanNewAccountBank = () => ({
  type: CLEAN_NEW_ACCOUNT_BANK,
});
export const cleanCountImageCamera = () => ({
  type: CLEAN_COUNT_IMAGE_CAMERA,
});

export const receiveAlertsConfig = (newConfig) => ({
  type: RECEIVE_ALERTS_CONFIG,
  newConfig,
});

export const cleanAlertsConfig = () => ({
  type: CLEAN_ALERTS_CONFIG,
});

export const showAlertError = (data) => ({
  type: SHOW_ALERT_ERROR,
  showAlertError: data,
});
export const cleanAlertError = () => ({
  type: CLEAN_ALERT_ERROR,
});

export const receiveUserOffLine = (data) => ({
  type: USER_OFF_LINE,
  userOffLine: data,
});
