import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Item from '../../../components/web/Items';
import NewDetail from '../../../components/web/NewDetail';
import { SliderBtn, SliderLink } from '../../../components/web/SliderNews/styles';
import SliderNews from '../../../components/web/SliderNews';
import TitlePages from '../../../components/web/TitlePages/Titulo';
import {
  Redes,
  RedesIcon,
  Wrap,
  Title,
} from '../../../components/web/SocialNetworks/styles';
import {
  Wancho,
  GetBackButton,
  GetBackBox,
  NewBoxLeft,
  NewBoxRight,
  NewWrapper,
  NewBoxCenter,
  NewBoxRightBtn,
  NewTitle,
} from '../../../components/web/NewDetail/styles';

const NewsDetail = ({ getNews2, fetchNews2, novedades, fetchNovedades }) => {
  const slugNews = useParams('slug');
  const history = useHistory();

  useEffect(() => {
    // if (getNews2) {
    //   return;
    // }
    fetchNovedades();
    if (slugNews) {
      fetchNews2(slugNews);
    }
  }, [fetchNews2, slugNews, fetchNovedades]);
  const shareFacebook = () => {
    const facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + document.URL,
      /* "https://www.facebook.com/sharer/sharer.php?u=http://sr-cambio.katudev.com/novedad/interfaz-de-usuario-que-respeta-el-contexto", */
      'facebook-popup',
      'height=350,width=600'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  };
  const shareLinked = () => {
    var linkedinWindow = window.open(
      'https://www.linkedin.com/shareArticle?mini=true&url=' + document.URL,
      'linkedin-popup',
      'height=350,width=600'
    );
    if (linkedinWindow.focus) {
      linkedinWindow.focus();
    }
    return false;
  };

  return (
    <>
      {getNews2 && (
        <>
          <Wancho>
            <TitlePages
              className='title_pregunta_frecuente'
              title='Nuestro Blog'
              subtitle='Últimas Novedades'
            />
            <GetBackBox>
              <GetBackButton onClick={() => history.goBack()}>
                <i className='icon-arrow-right'> </i> <span> Regresar </span>
              </GetBackButton>
            </GetBackBox>
            <NewWrapper>
              <NewBoxLeft>
                <NewDetail dataNews={getNews2} />
                <Wrap className='SocialNewDetail'>
                  <Title>Compartir</Title>
                  <Redes>
                    <RedesIcon
                      className='icon-fb'
                      onClick={() => shareFacebook()}
                    ></RedesIcon>
                    <RedesIcon
                      className='icon-linked-in'
                      onClick={() => shareLinked()}
                    ></RedesIcon>
                  </Redes>
                </Wrap>
              </NewBoxLeft>

              {(getNews2 && getNews2.featured_post) &&(
              <NewBoxRight>
              <NewBoxRightBtn>
                <span> Más Destacados </span>
              </NewBoxRightBtn>
              <NewBoxCenter>
                {getNews2.featured_post && (
                  <>
                    {getNews2.featured_post.map((item, key) => {
                      return <Item data={item} key={key} />;
                    })}
                  </>
                )}
                <SliderBtn className='buttonNews'>
                  <SliderLink to='/novedades/destacados'>Ver todas</SliderLink>
                </SliderBtn>
              </NewBoxCenter>
            </NewBoxRight>

                )}



            </NewWrapper>
          </Wancho>
          {(getNews2 && getNews2.related_post) && (
            <>
              <Wancho>
                <NewTitle>Publicaciones Relacionadas</NewTitle>
              </Wancho>
              <SliderNews data={getNews2.related_post}> </SliderNews>
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewsDetail;
