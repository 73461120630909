import {
  REQUEST_USER,
  RECEIVE_USER,
  RECEIVE_CREATE_ACCOUNT,
  REQUEST_ACCOUNTS_USER,
  RECEIVE_ACCOUNTS_USER,
  SAVE_PROCESS_REGISTER_USER,
  CHECK_EMAIL_USER,
  RECEIVE_CODE_VERIFICATION,
  VALIDATE_CODE_VERIFICATION,
  SET_NEW_PASSWORD,
  RECEIVE_URL_IMAGE,
  RECEIVE_URL_IMAGE_DNI,
  RECEIVE_URL_IMAGE_DNI_BACK,
  RECEIVE_PERFIL_OPERATION,
  CLEAN_IMAGE_URL,
  RECEIVE_NEW_ACCOUNT_BANK,
  CLEAN_IMAGE_DNI_FRONT,
  CLEAN_IMAGE_DNI_BACK,
  CLEAN_PROCESS_REGISTER,
  CREATE_INFO_COMPANY,
  EDIT_INFO_PERSONAL,
  EDIT_INFO_IDENTITY,
  EDIT_INFO_COMPANY,
  INFO_CURRENT_USER,
  DELETE_ACCOUNT_BANK,
  SAVE_CURRENT_ACCOUNT_BANK,
  EDIT_ACCOUNT_BANK,
  CLEAN_CURRENT_ACCOUNT_BANK,
  SAVE_PROCESS_UPDATE_COMPANY,
  CLEAN_EDIT_INFO_COMPANY,
  CLEAN_EDIT_INFO_USER,
  CLEAN_USER_INFO,
  CLEAN_NEW_ACCOUNT_BANK,
  RECEIVE_ALERTS_CONFIG,
  COUNT_IMAGE_CAMERA,
  CLEAN_COUNT_IMAGE_CAMERA,
  CLEAN_ALERTS_CONFIG,
  SHOW_ALERT_ERROR,
  CLEAN_ALERT_ERROR,
  USER_OFF_LINE,
} from './types';
const INITIAL_STATE = {
  user: null,
  newAccount: null,
  accountsUser: null,
  loading: false,
  processRegister: null,
  statusCheckEmail: null,
  codeSecurity: null,
  validateCode: null,
  newPassword: null,
  urlImage: null,
  urlImageDni: null,
  urlImageDniBack: null,
  perfilOperation: null,
  newAccountBank: null,
  createInfoCompany: null,
  editInfoPersonal: null,
  editInfoIdentity: null,
  editInfoCompany: null,
  infoCurrentUser: null,
  deleteAccountBank: null,
  currentAccountBank: null,
  editAccountBank: null,
  processUpdateCompany: null,
  countImageCamera: [],
  alertsConfig: null,
  showAlertError: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_USER:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_USER:
      return {
        ...state,
        user: action.user,
        loading: false,
      };
    case USER_OFF_LINE:
      return {
        ...state,
        user: action.userOffLine,
        loading: false,
      };
    case RECEIVE_CREATE_ACCOUNT:
      return {
        ...state,
        newAccount: action.newAccount,
        loading: false,
      };
    case REQUEST_ACCOUNTS_USER:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_ACCOUNTS_USER:
      return {
        ...state,
        accountsUser: action.data,
        loading: false,
      };
    case SAVE_PROCESS_REGISTER_USER:
      return {
        ...state,
        processRegister: action.process_register,
        loading: false,
      };
    case COUNT_IMAGE_CAMERA:
      return {
        ...state,
        countImageCamera: [...state.countImageCamera, action.countImageCamera],
        loading: false,
      };
    case SAVE_PROCESS_UPDATE_COMPANY:
      return {
        ...state,
        processUpdateCompany: action.processUpdateCompany,
        loading: false,
      };
    case CHECK_EMAIL_USER:
      return {
        ...state,
        statusCheckEmail: action.checkEmail,
        loading: false,
      };
    case RECEIVE_CODE_VERIFICATION:
      return {
        ...state,
        codeSecurity: action.codeSecurity,
        loading: false,
      };
    case VALIDATE_CODE_VERIFICATION:
      return {
        ...state,
        validateCode: action.validateCode,
        loading: false,
      };
    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.newpassword,
        loading: false,
      };
    case RECEIVE_URL_IMAGE:
      return {
        ...state,
        urlImage: action.urlImage,
        loading: false,
      };
    case RECEIVE_URL_IMAGE_DNI:
      return {
        ...state,
        urlImageDni: action.urlImageDni,
        loading: false,
      };
    case RECEIVE_URL_IMAGE_DNI_BACK:
      return {
        ...state,
        urlImageDniBack: action.urlImageDniBack,
        loading: false,
      };

    case CLEAN_IMAGE_URL:
      return {
        ...state,
        urlImage: INITIAL_STATE.urlImage,
      };

    case CLEAN_IMAGE_DNI_FRONT:
      return {
        ...state,
        urlImageDni: INITIAL_STATE.urlImageDni,
      };
    case CLEAN_IMAGE_DNI_BACK:
      return {
        ...state,
        urlImageDniBack: INITIAL_STATE.urlImageDniBack,
      };
    case CLEAN_PROCESS_REGISTER:
      return {
        ...state,
        processRegister: INITIAL_STATE.processRegister,
      };
    case CLEAN_EDIT_INFO_COMPANY:
      return {
        ...state,
        editInfoCompany: INITIAL_STATE.editInfoCompany,
      };
    case CLEAN_EDIT_INFO_USER:
      return {
        ...state,
        editInfoPersonal: INITIAL_STATE.editInfoPersonal,
      };
    case CLEAN_COUNT_IMAGE_CAMERA:
      return {
        ...state,
        countImageCamera: INITIAL_STATE.countImageCamera,
      };

    case CLEAN_CURRENT_ACCOUNT_BANK:
      return {
        ...state,
        currentAccountBank: INITIAL_STATE.currentAccountBank,
      };
    case CLEAN_NEW_ACCOUNT_BANK:
      return {
        ...state,
        newAccountBank: INITIAL_STATE.newAccountBank,
      };
    case CLEAN_ALERTS_CONFIG:
      return {
        ...state,
        alertsConfig: INITIAL_STATE.alertsConfig,
      };

    case CLEAN_USER_INFO:
      return INITIAL_STATE;

    case RECEIVE_PERFIL_OPERATION:
      return {
        ...state,
        perfilOperation: action.perfilOperation,
        loading: false,
      };
    case RECEIVE_NEW_ACCOUNT_BANK:
      return {
        ...state,
        newAccountBank: action.newAccountBank,
        loading: false,
      };
    case CREATE_INFO_COMPANY:
      return {
        ...state,
        createInfoCompany: action.createInfoCompany,
        loading: false,
      };
    case EDIT_INFO_PERSONAL:
      return {
        ...state,
        editInfoPersonal: action.editInfoPersonal,
        loading: false,
      };
    case EDIT_INFO_IDENTITY:
      return {
        ...state,
        editInfoIdentity: action.editInfoIdentity,
        loading: false,
      };
    case EDIT_INFO_COMPANY:
      return {
        ...state,
        editInfoCompany: action.editInfoCompany,
        loading: false,
      };
    case INFO_CURRENT_USER:
      return {
        ...state,
        infoCurrentUser: action.infoCurrentUser,
        loading: false,
      };
    case DELETE_ACCOUNT_BANK:
      return {
        ...state,
        deleteAccountBank: action.deleteAccountBank,
        loading: false,
      };
    case SAVE_CURRENT_ACCOUNT_BANK:
      return {
        ...state,
        currentAccountBank: action.currentAccountBank,
        loading: false,
      };
    case EDIT_ACCOUNT_BANK:
      return {
        ...state,
        editAccountBank: action.editAccountBank,
        loading: false,
      };
    case RECEIVE_ALERTS_CONFIG:
      return {
        ...state,
        loading: false,
        alertsConfig: action.newConfig,
      };
    case SHOW_ALERT_ERROR:
      return {
        ...state,
        loading: false,
        showAlertError: action.showAlertError,
      };
    case CLEAN_ALERT_ERROR:
      return {
        ...state,
        showAlertError: INITIAL_STATE.showAlertError,
      };
    default:
      return state;
  }
};
