import { connect } from 'react-redux';
import {
  getNewAccountBank,
  requestCleanProcessRegister,
  getCleanNewAccountBank,
} from '../../store/users';
import FormNewBankAccount from './FormNewBankAccount';

const mapStateToProps = (state) => {
  return {
    newAccountBank: state.userState.newAccountBank,
    newAccount: state.userState.newAccount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewAccountBank: (data) => dispatch(getNewAccountBank(data)),
  getCleanNewAccountBank: () => dispatch(getCleanNewAccountBank()),
  requestCleanProcessRegister: () => dispatch(requestCleanProcessRegister()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormNewBankAccount);
