import React, { useEffect } from 'react';
import {
  CntButton,
  Button,
  WrapError,
  TextError,
  WrapSelectItem,
  SelectItem,
} from '../../theme/global';
import { TabsContainer, TabItem } from '../NavTabs';
import { Teeny, Typography, Strong, Title } from '../Text';
import Input, { Container } from '../InputRounded';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import { ItemInput, WrapSmallInputs } from './styles';
import { WrapPage } from '../../theme/shared';
const FormAddAddress = ({
  processRegister,
  user,
  desktop,
  createInfoCompany,
  ubigeoDepartamentos,
  ubigeoProvincias,
  history,
}) => {
  useEffect(() => {
    if (!processRegister) {
      history.push('/simulador');
    }
    if (createInfoCompany && createInfoCompany.status === 200) {
      history.push('/registration-completed');
    }
  }, [createInfoCompany, processRegister, history, user]);

  return (
    <>
      <WrapPage className={`${desktop || ''} edit-profile-company step2`}>
        <Form>
          <div className='inner-page'>
            <TabsContainer>
              <TabItem className='strong'>
                Paso <Teeny color='primary'>01</Teeny>
              </TabItem>
              <TabItem className='strong'>
                Paso <Teeny color='primary'>02</Teeny>
              </TabItem>
              <TabItem className='active'>
                Paso <Teeny color='primary'>03</Teeny>
              </TabItem>
            </TabsContainer>
            <div>
              <Title className='title-edit-company'>Domicilio</Title>
              <Container>
                {ubigeoDepartamentos && (
                  <>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field
                          className='select-departamento'
                          component='select'
                          name='departamento_company'
                        >
                          <option value=''>Departamento</option>
                          {ubigeoDepartamentos.departamentos.map((item, key) => (
                            <option
                              key={key}
                              data-ubigeo={item.id_ubigeo}
                              value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                            >
                              {item.nombre_ubigeo}
                            </option>
                          ))}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='departamento_company'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                    <WrapSelectItem>
                      <SelectItem>
                        <Field
                          disabled={!ubigeoProvincias}
                          component='select'
                          name='provincia_company'
                        >
                          <option value=''>Provincia*</option>
                          {ubigeoProvincias && (
                            <>
                              {ubigeoProvincias.map((item, key) => (
                                <option
                                  key={key}
                                  data-ubigeo={item.id_ubigeo}
                                  value={`${item.id_ubigeo}-${item.nombre_ubigeo}`}
                                >
                                  {item.nombre_ubigeo}
                                </option>
                              ))}
                            </>
                          )}
                        </Field>
                      </SelectItem>
                      <ErrorMessage name='provincia_company'>
                        {(message) => (
                          <WrapError>
                            <TextError>{message}</TextError>
                          </WrapError>
                        )}
                      </ErrorMessage>
                    </WrapSelectItem>
                  </>
                )}

                <Input
                  className='input-text'
                  name='distrito_company'
                  placeholder='Distrito'
                />
                <ErrorMessage name='distrito_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <Input
                  className='input-text'
                  name='direccion_company'
                  placeholder='Dirección'
                />
                <ErrorMessage name='direccion_company'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>
                <WrapSmallInputs className='small-input'>
                  <ItemInput>
                    <Field name='nro_calle_company' placeholder='Nº' />
                    <ErrorMessage name='nro_calle_company'>
                      {(message) => (
                        <WrapError>
                          <TextError>{message}</TextError>
                        </WrapError>
                      )}
                    </ErrorMessage>
                  </ItemInput>
                  <ItemInput>
                    <Field name='piso_dpto_company' placeholder='Piso/dpto' />
                    <ErrorMessage name='piso_dpto_company'>
                      {(message) => (
                        <WrapError>
                          <TextError>{message}</TextError>
                        </WrapError>
                      )}
                    </ErrorMessage>
                  </ItemInput>
                  <ItemInput>
                    <Field name='codigo_postal_company' placeholder='C.P.' />
                    <ErrorMessage name='codigo_postal_company'>
                      {(message) => (
                        <WrapError>
                          <TextError>{message}</TextError>
                        </WrapError>
                      )}
                    </ErrorMessage>
                  </ItemInput>
                </WrapSmallInputs>
              </Container>
              <Typography
                color='primary'
                center
                size='13px'
                font='quaternary'
                style={{ marginTop: 8 }}
                className='required-fields'
              >
                *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
              </Typography>
            </div>
            <CntButton
              center
              slot='fixed'
              style={{ width: '100%', bottom: 0, zIndex: 999 }}
            >
              <Button round>Continuar</Button>
            </CntButton>
          </div>
        </Form>
      </WrapPage>
    </>
  );
};

const withForm = withFormik({
  validateOnBlur: false,
  mapPropsToValues: () => ({
    departamento_company: '',
    provincia_company: '',
    direccion_company: '',
    piso_dpto_company: '',
    distrito_company: '',
    nro_calle_company: '',
    codigo_postal_company: '',
  }),
  handleSubmit(
    values,
    { setSubmitting, props: { processRegister, getCreateInfoCompany, history, user } }
  ) {
    const dataUser = {
      pais_company: 'Perú',
      departamento_company: values.departamento_company,
      provincia_company: values.provincia_company,
      direccion_company: values.direccion_company,
      piso_dpto_company: values.piso_dpto_company,
      distrito_company: values.distrito_company,
      nro_calle_company: values.nro_calle_company,
      codigo_postal_company: values.codigo_postal_company,
      token: user.info.token,
      user_id: user.info.user_id,
      ...processRegister,
    };

    getCreateInfoCompany(dataUser);
    setSubmitting(false);
  },
  validate(values, { getUbigeoProvincias, ubigeoDepartamentos }) {
    const getProvinciaCurrent = () => {
      const selectDpto = document.querySelector('.select-departamento');
      const optionDpto = selectDpto.options[selectDpto.selectedIndex].getAttribute(
        'data-ubigeo'
      );
      getUbigeoProvincias(ubigeoDepartamentos.provincias[optionDpto]);
    };
    getProvinciaCurrent();
    const errors = {};
    if (!values.departamento_company) errors.departamento_company = 'Campo Obligatorio';
    if (!values.provincia_company) errors.provincia_company = 'Campo Obligatorio';
    if (!values.direccion_company) errors.direccion_company = 'Campo Obligatorio';
    if (!values.piso_dpto_company) errors.piso_dpto_company = 'Campo Obligatorio';
    if (!values.distrito_company) errors.distrito_company = 'Campo Obligatorio';
    if (!values.nro_calle_company) errors.nro_calle_company = 'Campo Obligatorio';
    if (!values.codigo_postal_company) errors.codigo_postal_company = 'Campo Obligatorio';

    return errors;
  },
});

export default withForm(FormAddAddress);
