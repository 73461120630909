import React from 'react';
import styled from 'styled-components/macro';
import { Strong } from '../Text';
const CameraBox = styled((p) => (
  <section {...p}>
    <svg viewBox='0 0 13 13'>
      <path d='M12 1H4C2.34315 1 1 2.34315 1 4V12' />
    </svg>
    <svg viewBox='215 0 13 13'>
      <path d='M216 1H224C225.657 1 227 2.34315 227 4V12' />
    </svg>
    <svg viewBox='0 87 13 13'>
      <path d='M12 99H4C2.34315 99 1 97.6569 1 96V88' />
    </svg>
    <svg viewBox='215 87 13 13'>
      <path d='M216 99H224C225.657 99 227 97.6569 227 96V88' />
    </svg>

    <img src='/assets/images/icon-camera.svg' alt='camera' />
    <Strong secondary size='14px'>
      Abrir Camara
    </Strong>
  </section>
))`
  --padding: 12px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 15px;
  border-radius: 10px;
  padding: var(--padding);
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 230px;
  height: 130px;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  user-select: none;
  svg {
    position: absolute;
    width: 13px;
    height: 13px;
    fill: none;
    path {
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--ion-color-secondary);
    }
    :nth-of-type(1) {
      top: var(--padding);
      left: var(--padding);
    }
    :nth-of-type(2) {
      top: var(--padding);
      right: var(--padding);
    }
    :nth-of-type(3) {
      bottom: var(--padding);
      left: var(--padding);
    }
    :nth-of-type(4) {
      bottom: var(--padding);
      right: var(--padding);
    }
  }
`;

export default CameraBox;
