import React from 'react';
import FormEditAddress from '../components/FormEditAddress';
const EditAddress = ({ desktop, user, history }) => {
  return (
    <>
      <FormEditAddress desktop={desktop} user={user} history={history} />
    </>
  );
};

export default EditAddress;
