import { connect } from 'react-redux';
import {
  fetchUserDash,
  getInforCurrentUserDash,
  getCleanUserRoleDash,
} from '../../../store/web/users/operations';

import LoginForm from './LoginForm';

const mapStateToProps = (state) => {
  return {
    userDashboard: state.userDashboardState.userDashboard,
    infoCurrentUserDashboard: state.userDashboardState.infoCurrentUserDashboard,
    loadingDashboard: state.userDashboardState.loadingDashboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserDash: (data) => dispatch(fetchUserDash(data)),
  getInforCurrentUserDash: (data) => dispatch(getInforCurrentUserDash(data)),
  getCleanUserRoleDash: () => dispatch(getCleanUserRoleDash()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
