import React, { useEffect, useState } from 'react';
import {
  WrapDashboard,
  ContainerDashboard,
  TitleDashboard,
  HeaderTop,
  HeaderChild,
  HeaderParent,
  HeaderWrap,
} from './styles';

import DashboardCliente from '../DashboardClientes';
import DashboardOperation from '../DashboardOperation';
import DashboardDetalle from '../DashboardDetalle';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const DashboardTemplate = ({
  fetchSearchClients,
  searchClients,
  userDashboard,
  isActiveButton,
  activeButton,
  fetchSelectOperation,
  selectOperation,
}) => {
  const [idClientCurrent, setIdClientCurrent] = useState(null);

  useEffect(() => {
    if (userDashboard && userDashboard.status === 200) {
      const token = userDashboard.info.token;
      fetchSearchClients(token);
      return;
    }
  }, [fetchSearchClients, userDashboard]);

  const getIdClientCurrent = (id) => {
    if (id) {
      setIdClientCurrent(id);
    } else {
      setIdClientCurrent(null);
    }
  };

  return (
    <WrapDashboard>
      <HeaderParent>
        <HeaderTop>
          <HeaderWrap>
            <HeaderChild
              onClick={() => {
                window.location.reload();
              }}
            >
              <img src='/assets/images/loop.svg' width={20} alt='' />
              <span>Actualizar Operaciones</span>
            </HeaderChild>
            <HeaderChild
              onClick={() => {
                sessionStorage.clear();
                Storage.remove({ key: 'currentUser' }).then( (data)=>{
                    window.location.replace('/login-dashboard');
                  }
                );
              }}
            >
              <img src='/assets/images/exit.svg' width={20} alt='' />
              <span>Cerrar Sesión</span>
            </HeaderChild>
          </HeaderWrap>
        </HeaderTop>
      </HeaderParent>
      <TitleDashboard onClick={() => isActiveButton(10)}>
        Dashboard de Operaciones
      </TitleDashboard>
      <ContainerDashboard>
        <DashboardCliente
          searchClients={searchClients}
          getIdClientCurrent={getIdClientCurrent}
          isActiveButton={isActiveButton}
        />
        <DashboardOperation
          fetchSelectOperation={fetchSelectOperation}
          selectOperation={selectOperation}
          idClientCurrent={idClientCurrent}
          isActiveButton={isActiveButton}
          activeButton={activeButton}
        />
        <DashboardDetalle />
      </ContainerDashboard>
    </WrapDashboard>
  );
};

export default DashboardTemplate;
