import { connect } from 'react-redux';
import { fetchComoFunciona } from '../../../store/web/home';
import ComoFunciona from './ComoFunciona';

const mapStateToProps = (state) => {
  return {
    comoFunciona: state.homeState.comoFunciona,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchComoFunciona: (data) => dispatch(fetchComoFunciona(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComoFunciona);
