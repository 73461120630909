import deepFreez from './deep-freeze'

const CurrenciesSymbol = {
  PEN: {
    symbol: 'S/',
    spanishName: 'SOLES'
  },
  USD: {
    symbol: '$',
    spanishName: 'DÓLARES'
  }
};

export default deepFreez(CurrenciesSymbol)
