import { SHOW_API_ERROR, DISMISS_API_ERROR } from './types';

const INITIAL_STATE = {
  showApiError: false,
};

export const uiReducer = ((reducers) => (state = INITIAL_STATE, action) => {
  if (reducers[action.type]) return { state, ...reducers[action.type](state, action) };
  return state;
})({
  [SHOW_API_ERROR]: () => ({ showApiError: true }),
  [DISMISS_API_ERROR]: () => ({ showApiError: false }),
});
