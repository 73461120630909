import React, { useRef, useEffect, useState } from 'react';
import {
  CntButton,
  Button,
  IconButton,
  IconButtonContainer,
  TextError,
  WrapError,
} from '../../theme/global';
import { TabsContainer, TabItem } from '../NavTabs';
import { Teeny, Typography, Strong, Title } from '../Text';
import Input from '../InputRounded';
import { withFormik, Form, ErrorMessage, Field } from 'formik';
import withProps from 'recompose/withProps';
import compose from 'recompose/compose';
// import { IonSelect, IonSelectOption } from '@ionic/react';
import { WrapSelectItem, SelectItem } from '../OperationForm/styles';
import PopUp from '../Popup';
import Alert from '../Alert';
import { WrapPage } from '../../theme/shared';
const FormNewBankAccount = ({
  asStep,
  getNewAccountBank,
  desktop,
  history,
  newAccount,
  newAccountBank,
  requestCleanProcessRegister,
  generalInfo,
  getCleanNewAccountBank,
  values,
  resetForm,
}) => {
  const popupRef = useRef(null);
  const [numAccount, setnumAccount] = useState(0);
  const [typeAccount, setTypeAccount] = useState('all');
  const goMessage = () => {
    history.push('/registration-completed');
  };
  const textContinue = 'Podra agregar, mas cuentas desde su perfil';
  const textAdd = 'Debe agregar minimo dos cuentas, una en soles y otra en dólares';
  function validateNumber(event) {
    var key = event.which;
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }
  useEffect(() => {
    if (!newAccount) {
      history.push('/simulador');
    }
    if (newAccountBank && newAccountBank.status === 200) {
      setnumAccount(numAccount + 1);
      setTypeAccount(values.currencyType);
      getCleanNewAccountBank();
      resetForm();
      popupRef.current.open();

      return;
    }
  }, [
    newAccountBank,
    values,
    numAccount,
    newAccount,
    resetForm,
    requestCleanProcessRegister,
    history,
    getCleanNewAccountBank,
  ]);
  return (
    <>
      <WrapPage
        className={`${desktop || ''} add-bank-account`}
        padding={!asStep && '36px 0 0'}
      >
        <Form>
          <div className='inner-page'>
            <div>
              <TabsContainer>
                <TabItem className='strong'>
                  Paso <Teeny color='primary'>01</Teeny>
                </TabItem>
                <TabItem>
                  Paso <Teeny color='primary'>02</Teeny>
                </TabItem>
                <TabItem className='active'>
                  Paso <Teeny color='primary'>03</Teeny>
                </TabItem>
              </TabsContainer>

              <Title>Datos de Bancarios</Title>
              <main style={{ display: 'flex', flexDirection: 'column' }}>
                <Input
                  onKeyPress={(event) => {
                    validateNumber(event);
                  }}
                  type='tel'
                  name='accountNumber'
                  placeholder='Numero de Cuenta*'
                />
                <ErrorMessage name='accountNumber'>
                  {(message) => (
                    <WrapError>
                      <TextError>{message}</TextError>
                    </WrapError>
                  )}
                </ErrorMessage>

                <>
                  {generalInfo && (
                    <>
                      <WrapSelectItem>
                        <SelectItem>
                          <Field component='select' name='bankingEntity'>
                            <option value=''>Entidad Bancaria*</option>
                            {generalInfo.banks.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='bankingEntity'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>

                      <WrapSelectItem>
                        <SelectItem>
                          <Field component='select' name='accountType'>
                            <option value=''>Tipo de Cuenta</option>
                            {generalInfo.account_type.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='accountType'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                      <WrapSelectItem>
                        <SelectItem>
                          <Field component='select' name='currencyType'>
                            <option value=''>Tipo de Moneda</option>
                            {generalInfo.currency.map((item, key) => {
                              if (typeAccount === 'all') {
                                return (
                                  <option key={key} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              }
                              if (typeAccount !== `${item.id}`) {
                                return (
                                  <option key={key} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              }
                              return (
                                <option key={key} className='option-no-data' value=''>
                                  No hay data
                                </option>
                              );
                            })}
                          </Field>
                        </SelectItem>
                        <ErrorMessage name='currencyType'>
                          {(message) => (
                            <WrapError>
                              <TextError>{message}</TextError>
                            </WrapError>
                          )}
                        </ErrorMessage>
                      </WrapSelectItem>
                    </>
                  )}
                </>

                <Typography
                  color='primary'
                  center
                  size='13px'
                  font='quaternary'
                  style={{ marginTop: 8 }}
                  className='required-fields'
                >
                  *Todos lo <Strong secondary>Campos son Obligatorios</Strong>
                </Typography>
              </main>
            </div>

            {asStep && (
              <CntButton center>
                <IconButtonContainer margin='30px 0'>
                  <IconButton round>+</IconButton>
                </IconButtonContainer>
              </CntButton>
            )}
            <CntButton center>
              <Button type='submit' round>
                Agregar Cuenta
              </Button>
            </CntButton>
            {newAccountBank && (
              <CntButton center style={{ marginTop: `10px` }}>
                <Button type='button' onClick={() => goMessage()} round>
                  Terminar Registro
                </Button>
              </CntButton>
            )}
          </div>
        </Form>
        <PopUp ref={popupRef} onClose={() => {}}>
          <Alert
            icon='success'
            title={`Ha Agregado una cuenta en ${
              typeAccount === '481' ? 'Dólares' : 'Soles'
            }  `}
            subtitle={`${numAccount >= 2 ? textContinue : textAdd}`}
          />
          {numAccount >= 2 && (
            <CntButton center style={{ marginTop: `10px` }}>
              <Button
                className='redirect'
                type='button'
                onClick={() => goMessage()}
                round
              >
                Terminar Registro
              </Button>
            </CntButton>
          )}
        </PopUp>
      </WrapPage>
    </>
  );
};

const enhacer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      accountNumber: '',
      bankingEntity: '',
      accountType: '',
      currencyType: '',
    }),
    handleSubmit(
      values,
      { setSubmitting, resetForm, props: { getNewAccountBank, newAccount, user } }
    ) {
      setSubmitting(false);
      const dataAccountBank = {
        client_id: newAccount.id,
        numero_cuenta_banco: values.accountNumber,
        entidad_bancaria: values.bankingEntity,
        tipo_cuenta: values.accountType,
        tipo_moneda: values.currencyType,
      };
      getNewAccountBank(dataAccountBank);
      // resetForm();
    },
    validate(values) {
      const errors = {};

      if (!values.accountNumber) {
        errors.accountNumber = 'Campo Obligatorio';
      } else if (values.accountNumber.length < 8) {
        errors.accountNumber = 'El número de cuenta debe tener minimo 8 caracteres';
      }

      if (!values.bankingEntity) {
        errors.bankingEntity = 'Campo Obligatorio';
      }

      if (!values.accountType) {
        errors.accountType = 'Campo Obligatorio';
      }

      if (!values.currencyType) {
        errors.currencyType = 'Campo Obligatorio';
      }

      return errors;
    },
  }),
  withProps(({ handleSubmit, values, ...p }) => ({
    onSubmit: () => handleSubmit(values, p),
  }))
);
export default enhacer(FormNewBankAccount);
