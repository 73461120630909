import React from 'react';
import MessageTemplate from '../../components/web/Message/MessageTemplate';
import { WrapPage } from '../../theme/shared';
const Message = ({ desktop }) => {
  return (
    <WrapPage className={`${desktop || ''} web-wrap`}>
      <MessageTemplate
        title='Formulario Enviado'
        description='Gracias por Escribirnos, pronto nos comunicaremos con usted.'
      />
    </WrapPage>
  );
};

export default Message;
