import React from 'react';
import { Wrapp, EnvolverImg, InfoMessage } from './style';

const MessageTemplate = ({ title, description }) => {
  return (
    <Wrapp className='wrap-message-register'>
      <EnvolverImg>
        <figure>
          <img src='/assets/images/img-message.png' alt='' />
        </figure>
      </EnvolverImg>
      <InfoMessage>
        <h2>{title}</h2>
        <p>{description}</p>
      </InfoMessage>
    </Wrapp>
  );
};

export default MessageTemplate;
