import styled from 'styled-components/macro';
import { fonts } from '../../../theme/web/theme';
export const WrapOperations = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  width: 100%;
  margin: 0;
  padding: 14px 20px 20px 20px;
  box-sizing: border-box;
`;
export const TextAviso = styled.h2`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #717171;
  opacity: 0.7;
  font-family: ${fonts.lato400};
  width: 100%;
  box-sizing: border-box;
  padding: 19px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
`;

export const WrapBtns = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const LinkBtn = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 36px;
  line-height: 36px;
  color: #717171;
  width: 47%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 5px;
  text-align: center;
  &.active {
    color: white;
    background-color: #3aa229;
  }
  @media (min-width: 1025px) {
    transition: all 0.2s ease-in;
    &:hover {
      color: white;
      background-color: #3aa229;
      cursor: pointer;
    }
  }
`;

export const InputOperations = styled.div`
  position: relative;
  padding-bottom: 11px;
  padding-top: 10px;
  input {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    height: 38px;
    width: 100%;
    font-size: 12px;
    border: none;
    padding: 0 30px 0px 20px;
    box-sizing: border-box;
    color: #717171;
    font-family: ${fonts.lato400};
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    display: inline-block;
    width: 14px;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
`;

export const WrappHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  width: 100%;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
`;

export const HeadTitle = styled.div`
  border-radius: 5px;
  display: flex;
  flex-wrap: wrapp;
  align-items: center;
  width: 50%;
  span {
    font-family: ${fonts.lato400};
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.05em;
    color: #717171;
  }
  strong {
    color: #3aa229;
    font-family: ${fonts.lato800};
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.03em;
    padding: 0;
    padding-right: 10px;
  }
  & > * {
    width: 50%;
    box-sizing: border-box;
    padding: 0 15px;
    position: relative;
    &:firts-child {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: red;
      margin: auto;
      width: 1px;
      height: 25px;
    }
  }
`;

export const SelectElement = styled.div`
  position: relative;
  select {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    width: 100%;
    border: none;
    height: 36px;
    padding: 0 20px;
    color: #717171;
    font-size: 12px;
    font-family: ${fonts.lato400};
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
    &:focus {
      background-color: #3aa229;
      color: #ffffff;
    }
    &:focus + span {
      border-color: #ffffff transparent transparent transparent;
    }
  }
  span {
    width: 0;
    height: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    border-radius: 1.5px;
    border-style: solid;
    border-width: 7px 4.5px 0 4.5px;
    border-color: #717171 transparent transparent transparent;
  }
`;

export const SelectOperations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 13px;
  & > * {
    width: calc(100% / 3 - 2%);
  }
`;

export const WrapFather = styled.div`
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  height: 400px;
  overflow: auto;

  .parent {
    margin-bottom: 12px;
    border: 1px solid #eee;
    border-radius: 6px;
  }
  ${WrappHead} {
    &.active {
      cursor: pointer;
      background-color: #3aa229;
      ${HeadTitle} {
        span,
        strong {
          color: #ffffff;
        }
      }
    }
    @media (min-width: 1025px) {
      &:hover {
        cursor: pointer;
        background-color: #3aa229;
        ${HeadTitle} {
          span,
          strong {
            color: #ffffff;
          }
        }
      }
    }
  }
`;
export const ItemInner = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  & > * {
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  &:before {
    content: '';
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: auto;
    display: inline-block;
    background-color: #ffffff;
  }
  &:last-child {
    border: none;
    &:before {
      display: none;
    }
  }
`;

export const BoxText = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  p {
    margin: 0;
    font-family: ${fonts.lato400};
    font-size: 12px;
  }
  strong {
    color: #3aa229;
    font-size: 13px;
    font-family: ${fonts.lato800};
  }
  &.changeStyle {
    p {
      margin: 0;
      font-family: ${fonts.lato400};
      font-size: 12px;
    }
    strong {
      color: #3aa229;
      font-size: 11px;
      font-family: ${fonts.lato800};
    }
  }
  &:last-child {
    border: none;
  }
`;
