import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  WrapClientes,
  InputClientes,
  ClientesDatos,
  TitleClientes,
  WrapClientesScroll,
} from './styles';

import { fetchDashboard, getClearSingleOperation, } from '../../../store/web/dashboard';

const DashboardCliente = ({
  singleDetailOperation,
  searchClients,
  fetchDashboard,
  getClearSingleOperation,
  getIdClientCurrent,
  isActiveButton,
  userDashboard,
}) => {
  const [getInfoIdClients, setGetInfoIdClients] = useState([]);
  const [clearInput, setClearInput] = useState('');
  const getId = singleDetailOperation ? singleDetailOperation[0] : null;

  useEffect(() => {
    if (singleDetailOperation) {
      const infoClients = [
        {
          status: '10',
          id: singleDetailOperation ? getId.client_info.id : '',
          name: singleDetailOperation ? getId.client_info.name : '',
          dni: singleDetailOperation ? getId.client_info.dni : '',
          email: singleDetailOperation ? getId.client_info.email : '',
          phone_number: singleDetailOperation ? getId.client_info.phone_number : '',
        },
      ];
      setGetInfoIdClients(infoClients);
      return;
    }
  }, [getId, singleDetailOperation]);

  const handleSearch = (e) => {
    if (e.target.value.trim() === '') {
      setGetInfoIdClients([]);
      return;
    }
    if (e.target.value.length >= 2 && searchClients) {
      const result = searchClients.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setGetInfoIdClients(result);
    }
  };

  const getClientIdStatus = (id, getStatus, event) => {
    const token = userDashboard.info.token;

    fetchDashboard(getStatus, id, token);
    getIdClientCurrent(id);

    isActiveButton(10);

    const buttonClients = document.querySelectorAll('.wrap_clients');
    for (let i = 0; i < buttonClients.length; i++) {
      buttonClients[i].classList.remove('showButton');
    }
    event.currentTarget.closest('.wrap_clients').classList.add('showButton');
  };

  const clearCurrentUser = (e) => {
    const token2 = userDashboard.info.token;

    fetchDashboard('10', null, token2);
    setGetInfoIdClients([]);
    setClearInput('');
    isActiveButton(10);
    getIdClientCurrent(null);
  };

  return (
    <WrapClientes>
      <TitleClientes>Clientes</TitleClientes>
      <InputClientes>
        <h1>{clearInput}</h1>
        <input
          onChange={(e) => {
            handleSearch(e);
          }}
          type='text'
          defaultValue={clearInput}
          placeholder='Buscar Usuarios'
          name='buscar'
          autoComplete='off'
        />
        <span>
          <img src='/static/image/searchimg.png' width='14' height='' alt='' />
        </span>
      </InputClientes>
      <div className='wrap_items'>
        <WrapClientesScroll>
          {getInfoIdClients &&
            getInfoIdClients.length >= 1 &&
            getInfoIdClients.map((item, keys) => (
              <div
                data-client={item.id}
                className='wrap_clients'
                key={keys}
                style={{ position: `relative` }}
              >
                <ClientesDatos
                  onClick={(event) => {
                    getClearSingleOperation();
                    getClientIdStatus(item.id, '10', event);
                  }}
                >
                  <table>
                    <tbody>
                      <tr>
                        <td width='150'>
                          <strong>{item.name}</strong>
                          <span>{item.email}</span>
                        </td>
                        <td width='150'>
                          <strong>DNI: {item.dni}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>{item.phone_number}</span>
                        </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </table>
                </ClientesDatos>
                <button
                  type='button'
                  onClick={() => {
                    getClearSingleOperation();
                    clearCurrentUser();
                  }}
                >
                  <img src='/static/image/arrow-1.svg' width='14' height='' alt='' />
                </button>
              </div>
            ))}
        </WrapClientesScroll>
      </div>
    </WrapClientes>
  );
};

const mapStateToProps = (state) => {
  return {
    singleDetailOperation: state.dashboardState.singleDetailOperation,
    userDashboard: state.userDashboardState.userDashboard,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchDashboard: (id, status, token) => dispatch(fetchDashboard(id, status, token)),
  getClearSingleOperation: () => dispatch(getClearSingleOperation()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardCliente);
